export const GET_ID_PROOF_REQUEST = "GET_ID_PROOF_REQUEST";
export const GET_ID_PROOF_SUCCESS = "GET_ID_PROOF_SUCCESS";
export const GET_ID_PROOF_FAILURE = "GET_ID_PROOF_FAILURE";

export const ADD_ID_PROOF_REQUEST = "ADD_ID_PROOF_REQUEST";
export const ADD_ID_PROOF_SUCCESS = "ADD_ID_PROOF_SUCCESS";
export const ADD_ID_PROOF_FAILURE = "ADD_ID_PROOF_FAILURE";

export const UPDATE_ID_PROOF_REQUEST = "UPDATE_ID_PROOF_REQUEST";
export const UPDATE_ID_PROOF_SUCCESS = "UPDATE_ID_PROOF_SUCCESS";
export const UPDATE_ID_PROOF_FAILURE = "UPDATE_ID_PROOF_FAILURE";

export const GET_IDPROOF_SEARCH_REQUEST="GET_IDPROOF_SEARCH_REQUEST";
export const GET_IDPROOF_SEARCH_SUCCESS="GET_IDPROOF_SEARCH_SUCCESS";
export const GET_IDPROOF_SEARCH_FAILURE="GET_IDPROOF_SEARCH_FAILURE";

export const REMOVE_ID_PROOF_REQUEST = "REMOVE_ID_PROOF_REQUEST";
export const REMOVE_ID_PROOF_SUCCESS = "REMOVE_ID_PROOF_SUCCESS";
export const REMOVE_ID_PROOF_FAILURE = "REMOVE_ID_PROOF_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_ID_PROOF = "HANDLE_CLAER_REDUCER_DATA_ID_PROOF";

export const GET_ID_PROOF_COUNT_REQUEST = "GET_ID_PROOF_COUNT_REQUEST";
export const GET_ID_PROOF_COUNT_SUCCESS = "GET_ID_PROOF_COUNT_SUCCESS";
export const GET_ID_PROOF_COUNT_FAILURE = "GET_ID_PROOF_COUNT_FAILURE";

