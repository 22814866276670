export const GET_DESIGNATIONS_REQUEST = "GET_DESIGNATIONS_REQUEST";
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS";
export const GET_DESIGNATIONS_FAILURE = "GET_DESIGNATIONS_FAILURE";

export const ADD_DESIGNATIONS_REQUEST = "ADD_DESIGNATIONS_REQUEST";
export const ADD_DESIGNATIONS_SUCCESS = "ADD_DESIGNATIONS_SUCCESS";
export const ADD_DESIGNATIONS_FAILURE = "ADD_DESIGNATIONS_FAILURE";

export const REMOVE_DESIGNATIONS_REQUEST = "REMOVE_DESIGNATIONS_REQUEST";
export const REMOVE_DESIGNATIONS_SUCCESS = "REMOVE_DESIGNATIONS_SUCCESS";
export const REMOVE_DESIGNATIONS_FAILURE = "REMOVE_DESIGNATIONS_FAILURE";

export const UPDATE_DESIGNATIONS_REQUEST = "UPDATE_DESIGNATIONS_REQUEST";
export const UPDATE_DESIGNATIONS_SUCCESS = "UPDATE_DESIGNATIONS_SUCCESS";
export const UPDATE_DESIGNATIONS_FAILURE = "UPDATE_DESIGNATIONS_FAILURE";

export const GET_DESIGNATION_SEARCH_REQUEST="GET_DESIGNATION_SEARCH_REQUEST";
export const GET_DESIGNATION_SEARCH_SUCCESS="GET_DESIGNATION_SEARCH_SUCCESS";
export const GET_DESIGNATION_SEARCH_FAILURE="GET_DESIGNATION_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_DESIGNATION = "HANDLE_CLAER_REDUCER_DATA_DESIGNATION";

export const GET_DESIGNATION_COUNT_REQUEST = "GET_DESIGNATION_COUNT_REQUEST";
export const GET_DESIGNATION_COUNT_SUCCESS = "GET_DESIGNATION_COUNT_SUCCESS";
export const GET_DESIGNATION_COUNT_FAILURE = "GET_DESIGNATION_COUNT_FAILURE";

