export const ADD_PROGRAM_REQUEST = "ADD_PROGRAM_REQUEST";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const ADD_PROGRAM_FAILURE = "ADD_PROGRAM_FAILURE";

export const HANDLE_PROGRAM_MODAL = "HANDLE_PROGRAM_MODAL";

export const SET_PROGRAM_VIEW_TYPE = "SET_PROGRAM_VIEW_TYPE";

export const GET_PROGRAM_REQUEST = "GET_PROGRAM_REQUEST";
export const GET_PROGRAM_SUCCESS = "GET_PROGRAM_SUCCESS";
export const GET_PROGRAM_FAILURE = "GET_PROGRAM_FAILURE";


export const GET_PROGRAM_DETAILS_BY_ID_REQUEST =
  "GET_PROGRAM_DETAILS_BY_ID_REQUEST";
export const GET_PROGRAM_DETAILS_BY_ID_SUCCESS =
  "GET_PROGRAM_DETAILS_BY_ID_SUCCESS";
export const GET_PROGRAM_DETAILS_BY_ID_FAILURE =
  "GET_PROGRAM_DETAILS_BY_ID_FAILURE";
