export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";

export const ADD_DEPARTMENTS_REQUEST = "ADD_DEPARTMENTS_REQUEST";
export const ADD_DEPARTMENTS_SUCCESS = "ADD_DEPARTMENTS_SUCCESS";
export const ADD_DEPARTMENTS_FAILURE = "ADD_DEPARTMENTS_FAILURE";

export const REMOVE_DEPARTMENTS_REQUEST = "REMOVE_DEPARTMENTS_REQUEST";
export const REMOVE_DEPARTMENTS_SUCCESS = "REMOVE_DEPARTMENTS_SUCCESS";
export const REMOVE_DEPARTMENTS_FAILURE = "REMOVE_DEPARTMENTS_FAILURE";

export const UPDATE_DEPARTMENTS_REQUEST = "UPDATE_DEPARTMENTS_REQUEST";
export const UPDATE_DEPARTMENTS_SUCCESS = "UPDATE_DEPARTMENTS_SUCCESS";
export const UPDATE_DEPARTMENTS_FAILURE = "UPDATE_DEPARTMENTS_FAILURE";

export const GET_DEPARTMENT_SEARCH_REQUEST="GET_DEPARTMENT_SEARCH_REQUEST";
export const GET_DEPARTMENT_SEARCH_SUCCESS="GET_DEPARTMENT_SEARCH_SUCCESS";
export const GET_DEPARTMENT_SEARCH_FAILURE="GET_DEPARTMENT_SEARCH_FAILURE";

export const LINK_DEPARTMENT_DOCUMENT_TOGGLE_REQUEST =
  "LINK_DEPARTMENT_DOCUMENT_TOGGLE_REQUEST";
export const LINK_DEPARTMENT_DOCUMENT_TOGGLE_SUCCESS =
  "LINK_DEPARTMENT_DOCUMENT_TOGGLE_SUCCESS";
export const LINK_DEPARTMENT_DOCUMENT_TOGGLE_FAILURE =
  "LINK_DEPARTMENT_DOCUMENT_TOGGLE_FAILURE";


  export const LINK_CRM_TOGGLE_REQUEST =
  "LINK_CRM_TOGGLE_REQUEST";
export const LINK_CRM_TOGGLE_SUCCESS =
  "LINK_CRM_TOGGLE_SUCCESS";
export const LINK_CRM_TOGGLE_FAILURE =
  "LINK_CRM_TOGGLE_FAILURE";

  export const LINK_IM_TOGGLE_REQUEST =
  "LINK_IM_TOGGLE_REQUEST";
export const LINK_IM_TOGGLE_SUCCESS =
  "LINK_IM_TOGGLE_SUCCESS";
export const LINK_IM_TOGGLE_FAILURE =
  "LINK_IM_TOGGLE_FAILURE";

  export const LINK_HR_TOGGLE_REQUEST =
  "LINK_HR_TOGGLE_REQUEST";
export const LINK_HR_TOGGLE_SUCCESS =
  "LINK_HR_TOGGLE_SUCCESS";
export const LINK_HR_TOGGLE_FAILURE =
  "LINK_HR_TOGGLE_FAILURE";

  export const LINK_ACCOUNTING_TOGGLE_REQUEST =
  "LINK_ACCOUNTING_TOGGLE_REQUEST";
export const LINK_ACCOUNTING_TOGGLE_SUCCESS =
  "LINK_ACCOUNTING_TOGGLE_SUCCESS";
export const LINK_ACCOUNTING_TOGGLE_FAILURE =
  "LINK_ACCOUNTING_TOGGLE_FAILURE";

  export const LINK_ERP_TOGGLE_REQUEST =
  "LINK_ERP_TOGGLE_REQUEST";
export const LINK_ERP_TOGGLE_SUCCESS =
  "LINK_ERP_TOGGLE_SUCCESS";
export const LINK_ERP_TOGGLE_FAILURE =
  "LINK_ERP_TOGGLE_FAILURE";

  export const LINK_RECRUIT_TOGGLE_REQUEST =
  "LINK_RECRUIT_TOGGLE_REQUEST";
export const LINK_RECRUIT_TOGGLE_SUCCESS =
  "LINK_RECRUIT_TOGGLE_SUCCESS";
export const LINK_RECRUIT_TOGGLE_FAILURE =
  "LINK_RECRUIT_TOGGLE_FAILURE";

  export const LINK_ELEARNING_TOGGLE_REQUEST =
  "LINK_ELEARNING_TOGGLE_REQUEST";
export const LINK_ELEARNING_TOGGLE_SUCCESS =
  "LINK_ELEARNING_TOGGLE_SUCCESS";
export const LINK_ELEARNING_TOGGLE_FAILURE =
  "LINK_ELEARNING_TOGGLE_FAILURE";


  export const HANDLE_CLAER_REDUCER_DATA_DEPARTMENT = "HANDLE_CLAER_REDUCER_DATA_DEPARTMENT";

  export const ADDING_MODULE_REQUEST =
  "ADDING_MODULE_REQUEST";
export const ADDING_MODULE_SUCCESS =
  "ADDING_MODULE_SUCCESS";
export const ADDING_MODULE_FAILURE =
  "ADDING_MODULE_FAILURE";

  export const GET_DEPARTMENT_COUNT_REQUEST = "GET_DEPARTMENT_COUNT_REQUEST";
export const GET_DEPARTMENT_COUNT_SUCCESS = "GET_DEPARTMENT_COUNT_SUCCESS";
export const GET_DEPARTMENT_COUNT_FAILURE = "GET_DEPARTMENT_COUNT_FAILURE";


