export const HANDLE_PARTNER_MODAL = "HANDLE_PARTNER_MODAL";

export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";
//get Partners
export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";


export const EMPTY_PARTNER_TABLE = "EMPTY_PARTNER_TABLE";



export const GET_PARTNER_PAGINATION_REQUEST = "GET_PARTNER_PAGINATION_REQUEST";
export const GET_PARTNER_PAGINATION_SUCCESS = "GET_PARTNER_PAGINATION_SUCCESS";
export const GET_PARTNER_PAGINATION_FAILURE = "GET_PARTNER_PAGINATION_FAILURE";

//contact of the partner
export const GET_PARTNER_CONTACT_REQUEST = "GET_PARTNER_CONTACT_REQUEST";
export const GET_PARTNER_CONTACT_SUCCESS = "GET_PARTNER_CONTACT_SUCCESS";
export const GET_PARTNER_CONTACT_FAILURE = "GET_PARTNER_CONTACT_FAILURE";

export const ADD_PARTNER_NOTES_REQUEST = "ADD_PARTNER_NOTES_REQUEST";
export const ADD_PARTNER_NOTES_SUCCESS = "ADD_PARTNER_NOTES_SUCCESS";
export const ADD_PARTNER_NOTES_FAILURE = "ADD_PARTNER_NOTES_FAILURE";

//document
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const ADD_PARTNER_DOCUMENT_REQUEST = "ADD_PARTNER_DOCUMENT_REQUEST";
export const ADD_PARTNER_DOCUMENT_SUCCESS = "ADD_PARTNER_DOCUMENT_SUCCESS";
export const ADD_PARTNER_DOCUMENT_FAILURE = "ADD_PARTNER_DOCUMENT_FAILURE";

export const GET_PARTNER_DOCUMENTS_REQUEST = "GET_PARTNER_DOCUMENTS_REQUEST";
export const GET_PARTNER_DOCUMENTS_SUCCESS = "GET_PARTNER_DOCUMENTS_SUCCESS";
export const GET_PARTNER_DOCUMENTS_FAILURE = "GET_PARTNER_DOCUMENTS_FAILURE";

/*Partner notes*/
export const GET_NOTES_LIST_BY_PARTNER_ID_REQUEST =
  "GET_NOTES_LIST_BY_PARTNER_ID_REQUEST";
export const GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_PARTNER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_PARTNER_ID_FAILURE =
  "GET_NOTES_LIST_BY_PARTNER_ID_FAILURE";

// get opportunity

export const GET_PARTNER_OPPORTUNITY_REQUEST =
  "GET_PARTNER_OPPORTUNITY_REQUEST";
export const GET_PARTNER_OPPORTUNITY_SUCCESS =
  "GET_PARTNER_OPPORTUNITY_SUCCESS";
export const GET_PARTNER_OPPORTUNITY_FAILURE =
  "GET_PARTNER_OPPORTUNITY_FAILURE";

//get partner details
export const GET_PARTNER_DETAILS_BY_ID_REQUEST =
  "GET_PARTNER_DETAILS_BY_ID_REQUEST";
export const GET_PARTNER_DETAILS_BY_ID_SUCCESS =
  "GET_PARTNER_DETAILS_BY_ID_SUCCESS";
export const GET_PARTNER_DETAILS_BY_ID_FAILURE =
  "GET_PARTNER_DETAILS_BY_ID_FAILURE";

//contactTab
export const HANDLE_PARTNER_CONTACT_MODAL = "HANDLE_PARTNER_CONTACT_MODAL";
//add contact
export const ADD_PARTNER_CONTACT_REQUEST = "ADD_PARTNER_CONTACT_REQUEST";
export const ADD_PARTNER_CONTACT_SUCCESS = "ADD_PARTNER_CONTACT_SUCCESS";
export const ADD_PARTNER_CONTACT_FAILURE = "ADD_PARTNER_CONTACT_FAILURE";
//add opportunity
export const HANDLE_PARTNER_OPPORTUNITY_MODAL =
  "HANDLE_PARTNER_OPPORTUNITY_MODAL";

export const ADD_PARTNER_OPPORTUNITY_REQUEST =
  "ADD_PARTNER_OPPORTUNITY_REQUEST";
export const ADD_PARTNER_OPPORTUNITY_SUCCESS =
  "ADD_PARTNER_OPPORTUNITY_SUCCESS";
export const ADD_PARTNER_OPPORTUNITY_FAILURE =
  "ADD_PARTNER_OPPORTUNITY_FAILURE";

//partnerskills
export const ADD_TOPIC_BY_PARTNER_ID_REQUEST =
  "ADD_TOPIC_BY_PARTNER_ID_REQUEST";
export const ADD_TOPIC_BY_PARTNER_ID_SUCCESS =
  "ADD_TOPIC_BY_PARTNER_ID_SUCCESS";
export const ADD_TOPIC_BY_PARTNER_ID_FAILURE =
  "ADD_TOPIC_BY_PARTNER_ID_FAILURE";

export const GET_TOPICS_BY_PARTNER_ID_REQUEST =
  "GET_TOPICS_BY_PARTNER_ID_REQUEST";
export const GET_TOPICS_BY_PARTNER_ID_SUCCESS =
  "GET_TOPICS_BY_PARTNER_ID_SUCCESS";
export const GET_TOPICS_BY_PARTNER_ID_FAILURE =
  "GET_TOPICS_BY_PARTNER_ID_FAILURE";

export const DELETE_TOPIC_BY_PARTNER_ID_REQUEST =
  "DELETE_TOPIC_BY_PARTNER_ID_REQUEST";
export const DELETE_TOPIC_BY_PARTNER_ID_SUCCESS =
  "DELETE_TOPIC_BY_PARTNER_ID_SUCCESS";
export const DELETE_TOPIC_BY_PARTNER_ID_FAILURE =
  "DELETE_TOPIC_BY_PARTNER_ID_FAILURE";


export const HANDLE_UPDATE_PARTNER_MODAL = "HANDLE_UPDATE_PARTNER_MODAL";

export const SET_PARTNER_EDIT = "SET_PARTNER_EDIT";
export const UPDATE_PARTNER_BY_ID_REQUEST = "UPDATE_PARTNER_BY_ID_REQUEST";
export const UPDATE_PARTNER_BY_ID_SUCCESS = "UPDATE_PARTNER_BY_ID_SUCCESS";
export const UPDATE_PARTNER_BY_ID_FAILURE = "UPDATE_PARTNER_BY_ID_FAILURE";

export const HANDLE_UPDATE_PARTNER_CONTACT_MODAL = "HANDLE_UPDATE_PARTNER_CONTACT_MODAL";
export const SET_PARTNER_CONTACT_EDIT = "SET_PARTNER_CONTACT_EDIT";

export const UPDATE_PARTNER_CONTACT_BY_ID_REQUEST = "UPDATE_PARTNER_CONTACT_BY_ID_REQUEST";
export const UPDATE_PARTNER_CONTACT_BY_ID_SUCCESS = "UPDATE_PARTNER_CONTACT_BY_ID_SUCCESS";
export const UPDATE_PARTNER_CONTACT_BY_ID_FAILURE = "UPDATE_PARTNER_CONTACT_BY_ID_FAILURE";

//search
export const INPUT_PARTNER_SEARCH_DATA_REQUEST =
  "INPUT_PARTNER_SEARCH_DATA_REQUEST";
export const INPUT_PARTNER_SEARCH_DATA_SUCCESS =
  "INPUT_PARTNER_SEARCH_DATA_SUCCESS";
export const INPUT_PARTNER_SEARCH_DATA_FAILURE =
  "INPUT_PARTNER_SEARCH_DATA_FAILURE";

  export const UPDATE_PARTNER_BANK_DETAILS_BY_ID_REQUEST = "UPDATE_PARTNER_BANK_DETAILS_BY_ID_REQUEST";
export const UPDATE_PARTNER_BANK_DETAILS_BY_ID_SUCCESS = "UPDATE_PARTNER_BANK_DETAILS_BY_ID_SUCCESS";
export const UPDATE_PARTNER_BANK_DETAILS_BY_ID_FAILURE = "UPDATE_PARTNER_BANK_DETAILS_BY_ID_FAILURE";

export const UPDATE_PARTNER_ADDRESS = "UPDATE_PARTNER_ADDRESS";
export const ADD_PARTNER_ADDRESS = "ADD_PARTNER_ADDRESS";

export const GET_PERMISSIONS_LIST_REQUEST = "GET_PERMISSIONS_LIST_REQUEST";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";
export const GET_PERMISSIONS_LIST_FAILURE = "GET_PERMISSIONS_LIST_FAILURE";

//partner share
export const ADD_SHARE_PARTNER_PERMISSION_REQUEST = "ADD_SHARE_PARTNER_PERMISSION_REQUEST";
export const ADD_SHARE_PARTNER_PERMISSION_SUCCESS = "ADD_SHARE_PARTNER_PERMISSION_SUCCESS";
export const ADD_SHARE_PARTNER_PERMISSION_FAILURE = "ADD_SHARE_PARTNER_PERMISSION_FAILURE";

export const LINK_PARTNER_STATUS_REQUEST = "LINK_PARTNER_STATUS_REQUEST";
export const LINK_PARTNER_STATUS_SUCCESS = "LINK_PARTNER_STATUS_SUCCESS";
export const LINK_PARTNER_STATUS_FAILURE = "LINK_PARTNER_STATUS_FAILURE";


export const GET_PARTNERS_ALL_REQUEST = "GET_PARTNERS_ALL_REQUEST";
export const GET_PARTNERS_ALL_SUCCESS = "GET_PARTNERS_ALL_SUCCESS";
export const GET_PARTNERS_ALL_FAILURE = "GET_PARTNERS_ALL_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const PUT_PARTNER_CONTACT_TOGGLE_REQUEST="PUT_PARTNER_CONTACT_TOGGLE_REQUEST";
export const PUT_PARTNER_CONTACT_TOGGLE_SUCCESS="PUT_PARTNER_CONTACT_TOGGLE_SUCCESS";
export const PUT_PARTNER_CONTACT_TOGGLE_FAILURE="PUT_PARTNER_CONTACT_TOGGLE_FAILURE";

export const SET_PARTNER_VIEW_TYPE="SET_PARTNER_VIEW_TYPE"; 


//get Partner
export const GET_COMMERCIALS_BY_PARTNER_ID_REQUEST = "GET_COMMERCIALS_BY_PARTNER_ID_REQUEST";
export const GET_COMMERCIALS_BY_PARTNER_ID_SUCCESS = "GET_COMMERCIALS_BY_PARTNER_ID_SUCCESS";
export const GET_COMMERCIALS_BY_PARTNER_ID_FAILURE = "GET_COMMERCIALS_BY_PARTNER_ID_FAILURE";

//Add partner
export const ADD_COMMERCIALS_BY_PARTNER_ID_REQUEST = "ADD_COMMERCIALS_BY_PARTNER_ID_REQUEST";
export const ADD_COMMERCIALS_BY_PARTNER_ID_SUCCESS = "ADD_COMMERCIALS_BY_PARTNER_ID_SUCCESS";
export const ADD_COMMERCIALS_BY_PARTNER_ID_FAILURE = "ADD_COMMERCIALS_BY_PARTNER_ID_FAILURE";

export const UPDATE_PARTNER_OWNERSHIP_REQUEST="UPDATE_PARTNER_OWNERSHIP_REQUEST";
export const UPDATE_PARTNER_OWNERSHIP_SUCCESS="UPDATE_PARTNER_OWNERSHIP_SUCCESS";
export const UPDATE_PARTNER_OWNERSHIP_FAILURE="UPDATE_PARTNER_OWNERSHIP_FAILURE";

// //search
// export const INPUT_PARTNER_SEARCH_REQUEST =
//   "INPUT_PARTNER_SEARCH_REQUEST";
// export const INPUT_PARTNER_SEARCH_SUCCESS =
//   "INPUT_PARTNER_SEARCH_SUCCESS";
// export const INPUT_PARTNER_SEARCH_FAILURE =
//   "INPUT_PARTNER_SEARCH_FAILURE";
export const HANDLE_PARTNER_REACT_SPEECH_MODAL="HANDLE_PARTNER_REACT_SPEECH_MODAL";

export const HANDLE_PARTNER_DRAWER_MODAL = "HANDLE_PARTNER_DRAWER_MODAL";
export const HANDLE_DONOT_CALL_MODAL = "HANDLE_DONOT_CALL_MODAL";

export const HANDLE_PARTNER_EMAIL_DRAWER_MODAL="HANDLE_PARTNER_EMAIL_DRAWER_MODAL";

export const GET_OWN_SALES_LIST_REQUEST = "GET_OWN_SALES_LIST_REQUEST";
export const GET_OWN_SALES_LIST_SUCCESS = "GET_OWN_SALES_LIST_SUCCESS";
export const GET_OWN_SALES_LIST_FAILURE = "GET_OWN_SALES_LIST_FAILURE";