export const HANDLE_LEAVES_MODAL = "HANDLE_LEAVES_MODAL";

export const ADD_LEAVES_REQUEST = "ADD_LEAVES_REQUEST";
export const ADD_LEAVES_SUCCESS = "ADD_LEAVES_SUCCESS";
export const ADD_LEAVES_FAILURE = "ADD_LEAVES_FAILURE";

export const GET_LEAVES_DETAIL_REQUEST = "GET_LEAVES_DETAIL_REQUEST";
export const GET_LEAVES_DETAIL_SUCCESS = "GET_LEAVES_DETAIL_SUCCESS";
export const GET_LEAVES_DETAIL_FAILURE = "GET_LEAVES_DETAIL_FAILURE";

export const GET_LEAVE_LIST_RANGE_BY_USER_ID_REQUEST = "GET_LEAVE_LIST_RANGE_BY_USER_ID_REQUEST";
export const GET_LEAVE_LIST_RANGE_BY_USER_ID_SUCCESS =
    "GET_LEAVE_LIST_RANGE_BY_USER_ID_SUCCESS";
export const GET_LEAVE_LIST_RANGE_BY_USER_ID_FAILURE =
    "GET_LEAVE_LIST_RANGE_BY_USER_ID_FAILURE";


export const GET_LEAVE_LEFT_SIDE_DETAILS_REQUEST = "GET_LEAVE_LEFT_SIDE_DETAILS_REQUEST";
export const GET_LEAVE_LEFT_SIDE_DETAILS_SUCCESS = "GET_LEAVE_LEFT_SIDE_DETAILS_SUCCESS";
export const GET_LEAVE_LEFT_SIDE_DETAILS_FAILURE = "GET_LEAVE_LEFT_SIDE_DETAILS_FAILURE";

export const HANDLE_UPDATE_LEAVE_MODAL="HANDLE_UPDATE_LEAVE_MODAL";

export const HANDLE_STATUS_LEAVE_MODAL="HANDLE_STATUS_LEAVE_MODAL";
export const SET_LEAVE_EDIT="SET_LEAVE_EDIT";

export const UPDATE_LEAVE_REQUEST="UPDATE_LEAVE_REQUEST";
export const UPDATE_LEAVE_SUCCESS="UPDATE_LEAVE_SUCCESS";
export const UPDATE_LEAVE_FAILURE="UPDATE_LEAVE_FAILURE";

export const DELETE_LEAVES_REQUEST="DELETE_LEAVES_REQUEST";
export const DELETE_LEAVES_SUCCESS="DELETE_LEAVES_SUCCESS";
export const DELETE_LEAVES_FAILURE="DELETE_LEAVES_FAILURE";

export const SET_LEAVES_VIEW_TYPE = "SET_LEAVES_VIEW_TYPE";

export const GET_LEAVE_STATUS_BY_LEAVEID_REQUEST = "GET_LEAVE_STATUS_BY_LEAVEID_REQUEST";
export const GET_LEAVE_STATUS_BY_LEAVEID_SUCCESS = "GET_LEAVE_STATUS_BY_LEAVEID_SUCCESS";
export const GET_LEAVE_STATUS_BY_LEAVEID_FAILURE = "GET_LEAVE_STATUS_BY_LEAVEID_FAILURE";

export const HANDLE_LEAVE_NOTE_DRAWER="HANDLE_LEAVE_NOTE_DRAWER";
export const ADD_LEAVE_NOTE_REQUEST="ADD_LEAVE_NOTE_REQUEST";
export const ADD_LEAVE_NOTE_SUCCESS="ADD_LEAVE_NOTE_SUCCESS";
export const ADD_LEAVE_NOTE_FAILURE="ADD_LEAVE_NOTE_FAILURE";
export const GET_LEAVE_NOTES_REQUEST="GET_LEAVE_NOTES_REQUEST";
export const GET_LEAVE_NOTES_SUCCESS="GET_LEAVE_NOTES_SUCCESS";
export const GET_LEAVE_NOTES_FAILURE="GET_LEAVE_NOTES_FAILURE";