export const GET_LOB_REQUEST = "GET_LOB_REQUEST";
export const GET_LOB_SUCCESS = "GET_LOB_SUCCESS";
export const GET_LOB_FAILURE = "GET_LOB_FAILURE";

export const ADD_LOB_REQUEST = "ADD_LOB_REQUEST";
export const ADD_LOB_SUCCESS = "ADD_LOB_SUCCESS";
export const ADD_LOB_FAILURE = "ADD_LOB_FAILURE";

export const REMOVE_LOB_REQUEST = "REMOVE_LOB_REQUEST";
export const REMOVE_LOB_SUCCESS = "REMOVE_LOB_SUCCESS";
export const REMOVE_LOB_FAILURE = "REMOVE_LOB_FAILURE";

export const UPDATE_LOB_REQUEST = "UPDATE_LOB_REQUEST";
export const UPDATE_LOB_SUCCESS = "UPDATE_LOB_SUCCESS";
export const UPDATE_LOB_FAILURE = "UPDATE_LOB_FAILURE";

export const GET_LOB_SEARCH_REQUEST="GET_LOB_SEARCH_REQUEST";
export const GET_LOB_SEARCH_SUCCESS="GET_LOB_SEARCH_SUCCESS";
export const GET_LOB_SEARCH_FAILURE="GET_LOB_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_LOB = "HANDLE_CLAER_REDUCER_DATA_LOB";


export const GET_LOB_COUNT_REQUEST = "GET_LOB_COUNT_REQUEST";
export const GET_LOB_COUNT_SUCCESS = "GET_LOB_COUNT_SUCCESS";
export const GET_LOB_COUNT_FAILURE = "GET_LOB_COUNT_FAILURE";


