// export const GET_SKILLS_CLOUD_REQUEST = "GET_SKILLS_CLOUD_REQUEST";
// export const GET_SKILLS_CLOUD_SUCCESS = "GET_SKILLS_CLOUD_SUCCESS";
// export const GET_SKILLS_CLOUD_FAILURE = "GET_SKILLS_CLOUD_FAILURE";

// export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
//   "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
// export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

// export const GET_ORDER_LIST_BY_ORDER_ID_REQUEST="GET_ORDER_LIST_BY_ORDER_ID_REQUEST";
// export const GET_ORDER_LIST_BY_ORDER_ID_SUCCESS="GET_ORDER_LIST_BY_ORDER_ID_SUCCESS";
// export const GET_ORDER_LIST_BY_ORDER_ID_FAILURE="GET_ORDER_LIST_BY_ORDER_ID_FAILURE";

export const SET_PUBLISH_VIEW_TYPE="SET_PUBLISH_VIEW_TYPE";

// export const GET_DATE_WISE_REPORT_REQUEST = "GET_DATE_WISE_REPORT_REQUEST";
// export const GET_DATE_WISE_REPORT_SUCCESS = "GET_DATE_WISE_REPORT_SUCCESS";
// export const GET_DATE_WISE_REPORT_FAILURE = "GET_DATE_WISE_REPORT_FAILURE";

// export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";

// export const SET_SUB_SELECTED_REPORT_TYPE = "SET_SUB_SELECTED_REPORT_TYPE";


export const GET_PUBLISH_TABLE_REQUEST = "GET_PUBLISH_TABLE_REQUEST";
export const GET_PUBLISH_TABLE_SUCCESS = "GET_PUBLISH_TABLE_SUCCESS";
export const GET_PUBLISH_TABLE_FAILURE = "GET_PUBLISH_TABLE_FAILURE";

// export const GET_DASHBOARD_TABLE2_REQUEST = "GET_DASHBOARD_TABLE2_REQUEST";
// export const GET_DASHBOARD_TABLE2_SUCCESS = "GET_DASHBOARD_TABLE2_SUCCESS";
// export const GET_DASHBOARD_TABLE2_FAILURE = "GET_DASHBOARD_TABLE2_FAILURE";

export const LINK_PUBLISH_STATUS_REQUEST = "LINK_PUBLISH_STATUS_REQUEST";
export const LINK_PUBLISH_STATUS_SUCCESS = "LINK_PUBLISH_STATUS_SUCCESS";
export const LINK_PUBLISH_STATUS_FAILURE = "LINK_PUBLISH_STATUS_FAILURE";

