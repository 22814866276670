export const GET_FUNCTION_REQUEST = "GET_FUNCTION_REQUEST";
export const GET_FUNCTION_SUCCESS = "GET_FUNCTION_SUCCESS";
export const GET_FUNCTION_FAILURE = "GET_FUNCTION_FAILURE";


export const ADD_FUNCTION_REQUEST = "ADD_FUNCTION_REQUEST";
export const ADD_FUNCTION_SUCCESS = "ADD_FUNCTION_SUCCESS";
export const ADD_FUNCTION_FAILURE = "ADD_FUNCTION_FAILURE";

export const UPDATE_FUNCTION_REQUEST="UPDATE_FUNCTION_REQUEST";
export const UPDATE_FUNCTION_SUCCESS="UPDATE_FUNCTION_SUCCESS";
export const UPDATE_FUNCTION_FAILURE="UPDATE_FUNCTION_FAILURE";