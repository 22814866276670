export const GET_EQUIPMENT_REQUEST = "GET_EQUIPMENT_REQUEST";
export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS";
export const GET_EQUIPMENT_FAILURE = "GET_EQUIPMENT_FAILURE";

export const ADD_EQUIPMENT_REQUEST = "ADD_EQUIPMENT_REQUEST";
export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS";
export const ADD_EQUIPMENT_FAILURE = "ADD_EQUIPMENT_FAILURE";

export const REMOVE_EQUIPMENT_REQUEST = "REMOVE_EQUIPMENT_REQUEST";
export const REMOVE_EQUIPMENT_SUCCESS = "REMOVE_EQUIPMENT_SUCCESS";
export const REMOVE_EQUIPMENT_FAILURE = "REMOVE_EQUIPMENT_FAILURE";

export const UPDATE_EQUIPMENT_REQUEST = "UPDATE_EQUIPMENT_REQUEST";
export const UPDATE_EQUIPMENT_SUCCESS = "UPDATE_EQUIPMENT_SUCCESS";
export const UPDATE_EQUIPMENT_FAILURE = "UPDATE_EQUIPMENT_FAILURE";

export const GET_EQUIPMENT_SEARCH_REQUEST="GET_EQUIPMENT_SEARCH_REQUEST";
export const GET_EQUIPMENT_SEARCH_SUCCESS="GET_EQUIPMENT_SEARCH_SUCCESS";
export const GET_EQUIPMENT_SEARCH_FAILURE="GET_EQUIPMENT_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_EQUIPMENT = "HANDLE_CLAER_REDUCER_DATA_EQUIPMENT";

export const GET_EQUIPMENT_COUNT_REQUEST = "GET_EQUIPMENT_COUNT_REQUEST";
export const GET_EQUIPMENT_COUNT_SUCCESS = "GET_EQUIPMENT_COUNT_SUCCESS";
export const GET_EQUIPMENT_COUNT_FAILURE = "GET_EQUIPMENT_COUNT_FAILURE";

