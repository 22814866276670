export const HANDLE_CREATE_PRODUCTION_DRAWER = "HANDLE_CREATE_PRODUCTION_DRAWER";

export const SET_PRODUCTION_VIEW_TYPE = "SET_PRODUCTION_VIEW_TYPE";

export const CREATE_PRODUCTION_LINK_REQUEST = "CREATE_PRODUCTION_LINK_REQUEST";
export const CREATE_PRODUCTION_LINK_SUCCESS = "CREATE_PRODUCTION_LINK_SUCCESS";
export const CREATE_PRODUCTION_LINK_FAILURE = "CREATE_PRODUCTION_LINK_FAILURE";



export const ADD_SPARE_NOTES_DRAWER_MODAL = "ADD_SPARE_NOTES_DRAWER_MODAL";



export const ADD_CREATE_MANUFACTURE_CARD_MODAL =
  "ADD_CREATE_MANUFACTURE_CARD_MODAL";



  export const GET_MANUFACTURE_DETAILS_DATA_REQUEST = "GET_MANUFACTURE_DETAILS_DATA_REQUEST";
  export const GET_MANUFACTURE_DETAILS_DATA_SUCCESS = "GET_MANUFACTURE_DETAILS_DATA_SUCCESS";
  export const GET_MANUFACTURE_DETAILS_DATA_FAILURE = "GET_MANUFACTURE_DETAILS_DATA_FAILURE";



  // export const EMPTY_MANUFACTURE_LINK="EMPTY_MANUFACTURE_LINK";





  export const GET_MANUFACTURE_LINK_DATA_REQUEST = "GET_MANUFACTURE_LINK_DATA_REQUEST";
export const GET_MANUFACTURE_LINK_DATA_SUCCESS = "GET_MANUFACTURE_LINK_DATA_SUCCESS";
export const GET_MANUFACTURE_LINK_DATA_FAILURE = "GET_MANUFACTURE_LINK_DATA_FAILURE";




export const GET_PRODUCTION_CELL_LIST_REQUEST = "GET_PRODUCTION_CELL_LIST_REQUEST";
export const GET_PRODUCTION_CELL_LIST_SUCCESS = "GET_PRODUCTION_CELL_LIST_SUCCESS";
export const GET_PRODUCTION_CELL_LIST_FAILURE = "GET_PRODUCTION_CELL_LIST_FAILURE";


export const GET_PRODUCTION_STEPS_REQUEST = "GET_PRODUCTION_STEPS_REQUEST";
export const GET_PRODUCTION_STEPS_SUCCESS = "GET_PRODUCTION_STEPS_SUCCESS";
export const GET_PRODUCTION_STEPS_FAILURE = "GET_PRODUCTION_STEPS_FAILURE";



export const UPDATE_PRODUCTION_STAGE_REQUEST =
"UPDATE_UPDATE_PRODUCTION_STAGE_REQUEST";
export const UPDATE_PRODUCTION_STAGE_SUCCESS =
"UPDATE_UPDATE_PRODUCTION_STAGE_SUCCESS";
export const UPDATE_PRODUCTION_STAGE_FAILURE =
"UPDATE_PRODUCTION_STAGE_FAILURE";



export const ADD_SPARE_PARTS_DRAWER_MODAL = "ADD_SPARE_STEPS_DRAWER_MODAL";



export const GET_PRODUCTION_STAGE_REQUEST = "GET_PRODUCTION_STAGE_REQUEST";
export const GET_PRODUCTION_STAGE_SUCCESS = "GET_PRODUCTION_STAGE_SUCCESS";
export const GET_PRODUCTION_STAGE_FAILURE = "GET_PRODUCTION_STAGE_FAILURE";


export const UPDATE_PRODUCTION_PAUSE_STATUS_REQUEST = "UPDATE_PRODUCTION_PAUSE_STATUS_REQUEST";
export const UPDATE_PRODUCTION_PAUSE_STATUS_SUCCESS = "UPDATE_PRODUCTION_PAUSE_STATUS_SUCCESS";
export const UPDATE_PRODUCTION_PAUSE_STATUS_FAILURE = "UPDATE_PRODUCTION_PAUSE_STATUS_FAILURE";



export const GET_PRODUCTION_TABLE_REQUEST = "GET_PRODUCTION_TABLE_REQUEST";
export const GET_PRODUCTION_TABLE_SUCCESS = "GET_PRODUCTION_TABLE_SUCCESS";
export const GET_PRODUCTION_TABLE_FAILURE = "GET_PRODUCTION_TABLE_FAILURE";

export const GET_WORKFLOW_LIST_REQUEST = "GET_WORKFLOW_LIST_REQUEST";
export const GET_WORKFLOW_LIST_SUCCESS = "GET_WORKFLOW_LIST_SUCCESS";
export const GET_WORKFLOW_LIST_FAILURE = "GET_WORKFLOW_LIST_FAILURE";

export const GET_ARCHIEVE_PRODOCTION_LIST_REQUEST = "GET_ARCHIEVE_PRODOCTION_LIST_REQUEST";
export const GET_ARCHIEVE_PRODOCTION_LIST_SUCCESS = "GET_ARCHIEVE_PRODOCTION_LIST_SUCCESS";
export const GET_ARCHIEVE_PRODOCTION_LIST_FAILURE = "GET_ARCHIEVE_PRODOCTION_LIST_FAILURE";

export const GET_SEARCH_PRODOCTION_REQUEST = "GET_SEARCH_PRODOCTION_REQUEST";
export const GET_SEARCH_PRODOCTION_SUCCESS = "GET_SEARCH_PRODOCTION_SUCCESS";
export const GET_SEARCH_PRODOCTION_FAILURE = "GET_SEARCH_PRODOCTION_FAILURE";

export const GET_PRODUCTION_BYLOC_ID_REQUEST = "GET_PRODUCTION_BYLOC_ID_REQUEST";
export const GET_PRODUCTION_BYLOC_ID_SUCCESS = "GET_PRODUCTION_BYLOC_ID_SUCCESS";
export const GET_PRODUCTION_BYLOC_ID_FAILURE = "GET_PRODUCTION_BYLOC_ID_FAILURE";


export const HANDLE_PRODUCTION_QUALITY_MODAL = "HANDLE_PRODUCTION_QUALITY_MODAL";



export const GET_REASSIGN_PRODUCT_REQUEST = "GET_REASSIGN_PRODUCT_REQUEST";
export const GET_REASSIGN_PRODUCT_SUCCESS = "GET_REASSIGN_PRODUCT_SUCCESS";
export const GET_REASSIGN_PRODUCT_FAILURE = "GET_REASSIGN_PRODUCT_FAILURE";

export const REMOVE_PRODUCTION_REQUEST = "REMOVE_PRODUCTION_REQUEST";
export const REMOVE_PRODUCTION_SUCCESS = "REMOVE_PRODUCTION_SUCCESS";
export const REMOVE_PRODUCTION_FAILURE = "REMOVE_PRODUCTION_FAILURE";

export const HANDLE_BUILDER_PRODUCTION_DRAWER = "HANDLE_BUILDER_PRODUCTION_DRAWER";
export const HANDLE_PRODUCTIONID_DRAWER = "HANDLE_PRODUCTIONID_DRAWER";

export const GET_PRODUCTION_BUILDER_REQUEST = "GET_PRODUCTION_BUILDER_REQUEST";
export const GET_PRODUCTION_BUILDER_SUCCESS = "GET_PRODUCTION_BUILDER_SUCCESS";
export const GET_PRODUCTION_BUILDER_FAILURE = "GET_PRODUCTION_BUILDER_FAILURE";

export const UPDATE_PRODCUTION_STATUS_REQUEST = "UPDATE_PRODCUTION_STATUS_REQUEST";
export const UPDATE_PRODCUTION_STATUS_SUCCESS = "UPDATE_PRODCUTION_STATUS_SUCCESS";
export const UPDATE_PRODCUTION_STATUS_FAILURE = "UPDATE_PRODCUTION_STATUS_FAILURE";

export const SET_INSPECT_PRODN_REQUEST = "SET_INSPECT_PRODN_REQUEST";
export const SET_INSPECT_PRODN_SUCCESS = "SET_INSPECT_PRODN_SUCCESS";
export const SET_INSPECT_PRODN_FAILURE = "SET_INSPECT_PRODN_FAILURE";

export const GET_ALL_PRODUCTION_BYORG_ID_REQUEST = "GET_ALL_PRODUCTION_BYORG_ID_REQUEST";
export const GET_ALL_PRODUCTION_BYORG_ID_SUCCESS = "GET_ALL_PRODUCTION_BYORG_ID_SUCCESS";
export const GET_ALL_PRODUCTION_BYORG_ID_FAILURE = "GET_ALL_PRODUCTION_BYORG_ID_FAILURE";

export const GET_PRODUCT_RECORDS_REQUEST = "GET_PRODUCT_RECORDS_REQUEST";
export const GET_PRODUCT_RECORDS_SUCCESS = "GET_PRODUCT_RECORDS_SUCCESS";
export const GET_PRODUCT_RECORDS_FAILURE = "GET_PRODUCT_RECORDS_FAILURE";

export const UPDATE_ROOM_RACK_PRODN_REQUEST = "UPDATE_ROOM_RACK_PRODN_REQUEST";
export const UPDATE_ROOM_RACK_PRODN_SUCCESS = "UPDATE_ROOM_RACK_PRODN_SUCCESS";
export const UPDATE_ROOM_RACK_PRODN_FAILURE = "UPDATE_ROOM_RACK_PRODN_FAILURE";

export const GET_ALL_STAGE_PRODUCTION_REQUEST = "GET_ALL_STAGE_PRODUCTION_REQUEST";
export const GET_ALL_STAGE_PRODUCTION_SUCCESS = "GET_ALL_STAGE_PRODUCTION_SUCCESS";
export const GET_ALL_STAGE_PRODUCTION_FAILURE = "GET_ALL_STAGE_PRODUCTION_FAILURE";

export const UPDATE_PRODUCTION_DRAG_STAGE_REQUEST = "UPDATE_PRODUCTION_DRAG_STAGE_REQUEST";
export const UPDATE_PRODUCTION_DRAG_STAGE_SUCCESS = "UPDATE_PRODUCTION_DRAG_STAGE_SUCCESS";
export const UPDATE_PRODUCTION_DRAG_STAGE_FAILURE = "UPDATE_PRODUCTION_DRAG_STAGE_FAILURE";

export const GET_STAGE_LIST_REQUEST = "GET_STAGE_LIST_REQUEST";
export const GET_STAGE_LIST_SUCCESS = "GET_STAGE_LIST_SUCCESS";
export const GET_STAGE_LIST_FAILURE = "GET_STAGE_LIST_FAILURE";

export const UPDATE_ROOM_RACK_WIP_REQUEST = "UPDATE_ROOM_RACK_WIP_REQUEST";
export const UPDATE_ROOM_RACK_WIP_SUCCESS = "UPDATE_ROOM_RACK_WIP_SUCCESS";
export const UPDATE_ROOM_RACK_WIP_FAILURE = "UPDATE_ROOM_RACK_WIP_FAILURE";