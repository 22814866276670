export const SET_LEADS_VIEW_TYPE = "SET_LEADS_VIEW_TYPE";

export const HANDLE_LEADS_MODAL = "HANDLE_LEADS_MODAL";



export const ADD_LEADS_IMPORT_FORM_REQUEST = "ADD_LEADS_IMPORT_FORM_REQUEST";
export const ADD_LEADS_IMPORT_FORM_SUCCESS = "ADD_LEADS_IMPORT_FORM_SUCCESS";
export const ADD_LEADS_IMPORT_FORM_FAILURE = "ADD_LEADS_IMPORT_FORM_FAILURE";


export const GET_SUBSCRIPTION_COMPARE_REQUEST = "GET_SUBSCRIPTION_COMPARE_REQUEST";
export const GET_SUBSCRIPTION_COMPARE_SUCCESS = "GET_SUBSCRIPTION_COMPARE_SUCCESS";
export const GET_SUBSCRIPTION_COMPARE_FAILURE = "GET_SUBSCRIPTION_COMPARE_FAILURE";




export const ADD_SUBSCRIPTION_DATA_REQUEST = "ADD_SUBSCRIPTION_DATA_REQUEST";
export const ADD_SUBSCRIPTION_DATA_SUCCESS = "ADD_SUBSCRIPTION_DATA_SUCCESS";
export const ADD_SUBSCRIPTION_DATA_FAILURE = "ADD_SUBSCRIPTION_DATA_FAILURE";



export const GET_LEADS_SUBSCRIPTION_DATA_REQUEST = "GET_LEADS_SUBSCRIPTION_DATA_REQUEST";
export const GET_LEADS_SUBSCRIPTION_DATA_SUCCESS = "GET_LEADS_SUBSCRIPTION_DATA_SUCCESS";
export const GET_LEADS_SUBSCRIPTION_DATA_FAILURE = "GET_LEADS_SUBSCRIPTION_DATA_FAILURE";


export const HANDLE_LEADS_SUBSCRIPTION_DRAWER_MODAL = "HANDLE_LEADS_SUBSCRIPTION_DRAWER_MODAL";


export const HANDLE_CLAER_SEARCHED_DATA_LEAD = "HANDLE_CLAER_SEARCHED_DATA_LEAD";


export const HANDLE_LEADS_CONFIRMATION_MODAL = "HANDLE_LEADS_CONFIRMATION_MODAL";


export const HANDLE_LEADS_IMPORT_MODAL = "HANDLE_LEADS_IMPORT_MODAL";

export const ADD_LEADS_REQUEST = "ADD_LEADS_REQUEST";
export const ADD_LEADS_SUCCESS = "ADD_LEADS_SUCCESS";
export const ADD_LEADS_FAILURE = "ADD_LEADS_FAILURE";

export const GET_LEADS_REQUEST = "GET_LEADS_REQUEST";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAILURE = "GET_LEADS_FAILURE";

export const GET_LEADS_HOT_REQUEST = "GET_LEADS_HOT_REQUEST";
export const GET_LEADS_HOT_SUCCESS = "GET_LEADS_HOT_SUCCESS";
export const GET_LEADS_HOT_FAILURE = "GET_LEADS_HOT_FAILURE";

export const GET_LEADS_WARM_REQUEST = "GET_LEADS_WARM_REQUEST";
export const GET_LEADS_WARM_SUCCESS = "GET_LEADS_WARM_SUCCESS";
export const GET_LEADS_WARM_FAILURE = "GET_LEADS_WARM_FAILURE";

export const GET_LEADS_COLD_REQUEST = "GET_LEADS_COLD_REQUEST";
export const GET_LEADS_COLD_SUCCESS = "GET_LEADS_COLD_SUCCESS";
export const GET_LEADS_COLD_FAILURE = "GET_LEADS_COLD_FAILURE";

export const GET_CRM_REQUEST = "GET_CRM_REQUEST";
export const GET_CRM_SUCCESS = "GET_CRM_SUCCESS";
export const GET_CRM_FAILURE = "GET_CRM_FAILURE";

export const SET_CLEARBIT_DATA = "SET_CLEARBIT_DATA";

export const CONVERT_CUSTOMER_STATUS_REQUEST = "CONVERT_CUSTOMER_STATUS_REQUEST";
export const CONVERT_CUSTOMER_STATUS_SUCCESS = "CONVERT_CUSTOMER_STATUS_SUCCESS";
export const CONVERT_CUSTOMER_STATUS_FAILURE = "CONVERT_CUSTOMER_STATUS_FAILURE";

export const DELETE_LEADS_DATA_REQUEST = "DELETE_LEADS_DATA_REQUEST";
export const DELETE_LEADS_DATA_SUCCESS = "DELETE_LEADS_DATA_SUCCESS";
export const DELETE_LEADS_DATA_FAILURE = "DELETE_LEADS_DATA_FAILURE";


export const GET_LEAD_DETAILS_BY_ID_REQUEST =
  "GET_LEAD_DETAILS_BY_ID_REQUEST";
export const GET_LEAD_DETAILS_BY_ID_SUCCESS =
  "GET_LEAD_DETAILS_BY_ID_SUCCESS";
export const GET_LEAD_DETAILS_BY_ID_FAILURE =
  "GET_LEAD_DETAILS_BY_ID_FAILURE";

  export const SET_LEADS_EDIT = "SET_LEADS_EDIT";

  export const HANDLE_UPDATE_LEADS_MODAL = "HANDLE_UPDATE_LEADS_MODAL";

  export const UPDATE_LEADS_BY_ID_REQUEST = "UPDATE_LEADS_BY_ID_REQUEST";
export const UPDATE_LEADS_BY_ID_SUCCESS = "UPDATE_LEADS_BY_ID_SUCCESS";
export const UPDATE_LEADS_BY_ID_FAILURE = "UPDATE_LEADS_BY_ID_FAILURE";

export const HANDLE_LEADS_EMAIL_DRAWER_MODAL = "HANDLE_LEADS_EMAIL_DRAWER_MODAL";

export const HANDLE_LEADS_CONTACT_MODAL = "HANDLE_LEADS_CONTACT_MODAL";

export const ADD_LEADS_CONTACT_REQUEST = "ADD_LEADS_CONTACT_REQUEST";
export const ADD_LEADS_CONTACT_SUCCESS = "ADD_LEADS_CONTACT_SUCCESS";
export const ADD_LEADS_CONTACT_FAILURE = "ADD_LEADS_CONTACT_FAILURE";


export const GET_LEADS_CONTACT_REQUEST = "GET_LEADS_CONTACT_REQUEST";
export const GET_LEADS_CONTACT_SUCCESS = "GET_LEADS_CONTACT_SUCCESS";
export const GET_LEADS_CONTACT_FAILURE = "GET_LEADS_CONTACT_FAILURE";

export const HANDLE_UPDATE_LEADS_CONTACT_MODAL =
"HANDLE_UPDATE_LEADS_CONTACT_MODAL";

export const SET_EDIT_LEADS_CONTACT = "SET_EDIT_LEADS_CONTACT";

export const UPDATE_LEADS_CONTACT_BY_ID_REQUEST = "UPDATE_LEADS_CONTACT_BY_ID_REQUEST";
export const UPDATE_LEADS_CONTACT_BY_ID_SUCCESS = "UPDATE_LEADS_CONTACT_BY_ID_SUCCESS";
export const UPDATE_LEADS_CONTACT_BY_ID_FAILURE = "UPDATE_LEADS_CONTACT_BY_ID_FAILURE";

export const HANDLE_LEADS_OPPORTUNITY_MODAL =
  "HANDLE_LEADS_OPPORTUNITY_MODAL";

  export const ADD_LEADS_OPPORTUNITY_REQUEST =
  "ADD_LEADS_OPPORTUNITY_REQUEST";
export const ADD_LEADS_OPPORTUNITY_SUCCESS =
  "ADD_LEADS_OPPORTUNITY_SUCCESS";
export const ADD_LEADS_OPPORTUNITY_FAILURE =
  "ADD_LEADS_OPPORTUNITY_FAILURE";

  export const GET_LEADS_OPPORTUNITY_REQUEST =
  "GET_LEADS_OPPORTUNITY_REQUEST";
export const GET_LEADS_OPPORTUNITY_SUCCESS =
  "GET_LEADS_OPPORTUNITY_SUCCESS";
export const GET_LEADS_OPPORTUNITY_FAILURE =
  "GET_LEADS_OPPORTUNITY_FAILURE";

  export const HANDLE_UPDATE_LEADS_OPPORTUNITY_MODAL =
  "HANDLE_UPDATE_LEADS_OPPORTUNITY_MODAL";

  export const SET_EDIT_LEADS_OPPORTUNITY = "SET_EDIT_LEADS_OPPORTUNITY";

  export const HANDLE_LEADS_DOCUMENT_UPLOAD_MODAL = "HANDLE_LEADS_DOCUMENT_UPLOAD_MODAL";

  export const HANDLE_LEADS_REACT_SPEECH_MODAL = "HANDLE_LEADS_REACT_SPEECH_MODAL";

  export const ADD_LEADS_DOCUMENT_REQUEST = "ADD_LEADS_DOCUMENT_REQUEST";
export const ADD_LEADS_DOCUMENT_SUCCESS = "ADD_LEADS_DOCUMENT_SUCCESS";
export const ADD_LEADS_DOCUMENT_FAILURE = "ADD_LEADS_DOCUMENT_FAILURE";

export const GET_LEADS_DOCUMENTS_REQUEST = "GET_LEADS_DOCUMENTS_REQUEST";
export const GET_LEADS_DOCUMENTS_SUCCESS = "GET_LEADS_DOCUMENTS_SUCCESS";
export const GET_LEADS_DOCUMENTS_FAILURE = "GET_LEADS_DOCUMENTS_FAILURE";

export const ADD_INITIATIVE_BY_LEADS_ID_REQUEST =
  "ADD_INITIATIVE_BY_LEADS_ID_REQUEST";
export const ADD_INITIATIVE_BY_LEADS_ID_SUCCESS =
  "ADD_INITIATIVE_BY_LEADS_ID_SUCCESS";
export const ADD_INITIATIVE_BY_LEADS_ID_FAILURE =
  "ADD_INITIATIVE_BY_LEADS_ID_FAILURE";

  export const GET_INITIATIVE_BY_LEADS_ID_REQUEST =
  "GET_INITIATIVE_BY_LEADS_ID_REQUEST";
export const GET_INITIATIVE_BY_LEADS_ID_SUCCESS =
  "GET_INITIATIVE_BY_LEADS_ID_SUCCESS";
export const GET_INITIATIVE_BY_LEADS_ID_FAILURE =
  "GET_INITIATIVE_BY_LEADS_ID_FAILURE";


  export const DELETE_LEADS_DOCUMENT_REQUEST = "DELETE_LEADS_DOCUMENT_REQUEST";
export const DELETE_LEADS_DOCUMENT_SUCCESS = "DELETE_LEADS_DOCUMENT_SUCCESS";
export const DELETE_LEADS_DOCUMENT_FAILURE = "DELETE_LEADS_DOCUMENT_FAILURE";


export const ADD_LEADS_NOTES_REQUEST = "ADD_LEADS_NOTES_REQUEST";
export const ADD_LEADS_NOTES_SUCCESS = "ADD_LEADS_NOTES_SUCCESS";
export const ADD_LEADS_NOTES_FAILURE = "ADD_LEADS_NOTES_FAILURE";


export const GET_NOTES_LIST_BY_LEADS_ID_REQUEST =
  "GET_NOTES_LIST_BY_LEADS_ID_REQUEST";
export const GET_NOTES_LIST_BY_LEADS_ID_SUCCESS =
  "GET_NOTES_LIST_BY_LEADS_ID_SUCCESS";
export const GET_NOTES_LIST_BY_LEADS_ID_FAILURE =
  "GET_NOTES_LIST_BY_LEADS_ID_FAILURE";


  export const HANDLE_UPDATE_LEADS_INITIATIVE_MODAL = "HANDLE_UPDATE_LEADS_INITIATIVE_MODAL";

  export const SET_EDIT_LEADS_INITIATIVE = "SET_EDIT_LEADS_INITIATIVE";


  export const UPDATE_LEADS_OPPORTUNITY_REQUEST = "UPDATE_LEADS_OPPORTUNITY_REQUEST";
export const UPDATE_LEADS_OPPORTUNITY_SUCCESS = "UPDATE_LEADS_OPPORTUNITY_SUCCESS";
export const UPDATE_LEADS_OPPORTUNITY_FAILURE = "UPDATE_LEADS_OPPORTUNITY_FAILURE";

export const UPDATE_LEADS_INITIATIVE_REQUEST = "UPDATE_LEADS_INITIATIVE_REQUEST";
export const UPDATE_LEADS_INITIATIVE_SUCCESS = "UPDATE_LEADS_INITIATIVE_SUCCESS";
export const UPDATE_LEADS_INITIATIVE_FAILURE = "UPDATE_LEADS_INITIATIVE_FAILURE";


export const ADD_LEADS_SKILL_REQUEST =
  "ADD_LEADS_SKILL_REQUEST";
export const ADD_LEADS_SKILL_SUCCESS =
  "ADD_LEADS_SKILL_SUCCESS";
export const ADD_LEADS_SKILL_FAILURE =
  "ADD_LEADS_SKILL_FAILURE";


  export const GET_LEADS_SKILL_REQUEST =
  "GET_LEADS_SKILL_REQUEST";
export const GET_LEADS_SKILL_SUCCESS =
  "GET_LEADS_SKILL_SUCCESS";
export const GET_LEADS_SKILL_FAILURE =
  "GET_LEADS_SKILL_FAILURE";


  
  export const DELETE_LEADS_SKILL_REQUEST =
  "DELETE_LEADS_SKILL_REQUEST";
export const DELETE_LEADS_SKILL_SUCCESS =
  "DELETE_LEADS_SKILL_SUCCESS";
export const DELETE_LEADS_SKILL_FAILURE =
  "DELETE_LEADS_SKILL_FAILURE";

  export const INPUT_LEADS_SEARCH_DATA_REQUEST =
  "INPUT_LEADS_SEARCH_DATA_REQUEST";
export const INPUT_LEADS_SEARCH_DATA_SUCCESS =
  "INPUT_LEADS_SEARCH_DATA_SUCCESS";
export const INPUT_LEADS_SEARCH_DATA_FAILURE =
  "INPUT_LEADS_SEARCH_DATA_FAILURE";

export const GET_LEADS_PERMISSIONS_LIST_REQUEST = "GET_LEADS_PERMISSIONS_LIST_REQUEST";
export const GET_LEADS_PERMISSIONS_LIST_SUCCESS = "GET_LEADS_PERMISSIONS_LIST_SUCCESS";
export const GET_LEADS_PERMISSIONS_LIST_FAILURE = "GET_LEADS_PERMISSIONS_LIST_FAILURE";
export const ADD_SHARE_LEADS_PERMISSION_REQUEST="ADD_SHARE_LEADS_PERMISSION_REQUEST";
export const ADD_SHARE_LEADS_PERMISSION_SUCCESS="ADD_SHARE_LEADS_PERMISSION_SUCCESS";
export const ADD_SHARE_LEADS_PERMISSION_FAILURE="ADD_SHARE_LEADS_PERMISSION_FAILURE";

export const UPDATE_TYPE_FOR_LEAD_REQUEST="UPDATE_TYPE_FOR_LEAD_REQUEST";
export const UPDATE_TYPE_FOR_LEAD_SUCCESS="UPDATE_TYPE_FOR_LEAD_SUCCESS";
export const UPDATE_TYPE_FOR_LEAD_FAILURE="UPDATE_TYPE_FOR_LEAD_FAILURE";

export const GET_JUNKED_LEADS_REQUEST="GET_JUNKED_LEADS_REQUEST";
export const GET_JUNKED_LEADS_SUCCESS="GET_JUNKED_LEADS_SUCCESS";
export const GET_JUNKED_LEADS_FAILURE="GET_JUNKED_LEADS_FAILURE";

export const GET_LEADS_RECORDS_REQUEST="GET_LEADS_RECORDS_REQUEST";
export const GET_LEADS_RECORDS_SUCCESS="GET_LEADS_RECORDS_SUCCESS";
export const GET_LEADS_RECORDS_FAILURE="GET_LEADS_RECORDS_FAILURE";

export const GET_LEADS_TEAM_RECORDS_REQUEST="GET_LEADS_TEAM_RECORDS_REQUEST";
export const GET_LEADS_TEAM_RECORDS_SUCCESS="GET_LEADS_TEAM_RECORDS_SUCCESS";
export const GET_LEADS_TEAM_RECORDS_FAILURE="GET_LEADS_TEAM_RECORDS_FAILURE";

export const GET_JUNKED_LEADS_RECORDS_REQUEST="GET_JUNKED_LEADS_RECORDS_REQUEST";
export const GET_JUNKED_LEADS_RECORDS_SUCCESS="GET_JUNKED_LEADS_RECORDS_SUCCESS";
export const GET_JUNKED_LEADS_RECORDS_FAILURE="GET_JUNKED_LEADS_RECORDS_FAILURE";
export const REINSTATE_JUNKED_LEADS_REQUEST="REINSTATE_JUNKED_LEADS_REQUEST";
export const REINSTATE_JUNKED_LEADS_SUCCESS="REINSTATE_JUNKED_LEADS_SUCCESS";
export const REINSTATE_JUNKED_LEADS_FAILURE="REINSTATE_JUNKED_LEADS_FAILURE";

export const HANDLE_CET_MODAL="HANDLE_CET_MODAL";

export const GET_CALL_LIST_BY_REQUEST = "GET_CALL_LIST_BY_REQUEST";
export const GET_CALL_LIST_BY_SUCCESS = "GET_CALL_LIST_BY_SUCCESS";
export const GET_CALL_LIST_BY_FAILURE = "GET_CALL_LIST_BY_FAILURE";

export const HANDLE_LEADS_CALL_MODAL = "HANDLE_LEADS_CALL_MODAL";

export const GET_CALL_TIMELINE_REQUEST = "GET_CALL_TIMELINE_REQUEST";
export const GET_CALL_TIMELINE_SUCCESS = "GET_CALL_TIMELINE_SUCCESS";
export const GET_CALL_TIMELINE_FAILURE = "GET_CALL_TIMELINE_FAILURE";

export const HANDLE_LEADS_NOTES_DRAWER_MODAL = "HANDLE_LEADS_NOTES_DRAWER_MODAL";

export const GET_ALL_LEADS_REQUEST="GET_ALL_LEADS_REQUEST";
export const GET_ALL_LEADS_SUCCESS="GET_ALL_LEADS_SUCCESS";
export const GET_ALL_LEADS_FAILURE="GET_ALL_LEADS_FAILURE";

export const GET_ALL_LEADSHOT_REQUEST="GET_ALL_LEADSHOT_REQUEST";
export const GET_ALL_LEADSHOT_SUCCESS="GET_ALL_LEADSHOT_SUCCESS";
export const GET_ALL_LEADSHOT_FAILURE="GET_ALL_LEADSHOT_FAILURE";

export const GET_ALL_LEADSWARM_REQUEST="GET_ALL_LEADSWARM_REQUEST";
export const GET_ALL_LEADSWARM_SUCCESS="GET_ALL_LEADSWARM_SUCCESS";
export const GET_ALL_LEADSWARM_FAILURE="GET_ALL_LEADSWARM_FAILURE";

export const GET_ALL_LEADSCOLD_REQUEST="GET_ALL_LEADSCOLD_REQUEST";
export const GET_ALL_LEADSCOLD_SUCCESS="GET_ALL_LEADSCOLD_SUCCESS";
export const GET_ALL_LEADSCOLD_FAILURE="GET_ALL_LEADSCOLD_FAILURE";


export const CONVERT_LEADS_REQUEST = "CONVERT_LEADS_REQUEST";
export const CONVERT_LEADS_SUCCESS = "CONVERT_LEADS_SUCCESS";
export const CONVERT_LEADS_FAILURE = "CONVERT_LEADS_FAILURE";

export const GET_TEAM_LEADS_REQUEST="GET_TEAM_LEADS_REQUEST";
export const GET_TEAM_LEADS_SUCCESS="GET_TEAM_LEADS_SUCCESS";
export const GET_TEAM_LEADS_FAILURE="GET_TEAM_LEADS_FAILURE";

export const GET_TEAM_LEADSHOT_REQUEST="GET_TEAM_LEADSHOT_REQUEST";
export const GET_TEAM_LEADSHOT_SUCCESS="GET_TEAM_LEADSHOT_SUCCESS";
export const GET_TEAM_LEADSHOT_FAILURE="GET_TEAM_LEADSHOT_FAILURE";

export const GET_TEAM_LEADSWARM_REQUEST="GET_TEAM_LEADSWARM_REQUEST";
export const GET_TEAM_LEADSWARM_SUCCESS="GET_TEAM_LEADSWARM_SUCCESS";
export const GET_TEAM_LEADSWARM_FAILURE="GET_TEAM_LEADSWARM_FAILURE";

export const GET_TEAM_LEADSCOLD_REQUEST="GET_TEAM_LEADSCOLD_REQUEST";
export const GET_TEAM_LEADSCOLD_SUCCESS="GET_TEAM_LEADSCOLD_SUCCESS";
export const GET_TEAM_LEADSCOLD_FAILURE="GET_TEAM_LEADSCOLD_FAILURE";


export const ADD_LEADS_ACTIVITY_CALL_REQUEST = "ADD_LEADS_ACTIVITY_CALL_REQUEST";
export const ADD_LEADS_ACTIVITY_CALL_SUCCESS = "ADD_LEADS_ACTIVITY_CALL_SUCCESS";
export const ADD_LEADS_ACTIVITY_CALL_FAILURE = "ADD_LEADS_ACTIVITY_CALL_FAILURE";

export const ADD_LEADS_ACTIVITY_EVENT_REQUEST = "ADD_LEADS_ACTIVITY_EVENT_REQUEST";
export const ADD_LEADS_ACTIVITY_EVENT_SUCCESS = "ADD_LEADS_ACTIVITY_EVENT_SUCCESS";
export const ADD_LEADS_ACTIVITY_EVENT_FAILURE = "ADD_LEADS_ACTIVITY_EVENT_FAILURE";


export const ADD_LEADS_ACTIVITY_TASK_REQUEST = "ADD_LEADS_ACTIVITY_TASK_REQUEST";
export const ADD_LEADS_ACTIVITY_TASK_SUCCESS = "ADD_LEADS_ACTIVITY_TASK_SUCCESS";
export const ADD_LEADS_ACTIVITY_TASK_FAILURE = "ADD_LEADS_ACTIVITY_TASK_FAILURE";

export const GET_OPPORTUNITY_RECORD_REQUEST="GET_OPPORTUNITY_RECORD_REQUEST";
export const GET_OPPORTUNITY_RECORD_SUCCESS="GET_OPPORTUNITY_RECORD_SUCCESS";
export const GET_OPPORTUNITY_RECORD_FAILURE="GET_OPPORTUNITY_RECORD_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_LEAD = "HANDLE_CLAER_REDUCER_DATA_LEAD";

export const EMPTY_LEADS_LIST="EMPTY_LEADS_LIST";

export const GET_LEADS_ACTIVITY_RECORDS_REQUEST = "GET_LEADS_ACTIVITY_RECORDS_REQUEST";
export const GET_LEADS_ACTIVITY_RECORDS_SUCCESS = "GET_LEADS_ACTIVITY_RECORDS_SUCCESS";
export const GET_LEADS_ACTIVITY_RECORDS_FAILURE = "GET_LEADS_ACTIVITY_RECORDS_FAILURE";


export const GET_LEADS_ALL_RECORDS_REQUEST="GET_LEADS_ALL_RECORDS_REQUEST";
export const GET_LEADS_ALL_RECORDS_SUCCESS="GET_LEADS_ALL_RECORDS_SUCCESS";
export const GET_LEADS_ALL_RECORDS_FAILURE="GET_LEADS_ALL_RECORDS_FAILURE";

export const HANDLE_LEADS_NOTE_DRAWER_MODAL = "HANDLE_LEADS_NOTE_DRAWER_MODAL";

export const GET_NOTES_LIST_LEADS_REQUEST =
  "GET_NOTES_LIST_LEADS_REQUEST";
export const GET_NOTES_LIST_LEADS_SUCCESS =
  "GET_NOTES_LIST_LEADS_SUCCESS";
export const GET_NOTES_LIST_LEADS_FAILURE =
  "GET_NOTES_LIST_LEADS_FAILURE";

  export const ADD_LEAD_NOTES_REQUEST = "ADD_LEAD_NOTES_REQUEST";
export const ADD_LEAD_NOTES_SUCCESS = "ADD_LEAD_NOTES_SUCCESS";
export const ADD_LEAD_NOTES_FAILURE = "ADD_LEAD_NOTES_FAILURE";


export const REMOVE_LEADS_NOTE_REQUEST = "REMOVE_LEADS_NOTE_REQUEST";
export const REMOVE_LEADS_NOTE_SUCCESS = "REMOVE_LEADS_NOTE_SUCCESS";
export const REMOVE_LEADS_NOTE_FAILURE = "REMOVE_LEADS_NOTE_FAILURE";

export const UPDATE_LEADS_NOTE_REQUEST = "UPDATE_LEADS_NOTE_REQUEST";
export const UPDATE_LEADS_NOTE_SUCCESS = "UPDATE_LEADS_NOTE_SUCCESS";
export const UPDATE_LEADS_NOTE_FAILURE = "UPDATE_LEADS_NOTE_FAILURE";

export const UPDATE_LEADS_NOTE_DRAWER_MODAL = "UPDATE_LEADS_NOTE_DRAWER_MODAL";

export const EMPTY_CLEARBIT_TABLE = "EMPTY_CLEARBIT_TABLE";

export const UPDATE_LEADS_NOTE_MODAL = "UPDATE_LEADS_NOTE_MODAL";







