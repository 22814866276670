export const GET_LIBRARYS_REQUEST = "GET_LIBRARYS_REQUEST";
export const GET_LIBRARYS_SUCCESS = "GET_LIBRARYS_SUCCESS";
export const GET_LIBRARYS_FAILURE = "GET_LIBRARYS_FAILURE";

export const ADD_LIBRARYS_REQUEST = "ADD_LIBRARYS_REQUEST";
export const ADD_LIBRARYS_SUCCESS = "ADD_LIBRARYS_SUCCESS";
export const ADD_LIBRARYS_FAILURE = "ADD_LIBRARYS_FAILURE";

export const REMOVE_LIBRARYS_REQUEST = "REMOVE_LIBRARYS_REQUEST";
export const REMOVE_LIBRARYS_SUCCESS = "REMOVE_LIBRARYS_SUCCESS";
export const REMOVE_LIBRARYS_FAILURE = "REMOVE_LIBRARYS_FAILURE";

export const UPDATE_LIBRARYS_REQUEST = "UPDATE_LIBRARYS_REQUEST";
export const UPDATE_LIBRARYS_SUCCESS = "UPDATE_LIBRARYS_SUCCESS";
export const UPDATE_LIBRARYS_FAILURE = "UPDATE_LIBRARYS_FAILURE";

export const GET_LIBRARY_SEARCH_REQUEST="GET_LIBRARY_SEARCH_REQUEST";
export const GET_LIBRARY_SEARCH_SUCCESS="GET_LIBRARY_SEARCH_SUCCESS";
export const GET_LIBRARY_SEARCH_FAILURE="GET_LIBRARY_SEARCH_FAILURE";

export const REMOVE_SKILLS_REQUEST = "REMOVE_SKILLS_REQUEST";
export const REMOVE_SKILLS_SUCCESS = "REMOVE_SKILLS_SUCCESS";
export const REMOVE_SKILLS_FAILURE = "REMOVE_SKILLS_FAILURE";


export const HANDLE_CLAER_REDUCER_DATA_LIBRARY = "HANDLE_CLAER_REDUCER_DATA_LIBRARY";

export const GET_CERTIFICATION_RECORDS_REQUEST = "GET_CERTIFICATION_RECORDS_REQUEST";
export const GET_CERTIFICATION_RECORDS_SUCCESS = "GET_CERTIFICATION_RECORDS_SUCCESS";
export const GET_CERTIFICATION_RECORDS_FAILURE = "GET_CERTIFICATION_RECORDS_FAILURE";

export const GET_LIBRARY_RECORDS_REQUEST = "GET_LIBRARY_RECORDS_REQUEST";
export const GET_LIBRARY_RECORDS_SUCCESS = "GET_LIBRARY_RECORDS_SUCCESS";
export const GET_LIBRARY_RECORDS_FAILURE = "GET_LIBRARY_RECORDS_FAILURE";
