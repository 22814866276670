
export const GET_CURRENCY_LIST_REQUEST = "GET_CURRENCY_LIST_REQUEST";
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS";
export const GET_CURRENCY_LIST_FAILURE = "GET_CURRENCY_LIST_FAILURE";

export const LINK_CURRENCY_TOGGLE_REQUEST =
  "LINK_CURRENCY_TOGGLE_REQUEST";
export const LINK_CURRENCY_TOGGLE_SUCCESS =
  "LINK_CURRENCY_TOGGLE_SUCCESS";
export const LINK_CURRENCY_TOGGLE_FAILURE =
  "LINK_CURRENCY_TOGGLE_FAILURE";



  export const ALL_CURRENCY_MANDATORY_REQUEST =
"ALL_CURRENCY_MANDATORY_REQUEST";
export const ALL_CURRENCY_MANDATORY_SUCCESS =
"ALL_CURRENCY_MANDATORY_SUCCESS";
export const ALL_CURRENCY_MANDATORY_FAILURE =
"ALL_CURRENCY_MANDATORY_FAILURE";

export const GET_CURRENCY_SEARCH_REQUEST="GET_CURRENCY_SEARCH_REQUEST";
export const GET_CURRENCY_SEARCH_SUCCESS="GET_CURRENCY_SEARCH_SUCCESS";
export const GET_CURRENCY_SEARCH_FAILURE="GET_CURRENCY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_CURRENCY = "HANDLE_CLAER_REDUCER_DATA_CURRENCY";


export const LINK_SALES_CURRENCY_TOGGLE_REQUEST =
  "LINK_SALES_CURRENCY_TOGGLE_REQUEST";
export const LINK_SALES_CURRENCY_TOGGLE_SUCCESS =
  "LINK_CURRENCY_TOGGLE_SUCCESS";
export const LINK_SALES_CURRENCY_TOGGLE_FAILURE =
  "LINK_SALES_CURRENCY_TOGGLE_FAILURE";

  export const LINK_INVESTOR_CURRENCY_TOGGLE_REQUEST =
  "LINK_INVESTOR_CURRENCY_TOGGLE_REQUEST";
export const LINK_INVESTOR_CURRENCY_TOGGLE_SUCCESS =
  "LINK_CURRENCY_TOGGLE_SUCCESS";
export const LINK_INVESTOR_CURRENCY_TOGGLE_FAILURE =
  "LINK_INVESTOR_CURRENCY_TOGGLE_FAILURE";


  export const GET_CURRENCY_COUNT_REQUEST = "GET_CURRENCY_COUNT_REQUEST";
  export const GET_CURRENCY_COUNT_SUCCESS = "GET_CURRENCY_COUNT_SUCCESS";
  export const GET_CURRENCY_COUNT_FAILURE = "GET_CURRENCY_COUNT_FAILURE";
  
