export const GET_INVESTOR_LIST_REQUEST = "GET_INVESTOR_LIST_REQUEST";
export const GET_INVESTOR_LIST_SUCCESS = "GET_INVESTOR_LIST_SUCCESS";
export const GET_INVESTOR_LIST_FAILURE = "GET_INVESTOR_LIST_FAILURE";

export const ADD_INVESTOR_DATA_REQUEST = "ADD_INVESTOR_DATA_REQUEST";
export const ADD_INVESTOR_DATA_SUCCESS = "ADD_INVESTOR_DATA_SUCCESS";
export const ADD_INVESTOR_DATA_FAILURE = "ADD_INVESTOR_DATA_FAILURE";


export const ADD_INVESTOR_DATA_DUPLICATE = "ADD_INVESTOR_DATA_DUPLICATE";

export const REMOVE_INVESTOR_REQUEST = "REMOVE_INVESTOR_REQUEST";
export const REMOVE_INVESTOR_SUCCESS = "REMOVE_INVESTOR_SUCCESS";
export const REMOVE_INVESTOR_FAILURE = "REMOVE_INVESTOR_FAILURE";

export const UPDATE_INVESTOR_REQUEST = "UPDATE_INVESTOR_REQUEST";
export const UPDATE_INVESTOR_SUCCESS = "UPDATE_INVESTOR_SUCCESS";
export const UPDATE_INVESTOR_FAILURE = "UPDATE_INVESTOR_FAILURE";

export const GET_CUSTOMER_SEARCH_REQUEST="GET_CUSTOMER_SEARCH_REQUEST";
export const GET_CUSTOMER_SEARCH_SUCCESS="GET_CUSTOMER_SEARCH_SUCCESS";
export const GET_CUSTOMER_SEARCH_FAILURE="GET_CUSTOMER_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_INVESTORTYPE = "HANDLE_CLAER_REDUCER_DATA_INVESTORTYPE";

export const GET_INVESTORTYPE_SEARCH_REQUEST="GET_INVESTORTYPE_SEARCH_REQUEST";
export const GET_INVESTORTYPE_SEARCH_SUCCESS="GET_INVESTORTYPE_SEARCH_SUCCESS";
export const GET_INVESTORTYPE_SEARCH_FAILURE="GET_INVESTORTYPE_SEARCH_FAILURE";


export const GET_INVESTOR_COUNT_REQUEST = "GET_INVESTOR_COUNT_REQUEST";
export const GET_INVESTOR_COUNT_SUCCESS = "GET_INVESTOR_COUNT_SUCCESS";
export const GET_INVESTOR_COUNT_FAILURE = "GET_INVESTOR_COUNT_FAILURE";

export const HANDLE_INVESTOR_IMPORT_MODAL = "HANDLE_INVESTOR_IMPORT_MODAL";

export const ADD_INVESTOR_IMPORT_FORM_REQUEST = "ADD_INVESTOR_IMPORT_FORM_REQUEST";
export const ADD_INVESTOR_IMPORT_FORM_SUCCESS = "ADD_INVESTOR_IMPORT_FORM_SUCCESS";
export const ADD_INVESTOR_IMPORT_FORM_FAILURE = "ADD_INVESTOR_IMPORT_FORM_FAILURE";



