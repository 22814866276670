export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";

export const REMOVE_CATEGORY_REQUEST = "REMOVE_CATEGORY_REQUEST";
export const REMOVE_CATEGORY_SUCCESS = "REMOVE_CATEGORY_SUCCESS";
export const REMOVE_CATEGORY_FAILURE = "REMOVE_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const GET_CATEGORY_SEARCH_REQUEST="GET_CATEGORY_SEARCH_REQUEST";
export const GET_CATEGORY_SEARCH_SUCCESS="GET_CATEGORY_SEARCH_SUCCESS";
export const GET_CATEGORY_SEARCH_FAILURE="GET_CATEGORY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_CATEGORY = "HANDLE_CLAER_REDUCER_DATA_CATEGORY";

export const GET_CATEGORY_COUNT_REQUEST = "GET_CATEGORY_COUNT_REQUEST";
export const GET_CATEGORY_COUNT_SUCCESS = "GET_CATEGORY_COUNT_SUCCESS";
export const GET_CATEGORY_COUNT_FAILURE = "GET_CATEGORY_COUNT_FAILURE";

