export const SET_TRADE_VIEW_TYPE = "SET_TRADE_VIEW_TYPE";

export const GET_SEARCH_TRADE_REQUEST="GET_SEARCH_TRADE_REQUEST";
export const GET_SEARCH_TRADE_SUCCESS="GET_SEARCH_TRADE_SUCCESS";
export const GET_SEARCH_TRADE_FAILURE="GET_SEARCH_TRADE_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_TRADE = "HANDLE_CLAER_REDUCER_DATA_TRADE";

export const GET_INVENTORYALLLIST_REQUEST = "GET_INVENTORYALLLIST_REQUEST";
export const GET_INVENTORYALLLIST_SUCCESS = "GET_INVENTORYALLLIST_SUCCESS";
export const GET_INVENTORYALLLIST_FAILURE = "GET_INVENTORYALLLIST_FAILURE";