export const GET_QUALITY_REQUEST = "GET_QUALITY_REQUEST";
export const GET_QUALITY_SUCCESS = "GET_QUALITY_SUCCESS";
export const GET_QUALITY_FAILURE = "GET_QUALITY_FAILURE";

export const ADD_QUALITY_REQUEST = "ADD_QUALITY_REQUEST";
export const ADD_QUALITY_SUCCESS = "ADD_QUALITY_SUCCESS";
export const ADD_QUALITY_FAILURE = "ADD_QUALITY_FAILURE";

export const REMOVE_QUALITY_REQUEST = "REMOVE_QUALITY_REQUEST";
export const REMOVE_QUALITY_SUCCESS = "REMOVE_QUALITY_SUCCESS";
export const REMOVE_QUALITY_FAILURE = "REMOVE_QUALITY_FAILURE";

export const UPDATE_QUALITY_REQUEST = "UPDATE_QUALITY_REQUEST";
export const UPDATE_QUALITY_SUCCESS = "UPDATE_QUALITY_SUCCESS";
export const UPDATE_QUALITY_FAILURE = "UPDATE_QUALITY_FAILURE";

export const GET_QUALITY_SEARCH_REQUEST="GET_QUALITY_SEARCH_REQUEST";
export const GET_QUALITY_SEARCH_SUCCESS="GET_QUALITY_SEARCH_SUCCESS";
export const GET_QUALITY_SEARCH_FAILURE="GET_QUALITY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_QUALITY = "HANDLE_CLAER_REDUCER_DATA_QUALITY";

export const GET_QUALITY_COUNT_REQUEST = "GET_QUALITY_COUNT_REQUEST";
export const GET_QUALITY_COUNT_SUCCESS = "GET_QUALITY_COUNT_SUCCESS";
export const GET_QUALITY_COUNT_FAILURE = "GET_QUALITY_COUNT_FAILURE";

