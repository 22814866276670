export const ADD_HOLIDAY_REQUEST = "ADD_HOLIDAY_REQUEST";
export const ADD_HOLIDAY_SUCCESS = "ADD_HOLIDAY_SUCCESS";
export const ADD_HOLIDAY_FAILURE = "ADD_HOLIDAY_FAILURE";

export const HANDLE_HOLIDAY_MODAL = "HANDLE_HOLIDAY_MODAL";

export const GET_HOLIDAY_REQUEST = "GET_HOLIDAY_REQUEST";
export const GET_HOLIDAY_SUCCESS = "GET_HOLIDAY_SUCCESS";
export const GET_HOLIDAY_FAILURE = "GET_HOLIDAY_FAILURE";

export const GET_HOLIDAYYEAR_REQUEST = "GET_HOLIDAYYEAR_REQUEST";
export const GET_HOLIDAYYEAR_SUCCESS = "GET_HOLIDAYYEAR_SUCCESS";
export const GET_HOLIDAYYEAR_FAILURE = "GET_HOLIDAYYEAR_FAILURE";

export const GET_PLANNER_HOLIDAY_REQUEST = "GET_PLANNER_HOLIDAY_REQUEST";
export const GET_PLANNER_HOLIDAY_SUCCESS = "GET_PLANNER_HOLIDAY_SUCCESS";
export const GET_PLANNER_HOLIDAY_FAILURE = "GET_PLANNER_HOLIDAY_FAILURE";

export const UPDATE_HOLIDAY_REQUEST = "UPDATE_HOLIDAY_REQUEST";
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS";
export const UPDATE_HOLIDAY_FAILURE = "UPDATE_HOLIDAY_FAILURE";

export const DELETE_HOLIDAY_REQUEST = "DELETE_HOLIDAY_REQUEST";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_FAILURE = "DELETE_HOLIDAY_FAILURE";

// export const GET_HOLIDAY_BY_COUNTRY_AND_YEAR_REQUEST = "GET_HOLIDAY_BY_COUNTRY_AND_YEAR_REQUEST";
// export const GET_HOLIDAY_BY_COUNTRY_AND_YEAR_SUCCESS = "GET_HOLIDAY_BY_COUNTRY_AND_YEAR_SUCCESS";
// export const GET_HOLIDAY_BY_COUNTRY_AND_YEAR_FAILURE = "GET_HOLIDAY_BY_COUNTRY_AND_YEAR_FAILURE";
export const ADD_APPLY_OFFER_REQUEST = "ADD_APPLY_OFFER_REQUEST";
export const ADD_APPLY_OFFER_SUCCESS = "ADD_APPLY_OFFER_SUCCESS";
export const ADD_APPLY_OFFER_FAILURE = "ADD_APPLY_OFFER_FAILURE";