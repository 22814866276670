export const HANDLE_PROMOTIOND_MODAL = "HANDLE_PROMOTIOND_MODAL";

export const SET_PROMOTION_VIEW_TYPE="SET_PROMOTION_VIEW_TYPE";

export const ADD_PROMOTIONS_REQUEST = "ADD_PROMOTIONS_REQUEST";
export const ADD_PROMOTIONS_SUCCESS = "ADD_PROMOTIONS_SUCCESS";
export const ADD_PROMOTIONS_FAILURE = "ADD_PROMOTIONS_FAILURE";


export const GET_PRMOTION_DATA_REQUEST = "GET_PRMOTION_DATA_REQUEST";
export const GET_PRMOTION_DATA_SUCCESS = "GET_PRMOTION_DATA_SUCCESS";
export const GET_PRMOTION_DATA_FAILURE = "GET_PRMOTION_DATA_FAILURE";

export const ADDING_PRODUCT_TOGGLE_REQUEST =
"ADDING_PRODUCT_TOGGLE_REQUEST";
export const ADDING_PRODUCT_TOGGLE_SUCCESS =
"ADDING_PRODUCT_TOGGLE_SUCCESS";
export const ADDING_PRODUCT_TOGGLE_FAILURE =
"ADDING_PRODUCT_TOGGLE_FAILURE";

export const ADDING_MATERIAL_TOGGLE_REQUEST =
"ADDING_MATERIAL_TOGGLE_REQUEST";
export const ADDING_MATERIAL_TOGGLE_SUCCESS =
"ADDING_MATERIAL_TOGGLE_SUCCESS";
export const ADDING_MATERIAL_TOGGLE_FAILURE =
"ADDING_MATERIAL_TOGGLE_FAILURE";

export const ADDING_SUPPLIES_TOGGLE_REQUEST =
"ADDING_SUPPLIES_TOGGLE_REQUEST";
export const ADDING_SUPPLIES_TOGGLE_SUCCESS =
"ADDING_SUPPLIES_TOGGLE_SUCCESS";
export const ADDING_SUPPLIES_TOGGLE_FAILURE =
"ADDING_SUPPLIES_TOGGLE_FAILURE";

export const ADDING_DISCOUNT_TOGGLE_REQUEST =
"ADDING_DISCOUNT_TOGGLE_REQUEST";
export const ADDING_DISCOUNT_TOGGLE_SUCCESS =
"ADDING_DISCOUNT_TOGGLE_SUCCESS";
export const ADDING_DISCOUNT_TOGGLE_FAILURE =
"ADDING_DISCOUNT_TOGGLE_FAILURE";

export const HANDLE_UPDATE_PROMOTION_DRAWER="HANDLE_UPDATE_PROMOTION_DRAWER";


export const UPDATE_PRMOTIONS_REQUEST="UPDATE_PRMOTIONS_REQUEST";
export const UPDATE_PRMOTIONS_SUCCESS="UPDATE_PRMOTIONS_SUCCESS";
export const UPDATE_PRMOTIONS_FAILURE="UPDATE_PRMOTIONS_FAILURE";