export const HANDLE_TEAMS_MODAL = "HANDLE_TEAMS_MODAL";

export const SET_TEAMS_VIEW_TYPE = "SET_TEAMS_VIEW_TYPE";

export const HANDLE_ADD_TEAMS_MEMBER_MODAL = "HANDLE_ADD_TEAMS_MEMBER_MODAL";

export const GET_ONLY_MANAGEMENT_USERS_REQUEST =
  "GET_ONLY_MANAGEMENT_USERS_REQUEST";
export const GET_ONLY_MANAGEMENT_USERS_SUCCESS =
  " GET_ONLY_MANAGEMENT_USERS_SUCCESS";
export const GET_ONLY_MANAGEMENT_USERS_FAILURE =
  "GET_ONLY_MANAGEMENT_USERS_FAILURE";

export const GET_ONLY_SALES_USERS_REQUEST = "GET_ONLY_SALES_USERS_REQUEST";
export const GET_ONLY_SALES_USERS_SUCCESS = " GET_ONLY_SALES_USERS_SUCCESS";
export const GET_ONLY_SALES_USERS_FAILURE = "GET_ONLY_SALES_USERS_FAILURE";

export const ADD_TEAMS_CONTACT_REQUEST = "ADD_TEAMS_CONTACT_REQUEST";
export const ADD_TEAMS_CONTACT_SUCCESS = "ADD_TEAMS_CONTACT_SUCCESS";
export const ADD_TEAMS_CONTACT_FAILURE = "ADD_TEAMS_CONTACT_FAILURE";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

export const GET_TEAMTEAM_REQUEST = "GET_TEAMTEAM_REQUEST";
export const GET_TEAMTEAM_SUCCESS = "GET_TEAMTEAM_SUCCESS";
export const GET_TEAMTEAM_FAILURE = "GET_TEAMTEAM_FAILURE";

export const GET_TEAM_BY_TEAM_ID_REQUEST = "GET_TEAM_BY_TEAM_ID_REQUEST";
export const GET_TEAM_BY_TEAM_ID_SUCCESS = "GET_TEAM_BY_TEAM_ID_SUCCESS";
export const GET_TEAM_BY_TEAM_ID_FAILURE = "GET_TEAM_BY_TEAM_ID_FAILURE";

export const LINK_USER_TO_TEAM_REQUEST = "LINK_USER_TO_TEAM_REQUEST";
export const LINK_USER_TO_TEAM_SUCCESS = "LINK_USER_TO_TEAM_SUCCESS";
export const LINK_USER_TO_TEAM_FAILURE = "LINK_USER_TO_TEAM_FAILURE";

export const GET_USER_BY_TEAMID_REQUEST = "GET_USER_BY_TEAMID_REQUEST";
export const GET_USER_BY_TEAMID_SUCCESS = "GET_USER_BY_TEAMID_SUCCESS";
export const GET_USER_BY_TEAMID_FAILURE = "GET_USER_BY_TEAMID_FAILURE";

export const HANDLE_TEAMS_TRANSFER_MODAL = "HANDLE_TEAMS_TRANSFER_MODAL";

export const LINK_SALES_USER_TO_TEAM_REQUEST =
  "LINK_SALES_USER_TO_TEAM_REQUEST";
export const LINK_SALES_USER_TO_TEAM_SUCCESS =
  "LINK_SALES_USER_TO_TEAM_SUCCESS";
export const LINK_SALES_USER_TO_TEAM_FAILURE =
  "LINK_SALES_USER_TO_TEAM_FAILURE";

export const GET_SALES_MANAGER_USERS_REQUEST =
  "GET_SALES_MANAGER_USERS_REQUEST";
export const GET_SALES_MANAGER_USERS_SUCCESS =
  "GET_SALES_MANAGER_USERS_SUCCESS";
export const GET_SALES_MANAGER_USERS_FAILURE =
  "GET_SALES_MANAGER_USERS_FAILURE";

export const ADD_ORDER_VIEW_REQUEST = "ADD_ORDER_VIEW_REQUEST";
export const ADD_ORDER_VIEW_SUCCESS = "ADD_ORDER_VIEW_SUCCESS";
export const ADD_ORDER_VIEW_FAILURE = "ADD_ORDER_VIEW_FAILURE";

export const GET_ORDER_VIEW_REQUEST = "GET_ORDER_VIEW_REQUEST";
export const GET_ORDER_VIEW_SUCCESS = "GET_ORDER_VIEW_SUCCESS";
export const GET_ORDER_VIEW_FAILURE = "GET_ORDER_VIEW_FAILURE";

export const UPDATE_TEAMS_CARD_REQUEST = "UPDATE_TEAMS_CARD_REQUEST";
export const UPDATE_TEAMS_CARD_SUCCESS = "UPDATE_TEAMS_CARD_SUCCESS";
export const UPDATE_TEAMS_CARD_FAILURE = "UPDATE_TEAMS_CARD_FAILURE";

export const GET_PRODUCTION_EXECUTIVE_AND_MANAGER_REQUEST =
  "GET_PRODUCTION_EXECUTIVE_AND_MANAGER_REQUEST";
export const GET_PRODUCTION_EXECUTIVE_AND_MANAGER_SUCCESS =
  "GET_PRODUCTION_EXECUTIVE_AND_MANAGER_SUCCESS";
export const GET_PRODUCTION_EXECUTIVE_AND_MANAGER_FAILURE =
  "GET_PRODUCTION_EXECUTIVE_AND_MANAGER_FAILURE";
//addLocationsinteams
export const UPDATE_LOCATIONS_IN_TEAMS_REQUEST =
  "UPDATE_LOCATIONS_IN_TEAMS_REQUEST";
export const UPDATE_LOCATIONS_IN_TEAMS_SUCCESS =
  "UPDATE_LOCATIONS_IN_TEAMS_SUCCESS";
export const UPDATE_LOCATIONS_IN_TEAMS_FAILURE =
  "UPDATE_LOCATIONS_IN_TEAMS_FAILURE";

//editTeams
export const SET_EDIT_TEAMS = "SET_EDIT_TEAMS";
export const HANDLE_UPDATE_TEAMS_ALLOCATION_MODAL =
  "HANDLE_UPDATE_TEAMS_ALLOCATION_MODAL";

export const UPDATE_TEAMS_ALLOCATION_REQUEST =
  "UPDATE_TEAMS_ALLOCATION_REQUEST";
export const UPDATE_TEAMS_ALLOCATION_SUCCESS =
  "UPDATE_TEAMS_ALLOCATION_SUCCESS";
export const UPDATE_TEAMS_ALLOCATION_FAILURE =
  "UPDATE_TEAMS_ALLOCATION_FAILURE";

export const GET_CLIENT_IN_TEAM_REQUEST = "GET_CLIENT_IN_TEAM_REQUEST";
export const GET_CLIENT_IN_TEAM_SUCCESS = "GET_CLIENT_IN_TEAM_SUCCESS";
export const GET_CLIENT_IN_TEAM_FAILURE = "GET_CLIENT_IN_TEAM_FAILURE";

export const GET_INVENTORY_IN_TEAM_REQUEST = "GET_INVENTORY_IN_TEAM_REQUEST";
export const GET_INVENTORY_IN_TEAM_SUCCESS = "GET_INVENTORY_IN_TEAM_SUCCESS";
export const GET_INVENTORY_IN_TEAM_FAILURE = "GET_INVENTORY_IN_TEAM_FAILURE";


export const GET_TEAM_MEMBER_LIST_REQUEST = "GET_TEAM_MEMBER_LIST_REQUEST";
export const GET_TEAM_MEMBER_LIST_SUCCESS = "GET_TEAM_MEMBER_LIST_SUCCESS";
export const GET_TEAM_MEMBER_LIST_FAILURE = "GET_TEAM_MEMBER_LIST_FAILURE";

export const GET_REPORTING_MANAGER_REQUEST = "GET_REPORTING_MANAGER_REQUEST";
export const GET_REPORTING_MANAGER_SUCCESS = "GET_REPORTING_MANAGER_SUCCESS";
export const GET_REPORTING_MANAGER_FAILURE = "GET_REPORTING_MANAGER_FAILURE";

export const ADD_TEAMS_REQUEST = "ADD_TEAMS_REQUEST";
export const ADD_TEAMS_SUCCESS = "ADD_TEAMS_SUCCESS";
export const ADD_TEAMS_FAILURE = "ADD_TEAMS_FAILURE";


export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const HANDLE_PERFORMANE_DRAWER_MODAL = "HANDLE_PERFORMANE_DRAWER_MODAL";

export const GET_KPILIST_REQUEST =
  "GET_KPILIST_REQUEST";
export const GET_KPILIST_SUCCESS =
  "GET_KPILIST_SUCCESS";
export const GET_KPILIST_FAILURE =
  "GET_KPILIST_FAILURE";


  export const GET_EMPLOYEE_KPI_LIST_REQUEST="GET_EMPLOYEE_KPI_LIST_REQUEST";
export const GET_EMPLOYEE_KPI_LIST_SUCCESS="GET_EMPLOYEE_KPI_LIST_SUCCESS";
export const GET_EMPLOYEE_KPI_LIST_FAILURE="GET_EMPLOYEE_KPI_LIST_FAILURE";


  export const ADD_KPI_REQUEST = "ADD_KPI_REQUEST";
export const ADD_KPI_SUCCESS = "ADD_KPI_SUCCESS";
export const ADD_KPI_FAILURE = "ADD_KPI_FAILURE";

export const GET_PERFORMANCE_RECORDS_REQUEST = "GET_PERFORMANCE_RECORDS_REQUEST";
export const GET_PERFORMANCE_RECORDS_SUCCESS = "GET_PERFORMANCE_RECORDS_SUCCESS";
export const GET_PERFORMANCE_RECORDS_FAILURE = "GET_PERFORMANCE_RECORDS_FAILURE";

export const DELETE_KPI_DATA_REQUEST = "DELETE_KPI_DATA_REQUEST";
export const DELETE_KPI_DATA_SUCCESS = "DELETE_KPI_DATA_SUCCESS";
export const DELETE_KPI_DATA_FAILURE = "DELETE_KPI_DATA_FAILURE";

export const UPDATE_COMPLETED_VALUE_REQUEST = "UPDATE_COMPLETED_VALUE_REQUEST";
export const UPDATE_COMPLETED_VALUE_SUCCESS = "UPDATE_COMPLETED_VALUE_SUCCESS";
export const UPDATE_COMPLETED_VALUE_FAILURE = "UPDATE_COMPLETED_VALUE_FAILURE";


export const UPDATE_ASSIGNED_VALUE_REQUEST = "UPDATE_ASSIGNED_VALUE_REQUEST";
export const UPDATE_ASSIGNED_VALUE_SUCCESS = "UPDATE_ASSIGNED_VALUE_SUCCESS";
export const UPDATE_ASSIGNED_VALUE_FAILURE = "UPDATE_ASSIGNED_VALUE_FAILURE";

export const HANDLE_TEAMS_PULSE_DRAWER_MODAL = "HANDLE_TEAMS_PULSE_DRAWER_MODAL";


