export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";


export const ADD_TASK_REQUEST = "ADD_TASK_REQUEST";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAILURE = "ADD_TASK_FAILURE";

export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";

export const GET_TASK_SEARCH_REQUEST="GET_TASK_SEARCH_REQUEST";
export const GET_TASK_SEARCH_SUCCESS="GET_TASK_SEARCH_SUCCESS";
export const GET_TASK_SEARCH_FAILURE="GET_TASK_SEARCH_FAILURE";

export const REMOVE_TASK_REQUEST = "REMOVE_TASK_REQUEST";
export const REMOVE_TASK_SUCCESS = "REMOVE_TASK_SUCCESS";
export const REMOVE_TASK_FAILURE = "REMOVE_TASK_FAILURE";

export const LINK_TASK_WORKFLOW_TOGGLE_REQUEST =
  "LINK_TASK_WORKFLOW_TOGGLE_REQUEST";
export const LINK_TASK_WORKFLOW_TOGGLE_SUCCESS =
  "LINK_TASK_WORKFLOW_TOGGLE_SUCCESS";
export const LINK_TASK_WORKFLOW_TOGGLE_FAILURE =
  "LINK_TASK_WORKFLOW_TOGGLE_FAILURE";


  export const GET_TASK_COUNT_REQUEST = "GET_TASK_COUNT_REQUEST";
  export const GET_TASK_COUNT_SUCCESS = "GET_TASK_COUNT_SUCCESS";
  export const GET_TASK_COUNT_FAILURE = "GET_TASK_COUNT_FAILURE";
  

  export const HANDLE_CLAER_REDUCER_DATA_TASK = "HANDLE_CLAER_REDUCER_DATA_TASK";

