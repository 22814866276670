export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";

export const ADD_DOCUMENTS_REQUEST = "ADD_DOCUMENTS_REQUEST";
export const ADD_DOCUMENTS_SUCCESS = "ADD_DOCUMENTS_SUCCESS";
export const ADD_DOCUMENTS_FAILURE = "ADD_DOCUMENTS_FAILURE";

export const REMOVE_DOCUMENTS_REQUEST = "REMOVE_DOCUMENTS_REQUEST";
export const REMOVE_DOCUMENTS_SUCCESS = "REMOVE_DOCUMENTS_SUCCESS";
export const REMOVE_DOCUMENTS_FAILURE = "REMOVE_DOCUMENTS_FAILURE";

export const UPDATE_DOCUMENTS_REQUEST = "UPDATE_DOCUMENTS_REQUEST";
export const UPDATE_DOCUMENTS_SUCCESS = "UPDATE_DOCUMENTS_SUCCESS";
export const UPDATE_DOCUMENTS_FAILURE = "UPDATE_DOCUMENTS_FAILURE";

export const GET_DOCUMENT_SEARCH_REQUEST="GET_DOCUMENT_SEARCH_REQUEST";
export const GET_DOCUMENT_SEARCH_SUCCESS="GET_DOCUMENT_SEARCH_SUCCESS";
export const GET_DOCUMENT_SEARCH_FAILURE="GET_DOCUMENT_SEARCH_FAILURE";

export const LINK_DOCUMENT_TOGGLE_REQUEST =
  "LINK_DOCUMENT_TOGGLE_REQUEST";
export const LINK_DOCUMENT_TOGGLE_SUCCESS =
  "LINK_DOCUMENT_TOGGLE_SUCCESS";
export const LINK_DOCUMENT_TOGGLE_FAILURE =
  "LINK_DOCUMENT_TOGGLE_FAILURE";


  export const LINK_TYPE_TOGGLE_REQUEST =
  "LINK_TYPE_TOGGLE_REQUEST";
export const LINK_TYPE_TOGGLE_SUCCESS =
  "LINK_TYPE_TOGGLE_SUCCESS";
export const LINK_TYPE_TOGGLE_FAILURE =
  "LINK_TYPE_TOGGLE_FAILURE";

  export const LINK_WORKFLOW_DOCUMENT_TOGGLE_REQUEST =
  "LINK_WORKFLOW_DOCUMENT_TOGGLE_REQUEST";
export const LINK_WORKFLOW_DOCUMENT_TOGGLE_SUCCESS =
  "LINK_WORKFLOW_DOCUMENT_TOGGLE_SUCCESS";
export const LINK_WORKFLOW_DOCUMENT_TOGGLE_FAILURE =
  "LINK_WORKFLOW_DOCUMENT_TOGGLE_FAILURE";


  export const GET_DOCUMENT_COUNT_REQUEST = "GET_DOCUMENT_COUNT_REQUEST";
  export const GET_DOCUMENT_COUNT_SUCCESS = "GET_DOCUMENT_COUNT_SUCCESS";
  export const GET_DOCUMENT_COUNT_FAILURE = "GET_DOCUMENT_COUNT_FAILURE";
  


  export const GET_ALL_DOCUMENTS_TYPE_REQUEST = "GET_ALL_DOCUMENTS_TYPE_REQUEST";
export const GET_ALL_DOCUMENTS_TYPE_SUCCESS = "GET_ALL_DOCUMENTS_TYPE_SUCCESS";
export const GET_ALL_DOCUMENTS_TYPE_FAILURE = "GET_ALL_DOCUMENTS_TYPE_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_DOCUMENT = "HANDLE_CLAER_REDUCER_DATA_DOCUMENT";