
export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";

export const LINK_COUNTRY_TOGGLE_REQUEST =
  "LINK_COUNTRY_TOGGLE_REQUEST";
export const LINK_COUNTRY_TOGGLE_SUCCESS =
  "LINK_COUNTRY_TOGGLE_SUCCESS";
export const LINK_COUNTRY_TOGGLE_FAILURE =
  "LINK_COUNTRY_TOGGLE_FAILURE";

  export const LINK_COUNTRY_SALES_TOGGLE_REQUEST =
  "LINK_COUNTRY_SALES_TOGGLE_REQUEST";
export const LINK_COUNTRY_SALES_TOGGLE_SUCCESS =
  "LINK_COUNTRY_SALES_TOGGLE_SUCCESS";
export const LINK_COUNTRY_SALES_TOGGLE_FAILURE =
  "LINK_COUNTRY_SALES_TOGGLE_FAILURE";

  export const ALL_COUNTRY_MANDATORY_REQUEST =
"ALL_COUNTRY_MANDATORY_REQUEST";
export const ALL_COUNTRY_MANDATORY_SUCCESS =
"ALL_COUNTRY_MANDATORY_SUCCESS";
export const ALL_COUNTRY_MANDATORY_FAILURE =
"ALL_COUNTRY_MANDATORY_FAILURE";

export const GET_COUNTRY_SEARCH_REQUEST="GET_COUNTRY_SEARCH_REQUEST";
export const GET_COUNTRY_SEARCH_SUCCESS="GET_COUNTRY_SEARCH_SUCCESS";
export const GET_COUNTRY_SEARCH_FAILURE="GET_COUNTRY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_COUNTRY = "HANDLE_CLAER_REDUCER_DATA_COUNTRY";


export const GET_COUNTRY_COUNT_REQUEST = "GET_COUNTRY_COUNT_REQUEST";
export const GET_COUNTRY_COUNT_SUCCESS = "GET_COUNTRY_COUNT_SUCCESS";
export const GET_COUNTRY_COUNT_FAILURE = "GET_COUNTRY_COUNT_FAILURE";

