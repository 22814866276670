export const HANDLE_PROCESS_MODAL = "HANDLE_PROCESS_MODAL";

export const HANDLE_TASK_DRAWER = "HANDLE_TASK_DRAWERL";

export const HANDLE_PROCESS_HIRING_MODAL = "HANDLE_PROCESS_HIRING_MODAL";

export const ADD_PROCESS_TASK_STAGE_REQUEST = "ADD_PROCESS_TASK_STAGE_REQUEST";
export const ADD_PROCESS_TASK_STAGE_SUCCESS = "ADD_PROCESS_TASK_STAGE_SUCCESS";
export const ADD_PROCESS_TASK_STAGE_FAILURE = "ADD_PROCESS_TASK_STAGE_FAILURE";



export const GET_PAYMENT_FINANCE_REQUEST = "GET_PAYMENT_FINANCE_REQUEST";
export const GET_PAYMENT_FINANCE_SUCCESS = "GET_PAYMENT_FINANCE_SUCCESS";
export const GET_PAYMENT_FINANCE_FAILURE = "GET_PAYMENT_FINANCE_FAILURE";



export const MOVE_REJECT_TOGGLE_TASK_REQUEST = "MOVE_REJECT_TOGGLE_TASK_REQUEST";
export const MOVE_REJECT_TOGGLE_TASK_SUCCESS = "MOVE_REJECT_TOGGLE_TASK_SUCCESS";
export const MOVE_REJECT_TOGGLE_TASK_FAILURE = "MOVE_REJECT_TOGGLE_TASK_FAILURE";




export const UPDATE_PROCESS_TASK_STAGE_REQUEST = "UPDATE_PROCESS_TASK_STAGE_REQUEST";
export const UPDATE_PROCESS_TASK_STAGE_SUCCESS = "UPDATE_PROCESS_TASK_STAGE_SUCCESS";
export const UPDATE_PROCESS_TASK_STAGE_FAILURE = "UPDATE_PROCESS_TASK_STAGE_FAILURE";


export const ADD_GLOBAL_TYPE_DUPLICATE = "ADD_GLOBAL_TYPE_DUPLICATE";

export const ADD_GLOBAL_TYPE_REQUEST = "ADD_GLOBAL_TYPE_REQUEST";
export const ADD_GLOBAL_TYPE_SUCCESS = "ADD_GLOBAL_TYPE_SUCCESS";
export const ADD_GLOBAL_TYPE_FAILURE = "ADD_GLOBAL_TYPE_FAILURE";


export const HANDLE_DEALS_STAGES_DRAWER_MODAL = "HANDLE_DEALS_STAGES_DRAWER_MODAL";



export const GET_PROCESS_TASK_STAGE_REQUEST = "GET_PROCESS_TASK_STAGE_REQUEST";
export const GET_PROCESS_TASK_STAGE_SUCCESS = "GET_PROCESS_TASK_STAGE_SUCCESS";
export const GET_PROCESS_TASK_STAGE_FAILURE = "GET_PROCESS_TASK_STAGE_FAILURE";


export const LINK_DEALS_PROCESS_GLOBAL_REQUEST =
  "LINK_DEALS_PROCESS_GLOBAL_REQUEST";
export const LINK_DEALS_PROCESS_GLOBAL_SUCCESS =
  "LINK_DEALS_PROCESS_GLOBAL_SUCCESS";
export const LINK_DEALS_PROCESS_GLOBAL_FAILURE =
  "LINK_DEALS_PROCESS_GLOBAL_FAILURE";




export const GET_ORG_TYPE_REQUEST =
  "GET_ORG_TYPE_REQUEST";
export const GET_ORG_TYPE_SUCCESS =
  "GET_ORG_TYPE_SUCCESS";
export const GET_ORG_TYPE_FAILURE =
  "GET_ORG_TYPE_FAILURE";




export const UPDATE_ORGANIZATION_TYPE_REQUEST = "UPDATE_ORGANIZATION_TYPE_REQUEST";
export const UPDATE_ORGANIZATION_TYPE_SUCCESS = "UPDATE_ORGANIZATION_TYPE_SUCCESS";
export const UPDATE_ORGANIZATION_TYPE_FAILURE = "UPDATE_ORGANIZATION_TYPE_FAILURE";


export const ADD_TASK_FOR_RECRUIT_REQUEST =
  "ADD_TASK_FOR_RECRUIT_REQUEST";
export const ADD_TASK_FOR_RECRUIT_SUCCESS =
  "ADD_TASK_FOR_RECRUIT_SUCCESS";
export const ADD_TASK_FOR_RECRUIT_FAILURE =
  "ADD_TASK_FOR_RECRUIT_FAILURE";

  export const GET_MATRIX_DATA_REQUEST = "GET_MATRIX_DATA_REQUEST";
export const GET_MATRIX_DATA_SUCCESS = "GET_MATRIX_DATA_SUCCESS";
export const GET_MATRIX_DATA_FAILURE = "GET_MATRIX_DATA_FAILURE";

  export const ADD_SKILL_LEVEL_REQUEST = "ADD_SKILL_LEVEL_REQUEST";
  export const ADD_SKILL_LEVEL_SUCCESS = "ADD_SKILL_LEVEL_SUCCESS";
  export const ADD_SKILL_LEVEL_FAILURE = "ADD_SKILL_LEVEL_FAILURE";

export const UPDATE_TASK_STAGE_FOR_RECRUIT_REQUEST =
  "UPDATE_TASK_STAGE_FOR_RECRUIT_REQUEST";
export const UPDATE_TASK_STAGE_FOR_RECRUIT_SUCCESS =
  "UPDATE_TASK_STAGE_FOR_RECRUIT_SUCCESS";
export const UPDATE_TASK_STAGE_FOR_RECRUIT_FAILURE =
  "UPDATE_TASK_STAGE_FOR_RECRUIT_FAILURE";

export const DELETE_TASK_STAGES_DATA_REQUEST = "DELETE_TASK_STAGES_DATA_REQUEST";
export const DELETE_TASK_STAGES_DATA_SUCCESS = "DELETE_TASK_STAGES_DATA_SUCCESS";
export const DELETE_TASK_STAGES_DATA_FAILURE = "DELETE_TASK_STAGES_DATA_FAILURE";


export const DELETE_TASK_DATA_REQUEST = "DELETE_TASK_DATA_REQUEST";
export const DELETE_TASK_DATA_SUCCESS = "DELETE_TASK_DATA_SUCCESS";
export const DELETE_TASK_DATA_FAILURE = "DELETE_TASK_DATA_FAILURE";


export const GET_TASK_STAGES_FOR_RECRUIT_REQUEST =
  "GET_TASK_STAGES_FOR_RECRUIT_REQUEST";
export const GET_TASK_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_TASK_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_TASK_STAGES_FOR_RECRUIT_FAILURE =
  "GET_TASK_STAGES_FOR_RECRUIT_FAILURE";

export const GET_ALL_VAT_REQUEST =
  "GET_ALL_VAT_REQUEST";
export const GET_ALL_VAT_SUCCESS =
  "GET_ALL_VAT_SUCCESS";
export const GET_ALL_VAT_FAILURE =
  "GET_ALL_VAT_FAILURE";

export const ADD_TASK_STAGE_FOR_RECRUIT_REQUEST =
  "ADD_TASK_STAGE_FOR_RECRUIT_REQUEST";
export const ADD_TASK_STAGE_FOR_RECRUIT_SUCCESS =
  "ADD_TASK_STAGE_FOR_RECRUIT_SUCCESS";
export const ADD_TASK_STAGE_FOR_RECRUIT_FAILURE =
  "ADD_TASK_STAGE_FOR_RECRUIT_FAILURE";


export const UPDATE_TASK_NAME_FOR_RECRUIT_REQUEST =
  "UPDATE_TASK_NAME_FOR_RECRUIT_REQUEST";
export const UPDATE_TASK_NAME_FOR_RECRUIT_SUCCESS =
  "UPDATE_TASK_NAME_FOR_RECRUIT_SUCCESS";
export const UPDATE_TASK_NAME_FOR_RECRUIT_FAILURE =
  "UPDATE_TASK_NAME_FOR_RECRUIT_FAILURE";


export const GET_TASK_FOR_RECRUIT_REQUEST =
  "GET_TASK_FOR_RECRUIT_REQUEST";
export const GET_TASK_FOR_RECRUIT_SUCCESS =
  "GET_TASK_FOR_RECRUIT_SUCCESS";
export const GET_TASK_FOR_RECRUIT_FAILURE =
  "GET_TASK_FOR_RECRUIT_FAILURE";

export const HANDLE_TASK_EDIT_PROCESS_MODAL = "HANDLE_TASK_EDIT_PROCESS_MODAL";
export const HANDLE_PROCESS_TASK_MODAL = "HANDLE_PROCESS_TASK_MODAL";
export const HANDLE_RULES_MODAL = "HANDLE_RULES_MODAL";

export const ADD_PROCESS_REQUEST = "ADD_PROCESS_REQUEST";
export const ADD_PROCESS_SUCCESS = "ADD_PROCESS_SUCCESS";
export const ADD_PROCESS_FAILURE = "ADD_PROCESS_FAILURE";

export const HANDLE_CANDIDATE_SEQUENCE_MODAL = "HANDLE_CANDIDATE_SEQUENCE_MODAL";

export const UPDATE_PROCESS_TASK_REQUEST = "UPDATE_PROCESS_TASK_REQUEST";
export const UPDATE_PROCESS_TASK_SUCCESS = "UPDATE_PROCESS_TASK_SUCCESS";
export const UPDATE_PROCESS_TASK_FAILURE = "UPDATE_PROCESS_TASK_FAILURE";

export const GET_PROCESS_REQUEST = "GET_PROCESS_REQUEST";
export const GET_PROCESS_SUCCESS = "GET_PROCESS_SUCCESS";
export const GET_PROCESS_FAILURE = "GET_PROCESS_FAILURE";

export const UPDATE_STAGE_REQUEST = "UPDATE_STAGE_REQUEST";
export const UPDATE_STAGE_SUCCESS = "UPDATE_STAGE_SUCCESS";
export const UPDATE_STAGE_FAILURE = "UPDATE_STAGE_FAILURE";

export const GET_DEFAULT_PROCESS_REQUEST = "GET_DEFAULT_PROCESS_REQUEST";
export const GET_DEFAULT_PROCESS_SUCCESS = "GET_DEFAULT_PROCESS_SUCCESS";
export const GET_DEFAULT_PROCESS_FAILURE = "GET_DEFAULT_PROCESS_FAILURE";

export const ADD_PROCESS_TASK_REQUEST = "ADD_PROCESS_TASK_REQUEST";
export const ADD_PROCESS_TASK_SUCCESS = "ADD_PROCESS_TASK_SUCCESS";
export const ADD_PROCESS_TASK_FAILURE = "ADD_PROCESS_TASK_FAILURE";

export const GET_PROCESS_TASK_REQUEST = "GET_PROCESS_TASK_REQUEST";
export const GET_PROCESS_TASK_SUCCESS = "GET_PROCESS_TASK_SUCCESS";
export const GET_PROCESS_TASK_FAILURE = "GET_PROCESS_TASK_FAILURE";

export const GET_PROCESS_STAGES_REQUEST = "GET_PROCESS_STAGES_REQUEST";
export const GET_PROCESS_STAGES_SUCCESS = "GET_PROCESS_STAGES_SUCCESS";
export const GET_PROCESS_STAGES_FAILURE = "GET_PROCESS_STAGES_FAILURE";

export const REMOVE_STAGE_REQUEST = "REMOVE_STAGE_REQUEST";
export const REMOVE_STAGE_SUCCESS = "REMOVE_STAGE_SUCCESS";
export const REMOVE_STAGE_FAILURE = "REMOVE_STAGE_FAILURE";

export const ADD_PROCESS_STAGE_REQUEST = "ADD_PROCESS_STAGE_REQUEST";
export const ADD_PROCESS_STAGE_SUCCESS = "ADD_PROCESS_STAGE_SUCCESS";
export const ADD_PROCESS_STAGE_FAILURE = "ADD_PROCESS_STAGE_FAILURE";

export const UPDATE_PROCESS_NAME_REQUEST = "UPDATE_PROCESS_NAME_REQUEST";
export const UPDATE_PROCESS_NAME_SUCCESS = "UPDATE_PROCESS_NAME_SUCCESS";
export const UPDATE_PROCESS_NAME_FAILURE = "UPDATE_PROCESS_NAME_FAILURE";

export const GET_ALL_PROCESS_STAGES_REQUEST = "GET_ALL_PROCESS_STAGES_REQUEST";
export const GET_ALL_PROCESS_STAGES_SUCCESS = "GET_ALL_PROCESS_STAGES_SUCCESS";
export const GET_ALL_PROCESS_STAGES_FAILURE = "GET_ALL_PROCESS_STAGES_FAILURE";

export const GET_OPPO_STAGES_REQUEST = "GET_OPPO_STAGES_REQUEST";
export const GET_OPPO_STAGES_SUCCESS = "GET_OPPO_STAGES_SUCCESS";
export const GET_OPPO_STAGES_FAILURE = "GET_OPPO_STAGES_FAILURE";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";

export const GET_LEVELS_REQUEST = "GET_LEVELS_REQUEST";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAILURE = "GET_LEVELS_FAILURE";

export const UPDATE_TASK_BY_ID_REQUEST = "UPDATE_TASK_BY_ID_REQUEST";
export const UPDATE_TASK_BY_ID_SUCCESS = "UPDATE_TASK_BY_ID_SUCCESS";
export const UPDATE_TASK_BY_ID_FAILURE = "UPDATE_TASK_BY_ID_FAILURE";

export const DELETE_TASK_REQUEST = "DELETE_TASK_REQUEST";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILURE = "DELETE_TASK_FAILURE";

export const HANDLE_TASK_MODAL = "HANDLE_TASK_MODAL";
export const SET_TASK_EDIT = "SET_TASK_EDIT";

export const UPDATE_TASK_RESUFFEL_BY_ID_REQUEST =
  "UPDATE_TASK_RESUFFEL_BY_ID_REQUEST";
export const UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS =
  "UPDATE_TASK_RESUFFEL_BY_ID_SUCCESS";
export const UPDATE_TASK_RESUFFEL_BY_ID_FAILURE =
  "UPDATE_TASK_RESUFFEL_BY_ID_FAILURE";

//reQCRUITMENT
export const GET_PROCESS_FOR_RECRUIT_REQUEST =
  "GET_PROCESS_FOR_RECRUIT_REQUEST";
export const GET_PROCESS_FOR_RECRUIT_SUCCESS =
  "GET_PROCESS_FOR_RECRUIT_SUCCESS";
export const GET_PROCESS_FOR_RECRUIT_FAILURE =
  "GET_PROCESS_FOR_RECRUIT_FAILURE";

export const ADD_PROCESS_FOR_RECRUIT_REQUEST =
  "ADD_PROCESS_FOR_RECRUIT_REQUEST";
export const ADD_PROCESS_FOR_RECRUIT_SUCCESS =
  "ADD_PROCESS_FOR_RECRUIT_SUCCESS";
export const ADD_PROCESS_FOR_RECRUIT_FAILURE =
  "ADD_PROCESS_FOR_RECRUIT_FAILURE";

export const GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST =
  "GET_PROCESS_STAGES_FOR_RECRUIT_REQUEST";
export const GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_PROCESS_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE =
  "GET_PROCESS_STAGES_FOR_RECRUIT_FAILURE";

export const ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_REQUEST";
export const ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE =
  "ADD_PROCESS_STAGE_FOR_RECRUIT_FAILURE";

export const UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_RECRUIT_FAILURE";

export const UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_OPPORTUNITY_FAILURE";

export const UPDATE_STAGE_FOR_RECRUIT_REQUEST =
  "UPDATE_STAGE_FOR_RECRUIT_REQUEST";
export const UPDATE_STAGE_FOR_RECRUIT_SUCCESS =
  "UPDATE_STAGE_FOR_RECRUIT_SUCCESS";
export const UPDATE_STAGE_FOR_RECRUIT_FAILURE =
  "UPDATE_STAGE_FOR_RECRUIT_FAILURE";

export const UPDATE_STAGE_FOR_OPPORTUNITY_REQUEST =
  "UPDATE_STAGE_FOR_OPPORTUNITY_REQUEST";
export const UPDATE_STAGE_FOR_OPPORTUNITY_SUCCESS =
  "UPDATE_STAGE_FOR_OPPORTUNITY_SUCCESS";
export const UPDATE_STAGE_FOR_OPPORTUNITY_FAILURE =
  "UPDATE_STAGE_FOR_OPPORTUNITY_FAILURE";

export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_REQUEST";
export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE =
  "GET_ALL_PROCESS_STAGES_FOR_RECRUIT_FAILURE";

export const ADD_LEAVES_REQUEST = "ADD_LEAVES_REQUEST";
export const ADD_LEAVES_SUCCESS = "ADD_LEAVES_SUCCESS";
export const ADD_LEAVES_FAILURE = "ADD_LEAVES_FAILURE";

export const GET_LEAVES_DETAIL_REQUEST = "GET_LEAVES_DETAIL_REQUEST";
export const GET_LEAVES_DETAIL_SUCCESS = "GET_LEAVES_DETAIL_SUCCESS";
export const GET_LEAVES_DETAIL_FAILURE = "GET_LEAVES_DETAIL_FAILURE";

export const GET_SIGNATURE_REQUEST = "GET_SIGNATURE_REQUEST";
export const GET_SIGNATURE_SUCCESS = "GET_SIGNATURE_SUCCESS";
export const GET_SIGNATURE_FAILURE = "GET_SIGNATURE_FAILURE";

export const HANDLE_EMAIL_MODAL = "HANDLE_EMAIL_MODAL";
export const HANDLE_WEBSITE_MODAL = "HANDLE_WEBSITE_MODAL";

export const HANDLE_UPDATE_EMAIL_MODAL = "HANDLE_UPDATE_EMAIL_MODAL";

export const SET_EMAIL_EDIT = "SET_EMAIL_EDIT";

export const DATA_CLEAR = "DATA_CLEAR";

export const ENABLE_RECRUITMENT_ADVANCE_REQUEST =
  "ENABLE_RECRUITMENT_ADVANCE_REQUEST";
export const ENABLE_RECRUITMENT_ADVANCE_SUCCESS =
  "ENABLE_RECRUITMENT_ADVANCE_SUCCESS";
export const ENABLE_RECRUITMENT_ADVANCE_FAILURE =
  "ENABLE_RECRUITMENT_ADVANCE_FAILURE";


export const GET_MILEAGE_DETAIL_REQUEST = "GET_MILEAGE_DETAIL_REQUEST";
export const GET_MILEAGE_DETAIL_SUCCESS = "GET_MILEAGE_DETAIL_SUCCESS";
export const GET_MILEAGE_DETAIL_FAILURE = "GET_MILEAGE_DETAIL_FAILURE";

export const UPDATE_MILEAGE_REQUEST = "UPDATE_MILEAGE_REQUEST";
export const UPDATE_MILEAGE_SUCCESS = "UPDATE_MILEAGE_SUCCESS";
export const UPDATE_MILEAGE_FAILURE = "UPDATE_MILEAGE_FAILURE";

export const HANDLE_APPROVAL_MODAL = "HANDLE_APPROVAL_MODAL";

export const ADD_APPROVAL_REQUEST =
  "ADD_APPROVAL_REQUEST";
export const ADD_APPROVAL_SUCCESS =
  "ADD_APPROVAL_SUCCESS";
export const ADD_APPROVAL_FAILURE =
  "ADD_APPROVAL_FAILURE";


export const GET_APPROVAL_DATA_REQUEST = "GET_APPROVAL_DATA_REQUEST";
export const GET_APPROVAL_DATA_SUCCESS = "GET_APPROVAL_DATA_SUCCESS";
export const GET_APPROVAL_DATA_FAILURE = "GET_APPROVAL_DATA_FAILURE";

export const LINK_PROCESS_PUBLISH_REQUEST =
  "LINK_PROCESS_PUBLISH_REQUEST";
export const LINK_PROCESS_PUBLISH_SUCCESS =
  "LINK_PROCESS_PUBLISH_SUCCESS";
export const LINK_PROCESS_PUBLISH_FAILURE =
  "LINK_PROCESS_PUBLISH_FAILURE";


export const LINK_OPPORTUNITY_PROCESS_PUBLISH_REQUEST =
  "LINK_OPPORTUNITY_PROCESS_PUBLISH_REQUEST";
export const LINK_OPPORTUNITY_PROCESS_PUBLISH_SUCCESS =
  "LINK_OPPORTUNITY_PROCESS_PUBLISH_SUCCESS";
export const LINK_OPPORTUNITY_PROCESS_PUBLISH_FAILURE =
  "LINK_OPPORTUNITY_PROCESS_PUBLISH_FAILURE";

export const LINK_STAGES_PUBLISH_REQUEST =
  "LINK_STAGES_PUBLISH_REQUEST";
export const LINK_STAGES_PUBLISH_SUCCESS =
  "LINK_STAGES_PUBLISH_SUCCESS";
export const LINK_STAGES_PUBLISH_FAILURE =
  "LINK_STAGES_PUBLISH_FAILURE";


export const LINK_OPPORTUNITY_STAGES_PUBLISH_REQUEST =
  "LINK_OPPORTUNITY_STAGES_PUBLISH_REQUEST";
export const LINK_OPPORTUNITY_STAGES_PUBLISH_SUCCESS =
  "LINK_OPPORTUNITY_STAGES_PUBLISH_SUCCESS";
export const LINK_OPPORTUNITY_STAGES_PUBLISH_FAILURE =
  "LINK_OPPORTUNITY_STAGES_PUBLISH_FAILURE";

export const ADD_COMMISSION_REQUEST = "ADD_COMMISSION_REQUEST";
export const ADD_COMMISSION_SUCCESS = "ADD_COMMISSION_SUCCESS";
export const ADD_COMMISSION_FAILURE = "ADD_COMMISSION_FAILURE";

export const GET_COMMISSION_REQUEST = "GET_COMMISSION_REQUEST";
export const GET_COMMISSION_SUCCESS = "GET_COMMISSION_SUCCESS";
export const GET_COMMISSION_FAILURE = "GET_COMMISSION_FAILURE";

export const GET_COMMISSION_TABLE_REQUEST = "GET_COMMISSION_TABLE_REQUEST";
export const GET_COMMISSION_TABLE_SUCCESS = "GET_COMMISSION_TABLE_SUCCESS";
export const GET_COMMISSION_TABLE_FAILURE = "GET_COMMISSION_TABLE_FAILURE";

export const UPDATE_COMMISSION_REQUEST = "UPDATE_COMMISSION_REQUEST";
export const UPDATE_COMMISSION_SUCCESS = "UPDATE_COMMISSION_SUCCESS";
export const UPDATE_COMMISSION_FAILURE = "UPDATE_COMMISSION_FAILURE";

export const SET_EDIT_COMMISSION = "SET_EDIT_COMMISSION";
export const HANDLE_COMMISSION_MODAL = "HANDLE_COMMISSION_MODAL";


export const ADD_RECRUITER_REQUEST = "ADD_RECRUITER_REQUEST";
export const ADD_RECRUITER_SUCCESS = "ADD_RECRUITER_SUCCESS";
export const ADD_RECRUITER_FAILURE = "ADD_RECRUITER_FAILURE";

export const GET_RECRUITER_REQUEST = "GET_RECRUITER_REQUEST";
export const GET_RECRUITER_SUCCESS = "GET_RECRUITER_SUCCESS";
export const GET_RECRUITER_FAILURE = "GET_RECRUITER_FAILURE";

export const GET_RECRUITER_TABLE_REQUEST = "GET_RECRUITER_TABLE_REQUEST";
export const GET_RECRUITER_TABLE_SUCCESS = "GET_RECRUITER_TABLE_SUCCESS";
export const GET_RECRUITER_TABLE_FAILURE = "GET_RECRUITER_TABLE_FAILURE";
//get website
export const GET_WEBSITE_REQUEST = "GET_WEBSITE_REQUEST";
export const GET_WEBSITE_SUCCESS = "GET_WEBSITE_SUCCESS";
export const GET_WEBSITE_FAILURE = "GET_WEBSITE_FAILURE";

//Add website
export const ADD_WEBSITE_REQUEST = "ADD_WEBSITE_REQUEST";
export const ADD_WEBSITE_SUCCESS = "ADD_WEBSITE_SUCCESS";
export const ADD_WEBSITE_FAILURE = "ADD_WEBSITE_FAILURE";


//GET SCHEDULER INTERNAL
export const GET_SCHEDULER_BY_ORG_ID_REQUEST = "GET_SCHEDULER_BY_ORG_ID_REQUEST";
export const GET_SCHEDULER_BY_ORG_ID_SUCCESS = "GET_SCHEDULER_BY_ORG_ID_SUCCESS";
export const GET_SCHEDULER_BY_ORG_ID_FAILURE = "GET_SCHEDULER_BY_ORG_ID_FAILURE";

//ADD SCHEDULER INTERNAL
export const ADD_SCHEDULER_BY_ORG_ID_REQUEST = "ADD_SCHEDULER_BY_ORG_ID_REQUEST";
export const ADD_SCHEDULER_BY_ORG_ID_SUCCESS = "ADD_SCHEDULER_BY_ORG_ID_SUCCESS";
export const ADD_SCHEDULER_BY_ORG_ID_FAILURE = "ADD_SCHEDULER_BY_ORG_ID_FAILURE";

//GET SCHEDULER CUSTOMER
export const GET_SCHEDULER_CUSTOMER_REQUEST = "GET_SCHEDULER_CUSTOMER_REQUEST";
export const GET_SCHEDULER_CUSTOMER_SUCCESS = "GET_SCHEDULER_CUSTOMER_SUCCESS";
export const GET_SCHEDULER_CUSTOMER_FAILURE = "GET_SCHEDULER_CUSTOMER_FAILURE";

//ADD SCHEDULER CUSTOMER
export const ADD_SCHEDULER_CUSTOMER_REQUEST = "ADD_SCHEDULER_CUSTOMER_REQUEST";
export const ADD_SCHEDULER_CUSTOMER_SUCCESS = "ADD_SCHEDULER_CUSTOMER_SUCCESS";
export const ADD_SCHEDULER_CUSTOMER_FAILURE = "ADD_SCHEDULER_CUSTOMER_FAILURE";

//GET SCHEDULER VENDOR
export const GET_SCHEDULER_VENDOR_REQUEST = "GET_SCHEDULER_VENDOR_REQUEST";
export const GET_SCHEDULER_VENDOR_SUCCESS = "GET_SCHEDULER_VENDOR_SUCCESS";
export const GET_SCHEDULER_VENDOR_FAILURE = "GET_SCHEDULER_VENDOR_FAILURE";

//ADD SCHEDULER VENDOR
export const ADD_SCHEDULER_VENDOR_REQUEST = "ADD_SCHEDULER_VENDOR_REQUEST";
export const ADD_SCHEDULER_VENDOR_SUCCESS = "ADD_SCHEDULER_VENDOR_SUCCESS";
export const ADD_SCHEDULER_VENDOR_FAILURE = "ADD_SCHEDULER_VENDOR_FAILURE";

export const ADDING_THIRD_PARTY_ACCESS_REQUEST =
  "ADDING_THIRD_PARTY_ACCESS_REQUEST";
export const ADDING_THIRD_PARTY_ACCESS_SUCCESS =
  "ADDING_THIRD_PARTY_ACCESS_SUCCESS";
export const ADDING_THIRD_PARTY_ACCESS_FAILURE =
  "ADDING_THIRD_PARTY_ACCESS_FAILURE";

export const GET_THIRD_PARTY_ACCESS_REQUEST =
  "GET_THIRD_PARTY_ACCESS_REQUEST";
export const GET_THIRD_PARTY_ACCESS_SUCCESS =
  "GET_THIRD_PARTY_ACCESS_SUCCESS";
export const GET_THIRD_PARTY_ACCESS_FAILURE =
  "GET_THIRD_PARTY_ACCESS_FAILURE";

export const ADDING_THIRD_PARTY_VENDOR_ACCESS_REQUEST =
  "ADDING_THIRD_PARTY_VENDOR_ACCESS_REQUEST";
export const ADDING_THIRD_PARTY_VENDOR_ACCESS_SUCCESS =
  "ADDING_THIRD_PARTY_VENDOR_ACCESS_SUCCESS";
export const ADDING_THIRD_PARTY_VENDOR_ACCESS_FAILURE =
  "ADDING_THIRD_PARTY_VENDOR_ACCESS_FAILURE";

export const GET_THIRD_PARTY_VENDOR_ACCESS_REQUEST =
  "GET_THIRD_PARTY_VENDOR_ACCESS_REQUEST";
export const GET_THIRD_PARTY_VENDOR_ACCESS_SUCCESS =
  "GET_THIRD_PARTY_VENDOR_ACCESS_SUCCESS";
export const GET_THIRD_PARTY_VENDOR_ACCESS_FAILURE =
  "GET_THIRD_PARTY_VENDOR_ACCESS_FAILURE";

export const ADDING_DEPARTMENT_ACCESS_REQUEST = "ADDING_DEPARTMENT_ACCESS_REQUEST";
export const ADDING_DEPARTMENT_ACCESS_SUCCESS = "ADDING_DEPARTMENT_ACCESS_SUCCESS";
export const ADDING_DEPARTMENT_ACCESS_FAILURE = "ADDING_DEPARTMENT_ACCESS_FAILURE";

export const ADDING_NOTIFICATION_ACCESS_REQUEST = "ADDING_NOTIFICATION_ACCESS_REQUEST";
export const ADDING_NOTIFICATION_ACCESS_SUCCESS = "ADDING_NOTIFICATION_ACCESS_SUCCESS";
export const ADDING_NOTIFICATION_ACCESS_FAILURE = "ADDING_NOTIFICATION_ACCESS_FAILURE";


export const GET_CUSTOMER_CONFIGURE_REQUEST = "GET_CUSTOMER_CONFIGURE_REQUEST";
export const GET_CUSTOMER_CONFIGURE_SUCCESS = "GET_CUSTOMER_CONFIGURE_SUCCESS";
export const GET_CUSTOMER_CONFIGURE_FAILURE = "GET_CUSTOMER_CONFIGURE_FAILURE";



export const ADD_CUSTOMER_CONFIGURE_REQUEST = "ADD_CUSTOMER_CONFIGURE_REQUEST";
export const ADD_CUSTOMER_CONFIGURE_SUCCESS = "ADD_CUSTOMER_CONFIGURE_SUCCESS";
export const ADD_CUSTOMER_CONFIGURE_FAILURE = "ADD_CUSTOMER_CONFIGURE_FAILURE";

export const GET_DEPARTMENT_ACCESS_REQUEST = "GET_DEPARTMENT_ACCESS_REQUEST";
export const GET_DEPARTMENT_ACCESS_SUCCESS = "GET_DEPARTMENT_ACCESS_SUCCESS";
export const GET_DEPARTMENT_ACCESS_FAILURE = "GET_DEPARTMENT_ACCESS_FAILURE";


export const GET_NOTIFICATION_ACCESS_REQUEST = "GET_NOTIFICATION_ACCESS_REQUEST";
export const GET_NOTIFICATION_ACCESS_SUCCESS = "GET_NOTIFICATION_ACCESS_SUCCESS";
export const GET_NOTIFICATION_ACCESS_FAILURE = "GET_NOTIFICATION_ACCESS_FAILURE";


export const GET_DEPARTMENT_LIST_REQUEST = "GET_DEPARTMENT_LIST_REQUEST";
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAILURE = "GET_DEPARTMENT_LIST_FAILURE";

export const GET_THIRD_PARTY_MONETIZE_REQUEST =
  "GET_THIRD_PARTY_MONETIZE_REQUEST";
export const GET_THIRD_PARTY_MONETIZE_SUCCESS =
  "GET_THIRD_PARTY_MONETIZE_SUCCESS";
export const GET_THIRD_PARTY_MONETIZE_FAILURE =
  "GET_THIRD_PARTY_MONETIZE_FAILURE";

export const ADDING_COMPLIANCE_GDPR_REQUEST =
  "ADDING_COMPLIANCE_GDPR_REQUEST";
export const ADDING_COMPLIANCE_GDPR_SUCCESS =
  "ADDING_COMPLIANCE_GDPR_SUCCESS";
export const ADDING_COMPLIANCE_GDPR_FAILURE =
  "ADDING_COMPLIANCE_GDPR_FAILURE";

export const GET_COMPLIANCE_GDPR_REQUEST =
  "GET_COMPLIANCE_GDPR_REQUEST";
export const GET_COMPLIANCE_GDPR_SUCCESS =
  "GET_COMPLIANCE_GDPR_SUCCESS";
export const GET_COMPLIANCE_GDPR_FAILURE =
  "GET_COMPLIANCE_GDPR_FAILURE";


export const GET_ASSESSMENT_ACCESS_REQUEST =
  "GET_ASSESSMENT_ACCESS_REQUEST";
export const GET_ASSESSMENT_ACCESS_SUCCESS =
  "GET_ASSESSMENT_ACCESS_SUCCESS";
export const GET_ASSESSMENT_ACCESS_FAILURE =
  "GET_ASSESSMENT_ACCESS_FAILURE";


export const ADDING_UP_WORK_ACCESS_REQUEST =
  "ADDING_UP_WORK_ACCESS_REQUEST";
export const ADDING_UP_WORK_ACCESS_SUCCESS =
  "ADDING_UP_WORK_ACCESS_SUCCESS";
export const ADDING_UP_WORK_ACCESS_FAILURE =
  "ADDING_UP_WORK_ACCESS_FAILURE";


export const GET_UP_WORK_ACCESS_REQUEST =
  "GET_UP_WORK_ACCESS_REQUEST";
export const GET_UP_WORK_ACCESS_SUCCESS =
  "GET_UP_WORK_ACCESS_SUCCESS";
export const GET_UP_WORK_ACCESS_FAILURE =
  "GET_UP_WORK_ACCESS_FAILURE";

export const ADDING_COMMUNICATION_ACCESS_REQUEST =
  "ADDING_COMMUNICATION_ACCESS_REQUEST";
export const ADDING_COMMUNICATION_ACCESS_SUCCESS =
  "ADDING_COMMUNICATION_ACCESS_SUCCESS";
export const ADDING_COMMUNICATION_ACCESS_FAILURE =
  "ADDING_COMMUNICATION_ACCESS_FAILURE";

export const GET_COMMUNICATION_ACCESS_REQUEST =
  "GET_COMMUNICATION_ACCESS_REQUEST";
export const GET_COMMUNICATION_ACCESS_SUCCESS =
  "GET_COMMUNICATION_ACCESS_SUCCESS";
export const GET_COMMUNICATION_ACCESS_FAILURE =
  "GET_COMMUNICATION_ACCESS_FAILURE";


export const ADDING_SOURCING_ACCESS_REQUEST =
  "ADDING_SOURCING_ACCESS_REQUEST";
export const ADDING_SOURCING_ACCESS_SUCCESS =
  "ADDING_SOURCING_ACCESS_SUCCESS";
export const ADDING_SOURCING_ACCESS_FAILURE =
  "ADDING_SOURCING_ACCESS_FAILURE";

export const GET_SOURCING_ACCESS_REQUEST =
  "GET_SOURCING_ACCESS_REQUEST";
export const GET_SOURCING_ACCESS_SUCCESS =
  "GET_SOURCING_ACCESS_SUCCESS";
export const GET_SOURCING_ACCESS_FAILURE =
  "GET_SOURCING_ACCESS_FAILURE";

export const HANDLE_SEQUENCE_MODAL = "HANDLE_SEQUENCE_MODAL";


export const ADDING_PERMISSION_ACCESS_REQUEST =
  "ADDING_PERMISSION_ACCESS_REQUEST";
export const ADDING_PERMISSION_ACCESS_SUCCESS =
  "ADDING_PERMISSION_ACCESS_SUCCESS";
export const ADDING_PERMISSION_ACCESS_FAILURE =
  "ADDING_PERMISSION_ACCESS_FAILURE";

export const GET_PERMISSION_ACCESS_REQUEST =
  "GET_PERMISSION_ACCESS_REQUEST";
export const GET_PERMISSION_ACCESS_SUCCESS =
  "GET_PERMISSION_ACCESS_SUCCESS";
export const GET_PERMISSION_ACCESS_FAILURE =
  "GET_PERMISSION_ACCESS_FAILURE";

export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";


export const GET_PARTNER_REQUEST = "GET_PARTNER_REQUEST";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_FAILURE = "GET_PARTNER_FAILURE";

export const ADD_SEQUENCE_REQUEST = "ADD_SEQUENCE_REQUEST";
export const ADD_SEQUENCE_SUCCESS = "ADD_SEQUENCE_SUCCESS";
export const ADD_SEQUENCE_FAILURE = "ADD_SEQUENCE_FAILURE";

export const GET_SEQUENCE_REQUEST = "GET_SEQUENCE_REQUEST";
export const GET_SEQUENCE_SUCCESS = "GET_SEQUENCE_SUCCESS";
export const GET_SEQUENCE_FAILURE = "GET_SEQUENCE_FAILURE";


export const ADD_MONSTER_REQUEST = "ADD_MONSTER_REQUEST";
export const ADD_MONSTER_SUCCESS = "ADD_MONSTER_SUCCESS";
export const ADD_MONSTER_FAILURE = "ADD_MONSTER_FAILURE";

export const GET_MONSTER_REQUEST = "GET_MONSTER_REQUEST";
export const GET_MONSTER_SUCCESS = "GET_MONSTER_SUCCESS";
export const GET_MONSTER_FAILURE = "GET_MONSTER_FAILURE";


export const GET_SEQUENCE_DETAIL_REQUEST = "GET_SEQUENCE_DETAIL_REQUEST";
export const GET_SEQUENCE_DETAIL_SUCCESS = "GET_SEQUENCE_DETAIL_SUCCESS";
export const GET_SEQUENCE_DETAIL_FAILURE = "GET_SEQUENCE_DETAIL_FAILURE";

export const GET_NOTIFICATIONS_REQUEST =
  "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS =
  "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE =
  "GET_NOTIFICATIONS_FAILURE";

export const ADDING_NOTIFICATIONS_REQUEST =
  "ADDING_NOTIFICATIONS_REQUEST";
export const ADDING_NOTIFICATIONS_SUCCESS =
  "ADDING_NOTIFICATIONS_SUCCESS";
export const ADDING_NOTIFICATIONS_FAILURE =
  "ADDING_NOTIFICATIONS_FAILURE";

export const GET_REQUIREMENTS_DURATION_REQUEST =
  "GET_REQUIREMENTS_DURATION_REQUEST";
export const GET_REQUIREMENTS_DURATION_SUCCESS =
  "GET_REQUIREMENTS_DURATION_SUCCESS";
export const GET_REQUIREMENTS_DURATION_FAILURE =
  "GET_REQUIREMENTS_DURATION_FAILURE";


  export const GET_DEPARTMENTWISE_USER_REQUEST = "GET_DEPARTMENTWISE_USER_REQUEST";
export const GET_DEPARTMENTWISE_USER_SUCCESS = "GET_DEPARTMENTWISE_USER_SUCCESS";
export const GET_DEPARTMENTWISE_USER_FAILURE = "GET_DEPARTMENTWISE_USER_FAILURE";


  export const GET_DISTRIBUTION_AUTOMATION_REQUEST =
  "GET_DISTRIBUTION_AUTOMATION_REQUEST";
export const GET_DISTRIBUTION_AUTOMATION_SUCCESS =
  "GET_DISTRIBUTION_AUTOMATION_SUCCESS";
export const GET_DISTRIBUTION_AUTOMATION_FAILURE =
  "GET_DISTRIBUTION_AUTOMATION_FAILURE";

export const UPDATE_REQUIREMENT_REQUEST = "UPDATE_REQUIREMENT_REQUEST";
export const UPDATE_REQUIREMENT_SUCCESS = "UPDATE_REQUIREMENT_SUCCESS";
export const UPDATE_REQUIREMENT_FAILURE = "UPDATE_REQUIREMENT_FAILURE";

export const UPDATE_OPPORTUNITIES_REQUEST = "UPDATE_OPPORTUNITIES_REQUEST";
export const UPDATE_OPPORTUNITIES_SUCCESS = "UPDATE_OPPORTUNITIES_SUCCESS";
export const UPDATE_OPPORTUNITIES_FAILURE = "UPDATE_OPPORTUNITIES_FAILURE";

export const DELETE_REPORT_SCHEDULER_INTERNAL_DATA_REQUEST = "DELETE_REPORT_SCHEDULER_INTERNAL_DATA_REQUEST";
export const DELETE_REPORT_SCHEDULER_INTERNAL_DATA_SUCCESS = "DELETE_REPORT_SCHEDULER_INTERNAL_DATA_SUCCESS";
export const DELETE_REPORT_SCHEDULER_INTERNAL_DATA_FAILURE = "DELETE_REPORT_SCHEDULER_INTERNAL_DATA_FAILURE";

export const DELETE_SEQUENCE_DATA_REQUEST = "DELETE_SEQUENCE_DATA_REQUEST";
export const DELETE_SEQUENCE_DATA_SUCCESS = "DELETE_SEQUENCE_DATA_SUCCESS";
export const DELETE_SEQUENCE_DATA_FAILURE = "DELETE_SEQUENCE_DATA_FAILURE";


export const DELETE_HIRING_STAGES_DATA_REQUEST = "DELETE_HIRING_STAGES_DATA_REQUEST";
export const DELETE_HIRING_STAGES_DATA_SUCCESS = "DELETE_HIRING_STAGES_DATA_SUCCESS";
export const DELETE_HIRING_STAGES_DATA_FAILURE = "DELETE_HIRING_STAGES_DATA_FAILURE";

export const DELETE_OPPORTUNITY_STAGES_DATA_REQUEST = "DELETE_OPPORTUNITY_STAGES_DATA_REQUEST";
export const DELETE_OPPORTUNITY_STAGES_DATA_SUCCESS = "DELETE_OPPORTUNITY_STAGES_DATA_SUCCESS";
export const DELETE_OPPORTUNITY_STAGES_DATA_FAILURE = "DELETE_OPPORTUNITY_STAGES_DATA_FAILURE";

export const ADD_PROCESS_FOR_OPPORTUNITY_REQUEST =
  "ADD_PROCESS_FOR_OPPORTUNITY_REQUEST";
export const ADD_PROCESS_FOR_OPPORTUNITY_SUCCESS =
  "ADD_PROCESS_FOR_OPPORTUNITY_SUCCESS";
export const ADD_PROCESS_FOR_OPPORTUNITY_FAILURE =
  "ADD_PROCESS_FOR_OPPORTUNITY_FAILURE";

export const GET_PROCESS_FOR_OPPORTUNITY_REQUEST =
  "GET_PROCESS_FOR_OPPORTUNITY_REQUEST";
export const GET_PROCESS_FOR_OPPORTUNITY_SUCCESS =
  "GET_PROCESS_FOR_OPPORTUNITY_SUCCESS";
export const GET_PROCESS_FOR_OPPORTUNITY_FAILURE =
  "GET_PROCESS_FOR_OPPORTUNITY_FAILURE";


export const DELETE_WORKFLOW_DATA_REQUEST = "DELETE_WORKFLOW_DATA_REQUEST";
export const DELETE_WORKFLOW_DATA_SUCCESS = "DELETE_WORKFLOW_DATA_SUCCESS";
export const DELETE_WORKFLOW_DATA_FAILURE = "DELETE_WORKFLOW_DATA_FAILURE";



export const DELETE_OPPORTUNITY_PROCESS_DATA_REQUEST = "DELETE_OPPORTUNITY_PROCESS_DATA_REQUEST";
export const DELETE_OPPORTUNITY_PROCESS_DATA_SUCCESS = "DELETE_OPPORTUNITY_PROCESS_DATA_SUCCESS";
export const DELETE_OPPORTUNITY_PROCESS_DATA_FAILURE = "DELETE_OPPORTUNITY_PROCESS_DATA_FAILURE";

export const UPDATE_SEQUENCE_TABLE_DATA_REQUEST =
  "UPDATE_SEQUENCE_TABLE_DATA_REQUEST";
export const UPDATE_SEQUENCE_TABLE_DATA_SUCCESS =
  "UPDATE_SEQUENCE_TABLE_DATA_SUCCESS";
export const UPDATE_SEQUENCE_TABLE_DATA_FAILURE =
  "UPDATE_SEQUENCE_TABLE_DATA_FAILURE";

export const ADD_PROCESS_STAGE_FOR_OPPORTUNITY_REQUEST =
  "ADD_PROCESS_STAGE_FOR_OPPORTUNITY_REQUEST";
export const ADD_PROCESS_STAGE_FOR_OPPORTUNITY_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_OPPORTUNITY_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_OPPORTUNITY_FAILURE =
  "ADD_PROCESS_STAGE_FOR_OPPORTUNITY_FAILURE";

export const GET_PROCESS_STAGES_FOR_OPPORTUNITY_REQUEST =
  "GET_PROCESS_STAGES_FOR_OPPORTUNITY_REQUEST";
export const GET_PROCESS_STAGES_FOR_OPPORTUNITY_SUCCESS =
  "GET_PROCESS_STAGES_FOR_OPPORTUNITY_SUCCESS";
export const GET_PROCESS_STAGES_FOR_OPPORTUNITY_FAILURE =
  "GET_PROCESS_STAGES_FOR_OPPORTUNITY_FAILURE";

export const ADDING_ASSESSMENT_ACCESS_REQUEST =
  "ADDING_ASSESSMENT_ACCESS_REQUEST";
export const ADDING_ASSESSMENT_ACCESS_SUCCESS =
  "ADDING_ASSESSMENT_ACCESS_SUCCESS";
export const ADDING_ASSESSMENT_ACCESS_FAILURE =
  "ADDING_ASSESSMENT_ACCESS_FAILURE";



export const ADDING_REMOTE_ACCESS_REQUEST =
  "ADDING_REMOTE_ACCESS_REQUEST";
export const ADDING_REMOTE_ACCESS_SUCCESS =
  "ADDING_REMOTE_ACCESS_SUCCESS";
export const ADDING_REMOTE_ACCESS_FAILURE =
  "ADDING_REMOTE_ACCESS_FAILURE";


export const GET_REMOTE_ACCESS_REQUEST =
  "GET_REMOTE_ACCESS_REQUEST";
export const GET_REMOTE_ACCESS_SUCCESS =
  "GET_REMOTE_ACCESS_SUCCESS";
export const GET_REMOTE_ACCESS_FAILURE =
  "GET_REMOTE_ACCESS_FAILURE";

export const GET_OPPORTUNITIES_DURATION_REQUEST =
  "GET_OPPORTUNITIES_DURATION_REQUEST";
export const GET_OPPORTUNITIES_DURATION_SUCCESS =
  "GET_OPPORTUNITIES_DURATION_SUCCESS";
export const GET_OPPORTUNITIES_DURATION_FAILURE =
  "GET_OPPORTUNITIES_DURATION_FAILURE";

export const HANDLE_RECRUITMENT_DRAWER_MODAL = "HANDLE_RECRUITMENT_DRAWER_MODAL";

export const UPDATE_SEQUENCE_REQUEST =
  "UPDATE_SEQUENCE_REQUEST";
export const UPDATE_SEQUENCE_SUCCESS =
  "UPDATE_SEQUENCE_SUCCESS";
export const UPDATE_SEQUENCE_FAILURE =
  "UPDATE_SEQUENCE_FAILURE";



export const ADDING_WEEKEND_ACCESS_REQUEST =
  "ADDING_WEEKEND_ACCESS_REQUEST";
export const ADDING_WEEKEND_ACCESS_SUCCESS =
  "ADDING_WEEKEND_ACCESS_SUCCESS";
export const ADDING_WEEKEND_ACCESS_FAILURE =
  "ADDING_WEEKEND_ACCESS_FAILURE";


export const GET_WEEKEND_ACCESS_REQUEST =
  "GET_WEEKEND_ACCESS_REQUEST";
export const GET_WEEKEND_ACCESS_SUCCESS =
  "GET_WEEKEND_ACCESS_SUCCESS";
export const GET_WEEKEND_ACCESS_FAILURE =
  "GET_WEEKEND_ACCESS_FAILURE";

export const ADD_APPROVE_REQUEST = "ADD_APPROVE_REQUEST";
export const ADD_APPROVE_SUCCESS = "ADD_APPROVE_SUCCESS";
export const ADD_APPROVE_FAILURE = "ADD_APPROVE_FAILURE";

export const GET_DEPARTMENT_ROLE_DATA_REQUEST = "GET_DEPARTMENT_ROLE_DATA_REQUEST";
export const GET_DEPARTMENT_ROLE_DATA_SUCCESS = "GET_DEPARTMENT_ROLE_DATA_SUCCESS";
export const GET_DEPARTMENT_ROLE_DATA_FAILURE = "GET_DEPARTMENT_ROLE_DATA_FAILURE";




export const GET_APPROVE_DATA_REQUEST = "GET_APPROVE_DATA_REQUEST";
export const GET_APPROVE_DATA_SUCCESS = "GET_APPROVE_DATA_SUCCESS";
export const GET_APPROVE_DATA_FAILURE = "GET_APPROVE_DATA_FAILURE";

export const GET_TASK_FOR_STAGES_REQUEST =
  "GET_TASK_FOR_STAGES_REQUEST";
export const GET_TASK_FOR_STAGES_SUCCESS =
  "GET_TASK_FOR_STAGES_SUCCESS";
export const GET_TASK_FOR_STAGES_FAILURE =
  "GET_TASK_FOR_STAGES_FAILURE";

export const GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_REQUEST =
  "GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_REQUEST";
export const GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_SUCCESS =
  "GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_SUCCESS";
export const GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_FAILURE =
  "GET_WORKFLOW_TASK_STAGES_FOR_RECRUIT_FAILURE";


export const GET_TASK_FOR_WORKFLOW_REQUEST =
  "GET_TASK_FOR_WORKFLOW_REQUEST";
export const GET_TASK_FOR_WORKFLOW_SUCCESS =
  "GET_TASK_FOR_WORKFLOW_SUCCESS";
export const GET_TASK_FOR_WORKFLOW_FAILURE =
  "GET_TASK_FOR_WORKFLOW_FAILURE";


export const ADD_TASK_WORKFLOW_REQUEST = "ADD_TASK_WORKFLOW_REQUEST";
export const ADD_TASK_WORKFLOW_SUCCESS = "ADD_TASK_WORKFLOW_SUCCESS";
export const ADD_TASK_WORKFLOW_FAILURE = "ADD_TASK_WORKFLOW_FAILURE";

export const GET_TASK_TEAM_LIST_REQUEST = "GET_TASK_TEAM_LIST_REQUEST";
export const GET_TASK_TEAM_LIST_SUCCESS = "GET_TASK_TEAM_LIST_SUCCESS";
export const GET_TASK_TEAM_LIST_FAILURE = "GET_TASK_TEAM_LIST_FAILURE";

export const GET_LEAD_AGING_REQUEST = "GET_LEAD_AGING_REQUEST";
export const GET_LEAD_AGING_SUCCESS = "GET_LEAD_AGING_SUCCESS";
export const GET_LEAD_AGING_FAILURE = "GET_LEAD_AGING_FAILURE";
export const ADD_LEAD_AGING_REQUEST = "ADD_LEAD_AGING_REQUEST";
export const ADD_LEAD_AGING_SUCCESS = "ADD_LEAD_AGING_SUCCESS";
export const ADD_LEAD_AGING_FAILURE = "ADD_LEAD_AGING_FAILURE";


export const ADD_PROCESS_FOR_DEALS_REQUEST =
  "ADD_PROCESS_FOR_DEALS_REQUEST";
export const ADD_PROCESS_FOR_DEALS_SUCCESS =
  "ADD_PROCESS_FOR_DEALS_SUCCESS";
export const ADD_PROCESS_FOR_DEALS_FAILURE =
  "ADD_PROCESS_FOR_DEALS_FAILURE";

export const GET_PROCESS_FOR_DEALS_REQUEST =
  "GET_PROCESS_FOR_DEALS_REQUEST";
export const GET_PROCESS_FOR_DEALS_SUCCESS =
  "GET_PROCESS_FOR_DEALS_SUCCESS";
export const GET_PROCESS_FOR_DEALS_FAILURE =
  "GET_PROCESS_FOR_DEALS_FAILURE";


export const ADD_PROCESS_STAGE_FOR_DEALS_REQUEST =
  "ADD_PROCESS_STAGE_FOR_DEALS_REQUEST";
export const ADD_PROCESS_STAGE_FOR_DEALS_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_DEALS_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_DEALS_FAILURE =
  "ADD_PROCESS_STAGE_FOR_DEALS_FAILURE";


export const GET_PROCESS_STAGES_FOR_DEALS_REQUEST =
  "GET_PROCESS_STAGES_FOR_DEALS_REQUEST";
export const GET_PROCESS_STAGES_FOR_DEALS_SUCCESS =
  "GET_PROCESS_STAGES_FOR_DEALS_SUCCESS";
export const GET_PROCESS_STAGES_FOR_DEALS_FAILURE =
  "GET_PROCESS_STAGES_FOR_DEALS_FAILURE";




  export const UPDATE_GLOBAL_WORKFLOW_REQUEST = "UPDATE_GLOBAL_WORKFLOW_REQUEST";
export const UPDATE_GLOBAL_WORKFLOW_SUCCESS = "UPDATE_GLOBAL_WORKFLOW_SUCCESS";
export const UPDATE_GLOBAL_WORKFLOW_FAILURE = "UPDATE_GLOBAL_WORKFLOW_FAILURE";



export const LINK_DEALS_PROCESS_PUBLISH_REQUEST =
  "LINK_DEALS_PROCESS_PUBLISH_REQUEST";
export const LINK_DEALS_PROCESS_PUBLISH_SUCCESS =
  "LINK_DEALS_PROCESS_PUBLISH_SUCCESS";
export const LINK_DEALS_PROCESS_PUBLISH_FAILURE =
  "LINK_DEALS_PROCESS_PUBLISH_FAILURE";




  export const DELETE_TASK_STAGE_DATA_REQUEST = "DELETE_TASK_STAGE_DATA_REQUEST";
export const DELETE_TASK_STAGE_DATA_SUCCESS = "DELETE_TASK_STAGE_DATA_SUCCESS";
export const DELETE_TASK_STAGE_DATA_FAILURE = "DELETE_TASK_STAGE_DATA_FAILURE";


export const LINK_DEALS_STAGES_PUBLISH_REQUEST =
  "LINK_DEALS_STAGES_PUBLISH_REQUEST";
export const LINK_DEALS_STAGES_PUBLISH_SUCCESS =
  "LINK_DEALS_STAGES_PUBLISH_SUCCESS";
export const LINK_DEALS_STAGES_PUBLISH_FAILURE =
  "LINK_DEALS_STAGES_PUBLISH_FAILURE";

export const DELETE_DEALS_PROCESS_DATA_REQUEST = "DELETE_DEALS_PROCESS_DATA_REQUEST";
export const DELETE_DEALS_PROCESS_DATA_SUCCESS = "DELETE_DEALS_PROCESS_DATA_SUCCESS";
export const DELETE_DEALS_PROCESS_DATA_FAILURE = "DELETE_DEALS_PROCESS_DATA_FAILURE";

export const DELETE_DEALS_STAGES_DATA_REQUEST = "DELETE_DEALS_STAGES_DATA_REQUEST";
export const DELETE_DEALS_STAGES_DATA_SUCCESS = "DELETE_DEALS_STAGES_DATA_SUCCESS";
export const DELETE_DEALS_STAGES_DATA_FAILURE = "DELETE_DEALS_STAGES_DATA_FAILURE";

export const UPDATE_PROCESS_NAME_FOR_DEALS_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_DEALS_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_DEALS_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_DEALS_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_DEALS_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_DEALS_FAILURE";

export const UPDATE_STAGE_FOR_DEALS_REQUEST =
  "UPDATE_STAGE_FOR_DEALS_REQUEST";
export const UPDATE_STAGE_FOR_DEALS_SUCCESS =
  "UPDATE_STAGE_FOR_DEALS_SUCCESS";
export const UPDATE_STAGE_FOR_DEALS_FAILURE =
  "UPDATE_STAGE_FOR_DEALS_FAILURE";

export const UPDATE_WEBSITE_SINGLE_REQUEST = "UPDATE_WEBSITE_SINGLE_REQUEST";
export const UPDATE_WEBSITE_SINGLE_SUCCESS = "UPDATE_WEBSITE_SINGLE_SUCCESS";
export const UPDATE_WEBSITE_SINGLE_FAILURE = "UPDATE_WEBSITE_SINGLE_FAILURE";

export const ADD_NOTIFICATION_CONFIG_REQUEST = "ADD_NOTIFICATION_CONFIG_REQUEST";
export const ADD_NOTIFICATION_CONFIG_SUCCESS = "ADD_NOTIFICATION_CONFIG_SUCCESS";
export const ADD_NOTIFICATION_CONFIG_FAILURE = "ADD_NOTIFICATION_CONFIG_FAILURE";

export const GET_NOTIFICATION_CONFIG_REQUEST = "GET_NOTIFICATION_CONFIG_REQUEST";
export const GET_NOTIFICATION_CONFIG_SUCCESS = "GET_NOTIFICATION_CONFIG_SUCCESS";
export const GET_NOTIFICATION_CONFIG_FAILURE = "GET_NOTIFICATION_CONFIG_FAILURE";


export const ADD_PROCESS_FOR_ONBOARDING_REQUEST =
  "ADD_PROCESS_FOR_ONBOARDING_REQUEST";
export const ADD_PROCESS_FOR_ONBOARDING_SUCCESS =
  "ADD_PROCESS_FOR_ONBOARDING_SUCCESS";
export const ADD_PROCESS_FOR_ONBOARDING_FAILURE =
  "ADD_PROCESS_FOR_ONBOARDING_FAILURE";

  export const GET_PROCESS_FOR_ONBOARDING_REQUEST =
  "GET_PROCESS_FOR_ONBOARDING_REQUEST";
export const GET_PROCESS_FOR_ONBOARDING_SUCCESS =
  "GET_PROCESS_FOR_ONBOARDING_SUCCESS";
export const GET_PROCESS_FOR_ONBOARDING_FAILURE =
  "GET_PROCESS_FOR_ONBOARDING_FAILURE";

  
export const UPDATE_PROCESS_NAME_FOR_ONBOARDING_REQUEST =
"UPDATE_PROCESS_NAME_FOR_ONBOARDING_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_ONBOARDING_SUCCESS =
"UPDATE_PROCESS_NAME_FOR_ONBOARDING_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_ONBOARDING_FAILURE =
"UPDATE_PROCESS_NAME_FOR_ONBOARDING_FAILURE";

export const DELETE_ONBOARDING_PROCESS_DATA_REQUEST = "DELETE_ONBOARDING_PROCESS_DATA_REQUEST";
export const DELETE_ONBOARDING_PROCESS_DATA_SUCCESS = "DELETE_ONBOARDING_PROCESS_DATA_SUCCESS";
export const DELETE_ONBOARDING_PROCESS_DATA_FAILURE = "DELETE_ONBOARDING_PROCESS_DATA_FAILURE";

export const ADD_PROCESS_STAGE_FOR_ONBOARDING_REQUEST =
  "ADD_PROCESS_STAGE_FOR_ONBOARDING_REQUEST";
export const ADD_PROCESS_STAGE_FOR_ONBOARDING_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_ONBOARDING_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_ONBOARDING_FAILURE =
  "ADD_PROCESS_STAGE_FOR_ONBOARDING_FAILURE";


  export const GET_PROCESS_STAGES_FOR_ONBOARDING_REQUEST =
  "GET_PROCESS_STAGES_FOR_ONBOARDING_REQUEST";
export const GET_PROCESS_STAGES_FOR_ONBOARDING_SUCCESS =
  "GET_PROCESS_STAGES_FOR_ONBOARDING_SUCCESS";
export const GET_PROCESS_STAGES_FOR_ONBOARDING_FAILURE =
  "GET_PROCESS_STAGES_FOR_ONBOARDING_FAILURE";


  export const UPDATE_STAGE_FOR_ONBOARDING_REQUEST =
  "UPDATE_STAGE_FOR_ONBOARDING_REQUEST";
export const UPDATE_STAGE_FOR_ONBOARDING_SUCCESS =
  "UPDATE_STAGE_FOR_ONBOARDING_SUCCESS";
export const UPDATE_STAGE_FOR_ONBOARDING_FAILURE =
  "UPDATE_STAGE_FOR_ONBOARDING_FAILURE";

export const DELETE_ONBOARDING_STAGES_DATA_REQUEST = "DELETE_ONBOARDING_STAGES_DATA_REQUEST";
export const DELETE_ONBOARDING_STAGES_DATA_SUCCESS = "DELETE_ONBOARDING_STAGES_DATA_SUCCESS";
export const DELETE_ONBOARDING_STAGES_DATA_FAILURE = "DELETE_ONBOARDING_STAGES_DATA_FAILURE";


export const LINK_ONBOARDING_STAGES_PUBLISH_REQUEST =
  "LINK_ONBOARDING_STAGES_PUBLISH_REQUEST";
export const LINK_ONBOARDING_STAGES_PUBLISH_SUCCESS =
  "LINK_ONBOARDING_STAGES_PUBLISH_SUCCESS";
export const LINK_ONBOARDING_STAGES_PUBLISH_FAILURE =
  "LINK_ONBOARDING_STAGES_PUBLISH_FAILURE";


  export const LINK_ONBOARDING_PROCESS_PUBLISH_REQUEST =
  "LINK_ONBOARDING_PROCESS_PUBLISH_REQUEST";
export const LINK_ONBOARDING_PROCESS_PUBLISH_SUCCESS =
  "LINK_ONBOARDING_PROCESS_PUBLISH_SUCCESS";
export const LINK_ONBOARDING_PROCESS_PUBLISH_FAILURE =
  "LINK_ONBOARDING_PROCESS_PUBLISH_FAILURE";

export const CREATE_CURRENCY_CONVERSION_REQUEST = "CREATE_CURRENCY_CONVERSION_REQUEST";
export const CREATE_CURRENCY_CONVERSION_SUCCESS = "CREATE_CURRENCY_CONVERSION_SUCCESS";
export const CREATE_CURRENCY_CONVERSION_FAILURE = "CREATE_CURRENCY_CONVERSION_FAILURE";

export const GET_CURRENCY_CONVERSION_REQUEST = "GET_CURRENCY_CONVERSION_REQUEST";
export const GET_CURRENCY_CONVERSION_SUCCESS = "GET_CURRENCY_CONVERSION_SUCCESS";
export const GET_CURRENCY_CONVERSION_FAILURE = "GET_CURRENCY_CONVERSION_FAILURE";





export const ADD_PAYMENT_DATA_REQUEST = "ADD_PAYMENT_DATA_REQUEST";
export const ADD_PAYMENT_DATA_SUCCESS = "CREATE_PAYMENT_DATA_SUCCESS";
export const ADD_PAYMENT_DATA_FAILURE = "CREATE_PAYMENT_DATA_FAILURE";


export const ADD_PROCESS_FOR_SUPPLIER_REQUEST =
  "ADD_PROCESS_FOR_SUPPLIER_REQUEST";
export const ADD_PROCESS_FOR_SUPPLIER_SUCCESS =
  "ADD_PROCESS_FOR_SUPPLIER_SUCCESS";
export const ADD_PROCESS_FOR_SUPPLIER_FAILURE =
  "ADD_PROCESS_FOR_SUPPLIER_FAILURE";


  export const GET_PROCESS_FOR_SUPPLIER_REQUEST =
  "GET_PROCESS_FOR_SUPPLIER_REQUEST";
export const GET_PROCESS_FOR_SUPPLIER_SUCCESS =
  "GET_PROCESS_FOR_SUPPLIER_SUCCESS";
export const GET_PROCESS_FOR_SUPPLIER_FAILURE =
  "GET_PROCESS_FOR_SUPPLIER_FAILURE";

  export const UPDATE_PROCESS_NAME_FOR_SUPPLIER_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_SUPPLIER_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_SUPPLIER_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_SUPPLIER_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_SUPPLIER_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_SUPPLIER_FAILURE";

  export const DELETE_SUPPLIER_PROCESS_DATA_REQUEST = "DELETE_SUPPLIER_PROCESS_DATA_REQUEST";
export const DELETE_SUPPLIER_PROCESS_DATA_SUCCESS = "DELETE_SUPPLIER_PROCESS_DATA_SUCCESS";
export const DELETE_SUPPLIER_PROCESS_DATA_FAILURE = "DELETE_SUPPLIER_PROCESS_DATA_FAILURE";

export const ADD_PROCESS_STAGE_FOR_SUPPLIER_REQUEST =
  "ADD_PROCESS_STAGE_FOR_SUPPLIER_REQUEST";
export const ADD_PROCESS_STAGE_FOR_SUPPLIER_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_SUPPLIER_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_SUPPLIER_FAILURE =
  "ADD_PROCESS_STAGE_FOR_SUPPLIER_FAILURE";


  export const GET_PROCESS_STAGES_FOR_SUPPLIER_REQUEST =
  "GET_PROCESS_STAGES_FOR_SUPPLIER_REQUEST";
export const GET_PROCESS_STAGES_FOR_SUPPLIER_SUCCESS =
  "GET_PROCESS_STAGES_FOR_SUPPLIER_SUCCESS";
export const GET_PROCESS_STAGES_FOR_SUPPLIER_FAILURE =
  "GET_PROCESS_STAGES_FOR_SUPPLIER_FAILURE";

  export const UPDATE_STAGE_FOR_SUPPLIER_REQUEST =
  "UPDATE_STAGE_FOR_SUPPLIER_REQUEST";
export const UPDATE_STAGE_FOR_SUPPLIER_SUCCESS =
  "UPDATE_STAGE_FOR_SUPPLIER_SUCCESS";
export const UPDATE_STAGE_FOR_SUPPLIER_FAILURE =
  "UPDATE_STAGE_FOR_SUPPLIER_FAILURE";

  export const DELETE_SUPPLIER_STAGES_DATA_REQUEST = "DELETE_SUPPLIER_STAGES_DATA_REQUEST";
export const DELETE_SUPPLIER_STAGES_DATA_SUCCESS = "DELETE_SUPPLIER_STAGES_DATA_SUCCESS";
export const DELETE_SUPPLIER_STAGES_DATA_FAILURE = "DELETE_SUPPLIER_STAGES_DATA_FAILURE";


export const LINK_SUPPLIER_PROCESS_PUBLISH_REQUEST =
  "LINK_SUPPLIER_PROCESS_PUBLISH_REQUEST";
export const LINK_SUPPLIER_PROCESS_PUBLISH_SUCCESS =
  "LINK_SUPPLIER_PROCESS_PUBLISH_SUCCESS";
export const LINK_SUPPLIER_PROCESS_PUBLISH_FAILURE =
  "LINK_SUPPLIER_PROCESS_PUBLISH_FAILURE";


  export const LINK_SUPPLIER_STAGES_PUBLISH_REQUEST =
  "LINK_SUPPLIER_STAGES_PUBLISH_REQUEST";
export const LINK_SUPPLIER_STAGES_PUBLISH_SUCCESS =
  "LINK_SUPPLIER_STAGES_PUBLISH_SUCCESS";
export const LINK_SUPPLIER_STAGES_PUBLISH_FAILURE =
  "LINK_SUPPLIER_STAGES_PUBLISH_FAILURE";


  export const ADD_PROCESS_FOR_PRODUCTION_REQUEST =
  "ADD_PROCESS_FOR_PRODUCTION_REQUEST";
export const ADD_PROCESS_FOR_PRODUCTION_SUCCESS =
  "ADD_PROCESS_FOR_PRODUCTION_SUCCESS";
export const ADD_PROCESS_FOR_PRODUCTION_FAILURE =
  "ADD_PROCESS_FOR_PRODUCTION_FAILURE";

  export const GET_PROCESS_FOR_PRODUCTION_REQUEST =
  "GET_PROCESS_FOR_PRODUCTION_REQUEST";
export const GET_PROCESS_FOR_PRODUCTION_SUCCESS =
  "GET_PROCESS_FOR_PRODUCTION_SUCCESS";
export const GET_PROCESS_FOR_PRODUCTION_FAILURE =
  "GET_PROCESS_FOR_PRODUCTION_FAILURE";


  export const UPDATE_PROCESS_NAME_FOR_PRODUCTION_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_PRODUCTION_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_PRODUCTION_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_PRODUCTION_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_PRODUCTION_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_PRODUCTION_FAILURE";

  export const DELETE_PRODUCTION_PROCESS_DATA_REQUEST = "DELETE_PRODUCTION_PROCESS_DATA_REQUEST";
  export const DELETE_PRODUCTION_PROCESS_DATA_SUCCESS = "DELETE_PRODUCTION_PROCESS_DATA_SUCCESS";
  export const DELETE_PRODUCTION_PROCESS_DATA_FAILURE = "DELETE_PRODUCTION_PROCESS_DATA_FAILURE";

  export const ADD_PROCESS_STAGE_FOR_PRODUCTION_REQUEST =
  "ADD_PROCESS_STAGE_FOR_PRODUCTION_REQUEST";
export const ADD_PROCESS_STAGE_FOR_PRODUCTION_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_PRODUCTION_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_PRODUCTION_FAILURE =
  "ADD_PROCESS_STAGE_FOR_PRODUCTION_FAILURE";

  export const GET_PROCESS_STAGES_FOR_PRODUCTION_REQUEST =
  "GET_PROCESS_STAGES_FOR_PRODUCTION_REQUEST";
export const GET_PROCESS_STAGES_FOR_PRODUCTION_SUCCESS =
  "GET_PROCESS_STAGES_FOR_PRODUCTION_SUCCESS";
export const GET_PROCESS_STAGES_FOR_PRODUCTION_FAILURE =
  "GET_PROCESS_STAGES_FOR_PRODUCTION_FAILURE";

  export const UPDATE_STAGE_FOR_PRODUCTION_REQUEST =
  "UPDATE_STAGE_FOR_PRODUCTION_REQUEST";
export const UPDATE_STAGE_FOR_PRODUCTION_SUCCESS =
  "UPDATE_STAGE_FOR_PRODUCTION_SUCCESS";
export const UPDATE_STAGE_FOR_PRODUCTION_FAILURE =
  "UPDATE_STAGE_FOR_PRODUCTION_FAILURE";

  export const DELETE_PRODUCTION_STAGES_DATA_REQUEST = "DELETE_PRODUCTION_STAGES_DATA_REQUEST";
  export const DELETE_PRODUCTION_STAGES_DATA_SUCCESS = "DELETE_PRODUCTION_STAGES_DATA_SUCCESS";
  export const DELETE_PRODUCTION_STAGES_DATA_FAILURE = "DELETE_PRODUCTION_STAGES_DATA_FAILURE";

  export const LINK_PRODUCTION_PROCESS_PUBLISH_REQUEST =
  "LINK_PRODUCTION_PROCESS_PUBLISH_REQUEST";
export const LINK_PRODUCTION_PROCESS_PUBLISH_SUCCESS =
  "LINK_PRODUCTION_PROCESS_PUBLISH_SUCCESS";
export const LINK_PRODUCTION_PROCESS_PUBLISH_FAILURE =
  "LINK_PRODUCTION_PROCESS_PUBLISH_FAILURE";

  export const LINK_PRODUCTION_STAGES_PUBLISH_REQUEST =
  "LINK_PRODUCTION_STAGES_PUBLISH_REQUEST";
export const LINK_PRODUCTION_STAGES_PUBLISH_SUCCESS =
  "LINK_PRODUCTION_STAGES_PUBLISH_SUCCESS";
export const LINK_PRODUCTION_STAGES_PUBLISH_FAILURE =
  "LINK_PRODUCTION_STAGES_PUBLISH_FAILURE";

  export const ADD_PROCESS_FOR_REPAIR_REQUEST =
  "ADD_PROCESS_FOR_REPAIR_REQUEST";
export const ADD_PROCESS_FOR_REPAIR_SUCCESS =
  "ADD_PROCESS_FOR_REPAIR_SUCCESS";
export const ADD_PROCESS_FOR_REPAIR_FAILURE =
  "ADD_PROCESS_FOR_REPAIR_FAILURE";

  export const GET_PROCESS_FOR_WORKFLOW_DATA_REQUEST =
  "GET_PROCESS_FOR_WORKFLOW_DATA_REQUEST";
export const GET_PROCESS_FOR_WORKFLOW_DATA_SUCCESS =
  "GET_PROCESS_FOR_WORKFLOW_DATA_SUCCESS";
export const GET_PROCESS_FOR_WORKFLOW_DATA_FAILURE =
  "GET_PROCESS_FOR_WORKFLOW_DATA_FAILURE";

  export const UPDATE_PROCESS_NAME_FOR_REPAIR_REQUEST =
  "UPDATE_PROCESS_NAME_FOR_REPAIR_REQUEST";
export const UPDATE_PROCESS_NAME_FOR_REPAIR_SUCCESS =
  "UPDATE_PROCESS_NAME_FOR_REPAIR_SUCCESS";
export const UPDATE_PROCESS_NAME_FOR_REPAIR_FAILURE =
  "UPDATE_PROCESS_NAME_FOR_REPAIR_FAILURE";

  export const DELETE_REPAIR_PROCESS_DATA_REQUEST = "DELETE_REPAIR_PROCESS_DATA_REQUEST";
  export const DELETE_REPAIR_PROCESS_DATA_SUCCESS = "DELETE_REPAIR_PROCESS_DATA_SUCCESS";
  export const DELETE_REPAIR_PROCESS_DATA_FAILURE = "DELETE_REPAIR_PROCESS_DATA_FAILURE";

  export const ADD_PROCESS_STAGE_FOR_REPAIR_REQUEST =
  "ADD_PROCESS_STAGE_FOR_REPAIR_REQUEST";
export const ADD_PROCESS_STAGE_FOR_REPAIR_SUCCESS =
  "ADD_PROCESS_STAGE_FOR_REPAIR_SUCCESS";
export const ADD_PROCESS_STAGE_FOR_REPAIR_FAILURE =
  "ADD_PROCESS_STAGE_FOR_REPAIR_FAILURE";

  export const GET_PROCESS_STAGES_FOR_REPAIR_REQUEST =
  "GET_PROCESS_STAGES_FOR_REPAIR_REQUEST";
export const GET_PROCESS_STAGES_FOR_REPAIR_SUCCESS =
  "GET_PROCESS_STAGES_FOR_REPAIR_SUCCESS";
export const GET_PROCESS_STAGES_FOR_REPAIR_FAILURE =
  "GET_PROCESS_STAGES_FOR_REPAIR_FAILURE";

  export const UPDATE_STAGE_FOR_REPAIR_REQUEST =
  "UPDATE_STAGE_FOR_REPAIR_REQUEST";
export const UPDATE_STAGE_FOR_REPAIR_SUCCESS =
  "UPDATE_STAGE_FOR_REPAIR_SUCCESS";
export const UPDATE_STAGE_FOR_REPAIR_FAILURE =
  "UPDATE_STAGE_FOR_REPAIR_FAILURE";

  export const DELETE_REPAIR_STAGES_DATA_REQUEST = "DELETE_REPAIR_STAGES_DATA_REQUEST";
  export const DELETE_REPAIR_STAGES_DATA_SUCCESS = "DELETE_REPAIR_STAGES_DATA_SUCCESS";
  export const DELETE_REPAIR_STAGES_DATA_FAILURE = "DELETE_REPAIR_STAGES_DATA_FAILURE";

  export const LINK_REPAIR_PROCESS_PUBLISH_REQUEST =
  "LINK_REPAIR_PROCESS_PUBLISH_REQUEST";
export const LINK_REPAIR_PROCESS_PUBLISH_SUCCESS =
  "LINK_REPAIR_PROCESS_PUBLISH_SUCCESS";
export const LINK_REPAIR_PROCESS_PUBLISH_FAILURE =
  "LINK_REPAIR_PROCESS_PUBLISH_FAILURE";

  export const LINK_REPAIR_STAGES_PUBLISH_REQUEST =
  "LINK_REPAIR_STAGES_PUBLISH_REQUEST";
export const LINK_REPAIR_STAGES_PUBLISH_SUCCESS =
  "LINK_REPAIR_STAGES_PUBLISH_SUCCESS";
export const LINK_REPAIR_STAGES_PUBLISH_FAILURE =
  "LINK_REPAIR_STAGES_PUBLISH_FAILURE";

  export const HANDLE_SALARY_MODAL = "HANDLE_SALARY_MODAL";

export const GET_SALARY_REQUEST = "GET_SALARY_REQUEST";
export const GET_SALARY_SUCCESS = "GET_SALARY_SUCCESS";
export const GET_SALARY_FAILURE = "GET_SALARY_FAILURE";

export const ADD_SALARY_REQUEST = "ADD_SALARY_REQUEST";
export const ADD_SALARY_SUCCESS = "ADD_SALARY_SUCCESS";
export const ADD_SALARY_FAILURE = "ADD_SALARY_FAILURE";


export const GET_LANG_WORDS_REQUEST = "GET_LANG_WORDS_REQUEST";
export const GET_LANG_WORDS_SUCCESS = "GET_LANG_WORDS_SUCCESS";
export const GET_LANG_WORDS_FAILURE = "GET_LANG_WORDS_FAILURE";
  
export const ADD_LANG_WORDS_REQUEST = "ADD_LANG_WORDS_REQUEST";
export const ADD_LANG_WORDS_SUCCESS = "ADD_LANG_WORDS_SUCCESS";
export const ADD_LANG_WORDS_FAILURE = "ADD_LANG_WORDS_FAILURE";

export const UPDATE_SKILL_LEVEL_REQUEST = "UPDATE_SKILL_LEVEL_REQUEST";
export const UPDATE_SKILL_LEVEL_SUCCESS = "UPDATE_SKILL_LEVEL_SUCCESS";
export const UPDATE_SKILL_LEVEL_FAILURE = "UPDATE_SKILL_LEVEL_FAILURE";

export const CLUB_SHARE_REQUEST = "CLUB_SHARE_REQUEST";
export const CLUB_SHARE_SUCCESS = "CLUB_SHARE_SUCCESS";
export const CLUB_SHARE_FAILURE = "CLUB_SHARE_FAILURE";

export const GET_CLUB_SHARE_REQUEST = "GET_CLUB_SHARE_REQUEST";
export const GET_CLUB_SHARE_SUCCESS = "GET_CLUB_SHARE_SUCCESS";
export const GET_CLUB_SHARE_FAILURE = "GET_CLUB_SHARE_FAILURE";

export const UPDATE_CLUB_REQUEST = "UPDATE_CLUB_REQUEST";
export const UPDATE_CLUB_SUCCESS = "UPDATE_CLUB_SUCCESS";
export const UPDATE_CLUB_FAILURE = "UPDATE_CLUB_FAILURE";


export const GET_FEEBACK_REQUEST = "GET_FEEBACK_REQUEST";
export const GET_FEEBACK_SUCCESS = "GET_FEEBACK_SUCCESS";
export const GET_FEEBACK_FAILURE = "GET_FEEBACK_FAILURE";

export const ADD_FEEBACK_REQUEST = "ADD_FEEBACK_REQUEST";
export const ADD_FEEBACK_SUCCESS = "ADD_FEEBACK_SUCCESS";
export const ADD_FEEBACK_FAILURE = "ADD_FEEBACK_FAILURE";

export const REMOVE_FEEBACK_REQUEST = "REMOVE_FEEBACK_REQUEST";
export const REMOVE_FEEBACK_SUCCESS = "REMOVE_FEEBACK_SUCCESS";
export const REMOVE_FEEBACK_FAILURE = "REMOVE_FEEBACK_FAILURE";

export const UPDATE_FEEBACK_REQUEST = "UPDATE_FEEBACK_REQUEST";
export const UPDATE_FEEBACK_SUCCESS = "UPDATE_FEEBACK_SUCCESS";
export const UPDATE_FEEBACK_FAILURE = "UPDATE_FEEBACK_FAILURE";

export const GET_FEEBACK_COUNT_REQUEST = "GET_FEEBACK_COUNT_REQUEST";
export const GET_FEEBACK_COUNT_SUCCESS = "GET_FEEBACK_COUNT_SUCCESS";
export const GET_FEEBACK_COUNT_FAILURE = "GET_FEEBACK_COUNT_FAILURE";

export const GET_SUPPLIERCATEGORY_REQUEST = "GET_SUPPLIERCATEGORY_REQUEST";
export const GET_SUPPLIERCATEGORY_SUCCESS = "GET_SUPPLIERCATEGORY_SUCCESS";
export const GET_SUPPLIERCATEGORY_FAILURE = "GET_SUPPLIERCATEGORY_FAILURE";

export const ADD_SUPPLIERCATEGORY_REQUEST = "ADD_SUPPLIERCATEGORY_REQUEST";
export const ADD_SUPPLIERCATEGORY_SUCCESS = "ADD_SUPPLIERCATEGORY_SUCCESS";
export const ADD_SUPPLIERCATEGORY_FAILURE = "ADD_SUPPLIERCATEGORY_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_SUPPLIERCATEGORY = "HANDLE_CLAER_REDUCER_DATA_SUPPLIERCATEGORY";

export const GET_SHIPPERCATEGORY_REQUEST = "GET_SHIPPERCATEGORY_REQUEST";
export const GET_SHIPPERCATEGORY_SUCCESS = "GET_SHIPPERCATEGORY_SUCCESS";
export const GET_SHIPPERCATEGORY_FAILURE = "GET_SHIPPERCATEGORY_FAILURE";

export const ADD_SHIPPERCATEGORY_REQUEST = "ADD_SHIPPERCATEGORY_REQUEST";
export const ADD_SHIPPERCATEGORY_SUCCESS = "ADD_SHIPPERCATEGORY_SUCCESS";
export const ADD_SHIPPERCATEGORY_FAILURE = "ADD_SHIPPERCATEGORY_FAILURE";


export const ADD_CONFIGURE_GLOBAL_TYPE_DUPLICATE =
  "ADD_CONFIGURE_GLOBAL_TYPE_DUPLICATE";

export const ADD_CONFIGURE_GLOBAL_TYPE_REQUEST =
  "ADD_CONFIGURE_GLOBAL_TYPE_REQUEST";
export const ADD_CONFIGURE_GLOBAL_TYPE_SUCCESS =
  "ADD_CONFIGURE_GLOBAL_TYPE_SUCCESS";
export const ADD_CONFIGURE_GLOBAL_TYPE_FAILURE =
  "ADD_CONFIGURE_GLOBAL_TYPE_FAILURE";

export const GET_WORKFLOWCATEGORY_REQUEST = "GET_WORKFLOWCATEGORY_REQUEST";
export const GET_WORKFLOWCATEGORY_SUCCESS = "GET_WORKFLOWCATEGORY_SUCCESS";
export const GET_WORKFLOWCATEGORY_FAILURE = "GET_WORKFLOWCATEGORY_FAILURE";

export const ADD_WORKFLOWCATEGORY_REQUEST = "ADD_WORKFLOWCATEGORY_REQUEST";
export const ADD_WORKFLOWCATEGORY_SUCCESS = "ADD_WORKFLOWCATEGORY_SUCCESS";
export const ADD_SWORKFLOWCATEGORY_FAILURE = "ADD_SWORKFLOWCATEGORY_FAILURE";