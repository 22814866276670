export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const REMOVE_CUSTOMER_REQUEST = "REMOVE_CUSTOMER_REQUEST";
export const REMOVE_CUSTOMER_SUCCESS = "REMOVE_CUSTOMER_SUCCESS";
export const REMOVE_CUSTOMER_FAILURE = "REMOVE_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const GET_CUSTOMER_SEARCH_REQUEST="GET_CUSTOMER_SEARCH_REQUEST";
export const GET_CUSTOMER_SEARCH_SUCCESS="GET_CUSTOMER_SEARCH_SUCCESS";
export const GET_CUSTOMER_SEARCH_FAILURE="GET_CUSTOMER_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_CUSTOMER = "HANDLE_CLAER_REDUCER_DATA_CUSTOMER";

export const GET_CUSTOMER_COUNT_REQUEST = "GET_CUSTOMER_COUNT_REQUEST";
export const GET_CUSTOMER_COUNT_SUCCESS = "GET_CUSTOMER_COUNT_SUCCESS";
export const GET_CUSTOMER_COUNT_FAILURE = "GET_CUSTOMER_COUNT_FAILURE";

