export const SET_SUPPLIERS_VIEW_TYPE = "SET_SUPPLIERS_VIEW_TYPE";

export const HANDLE_SUPPLIERS_MODAL = "HANDLE_SUPPLIERS_MODAL";

export const HANDLE_PO_LIST_MODAL = "HANDLE_PO_LIST_MODAL";

export const HANDLE_TERMS_CONDITION_MODAL = "HANDLE_TERMS_CONDITION_MODAL";

export const HANDLE_SUPPLIER_INVENTORY_IMPORT_MODAL = "HANDLE_SUPPLIER_INVENTORY_IMPORT_MODAL";

export const ADD_SUPPLIER_INVENTORY_IMPORT_FORM_REQUEST = "ADD_SUPPLIER_INVENTORY_IMPORT_FORM_REQUEST";
export const ADD_SUPPLIER_INVENTORY_IMPORT_FORM_SUCCESS = "ADD_SUPPLIER_INVENTORY_IMPORT_FORM_SUCCESS";
export const ADD_SUPPLIER_INVENTORY_IMPORT_FORM_FAILURE = "ADD_SUPPLIER_INVENTORY_IMPORT_FORM_FAILURE";


export const REMOVE_SUPPLIER_NOT_APPROVAL_REQUEST = "REMOVE_SUPPLIER_NOT_APPROVAL_REQUEST";
export const REMOVE_SUPPLIER_NOT_APPROVAL_SUCCESS = "REMOVE_SUPPLIER_NOT_APPROVAL_SUCCESS";
export const REMOVE_SUPPLIER_NOT_APPROVAL_FAILURE = "REMOVE_SUPPLIER_NOT_APPROVAL_FAILURE";

export const GET_SUPPLIERS_NOT_APPROVAL_LIST_REQUEST = "GET_SUPPLIERS_NOT_APPROVAL_LIST_REQUEST";
export const GET_SUPPLIERS_NOT_APPROVAL_LIST_SUCCESS = "GET_SUPPLIERS_NOT_APPROVAL_LIST_SUCCESS";
export const GET_SUPPLIERS_NOT_APPROVAL_LIST_FAILURE = "GET_SUPPLIERS_NOT_APPROVAL_LIST_FAILURE";

export const ADD_SUPPLIERS_REQUEST = "ADD_SUPPLIERS_REQUEST";
export const ADD_SUPPLIERS_SUCCESS = "ADD_SUPPLIERS_SUCCESS";
export const ADD_SUPPLIERS_FAILURE = "ADD_SUPPLIERS_FAILURE";

export const UPDATE_QUALITY_SUPPLIERS_REQUEST = "UPDATE_QUALITY_SUPPLIERS_REQUEST";
export const UPDATE_QUALITY_SUPPLIERS_SUCCESS = "UPDATE_QUALITY_SUPPLIERS_SUCCESS";
export const UPDATE_QUALITY_SUPPLIERS_FAILURE = "UPDATE_QUALITY_SUPPLIERS_FAILURE";

export const GET_SUPPLIERS_LIST_REQUEST = "GET_SUPPLIERS_LIST_REQUEST";
export const GET_SUPPLIERS_LIST_SUCCESS = "GET_SUPPLIERS_LIST_SUCCESS";
export const GET_SUPPLIERS_LIST_FAILURE = "GET_SUPPLIERS_LIST_FAILURE";

export const GET_SUPPLIERS_PRICE_LIST_REQUEST = "GET_SUPPLIERS_PRICE_LIST_REQUEST";
export const GET_SUPPLIERS_PRICE_LIST_SUCCESS = "GET_SUPPLIERS_PRICE_LIST_SUCCESS";
export const GET_SUPPLIERS_PRICE_LIST_FAILURE = "GET_SUPPLIERS_PRICE_LIST_FAILURE";


export const GET_CONTACT_SHIPPER_LIST_BY_ID_REQUEST =
  "GET_CONTACT_SHIPPER_LIST_BY_ID_REQUEST";
export const GET_CONTACT_SHIPPER_LIST_BY_ID_SUCCESS =
  "GET_CONTACT_SHIPPER_LIST_BY_ID_SUCCESS";
export const GET_CONTACT_SHIPPER_LIST_BY_ID_FAILURE =
  "GET_CONTACT_SHIPPER_LIST_BY_ID_FAILURE";

export const APPLY_FOR_LOGIN_IN_CONTACT_REQUEST = "APPLY_FOR_LOGIN_IN_CONTACT_REQUEST";
export const APPLY_FOR_LOGIN_IN_CONTACT_SUCCESS = "APPLY_FOR_LOGIN_IN_CONTACT_SUCCESS";
export const APPLY_FOR_LOGIN_IN_CONTACT_FAILURE = "APPLY_FOR_LOGIN_IN_CONTACT_FAILURE";

export const GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_REQUEST =
  "GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_REQUEST";
export const GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_SUCCESS =
  "GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_SUCCESS";
export const GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_FAILURE =
  "GET_CONTACT_DISTRIBUTORS_LIST_BY_ID_FAILURE";

export const GET_SUPPLIES_LIST_BY_SUPPLIER_REQUEST = "GET_SUPPLIES_LIST_BY_SUPPLIER_REQUEST";
export const GET_SUPPLIES_LIST_BY_SUPPLIER_SUCCESS = "GET_SUPPLIES_LIST_BY_SUPPLIER_SUCCESS";
export const GET_SUPPLIES_LIST_BY_SUPPLIER_FAILURE = "GET_SUPPLIES_LIST_BY_SUPPLIER_FAILURE";

export const GET_PURCHASE_ORDER_REQUEST = "GET_PURCHASE_ORDER_REQUEST";
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS";
export const GET_PURCHASE_ORDER_FAILURE = "GET_PURCHASE_ORDER_FAILURE";

export const MOVE_TO_INVENTORY_REQUEST = "MOVE_TO_INVENTORY_REQUEST";
export const MOVE_TO_INVENTORY_SUCCESS = "MOVE_TO_INVENTORY_SUCCESS";
export const MOVE_TO_INVENTORY_FAILURE = "MOVE_TO_INVENTORY_FAILURE";

export const GET_SUPPLIER_BY_SUPPLIER_ID_REQUEST =
  "GET_SUPPLIER_BY_SUPPLIER_ID_REQUEST";
export const GET_SUPPLIER_BY_SUPPLIER_ID_SUCCESS =
  "GET_SUPPLIER_BY_SUPPLIER_ID_SUCCESS";
export const GET_SUPPLIER_BY_SUPPLIER_ID_FAILURE =
  "GET_SUPPLIER_BY_SUPPLIER_ID_FAILURE";

export const HANDLE_LINK_ORDER_CONFIGURE_MODAL =
  "HANDLE_LINK_ORDER_CONFIGURE_MODAL";

export const HANDLE_PO_LOCATION_MODAL =
  "HANDLE_PO_LOCATION_MODAL";

export const SET_CLEARBIT_PURCHASE_DATA = "SET_CLEARBIT_PURCHASE_DATA";

export const SET_CLEARBIT_PURCHASE_PRODUCT_DATA = "SET_CLEARBIT_PURCHASE_PRODUCT_DATA";

export const LINK_PURCHASE_SUPPLIERS_REQUEST =
  "LINK_PURCHASE_SUPPLIERS_REQUEST";
export const LINK_PURCHASE_SUPPLIERS_SUCCESS =
  "LINK_PURCHASE_SUPPLIERS_SUCCESS";
export const LINK_PURCHASE_SUPPLIERS_FAILURE =
  "LINK_PURCHASE_SUPPLIERS_FAILURE";

export const GET_GENERATOR_SUPPLIERS_LIST_REQUEST =
  "GET_GENERATOR_SUPPLIERS_LIST_REQUEST";
export const GET_GENERATOR_SUPPLIERS_LIST_SUCCESS =
  "GET_GENERATOR_SUPPLIERS_LIST_SUCCESS";
export const GET_GENERATOR_SUPPLIERS_LIST_FAILURE =
  "GET_GENERATOR_SUPPLIERS_LIST_FAILURE";

export const GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_REQUEST =
  "GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_REQUEST";
export const GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_SUCCESS =
  "GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_SUCCESS";
export const GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_FAILURE =
  "GET_GENERATOR_CATALOGUE_SUPPLIERS_LIST_FAILURE";

export const HANDLE_SUPPLIERS_SUBSCRIPTION_MODAL =
  "HANDLE_SUPPLIERS_SUBSCRIPTION_MODAL";

export const GENERATE_ORDER_BY_SUPPLIER_ID_REQUEST =
  "GENERATE_ORDER_BY_SUPPLIER_ID_REQUEST";
export const GENERATE_ORDER_BY_SUPPLIER_ID_SUCCESS =
  "GENERATE_ORDER_BY_SUPPLIER_ID_SUCCESS";
export const GENERATE_ORDER_BY_SUPPLIER_ID_FAILURE =
  "GENERATE_ORDER_BY_SUPPLIER_ID_FAILURE";

export const GET_PURCHASE_SUPPLIERS_LIST_REQUEST =
  "GET_PURCHASE_SUPPLIERS_LIST_REQUEST";
export const GET_PURCHASE_SUPPLIERS_LIST_SUCCESS =
  "GET_PURCHASE_SUPPLIERS_LIST_SUCCESS";
export const GET_PURCHASE_SUPPLIERS_LIST_FAILURE =
  "GET_PURCHASE_SUPPLIERS_LIST_FAILURE";

export const HANDLE_UPDATE_SUPPLIERS_MODAL = "HANDLE_UPDATE_SUPPLIERS_MODAL";

export const SET_SUPPLIER_EDIT = "SET_SUPPLIER_EDIT";

export const HANDLE_LINK_SUPPLIES_CONFIGURE_MODAL =
  "HANDLE_LINK_SUPPLIES_CONFIGURE_MODAL";

export const HANDLE_LINK_CATALOGUE_CONFIGURE_MODAL =
  "HANDLE_LINK_CATALOGUE_CONFIGURE_MODAL";

export const ADD_SUPPLIES_TO_SUPPLIER_REQUEST =
  "ADD_SUPPLIES_TO_SUPPLIER_REQUEST";
export const ADD_SUPPLIES_TO_SUPPLIER_SUCCESS =
  "ADD_SUPPLIES_TO_SUPPLIER_SUCCESS";
export const ADD_SUPPLIES_TO_SUPPLIER_FAILURE =
  "ADD_SUPPLIES_TO_SUPPLIER_FAILURE";

export const GET_SUPPLIES_LIST_REQUEST = "GET_SUPPLIES_LIST_REQUEST";
export const GET_SUPPLIES_LIST_SUCCESS = "GET_SUPPLIES_LIST_SUCCESS";
export const GET_SUPPLIES_LIST_FAILURE = "GET_SUPPLIES_LIST_FAILURE";

export const HANDLE_FEEDBACK_MODAL = "HANDLE_FEEDBACK_MODAL";

export const GET_FEEDBACK_BY_SUPPLIER_ID_REQUEST =
  "GET_FEEDBACK_BY_SUPPLIER_ID_REQUEST";
export const GET_FEEDBACK_BY_SUPPLIER_ID_SUCCESS =
  "GET_FEEDBACK_BY_SUPPLIER_ID_SUCCESS";
export const GET_FEEDBACK_BY_SUPPLIER_ID_FAILURE =
  "GET_FEEDBACK_BY_SUPPLIER_ID_FAILURE";
//ACTIVITY MODAL
export const HANDLE_SUPPLIERS_ACTIVITY_MODAL =
  "HANDLE_SUPPLIERS_ACTIVITY_MODAL";

//ADD CALL
export const ADD_SUPPLIERS_ACTIVITY_CALL_REQUEST =
  "ADD_SUPPLIERS_ACTIVITY_CALL_REQUEST";
export const ADD_SUPPLIERS_ACTIVITY_CALL_SUCCESS =
  "ADD_SUPPLIERS_ACTIVITY_CALL_SUCCESS";
export const ADD_SUPPLIERS_ACTIVITY_CALL_FAILURE =
  "ADD_SUPPLIERS_ACTIVITY_CALL_FAILURE";

//ADD EVENT
export const ADD_SUPPLIERS_ACTIVITY_EVENT_REQUEST =
  "ADD_SUPPLIERS_ACTIVITY_EVENT_REQUEST";
export const ADD_SUPPLIERS_ACTIVITY_EVENT_SUCCESS =
  "ADD_SUPPLIERS_ACTIVITY_EVENT_SUCCESS";
export const ADD_SUPPLIERS_ACTIVITY_EVENT_FAILURE =
  "ADD_SUPPLIERS_ACTIVITY_EVENT_FAILURE";

//ADD TASK
export const ADD_SUPPLIERS_ACTIVITY_TASK_REQUEST =
  "ADD_SUPPLIERS_ACTIVITY_TASK_REQUEST";
export const ADD_SUPPLIERS_ACTIVITY_TASK_SUCCESS =
  "ADD_SUPPLIERS_ACTIVITY_TASK_SUCCESS";
export const ADD_SUPPLIERS_ACTIVITY_TASK_FAILURE =
  "ADD_SUPPLIERS_ACTIVITY_TASK_FAILURE";

export const GET_ALL_SUPPLIERS_LIST_REQUEST = "GET_ALL_SUPPLIERS_LIST_REQUEST";
export const GET_ALL_SUPPLIERS_LIST_SUCCESS = "GET_ALL_SUPPLIERS_LIST_SUCCESS";
export const GET_ALL_SUPPLIERS_LIST_FAILURE = "GET_ALL_SUPPLIERS_LIST_FAILURE";

export const INPUT_SEARCH_DATA_REQUEST = "INPUT_SEARCH_DATA_REQUEST";
export const INPUT_SEARCH_DATA_SUCCESS = "INPUT_SEARCH_DATA_SUCCESS";
export const INPUT_SEARCH_DATA_FAILURE = "INPUT_SEARCH_DATA_FAILURE";

export const UPDATE_SUPPLIER_BY_ID_REQUEST = "UPDATE_SUPPLIER_BY_ID_REQUEST";
export const UPDATE_SUPPLIER_BY_ID_SUCCESS = "UPDATE_SUPPLIER_BY_ID_SUCCESS";
export const UPDATE_SUPPLIER_BY_ID_FAILURE = "UPDATE_SUPPLIER_BY_ID_FAILURE";

export const GET_NOTES_LIST_BY_SUPPLIER_ID_REQUEST =
  "GET_NOTES_LIST_BY_SUPPLIER_ID_REQUEST";
export const GET_NOTES_LIST_BY_SUPPLIER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_SUPPLIER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_SUPPLIER_ID_FAILURE =
  "GET_NOTES_LIST_BY_SUPPLIER_ID_FAILURE";

export const SET_CLEARBIT_PURCHASE_SUPPLIER_DATA = "SET_CLEARBIT_PURCHASE_SUPPLIER_DATA";

export const HANDLE_SUPPLIES_DELETE_MODAL = "HANDLE_SUPPLIES_DELETE_MODAL";

export const DELETE_SUPLLIES_DATA_REQUEST = "DELETE_SUPLLIES_DATA_REQUEST";
export const DELETE_SUPLLIES_DATA_SUCCESS = "DELETE_SUPLLIES_DATA_SUCCESS";
export const DELETE_SUPLLIES_DATA_FAILURE = "DELETE_SUPLLIES_DATA_FAILURE";

export const GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_REQUEST = "GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_REQUEST";
export const GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_SUCCESS = "GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_SUCCESS";
export const GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_FAILURE = "GET_DELETED_SUPPLIES_BY_SUPPLIES_ID_FAILURE";

export const HANDLE_PURCHASE_DELETE_MODAL = "HANDLE_PURCHASE_DELETE_MODAL";

export const DELETE_PURCHASE_DATA_REQUEST = "DELETE_PURCHASE_DATA_REQUEST";
export const DELETE_PURCHASE_DATA_SUCCESS = "DELETE_PURCHASE_DATA_SUCCESS";
export const DELETE_PURCHASE_DATA_FAILURE = "DELETE_PURCHASE_DATA_FAILURE";

export const GET_DELETED_PURCHASE_BY_ID_REQUEST = "GET_DELETED_PURCHASE_BY_ID_REQUEST";
export const GET_DELETED_PURCHASE_BY_ID_SUCCESS = "GET_DELETED_PURCHASE_BY_ID_SUCCESS";
export const GET_DELETED_PURCHASE_BY_ID_FAILURE = "GET_DELETED_PURCHASE_BY_ID_FAILURE";


//document
export const HANDLE_SUPPLIER_DOCUMENT_UPLOAD_MODAL =
  "HANDLE_SUPPLIER_DOCUMENT_UPLOAD_MODAL";

  export const HANDLE_SUPPLIER_EXCLE_UPLOAD_MODAL =
  "HANDLE_SUPPLIER_EXCLE_UPLOAD_MODAL";
//add SUPPLIER Document
export const ADD_SUPPLIER_DOCUMENT_REQUEST = "ADD_SUPPLIER_DOCUMENT_REQUEST";
export const ADD_SUPPLIER_DOCUMENT_SUCCESS = "ADD_SUPPLIER_DOCUMENT_SUCCESS";
export const ADD_SUPPLIER_DOCUMENT_FAILURE = "ADD_SUPPLIER_DOCUMENT_FAILURE";

//get SUPPLIER Document
export const GET_SUPPLIER_DOCUMENTS_REQUEST = "GET_SUPPLIER_DOCUMENTS_REQUEST";
export const GET_SUPPLIER_DOCUMENTS_SUCCESS = "GET_SUPPLIER_DOCUMENTS_SUCCESS";
export const GET_SUPPLIER_DOCUMENTS_FAILURE = "GET_SUPPLIER_DOCUMENTS_FAILURE";

export const HANDLE_SUPPLIER_CONTACT_MODAL =
  "HANDLE_SUPPLIER_CONTACT_MODAL";

export const ADD_SUPPLIER_CONTACT_REQUEST = "ADD_SUPPLIER_CONTACT_REQUEST";
export const ADD_SUPPLIER_CONTACT_SUCCESS = "ADD_SUPPLIER_CONTACT_SUCCESS";
export const ADD_SUPPLIER_CONTACT_FAILURE = "ADD_SUPPLIER_CONTACT_FAILURE";

export const GET_SUPPLIER_CONTACT_LIST_BY_ID_REQUEST = "GET_SUPPLIER_CONTACT_LIST_BY_ID_REQUEST";
export const GET_SUPPLIER_CONTACT_LIST_BY_ID_SUCCESS = "GET_SUPPLIER_CONTACT_LIST_BY_ID_SUCCESS";
export const GET_SUPPLIER_CONTACT_LIST_BY_ID_FAILURE = "GET_SUPPLIER_CONTACT_LIST_BY_ID_FAILURE";

//getallSupplier
export const GET_ALL_SUPPLIER_REQUEST = "GET_ALL_SUPPLIER_REQUEST";
export const GET_ALL_SUPPLIER_SUCCESS = "GET_ALL_SUPPLIER_SUCCESS";
export const GET_ALL_SUPPLIER_FAILURE = "GET_ALL_SUPPLIER_FAILURE";

//search dispatch item
export const SEARCH_DISPATCH_ITEM_REQUEST = "SEARCH_DISPATCH_ITEM_REQUEST";
export const SEARCH_DISPATCH_ITEM_SUCCESS = "SEARCH_DISPATCH_ITEM_SUCCESS";
export const SEARCH_DISPATCH_ITEM_FAILURE = "SEARCH_DISPATCH_ITEM_FAILURE";

export const GET_CONTACTS_OF_SUPPLIER_REQUEST = "GET_CONTACTS_OF_SUPPLIER_REQUEST";
export const GET_CONTACTS_OF_SUPPLIER_SUCCESS = "GET_CONTACTS_OF_SUPPLIER_SUCCESS";
export const GET_CONTACTS_OF_SUPPLIER_FAILURE = "GET_CONTACTS_OF_SUPPLIER_FAILURE";

export const GET_CONTACT_SUPPLIER_LIST_BY_ID_REQUEST = "GET_CONTACT_SUPPLIER_LIST_BY_ID_REQUEST";
export const GET_CONTACT_SUPPLIER_LIST_BY_ID_SUCCESS = "GET_CONTACT_SUPPLIER_LIST_BY_ID_SUCCESS";
export const GET_CONTACT_SUPPLIER_LIST_BY_ID_FAILURE = "GET_CONTACT_SUPPLIER_LIST_BY_ID_FAILURE";

export const SET_SUPPLIER_DASHBOARD_TYPE = "SET_SUPPLIER_DASHBOARD_TYPE";
export const CHANGE_SELECTED_TIME_INTERVAL = "CHANGE_SELECTED_TIME_INTERVAL";
export const SET_TIME_INTERVAL = "SET_TIME_INTERVAL";
export const SET_FISCAL_TIME_INTERVAL = "SET_FISCAL_TIME_INTERVAL";

export const GET_SUPPLIER_HISTORY_REQUEST = "GET_SUPPLIER_HISTORY_REQUEST";
export const GET_SUPPLIER_HISTORY_SUCCESS = "GET_SUPPLIER_HISTORY_SUCCESS";
export const GET_SUPPLIER_HISTORY_FAILURE = "GET_SUPPLIER_HISTORY_FAILURE";

export const GET_ACTIVITY_LIST_BY_SUPPLIERID_REQUEST = "GET_ACTIVITY_LIST_BY_SUPPLIERID_REQUEST";
export const GET_ACTIVITY_LIST_BY_SUPPLIERID_SUCCESS = "GET_ACTIVITY_LIST_BY_SUPPLIERID_SUCCESS";
export const GET_ACTIVITY_LIST_BY_SUPPLIERID_FAILURE = "GET_ACTIVITY_LIST_BY_SUPPLIERID_FAILURE";

export const HANDLE_UPDATE_SUPPLIERS_CONTACT_MODAL = "HANDLE_UPDATE_SUPPLIERS_CONTACT_MODAL";

export const SET_SUPPLIER_CONTACT_EDIT = "SET_SUPPLIER_CONTACT_EDIT";

export const UPDATE_SUPPLIER_CONTACT_REQUEST = "UPDATE_SUPPLIER_CONTACT_REQUEST";
export const UPDATE_SUPPLIER_CONTACT_SUCCESS = "UPDATE_SUPPLIER_CONTACT_SUCCESS";
export const UPDATE_SUPPLIER_CONTACT_FAILURE = "UPDATE_SUPPLIER_CONTACT_FAILURE";

export const HANDLE_UPDATE_SUPPLIERS_SUPPLIES_MODAL = "HANDLE_UPDATE_SUPPLIERS_SUPPLIES_MODAL";

export const SET_SUPPLIER_SUPPLIES_EDIT = "SET_SUPPLIER_SUPPLIES_EDIT";

export const UPDATE_PRICE_OF_PO_ITEM_REQUEST = "UPDATE_PRICE_OF_PO_ITEM_REQUEST";
export const UPDATE_PRICE_OF_PO_ITEM_SUCCESS = "UPDATE_PRICE_OF_PO_ITEM_SUCCESS";
export const UPDATE_PRICE_OF_PO_ITEM_FAILURE = "UPDATE_PRICE_OF_PO_ITEM_FAILURE";

export const ADD_TERMS_N_CONDITION_REQUEST = "ADD_TERMS_N_CONDITION_REQUEST";
export const ADD_TERMS_N_CONDITION_SUCCESS = "ADD_TERMS_N_CONDITION_SUCCESS";
export const ADD_TERMS_N_CONDITION_FAILURE = "ADD_TERMS_N_CONDITION_FAILURE";

export const ADD_CURRENCY_IN_PO_REQUEST = "ADD_CURRENCY_IN_PO_REQUEST";
export const ADD_CURRENCY_IN_PO_SUCCESS = "ADD_CURRENCY_IN_PO_SUCCESS";
export const ADD_CURRENCY_IN_PO_FAILURE = "ADD_CURRENCY_IN_PO_FAILURE";

export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_TERMS_AND_CONDITION_OF_PO_REQUEST = "GET_TERMS_AND_CONDITION_OF_PO_REQUEST";
export const GET_TERMS_AND_CONDITION_OF_PO_SUCCESS = "GET_TERMS_AND_CONDITION_OF_PO_SUCCESS";
export const GET_TERMS_AND_CONDITION_OF_PO_FAILURE = "GET_TERMS_AND_CONDITION_OF_PO_FAILURE";

export const HANDLE_LINK_SUPPLIER_CATALOGUE_MODAL = "HANDLE_LINK_SUPPLIER_CATALOGUE_MODAL";

export const GET_PURCHASE_ORDER_DETAILS_LIST_REQUEST =
  "GET_PURCHASE_ORDER_DETAILS_LIST_REQUEST";
export const GET_PURCHASE_ORDER_DETAILS_LIST_SUCCESS =
  "GET_PURCHASE_ORDER_DETAILS_LIST_SUCCESS";
export const GET_PURCHASE_ORDER_DETAILS_LIST_FAILURE =
  "GET_PURCHASE_ORDER_DETAILS_LIST_FAILURE";

export const EMPTY_SUPPLIER_LIST = "EMPTY_SUPPLIER_LIST"

export const EMPTY_SUPPLIER_PRICE_LIST = "EMPTY_SUPPLIER_PRICE_LIST"

export const HANDLE_SUPPLIERS_SUPPLIES_DRAWER = "HANDLE_SUPPLIERS_SUPPLIES_DRAWER";

export const GET_SUPPLIER_SUPPLIES_REQUEST = "GET_SUPPLIER_SUPPLIES_REQUEST";
export const GET_SUPPLIER_SUPPLIES_SUCCESS = "GET_SUPPLIER_SUPPLIES_SUCCESS";
export const GET_SUPPLIER_SUPPLIES_FAILURE = "GET_SUPPLIER_SUPPLIES_FAILURE";

export const SET_SUPPLIER_SUPPLIES_REQUEST = "SET_SUPPLIER_SUPPLIES_REQUEST";
export const SET_SUPPLIER_SUPPLIES_SUCCESS = "SET_SUPPLIER_SUPPLIES_SUCCESS";
export const SET_SUPPLIER_SUPPLIES_FAILURE = "SET_SUPPLIER_SUPPLIES_FAILURE";

export const GET_SUPPLIER_COUNT_REQUEST = "GET_SUPPLIER_COUNT_REQUEST";
export const GET_SUPPLIER_COUNT_SUCCESS = "GET_SUPPLIER_COUNT_SUCCESS";
export const GET_SUPPLIER_COUNT_FAILURE = "GET_SUPPLIER_COUNT_FAILURE";

export const GET_ALL_SUPPLIER_COUNT_REQUEST = "GET_ALL_SUPPLIER_COUNT_REQUEST";
export const GET_ALL_SUPPLIER_COUNT_SUCCESS = "GET_ALL_SUPPLIER_COUNT_SUCCESS";
export const GET_ALL_SUPPLIER_COUNT_FAILURE = "GET_ALL_SUPPLIER_COUNT_FAILURE";

export const GET_DELETED_SUPPLIER_COUNT_REQUEST = "GET_DELETED_SUPPLIER_COUNT_REQUEST";
export const GET_DELETED_SUPPLIER_COUNT_SUCCESS = "GET_DELETED_SUPPLIER_COUNT_SUCCESS";
export const GET_DELETED_SUPPLIER_COUNT_FAILURE = "GET_DELETED_SUPPLIER_COUNT_FAILURE";

export const GET_SUPPLIER_SUPPLIES_QUALITY_REQUEST = "GET_SUPPLIER_SUPPLIES_QUALITY_REQUEST";
export const GET_SUPPLIER_SUPPLIES_QUALITY_SUCCESS = "GET_SUPPLIER_SUPPLIES_QUALITY_SUCCESS";
export const GET_SUPPLIER_SUPPLIES_QUALITY_FAILURE = "GET_SUPPLIER_SUPPLIES_QUALITY_FAILURE";


export const DELETE_SUPPLIER_DATA_REQUEST = "DELETE_SUPPLIER_DATA_REQUEST";
export const DELETE_SUPPLIER_DATA_SUCCESS = "DELETE_SUPPLIER_DATA_SUCCESS";
export const DELETE_SUPPLIER_DATA_FAILURE = "DELETE_SUPPLIER_DATA_FAILURE";


export const UPDATE_SUPPLIERS_BY_ID_REQUEST = "UPDATE_SUPPLIERS_BY_ID_REQUEST";
export const UPDATE_SUPPLIERS_BY_ID_SUCCESS = "UPDATE_SUPPLIERS_BY_ID_SUCCESS";
export const UPDATE_SUPPLIERS_BY_ID_FAILURE = "UPDATE_SUPPLIERS_BY_ID_FAILURE";


export const SET_SUPPLIERS_EDIT = "SET_SUPPLIERS_EDIT";

export const GET_SUPPLIERS_DELETED_LIST_REQUEST = "GET_SUPPLIERS_DELETED_LIST_REQUEST";
export const GET_SUPPLIERS_DELETED_LIST_SUCCESS = "GET_SUPPLIERS_DELETED_LIST_SUCCESS";
export const GET_SUPPLIERS_DELETED_LIST_FAILURE = "GET_SUPPLIERS_DELETED_LIST_FAILURE";


export const HANDLE_SUPPLIERS_PRICE_DRAWER = 'HANDLE_SUPPLIERS_PRICE_DRAWER';

export const HANDLE_SUPPLIERS_LIST_DRAWER = 'HANDLE_SUPPLIERS_LIST_DRAWER';

export const UPDATE_PRICE_OF_SUPPLIER_REQUEST = "UPDATE_PRICE_OF_SUPPLIER_REQUEST";
export const UPDATE_PRICE_OF_SUPPLIER_SUCCESS = "UPDATE_PRICE_OF_SUPPLIER_SUCCESS";
export const UPDATE_PRICE_OF_SUPPLIER_FAILURE = "UPDATE_PRICE_OF_SUPPLIER_FAILURE";


export const GET_SUPPLIER_WISE_QUALITY_REQUEST = "GET_SUPPLIER_WISE_QUALITY_REQUEST";
export const GET_SUPPLIER_WISE_QUALITY_SUCCESS = "GET_SUPPLIER_WISE_QUALITY_SUCCESS";
export const GET_SUPPLIER_WISE_QUALITY_FAILURE = "GET_SUPPLIER_WISE_QUALITY_FAILURE";


export const REINSTATE_TOGGLE_FOR_SUPPLIER_REQUEST = "REINSTATE_TOGGLE_FOR_SUPPLIER_REQUEST";
export const REINSTATE_TOGGLE_FOR_SUPPLIER_SUCCESS = "REINSTATE_TOGGLE_FOR_SUPPLIER_SUCCESS";
export const REINSTATE_TOGGLE_FOR_SUPPLIER_FAILURE = "REINSTATE_TOGGLE_FOR_SUPPLIER_FAILURE";

export const ADD_MANUAL_REQUEST = "ADD_MANUAL_REQUEST";
export const ADD_MANUAL_SUCCESS = "ADD_MANUAL_SUCCESS";
export const ADD_MANUAL_FAILURE = "ADD_MANUAL_FAILURE";

export const GET_INVENTORYLIST_REQUEST = "GET_INVENTORYLIST_REQUEST";
export const GET_INVENTORYLIST_SUCCESS = "GET_INVENTORYLIST_SUCCESS";
export const GET_INVENTORYLIST_FAILURE = "GET_INVENTORYLIST_FAILURE";

export const GET_INVENTORYALLLIST_REQUEST = "GET_INVENTORYALLLIST_REQUEST";
export const GET_INVENTORYALLLIST_SUCCESS = "GET_INVENTORYALLLIST_SUCCESS";
export const GET_INVENTORYALLLIST_FAILURE = "GET_INVENTORYALLLIST_FAILURE";

export const GET_CATEGORYLIST_REQUEST = "GET_CATEGORYLIST_REQUEST";
export const GET_CATEGORYLIST_SUCCESS = "GET_CATEGORYLIST_SUCCESS";
export const GET_CATEGORYLIST_FAILURE = "GET_CATEGORYLIST_FAILURE";

export const UPDATE_PO_CONTACT_REQUEST="UPDATE_PO_CONTACT_REQUEST";
export const UPDATE_PO_CONTACT_SUCCESS="UPDATE_PO_CONTACT_SUCCESS";
export const UPDATE_PO_CONTACT_FAILURE="UPDATE_PO_CONTACT_FAILURE";

export const GET_SEARCH_INVENTORY_REQUEST="GET_SEARCH_INVENTORY_REQUEST";
export const GET_SEARCH_INVENTORY_SUCCESS="GET_SEARCH_INVENTORY_SUCCESS";
export const GET_SEARCH_INVENTORY_FAILURE="GET_SEARCH_INVENTORY_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_INVENTORY = "HANDLE_CLAER_REDUCER_DATA_INVENTORY";


export const LINK_SUPPLIERS_TOGGLE_REQUEST =
"LINK_SUPPLIERS_TOGGLE_REQUEST";
export const LINK_SUPPLIERS_TOGGLE_SUCCESS =
"LINK_SUPPLIERS_TOGGLE_SUCCESS";
export const LINK_SUPPLIERS_TOGGLE_FAILURE =
"LINK_SUPPLIERS_TOGGLE_FAILURE";

export const LINK_SUPPLIERS_INVENTORY_TOGGLE_REQUEST =
"LINK_SUPPLIERS_INVENTORY_TOGGLE_REQUEST";
export const LINK_SUPPLIERS_INVENTORY_TOGGLE_SUCCESS =
"LINK_SUPPLIERS_INVENTORY_TOGGLE_SUCCESS";
export const LINK_SUPPLIERS_INVENTORY_TOGGLE_FAILURE =
"LINK_SUPPLIERS_INVENTORY_TOGGLE_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_SUPPLIER = "HANDLE_CLAER_SEARCHED_DATA_SUPPLIER";