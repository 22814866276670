export const SET_DATAROOM_VIEW_TYPE = "SET_DATAROOM_VIEW_TYPE";

export const HANDLE_DATAROOM_MODAL = "HANDLE_DATAROOM_MODAL";

export const HANDLE_DATAROOM_NOTES_DRAWER_MODAL = "HANDLE_DATAROOM_NOTES_DRAWER_MODAL";

export const GET_DATAROOM_REQUEST = "GET_DATAROOM_REQUEST";
export const GET_DATAROOM_SUCCESS = "GET_DATAROOM_SUCCESS";
export const GET_DATAROOM_FAILURE = "GET_DATAROOM_FAILURE";

export const GET_USERLIST_REQUEST = "GET_USERLIST_REQUEST";
export const GET_USERLIST_SUCCESS = "GET_USERLIST_SUCCESS";
export const GET_USERLIST_FAILURE = "GET_USERLIST_FAILURE";

export const ADD_DATAROOM_REQUEST = "ADD_DATAROOM_REQUEST";
export const ADD_DATAROOM_SUCCESS = "ADD_DATAROOM_SUCCESS";
export const ADD_DATAROOM_FAILURE = "ADD_DATAROOM_FAILURE";