export const GET_LEVELS_REQUEST = "GET_LEVELS_REQUEST";
export const GET_LEVELS_SUCCESS = "GET_LEVELS_SUCCESS";
export const GET_LEVELS_FAILURE = "GET_LEVELS_FAILURE";

export const ADD_LEVELS_REQUEST = "ADD_LEVELS_REQUEST";
export const ADD_LEVELS_SUCCESS = "ADD_LEVELS_SUCCESS";
export const ADD_LEVELS_FAILURE = "ADD_LEVELS_FAILURE";

// export const REMOVE_LEVELS_REQUEST = "REMOVE_LEVELS_REQUEST";
// export const REMOVE_LEVELS_SUCCESS = "REMOVE_LEVELS_SUCCESS";
// export const REMOVE_LEVELS_FAILURE = "REMOVE_LEVELS_FAILURE";

// export const UPDATE_LEVELS_REQUEST = "UPDATE_LEVELS_REQUEST";
// export const UPDATE_LEVELS_SUCCESS = "UPDATE_LEVELS_SUCCESS";
// export const UPDATE_LEVELS_FAILURE = "UPDATE_LEVELS_FAILURE";

// export const GET_SECTOR_SEARCH_REQUEST="GET_SECTOR_SEARCH_REQUEST";
// export const GET_SECTOR_SEARCH_SUCCESS="GET_SECTOR_SEARCH_SUCCESS";
// export const GET_SECTOR_SEARCH_FAILURE="GET_SECTOR_SEARCH_FAILURE";
