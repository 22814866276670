export const HANDLE_DISTRIBUTOR_MODAL = "HANDLE_DISTRIBUTOR_MODAL";
export const SET_DISTRIBUTOR_VIEW_TYPE = "SET_DISTRIBUTOR_VIEW_TYPE";

export const ADD_DISTRIBUTOR_REQUEST = "ADD_DISTRIBUTOR_REQUEST";
export const ADD_DISTRIBUTOR_SUCCESS = "ADD_DISTRIBUTOR_SUCCESS";
export const ADD_DISTRIBUTOR_FAILURE = "ADD_DISTRIBUTOR_FAILURE";


export const ADD_ACCOUNT_IMPORT_FORM_REQUEST = "ADD_ACCOUNT_IMPORT_FORM_REQUEST";
export const ADD_ACCOUNT_IMPORT_FORM_SUCCESS = "ADD_ACCOUNT_IMPORT_FORM_SUCCESS";
export const ADD_ACCOUNT_IMPORT_FORM_FAILURE = "ADD_ACCOUNT_IMPORT_FORM_FAILURE";

export const HANDLE_ACCOUNT_IMPORT_MODAL = "HANDLE_ACCOUNT_IMPORT_MODAL";

export const ADD_SUPERVISOR_REQUEST = "ADD_SUPERVISOR_REQUEST";
export const ADD_SUPERVISOR_SUCCESS = "ADD_SUPERVISOR_SUCCESS";
export const ADD_SUPERVISOR_FAILURE = "ADD_SUPERVISOR_FAILURE";

export const GET_ORDER_BY_ID_REQUEST = "GET_ORDER_BY_ID_REQUEST";
export const GET_ORDER_BY_ID_SUCCESS = "GET_ORDER_BY_ID_SUCCESS";
export const GET_ORDER_BY_ID_FAILURE = "GET_ORDER_BY_ID_FAILURE";

export const ADD_LEAD_REQUEST = "ADD_LEAD_REQUEST";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const ADD_LEAD_FAILURE = "ADD_LEAD_FAILURE";

export const UPDATE_SUBORDER_AWB_REQUEST = "UPDATE_SUBORDER_AWB_REQUEST";
export const UPDATE_SUBORDER_AWB_SUCCESS = "UPDATE_SUBORDER_AWB_SUCCESS";
export const UPDATE_SUBORDER_AWB_FAILURE = "UPDATE_SUBORDER_AWB_FAILURE";

export const DELETE_DISTRIBUTOR_REQUEST = "DELETE_DISTRIBUTOR_REQUEST";
export const DELETE_DISTRIBUTOR_SUCCESS = "DELETE_DISTRIBUTOR_SUCCESS";
export const DELETE_DISTRIBUTOR_FAILURE = "DELETE_DISTRIBUTOR_FAILURE";

export const GET_LOB_LIST_REQUEST = "GET_LOB_LIST_REQUEST";
export const GET_LOB_LIST_SUCCESS = "GET_LOB_LIST_SUCCESS";
export const GET_LOB_LIST_FAILURE = "GET_LOB_LIST_FAILURE";

export const GET_DISPATCH_ITEM_LIST_REQUEST = "GET_DISPATCH_ITEM_LIST_REQUEST";
export const GET_DISPATCH_ITEM_LIST_SUCCESS = "GET_DISPATCH_ITEM_LIST_SUCCESS";
export const GET_DISPATCH_ITEM_LIST_FAILURE = "GET_DISPATCH_ITEM_LIST_FAILURE";

export const GET_PULSE_LIST_REQUEST = "GET_PULSE_LIST_REQUEST";
export const GET_PULSE_LIST_SUCCESS = "GET_PULSE_LIST_SUCCESS";
export const GET_PULSE_LIST_FAILURE = "GET_PULSE_LIST_FAILURE";

export const GET_ORDER_STATUS_REQUEST = "GET_ORDER_STATUS_REQUEST";
export const GET_ORDER_STATUS_SUCCESS = "GET_ORDER_STATUS_SUCCESS";
export const GET_ORDER_STATUS_FAILURE = "GET_ORDER_STATUS_FAILURE";

export const DELETE_SPARE_LIST_REQUEST = "DELETE_SPARE_LIST_REQUEST";
export const DELETE_SPARE_LIST_SUCCESS = "DELETE_SPARE_LIST_SUCCESS";
export const DELETE_SPARE_LIST_FAILURE = "DELETE_SPARE_LIST_FAILURE";

export const UPDATE_SPARELIST_ITEM_REQUEST = "UPDATE_SPARELIST_ITEM_REQUEST";
export const UPDATE_SPARELIST_ITEM_SUCCESS = "UPDATE_SPARELIST_ITEM_SUCCESS";
export const UPDATE_SPARELIST_ITEM_FAILURE = "UPDATE_SPARELIST_ITEM_FAILURE";

export const GET_DISTRIBUTORS_BY_USER_ID_REQUEST =
  "GET_DISTRIBUTORS_BY_USER_ID_REQUEST";
export const GET_DISTRIBUTORS_BY_USER_ID_SUCCESS =
  "GET_DISTRIBUTORS_BY_USER_ID_SUCCESS";
export const GET_DISTRIBUTORS_BY_USER_ID_FAILURE =
  "GET_DISTRIBUTORS_BY_USER_ID_FAILURE";

export const GET_LOCATION_BY_PRODUCTID_REQUEST =
  "GET_LOCATION_BY_PRODUCTID_REQUEST";
export const GET_LOCATION_BY_PRODUCTID_SUCCESS =
  "GET_LOCATION_BY_PRODUCTID_SUCCESS";
export const GET_LOCATION_BY_PRODUCTID_FAILURE =
  "GET_LOCATION_BY_PRODUCTID_FAILURE";

export const GET_PAYMENT_MODE_REQUEST =
  "GET_PAYMENT_MODE_REQUEST";
export const GET_PAYMENT_MODE_SUCCESS =
  "GET_PAYMENT_MODE_SUCCESS";
export const GET_PAYMENT_MODE_FAILURE =
  "GET_PAYMENT_MODE_FAILURE";

export const HANDLE_UPDATE_ORDER_MODAL =
  "HANDLE_UPDATE_ORDER_MODAL";

export const HANDLE_ADD_ORDER_MODAL =
  "HANDLE_ADD_ORDER_MODAL";

export const HANDLE_ORDER_GENERATE_MODAL = "HANDLE_ORDER_GENERATE_MODAL";

export const GET_LOCATION_LIST_REQUEST =
  "GET_LOCATION_LIST_REQUEST";
export const GET_LOCATION_LIST_SUCCESS =
  "GET_LOCATION_LIST_SUCCESS";
export const GET_LOCATION_LIST_FAILURE =
  "GET_LOCATION_LIST_FAILURE";

export const SET_CLEARBIT_DATA = "SET_CLEARBIT_DATA";


export const ADD_CAR_REQUEST = "ADD_CAR_REQUEST";
export const ADD_CAR_SUCCESS = "ADD_CAR_SUCCESS";
export const ADD_CAR_FAILURE = "ADD_CAR_FAILURE";

export const ADD_PROCURE_DETAILS_REQUEST = "ADD_PROCURE_DETAILS_REQUEST";
export const ADD_PROCURE_DETAILS_SUCCESS = "ADD_PROCURE_DETAILS_SUCCESS";
export const ADD_PROCURE_DETAILS_FAILURE = "ADD_PROCURE_DETAILS_FAILURE";

export const ADD_ORDER_REQUEST = "ADD_ORDER_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const ADD_QUOTATION_ORDER_REQUEST = "ADD_QUOTATION_ORDER_REQUEST";
export const ADD_QUOTATION_ORDER_SUCCESS = "ADD_QUOTATION_ORDER_SUCCESS";
export const ADD_QUOTATION_ORDER_FAILURE = "ADD_QUOTATION_ORDER_FAILURE";

export const GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_REQUEST =
  "GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_REQUEST";
export const GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_SUCCESS =
  "GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_SUCCESS";
export const GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_FAILURE =
  "GET_DISTRIBUTOR_BY_DISTRIBUTOR_ID_FAILURE";

export const HANDLE_LINK_ORDER_CONFIGURE_MODAL =
  "HANDLE_LINK_ORDER_CONFIGURE_MODAL";

export const HANDLE_LINK_CUSTOMER_PROCUREMENT_MODAL =
  "HANDLE_LINK_CUSTOMER_PROCUREMENT_MODAL";

export const HANDLE_LINK_QUOTE_CONFIGURE_MODAL =
  "HANDLE_LINK_QUOTE_CONFIGURE_MODAL";

export const HANDLE_DISTRIBUTOR_ACTIVITY_TABLE_MODAL =
  "HANDLE_DISTRIBUTOR_ACTIVITY_TABLE_MODAL";

//Add order

export const LINK_ORDER_BY_DISTRIBUTOR_ID_REQUEST =
  "LINK_ORDER_BY_DISTRIBUTOR_ID_REQUEST";
export const LINK_ORDER_BY_DISTRIBUTOR_ID_SUCCESS =
  "LINK_ORDER_BY_DISTRIBUTOR_ID_SUCCESS";
export const LINK_ORDER_BY_DISTRIBUTOR_ID_FAILURE =
  "LINK_ORDER_BY_DISTRIBUTOR_ID_FAILURE";

export const SET_CLEARBIT_ORDER_DATA = "SET_CLEARBIT_ORDER_DATA";

export const FETCHING_NEW_DISTRIBUTOR_ORDER_REQUEST =
  "FETCHING_NEW_DISTRIBUTOR_ORDER_REQUEST";
export const FETCHING_NEW_DISTRIBUTOR_ORDER_SUCCESS =
  "FETCHING_NEW_DISTRIBUTOR_ORDER_SUCCESS";
export const FETCHING_NEW_DISTRIBUTOR_ORDER_FAILURE =
  "FETCHING_NEW_DISTRIBUTOR_ORDER_FAILURE";

export const HANDLE_DISTRIBUTOR_SUBSCRIPTION_MODAL =
  "HANDLE_DISTRIBUTOR_SUBSCRIPTION_MODAL";

export const GET_ACTIVITY_LIST_BY_DISTRIBUTORID_REQUEST =
  "GET_ACTIVITY_LIST_BY_DISTRIBUTORID_REQUEST";
export const GET_ACTIVITY_LIST_BY_DISTRIBUTORID_SUCCESS =
  "GET_ACTIVITY_LIST_BY_DISTRIBUTORID_SUCCESS";
export const GET_ACTIVITY_LIST_BY_DISTRIBUTORID_FAILURE =
  "GET_ACTIVITY_LIST_BY_DISTRIBUTORID_FAILURE";

export const HANDLE_DISTRIBUTOR_ACTIVITY_MODAL =
  "HANDLE_DISTRIBUTOR_ACTIVITY_MODAL";

// add call

export const ADD_DISTRIBUTOR_ACTIVITY_CALL_REQUEST =
  "ADD_DISTRIBUTOR_ACTIVITY_CALL_REQUEST";
export const ADD_DISTRIBUTOR_ACTIVITY_CALL_SUCCESS =
  "ADD_DISTRIBUTOR_ACTIVITY_CALL_SUCCESS";
export const ADD_DISTRIBUTOR_ACTIVITY_CALL_FAILURE =
  "ADD_DISTRIBUTOR_ACTIVITY_CALL_FAILURE";

//add event
export const ADD_DISTRIBUTOR_ACTIVITY_EVENT_REQUEST =
  "ADD_DISTRIBUTOR_ACTIVITY_EVENT_REQUEST";
export const ADD_DISTRIBUTOR_ACTIVITY_EVENT_SUCCESS =
  "ADD_DISTRIBUTOR_ACTIVITY_EVENT_SUCCESS";
export const ADD_DISTRIBUTOR_ACTIVITY_EVENT_FAILURE =
  "ADD_DISTRIBUTOR_ACTIVITY_EVENT_FAILURE";

// add task
export const ADD_DISTRIBUTOR_ACTIVITY_TASK_REQUEST =
  "ADD_DISTRIBUTOR_ACTIVITY_TASK_REQUEST";
export const ADD_DISTRIBUTOR_ACTIVITY_TASK_SUCCESS =
  "ADD_DISTRIBUTOR_ACTIVITY_TASK_SUCCESS";
export const ADD_DISTRIBUTOR_ACTIVITY_TASK_FAILURE =
  "ADD_DISTRIBUTOR_ACTIVITY_TASK_FAILURE";

// get note lists

export const GET_NOTES_LIST_BY_DISTRIBUTOR_ID_REQUEST =
  "GET_NOTES_LIST_BY_DISTRIBUTOR_ID_REQUEST";
export const GET_NOTES_LIST_BY_DISTRIBUTOR_ID_SUCCESS =
  "GET_NOTES_LIST_BY_DISTRIBUTOR_ID_SUCCESS";
export const GET_NOTES_LIST_BY_DISTRIBUTOR_ID_FAILURE =
  "GET_NOTES_LIST_BY_DISTRIBUTOR_ID_FAILURE";

//generate order

export const GENERATE_ORDER_BY_DISTRIBUTOR_ID_REQUEST =
  "GENERATE_ORDER_BY_DISTRIBUTOR_ID_REQUEST";
export const GENERATE_ORDER_BY_DISTRIBUTOR_ID_SUCCESS =
  "GENERATE_ORDER_BY_DISTRIBUTOR_ID_SUCCESS";
export const GENERATE_ORDER_BY_DISTRIBUTOR_ID_FAILURE =
  "GENERATE_ORDER_BY_DISTRIBUTOR_ID_FAILURE";

//get order by distributorid

export const GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_REQUEST =
  "GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_REQUEST";
export const GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_SUCCESS =
  "GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_SUCCESS";
export const GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_FAILURE =
  "GET_DISTRIBUTOR_ORDER_BY_DISTRIBUTOR_ID_FAILURE";

  export const GET_DISTRIBUTOR_ORDER_OF_HIGH_REQUEST =
  "GET_DISTRIBUTOR_ORDER_OF_HIGH_REQUEST";
export const GET_DISTRIBUTOR_ORDER_OF_HIGH_SUCCESS =
  "GET_DISTRIBUTOR_ORDER_OF_HIGH_SUCCESS";
export const GET_DISTRIBUTOR_ORDER_OF_HIGH_FAILURE =
  "GET_DISTRIBUTOR_ORDER_OF_HIGH_FAILURE";

  export const GET_DISTRIBUTOR_ORDER_OF_MEDIUM_REQUEST =
  "GET_DISTRIBUTOR_ORDER_OF_MEDIUM_REQUEST";
export const GET_DISTRIBUTOR_ORDER_OF_MEDIUM_SUCCESS =
  "GET_DISTRIBUTOR_ORDER_OF_MEDIUM_SUCCESS";
export const GET_DISTRIBUTOR_ORDER_OF_MEDIUM_FAILURE =
  "GET_DISTRIBUTOR_ORDER_OF_MEDIUM_FAILURE";

  export const GET_DISTRIBUTOR_ORDER_OF_LOW_REQUEST =
  "GET_DISTRIBUTOR_ORDER_OF_LOW_REQUEST";
export const GET_DISTRIBUTOR_ORDER_OF_LOW_SUCCESS =
  "GET_DISTRIBUTOR_ORDER_OF_LOW_SUCCESS";
export const GET_DISTRIBUTOR_ORDER_OF_LOW_FAILURE =
  "GET_DISTRIBUTOR_ORDER_OF_LOW_FAILURE";
  

export const HANDLE_RENEWAL_BUTTON_MODAL = "HANDLE_RENEWAL_BUTTON_MODAL";

export const HANDLE_PAUSE_BUTTON_MODAL = "HANDLE_PAUSE_BUTTON_MODAL";

export const HANDLE_UPDATE_EVENT_MODAL = "HANDLE_UPDATE_EVENT_MODAL";
export const HANDLE_UPDATE_CALL_MODAL = "HANDLE_UPDATE_CALL_MODAL";
export const HANDLE_UPDATE_TASK_MODAL = "HANDLE_UPDATE_TASK_MODAL";

//post renewal form

export const LINK_RENEWAL_BY_DISTRIBUTOR_ID_REQUEST =
  "LINK_RENEWAL_BY_DISTRIBUTOR_ID_REQUEST";
export const LINK_RENEWAL_BY_DISTRIBUTOR_ID_SUCCESS =
  "LINK_RENEWAL_BY_DISTRIBUTOR_ID_SUCCESS";
export const LINK_RENEWAL_BY_DISTRIBUTOR_ID_FAILURE =
  "LINK_RENEWAL_BY_DISTRIBUTOR_ID_FAILURE";

//post pause form

export const LINK_PAUSE_BY_DISTRIBUTOR_ID_REQUEST =
  "LINK_PAUSE_BY_DISTRIBUTOR_ID_REQUEST";
export const LINK_PAUSE_BY_DISTRIBUTOR_ID_SUCCESS =
  "LINK_PAUSE_BY_DISTRIBUTOR_ID_SUCCESS";
export const LINK_PAUSE_BY_DISTRIBUTOR_ID_FAILURE =
  "LINK_PAUSE_BY_DISTRIBUTOR_ID_FAILURE";

export const SET_ORDER_EDIT = "SET_ORDER_EDIT";

export const SET_DISTRIBUTOR_EDIT = "SET_DISTRIBUTOR_EDIT";

export const HANDLE_UPDATE_DISTRIBUTOR_MODAL =
  "HANDLE_UPDATE_DISTRIBUTOR_MODAL";

export const UPDATE_DISTRIBUTOR_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_BY_ID_FAILURE";

export const UPDATE_ORDER_PAYMENT_AMOUNT_REQUEST =
  "UPDATE_ORDER_PAYMENT_AMOUNT_REQUEST";
export const UPDATE_ORDER_PAYMENT_AMOUNT_SUCCESS =
  "UPDATE_ORDER_PAYMENT_AMOUNT_SUCCESS";
export const UPDATE_ORDER_PAYMENT_AMOUNT_FAILURE =
  "UPDATE_ORDER_PAYMENT_AMOUNT_FAILURE";

export const INPUT_SEARCH_DATA_REQUEST = "INPUT_SEARCH_DATA_REQUEST";
export const INPUT_SEARCH_DATA_SUCCESS = "INPUT_SEARCH_DATA_SUCCESS";
export const INPUT_SEARCH_DATA_FAILURE = "INPUT_SEARCH_DATA_FAILURE";

export const GET_ORDER_DETAILS_BY_ID_REQUEST =
  "GET_ORDER_DETAILS_BY_ID_REQUEST";
export const GET_ORDER_DETAILS_BY_ID_SUCCESS =
  "GET_ORDER_DETAILS_BY_ID_SUCCESS";
export const GET_ORDER_DETAILS_BY_ID_FAILURE =
  "GET_ORDER_DETAILS_BY_ID_FAILURE";

export const GET_ALL_DISTRIBUTORS_LIST_REQUEST =
  "GET_ALL_DISTRIBUTORS_LIST_REQUEST";
export const GET_ALL_DISTRIBUTORS_LIST_SUCCESS =
  "GET_ALL_DISTRIBUTORS_LIST_SUCCESS";
export const GET_ALL_DISTRIBUTORS_LIST_FAILURE =
  "GET_ALL_DISTRIBUTORS_LISTFAILURE";

export const HANDLE_DISTRIBUTOR_ORDER_MODAL = "HANDLE_DISTRIBUTOR_ORDER_MODAL";

export const SET_DISTRIBUTOR_ORDER_EDIT = "SET_DISTRIBUTOR_ORDER_EDIT";

export const HANDLE_UPDATE_DISTRIBUTOR_ORDER_MODAL =
  "HANDLE_UPDATE_DISTRIBUTOR_ORDER_MODAL";

export const UPDATE_DISTRIBUTOR_ORDER_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_ORDER_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_ORDER_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_ORDER_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_ORDER_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_ORDER_BY_ID_FAILURE";

  export const UPDATE_SUSCRIPTION_REQUEST =
  "UPDATE_SUSCRIPTION_REQUEST";
export const UPDATE_SUSCRIPTION_SUCCESS =
  "UPDATE_SUSCRIPTION_SUCCESS";
export const UPDATE_SUSCRIPTION_FAILURE =
  "UPDATE_SUSCRIPTION_FAILURE";

export const FETCHING_DISTRIBUTOR_ORDER_HISTORY_REQUEST =
  "FETCHING_DISTRIBUTOR_ORDER_HISTORY_REQUEST";
export const FETCHING_DISTRIBUTOR_ORDER_HISTORY_SUCCESS =
  "FETCHING_DISTRIBUTOR_ORDER_HISTORY_SUCCESS";
export const FETCHING_DISTRIBUTOR_ORDER_HISTORY_FAILURE =
  "FETCHING_DISTRIBUTOR_ORDER_HISTORY_FAILURE";

export const UPDATE_DISTRIBUTOR_CARD_REQUEST =
  "UPDATE_DISTRIBUTOR_CARD_REQUEST";
export const UPDATE_DISTRIBUTOR_CARD_SUCCESS =
  "UPDATE_DISTRIBUTOR_CARD_SUCCESS";
export const UPDATE_DISTRIBUTOR_CARD_FAILURE =
  "UPDATE_DISTRIBUTOR_CARD_FAILURE";

export const GET_FEEDBACK_BY_DISTRIBUTOR_ID_REQUEST =
  "GET_FEEDBACK_BY_DISTRIBUTOR_ID_REQUEST";
export const GET_FEEDBACK_BY_DISTRIBUTOR_ID_SUCCESS =
  "GET_FEEDBACK_BY_DISTRIBUTOR_ID_SUCCESS";
export const GET_FEEDBACK_BY_DISTRIBUTOR_ID_FAILURE =
  "GET_FEEDBACK_BY_DISTRIBUTOR_ID_FAILURE";

export const HANDLE_FEEDBACK_MODAL = "HANDLE_FEEDBACK_MODAL";

export const GET_FEEDBACK_BY_ORDER_ID_REQUEST =
  "GET_FEEDBACK_BY_ORDER_ID_REQUEST";
export const GET_FEEDBACK_BY_ORDER_ID_SUCCESS =
  "GET_FEEDBACK_BY_ORDER_ID_SUCCESS";
export const GET_FEEDBACK_BY_ORDER_ID_FAILURE =
  "GET_FEEDBACK_BY_ORDER_ID_FAILURE";

export const GET_DISTRIBUTOR_HISTORY_REQUEST =
  "GET_DISTRIBUTOR_HISTORY_REQUEST";
export const GET_DISTRIBUTOR_HISTORY_SUCCESS =
  "GET_DISTRIBUTOR_HISTORY_SUCCESS";
export const GET_DISTRIBUTOR_HISTORY_FAILURE =
  "GET_DISTRIBUTOR_HISTORY_FAILURE";

export const EMPTY_DISTRIBUTOR_LIST = "EMPTY_DISTRIBUTOR_LIST";

export const HANDLE_PAID_BUTTON_MODAL = "HANDLE_PAID_BUTTON_MODAL";

export const HANDLE_ACCOUNT_UPDATE_MODAL = "HANDLE_ACCOUNT_UPDATE_MODAL";

export const HANDLE_ACCOUNT_MODAL = "HANDLE_ACCOUNT_MODAL";

export const ADD_PAID_BY_DISTRIBUTOR_ID_REQUEST =
  "ADD_PAID_BY_DISTRIBUTOR_ID_REQUEST";
export const ADD_PAID_BY_DISTRIBUTOR_ID_SUCCESS =
  "ADD_PAID_BY_DISTRIBUTOR_ID_SUCCESS";
export const ADD_PAID_BY_DISTRIBUTOR_ID_FAILURE =
  "ADD_PAID_BY_DISTRIBUTOR_ID_FAILURE";

export const FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_REQUEST =
  "FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_REQUEST";
export const FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_SUCCESS =
  "FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_SUCCESS";
export const FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_FAILURE =
  "FETCHING_DISTRIBUTOR_PAYMENT_HISTORY_FAILURE";

export const DELETE_DISTRIBUTOR_DATA_REQUEST =
  "DELETE_DISTRIBUTOR_DATA_REQUEST";
export const DELETE_DISTRIBUTOR_DATA_SUCCESS =
  "DELETE_DISTRIBUTOR_DATA_SUCCESS";
export const DELETE_DISTRIBUTOR_DATA_FAILURE =
  "DELETE_DISTRIBUTOR_DATA_FAILURE";

export const UPDATE_DISTRIBUTOR_CALL_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_CALL_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_CALL_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_CALL_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_CALL_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_CALL_BY_ID_FAILURE";

export const UPDATE_DISTRIBUTOR_EVENT_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_EVENT_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_EVENT_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_EVENT_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_EVENT_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_EVENT_BY_ID_FAILURE";

export const UPDATE_DISTRIBUTOR_TASK_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_TASK_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_TASK_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_TASK_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_TASK_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_TASK_BY_ID_FAILURE";

//deletedistributorbyid
export const GET_DELETED_DISTRIBUTORS_REQUEST =
  "GET_DELETED_DISTRIBUTORS_REQUEST";
export const GET_DELETED_DISTRIBUTORS_SUCCESS =
  "GET_DELETED_DISTRIBUTORS_SUCCESS";
export const GET_DELETED_DISTRIBUTORS_FAILURE =
  "GET_DELETED_DISTRIBUTORS_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_REQUEST =
  "FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_REQUEST";
export const FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_SUCCESS =
  "FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_SUCCESS";
export const FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_FAILURE =
  "FETCHING_DISTRIBUTOR_DELETED_ORDER_BY_ID_FAILURE";

export const FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_REQUEST =
  "FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_REQUEST";
export const FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_SUCCESS =
  "FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_SUCCESS";
export const FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_FAILURE =
  "FETCHING_DISTRIBUTOR_DELETED_QUOTE_BY_ID_FAILURE";

export const HANDLE_DELETE_ORDER_MODAL = "HANDLE_DELETE_ORDER_MODAL";

export const DELETE_DISTRIBUTOR_ORDER_DATA_REQUEST =
  "DELETE_DISTRIBUTOR_ORDER_DATA_REQUEST";
export const DELETE_DISTRIBUTOR_ORDER_DATA_SUCCESS =
  "DELETE_DISTRIBUTOR_ORDER_DATA_SUCCESS";
export const DELETE_DISTRIBUTOR_ORDER_DATA_FAILURE =
  "DELETE_DISTRIBUTOR_ORDER_DATA_FAILURE";

export const GET_ALL_RECORDS_REQUEST = "GET_ALL_RECORDS_REQUEST";
export const GET_ALL_RECORDS_SUCCESS = "GET_ALL_RECORDS_SUCCESS";
export const GET_ALL_RECORDS_FAILURE = "GET_ALL_RECORDS_FAILURE";

export const HANDLE_UPDATE_PRODUCT_DETAIL_MODAL =
  "HANDLE_UPDATE_PRODUCT_DETAIL_MODAL";

export const SET_ORDER_DETAIL_EDIT = "SET_ORDER_DETAIL_EDIT";

//document
export const HANDLE_DISTRIBUTOR_DOCUMENT_UPLOAD_MODAL =
  "HANDLE_DISTRIBUTOR_DOCUMENT_UPLOAD_MODAL";

//add DISTRIBUTOR Document
export const ADD_DISTRIBUTOR_DOCUMENT_REQUEST =
  "ADD_DISTRIBUTOR_DOCUMENT_REQUEST";
export const ADD_DISTRIBUTOR_DOCUMENT_SUCCESS =
  "ADD_DISTRIBUTOR_DOCUMENT_SUCCESS";
export const ADD_DISTRIBUTOR_DOCUMENT_FAILURE =
  "ADD_DISTRIBUTOR_DOCUMENT_FAILURE";

//get DISTRIBUTOR Document
export const GET_DISTRIBUTOR_DOCUMENTS_REQUEST =
  "GET_DISTRIBUTOR_DOCUMENTS_REQUEST";
export const GET_DISTRIBUTOR_DOCUMENTS_SUCCESS =
  "GET_DISTRIBUTOR_DOCUMENTS_SUCCESS";
export const GET_DISTRIBUTOR_DOCUMENTS_FAILURE =
  "GET_DISTRIBUTOR_DOCUMENTS_FAILURE";


export const GET_DISTRIBUTOR_TABLE_REQUEST =
  "GET_DISTRIBUTOR_TABLE_REQUEST";
export const GET_DISTRIBUTOR_TABLE_SUCCESS =
  "GET_DISTRIBUTOR_TABLE_SUCCESS";
export const GET_DISTRIBUTOR_TABLE_FAILURE =
  "GET_DISTRIBUTOR_TABLE_FAILURE";

export const GET_RENEW_ORDER_REQUEST = "GET_RENEW_ORDER_REQUEST";
export const GET_RENEW_ORDER_SUCCESS = "GET_RENEW_ORDER_SUCCESS";
export const GET_RENEW_ORDER_FAILURE = "GET_RENEW_ORDER_FAILURE";

export const HANDLE_DISTRIBUTOR_CONTACT_MODAL =
  "HANDLE_DISTRIBUTOR_CONTACT_MODAL";

export const ADD_CONTACT_DISTRIBUTOR_REQUEST =
  "ADD_CONTACT_DISTRIBUTOR_REQUEST";
export const ADD_CONTACT_DISTRIBUTOR_SUCCESS =
  "ADD_CONTACT_DISTRIBUTOR_SUCCESS";
export const ADD_CONTACT_DISTRIBUTOR_FAILURE =
  "ADD_CONTACT_DISTRIBUTOR_FAILURE";

export const SET_DISTRIBUTOR_CONTACT_EDIT = "SET_DISTRIBUTOR_CONTACT_EDIT";

export const HANDLE_UPDATE_DISTRIBUTOR_CONTACT_MODAL =
  "HANDLE_UPDATE_DISTRIBUTOR_CONTACT_MODAL";

export const UPDATE_DISTRIBUTOR_CONTACT_BY_ID_REQUEST =
  "UPDATE_DISTRIBUTOR_CONTACT_BY_ID_REQUEST";
export const UPDATE_DISTRIBUTOR_CONTACT_BY_ID_SUCCESS =
  "UPDATE_DISTRIBUTOR_CONTACT_BY_ID_SUCCESS";
export const UPDATE_DISTRIBUTOR_CONTACT_BY_ID_FAILURE =
  "UPDATE_DISTRIBUTOR_CONTACT_BY_ID_FAILURE";

export const SET_EDIT_PAYMENT_DATA = "SET_EDIT_PAYMENT_DATA";

export const HANDLE_ORDER_PAYMENT_MODAL = "HANDLE_ORDER_PAYMENT_MODAL";

export const UPDATE_ORDER_PAYMENT_REQUEST = "UPDATE_ORDER_PAYMENT_REQUEST";
export const UPDATE_ORDER_PAYMENT_SUCCESS = "UPDATE_ORDER_PAYMENT_SUCCESS";
export const UPDATE_ORDER_PAYMENT_FAILURE = "UPDATE_ORDER_PAYMENT_FAILURE";

export const DELETE_ORDER_PAYMENT_DATA_REQUEST =
  "DELETE_ORDER_PAYMENT_DATA_REQUEST";
export const DELETE_ORDER_PAYMENT_DATA_SUCCESS =
  "DELETE_ORDER_PAYMENT_DATA_SUCCESS";
export const DELETE_ORDER_PAYMENT_DATA_FAILURE =
  "DELETE_ORDER_PAYMENT_DATA_FAILURE ";

export const GET_REAL_TIME_DISTRIBUTOR_PAYMENT_REQUEST =
  "GET_REAL_TIME_DISTRIBUTOR_PAYMENT_REQUEST";
export const GET_REAL_TIME_DISTRIBUTOR_PAYMENT_SUCCESS =
  "GET_REAL_TIME_DISTRIBUTOR_PAYMENT_SUCCESS";
export const GET_REAL_TIME_DISTRIBUTOR_PAYMENT_FAILURE =
  "GET_REAL_TIME_DISTRIBUTOR_PAYMENT_FAILURE";

export const UPDATE_ORDER_DETAILS_REQUEST = "UPDATE_ORDER_DETAILS_REQUEST";
export const UPDATE_ORDER_DETAILS_SUCCESS = "UPDATE_ORDER_DETAILS_SUCCESS";
export const UPDATE_ORDER_DETAILS_FAILURE = "UPDATE_ORDER_DETAILS_FAILURE";

export const REINSTATE_TOGGLE_FOR_ORDER_REQUEST =
  "REINSTATE_TOGGLE_FOR_ORDER_REQUEST";
export const REINSTATE_TOGGLE_FOR_ORDER_SUCCESS =
  "REINSTATE_TOGGLE_FOR_ORDER_SUCCESS";
export const REINSTATE_TOGGLE_FOR_ORDER_FAILURE =
  "REINSTATE_TOGGLE_FOR_ORDER_FAILURE";

export const HANDLE_BILLING_ADDRESS_MODAL = "HANDLE_BILLING_ADDRESS_MODAL";

export const ADD_BILLING_ADDRESS_DISTRIBUTOR_REQUEST =
  "ADD_BILLING_ADDRESS_DISTRIBUTOR_REQUEST";
export const ADD_BILLING_ADDRESS_DISTRIBUTOR_SUCCESS =
  "ADD_BILLING_ADDRESS_DISTRIBUTOR_SUCCESS";
export const ADD_BILLING_ADDRESS_DISTRIBUTOR_FAILURE =
  "ADD_BILLING_ADDRESS_DISTRIBUTOR_FAILURE";

export const GET_BILLING_ADDRESS_BY_ID_REQUEST =
  "GET_BILLING_ADDRESS_BY_ID_REQUEST";
export const GET_BILLING_ADDRESS_BY_ID_SUCCESS =
  "GET_BILLING_ADDRESS_BY_ID_SUCCESS";
export const GET_BILLING_ADDRESS_BY_ID_FAILURE =
  "GET_BILLING_ADDRESS_BY_ID_FAILURE";

export const ADD_CURRENCY_FOR_ORDER_REQUEST = "ADD_CURRENCY_FOR_ORDER_REQUEST";
export const ADD_CURRENCY_FOR_ORDER_SUCCESS = "ADD_CURRENCY_FOR_ORDER_SUCCESS";
export const ADD_CURRENCY_FOR_ORDER_FAILURE = "ADD_CURRENCY_FOR_ORDER_FAILURE";

export const ADD_CURRENCY_FOR_QUOTE_REQUEST = "ADD_CURRENCY_FOR_ORDER_REQUEST";
export const ADD_CURRENCY_FOR_QUOTE_SUCCESS = "ADD_CURRENCY_FOR_ORDER_SUCCESS";
export const ADD_CURRENCY_FOR_QUOTE_FAILURE = "ADD_CURRENCY_FOR_ORDER_FAILURE";

export const GET_CHOOSEN_CURRENCYID_REQUEST =
  "GET_CHOOSEN_CURRENCYID_REQUEST";
export const GET_CHOOSEN_CURRENCYID_SUCCESS =
  "GET_CHOOSEN_CURRENCYID_SUCCESS";
export const GET_CHOOSEN_CURRENCYID_FAILURE =
  "GET_CHOOSEN_CURRENCYID_FAILURE";

export const GET_PRODUCT_BY_CURRENCY_REQUEST =
  "GET_PRODUCT_BY_CURRENCY_REQUEST";
export const GET_PRODUCT_BY_CURRENCY_SUCCESS =
  "GET_PRODUCT_BY_CURRENCY_SUCCESS";
export const GET_PRODUCT_BY_CURRENCY_FAILURE =
  "GET_PRODUCT_BY_CURRENCY_FAILURE";

export const GET_DISTRIBUTOR_BY_GROUP_REQUEST = "GET_DISTRIBUTOR_BY_GROUP_REQUEST";
export const GET_DISTRIBUTOR_BY_GROUP_SUCCESS = "GET_DISTRIBUTOR_BY_GROUP_SUCCESS";
export const GET_DISTRIBUTOR_BY_GROUP_FAILURE = "GET_DISTRIBUTOR_BY_GROUP_FAILURE";

export const HANDLE_DISTRIBUTOR_GENERATE_QUOTE_MODAL =
  "HANDLE_DISTRIBUTOR_GENERATE_QUOTE_MODAL";

export const HANDLE_ORDER_PICKUP_MODAL =
  "HANDLE_ORDER_PICKUP_MODAL";

export const GENERATE_QUOTE_BY_DISTRIBUTOR_ID_REQUEST = "GENERATE_QUOTE_BY_DISTRIBUTOR_ID_REQUEST";
export const GENERATE_QUOTE_BY_DISTRIBUTOR_ID_SUCCESS = "GENERATE_QUOTE_BY_DISTRIBUTOR_ID_SUCCESS";
export const GENERATE_QUOTE_BY_DISTRIBUTOR_ID_FAILURE = "GENERATE_QUOTE_BY_DISTRIBUTOR_ID_FAILURE";

export const GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_REQUEST = "GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_REQUEST";
export const GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_SUCCESS = "GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_SUCCESS";
export const GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_FAILURE = "GET_DISTRIBUTOR_QUOTE_BY_DISTRIBUTOR_ID_FAILURE";

export const ADD_LOCATION_IN_ORDER_REQUEST =
  "ADD_LOCATION_IN_ORDER_REQUEST";
export const ADD_LOCATION_IN_ORDER_SUCCESS =
  "ADD_LOCATION_IN_ORDER_SUCCESS";
export const ADD_LOCATION_IN_ORDER_FAILURE =
  "ADD_LOCATION_IN_ORDER_FAILURE";

export const GET_PHONE_LIST_BY_ID_REQUEST =
  "GET_PHONE_LIST_BY_ID_REQUEST";
export const GET_PHONE_LIST_BY_ID_SUCCESS =
  "GET_PHONE_LIST_BY_ID_SUCCESS";
export const GET_PHONE_LIST_BY_ID_FAILURE =
  "GET_PHONE_LIST_BY_ID_FAILURE";

export const HANDLE_INVENTORY_LOCATION_IN_ORDER_MODAL =
  "HANDLE_INVENTORY_LOCATION_IN_ORDER_MODAL";
export const HANDLE_NOTES_MODAL_IN_ORDER = "HANDLE_NOTES_MODAL_IN_ORDER";

export const HANDLE_LEAD_MODAL = "HANDLE_LEAD_MODAL";

export const HANDLE_STATUS_OF_ORDER_MODAL = "HANDLE_STATUS_OF_ORDER_MODAL";
export const GET_NOTES_LIST_IN_ORDER_REQUEST = "GET_NOTES_LIST_IN_ORDER_REQUEST";
export const GET_NOTES_LIST_IN_ORDER_SUCCESS = "GET_NOTES_LIST_IN_ORDER_SUCCESS";
export const GET_NOTES_LIST_IN_ORDER_FAILURE = "GET_NOTES_LIST_IN_ORDER_FAILURE";

export const GET_ORDER_PHONE_NOTE_REQUEST = "GET_ORDER_PHONE_NOTE_REQUEST";
export const GET_ORDER_PHONE_NOTE_SUCCESS = "GET_ORDER_PHONE_NOTE_SUCCESS";
export const GET_ORDER_PHONE_NOTE_FAILURE = "GET_ORDER_PHONE_NOTE_FAILURE";

export const HANDLE_PHONE_NOTES_ORDER_MODAL = "HANDLE_PHONE_NOTES_ORDER_MODAL";

export const GET_PHONE_TASK_LIST_REQUEST = "GET_PHONE_TASK_LIST_REQUEST";
export const GET_PHONE_TASK_LIST_SUCCESS = "GET_PHONE_TASK_LIST_SUCCESS";
export const GET_PHONE_TASK_LIST_FAILURE = "GET_PHONE_TASK_LIST_FAILURE";

export const ADD_SPARE_LIST_REQUEST = "ADD_SPARE_LIST_REQUEST";
export const ADD_SPARE_LIST_SUCCESS = "ADD_SPARE_LIST_SUCCESS";
export const ADD_SPARE_LIST_FAILURE = "ADD_SPARE_LIST_FAILURE";

export const GET_SUBORDER_DATA_REQUEST = "GET_SUBORDER_DATA_REQUEST";
export const GET_SUBORDER_DATA_SUCCESS = "GET_SUBORDER_DATA_SUCCESS";
export const GET_SUBORDER_DATA_FAILURE = "GET_SUBORDER_DATA_FAILURE";

export const GET_SPARE_LIST_BY_PHONEID_REQUEST = "GET_SPARE_LIST_BY_PHONEID_REQUEST";
export const GET_SPARE_LIST_BY_PHONEID_SUCCESS = "GET_SPARE_LIST_BY_PHONEID_SUCCESS";
export const GET_SPARE_LIST_BY_PHONEID_FAILURE = "GET_SPARE_LIST_BY_PHONEID_FAILURE";

export const GET_SPARE_LIST_BY_PHONETASKID_REQUEST = "GET_SPARE_LIST_BY_PHONETASKID_REQUEST";
export const GET_SPARE_LIST_BY_PHONETASKID_SUCCESS = "GET_SPARE_LIST_BY_PHONETASKID_SUCCESS";
export const GET_SPARE_LIST_BY_PHONETASKID_FAILURE = "GET_SPARE_LIST_BY_PHONETASKID_FAILURE";

export const START_QC_STATUS_REQUEST = "START_QC_STATUS_REQUEST";
export const START_QC_STATUS_SUCCESS = "START_QC_STATUS_SUCCESS";
export const START_QC_STATUS_FAILURE = "START_QC_STATUS_FAILURE";

export const START_REPAIR_IN_STATUS_REQUEST = "START_REPAIR_IN_STATUS_REQUEST";
export const START_REPAIR_IN_STATUS_SUCCESS = "START_REPAIR_IN_STATUS_SUCCESS";
export const START_REPAIR_IN_STATUS_FAILURE = "START_REPAIR_IN_STATUS_FAILURE";

export const UPDATE_OFFER_PRICE_REQUEST = "UPDATE_OFFER_PRICE_REQUEST";
export const UPDATE_OFFER_PRICE_SUCCESS = "UPDATE_OFFER_PRICE_SUCCESS";
export const UPDATE_OFFER_PRICE_FAILURE = "UPDATE_OFFER_PRICE_FAILURE";

export const UPDATE_ACCOUNT_PRICE_REQUEST = "UPDATE_ACCOUNT_PRICE_REQUEST";
export const UPDATE_ACCOUNT_PRICE_SUCCESS = "UPDATE_ACCOUNT_PRICE_SUCCESS";
export const UPDATE_ACCOUNT_PRICE_FAILURE = "UPDATE_ACCOUNT_PRICE_FAILURE";


export const CHECK_TASK_COMPLETION_REQUEST =
  "CHECK_TASK_COMPLETION_REQUEST";
export const CHECK_TASK_COMPLETION_SUCCESS =
  "CHECK_TASK_COMPLETION_SUCCESS";
export const CHECK_TASK_COMPLETION_FAILURE =
  "CHECK_TASK_COMPLETION_FAILURE";

export const GET_TAGGED_SUPPLIES_BYBRAND_REQUEST =
  "GET_TAGGED_SUPPLIES_BYBRAND_REQUEST";
export const GET_TAGGED_SUPPLIES_BYBRAND_SUCCESS =
  "GET_TAGGED_SUPPLIES_BYBRAND_SUCCESS";
export const GET_TAGGED_SUPPLIES_BYBRAND_FAILURE =
  "GET_TAGGED_SUPPLIES_BYBRAND_FAILURE";

export const RECEIVE_TASK_BY_DISPATCH_REQUEST = "RECEIVE_TASK_BY_DISPATCH_REQUEST";
export const RECEIVE_TASK_BY_DISPATCH_SUCCESS = "RECEIVE_TASK_BY_DISPATCH_SUCCESS";
export const RECEIVE_TASK_BY_DISPATCH_FAILURE = "RECEIVE_TASK_BY_DISPATCH_FAILURE";

export const HANDLE_ORDER_CART_MODAL = "HANDLE_ORDER_CART_MODAL";


export const GET_ACCOUNT_RECORDS_REQUEST = "GET_ACCOUNT_RECORDS_REQUEST";
export const GET_ACCOUNT_RECORDS_SUCCESS = "GET_ACCOUNT_RECORDS_SUCCESS";
export const GET_ACCOUNT_RECORDS_FAILURE = "GET_ACCOUNT_RECORDS_FAILURE";


export const GET_ORDER_RECORDS_REQUEST = "GET_ORDER_RECORDS_REQUEST";
export const GET_ORDER_RECORDS_SUCCESS = "GET_ORDER_RECORDS_SUCCESS";
export const GET_ORDER_RECORDS_FAILURE = "GET_ORDER_RECORDS_FAILURE";


export const GET_PROCURE_RECORDS_REQUEST = "GET_PROCURE_RECORDS_REQUEST";
export const GET_PROCURE_RECORDS_SUCCESS = "GET_PROCURE_RECORDS_SUCCESS";
export const GET_PROCURE_RECORDS_FAILURE = "GET_PROCURE_RECORDS_FAILURE";

export const HANDLE_REPAIR_REASON_MODAL = "HANDLE_REPAIR_REASON_MODAL";
export const HANDLE_PAYMENT_HISTORY_MODAL = "HANDLE_PAYMENT_HISTORY_MODAL";
export const HANDLE_ACCOUNT_PRODUCTION_MODAL = "HANDLE_ACCOUNT_PRODUCTION_MODAL";

export const GET_DISTRIBUTOR_COUNT_REQUEST = "GET_DISTRIBUTOR_COUNT_REQUEST";
export const GET_DISTRIBUTOR_COUNT_SUCCESS = "GET_DISTRIBUTOR_COUNT_SUCCESS";
export const GET_DISTRIBUTOR_COUNT_FAILURE = "GET_DISTRIBUTOR_COUNT_FAILURE";

export const GET_OPPORTUNITY_RECORD_REQUEST = "GET_OPPORTUNITY_RECORD_REQUEST";
export const GET_OPPORTUNITY_RECORD_SUCCESS = "GET_OPPORTUNITY_RECORD_SUCCESS";
export const GET_OPPORTUNITY_RECORD_FAILURE = "GET_OPPORTUNITY_RECORD_FAILURE";

export const GET_ALL_PRODUCT_LIST_REQUEST = "GET_ALL_PRODUCT_LIST_REQUEST";
export const GET_ALL_PRODUCT_LIST_SUCCESS = "GET_ALL_PRODUCT_LIST_SUCCESS";
export const GET_ALL_PRODUCT_LIST_FAILURE = "GET_ALL_PRODUCT_LIST_FAILURE";

export const GET_PRODUCT_BY_DISTRIBUTOR_REQUEST = "GET_PRODUCT_BY_DISTRIBUTOR_REQUEST";
export const GET_PRODUCT_BY_DISTRIBUTOR_SUCCESS = "GET_PRODUCT_BY_DISTRIBUTOR_SUCCESS";
export const GET_PRODUCT_BY_DISTRIBUTOR_FAILURE = "GET_PRODUCT_BY_DISTRIBUTOR_FAILURE";

export const SAVE_UNIT_FOR_CATALOGUE_ITEM_REQUEST = "SAVE_UNIT_FOR_CATALOGUE_ITEM_REQUEST";
export const SAVE_UNIT_FOR_CATALOGUE_ITEM_SUCCESS = "SAVE_UNIT_FOR_CATALOGUE_ITEM_SUCCESS";
export const SAVE_UNIT_FOR_CATALOGUE_ITEM_FAILURE = "SAVE_UNIT_FOR_CATALOGUE_ITEM_FAILURE";

export const ADD_ALL_PRODUCT_FOR_ORDER_REQUEST = "ADD_ALL_PRODUCT_FOR_ORDER_REQUEST";
export const ADD_ALL_PRODUCT_FOR_ORDER_SUCCESS = "ADD_ALL_PRODUCT_FOR_ORDER_SUCCESS";
export const ADD_ALL_PRODUCT_FOR_ORDER_FAILURE = "ADD_ALL_PRODUCT_FOR_ORDER_FAILURE";

export const ADD_PRODUCTION_LOCATION_IN_ORDER_REQUEST = "ADD_PRODUCTION_LOCATION_IN_ORDER_REQUEST";
export const ADD_PRODUCTION_LOCATION_IN_ORDER_SUCCESS = "ADD_PRODUCTION_LOCATION_IN_ORDER_SUCCESS";
export const ADD_PRODUCTION_LOCATION_IN_ORDER_FAILURE = "ADD_PRODUCTION_LOCATION_IN_ORDER_FAILURE";

export const GET_PRODUCT_LIST_BY_ID_REQUEST = "GET_PRODUCT_LIST_BY_ID_REQUEST";
export const GET_PRODUCT_LIST_BY_ID_SUCCESS = "GET_PRODUCT_LIST_BY_ID_SUCCESS";
export const GET_PRODUCT_LIST_BY_ID_FAILURE = "GET_PRODUCT_LIST_BY_ID_FAILURE";

export const UPDATE_ORDER_STEP1_REQUEST =
  "UPDATE_ORDER_STEP1_REQUEST";
export const UPDATE_ORDER_STEP1_SUCCESS =
  "UPDATE_ORDER_STEP1_SUCCESS";
export const UPDATE_ORDER_STEP1_FAILURE =
  "UPDATE_ORDER_STEP1_FAILURE";

export const REMOVE_ORDER_ACC_REQUEST = "REMOVE_ORDER_ACC_REQUEST";
export const REMOVE_ORDER_ACC_SUCCESS = "REMOVE_ORDER_ACC_SUCCESS";
export const REMOVE_ORDER_ACC_FAILURE = "REMOVE_ORDER_ACC_FAILURE";

export const GET_CUSTOMER_BY_USER_REQUEST = "GET_CUSTOMER_BY_USER_REQUEST";
export const GET_CUSTOMER_BY_USER_SUCCESS = "GET_CUSTOMER_BY_USER_SUCCESS";
export const GET_CUSTOMER_BY_USER_FAILURE = "GET_CUSTOMER_BY_USER_FAILURE";

export const GET_COMPLETE_ORDERS_REQUEST = "GET_COMPLETE_ORDERS_REQUEST";
export const GET_COMPLETE_ORDERS_SUCCESS = "GET_COMPLETE_ORDERS_SUCCESS";
export const GET_COMPLETE_ORDERS_FAILURE = "GET_COMPLETE_ORDERS_FAILURE";

export const GET_HIGH_COMPLETE_ORDERS_REQUEST = "GET_HIGH_COMPLETE_ORDERS_REQUEST";
export const GET_HIGH_COMPLETE_ORDERS_SUCCESS = "GET_HIGH_COMPLETE_ORDERS_SUCCESS";
export const GET_HIGH_COMPLETE_ORDERS_FAILURE = "GET_HIGH_COMPLETE_ORDERS_FAILURE";

export const GET_MEDIUM_COMPLETE_ORDERS_REQUEST = "GET_MEDIUM_COMPLETE_ORDERS_REQUEST";
export const GET_MEDIUM_COMPLETE_ORDERS_SUCCESS = "GET_MEDIUM_COMPLETE_ORDERS_SUCCESS";
export const GET_MEDIUM_COMPLETE_ORDERS_FAILURE = "GET_MEDIUM_COMPLETE_ORDERS_FAILURE";

export const GET_LOW_COMPLETE_ORDERS_REQUEST = "GET_LOW_COMPLETE_ORDERS_REQUEST";
export const GET_LOW_COMPLETE_ORDERS_SUCCESS = "GET_LOW_COMPLETE_ORDERS_SUCCESS";
export const GET_LOW_COMPLETE_ORDERS_FAILURE = "GET_LOW_COMPLETE_ORDERS_FAILURE";


export const MOVE_TO_PRODUCTION_ARCHIEVE_REQUEST = "MOVE_TO_PRODUCTION_ARCHIEVE_REQUEST";
export const MOVE_TO_PRODUCTION_ARCHIEVE_SUCCESS = "MOVE_TO_PRODUCTION_ARCHIEVE_SUCCESS";
export const MOVE_TO_PRODUCTION_ARCHIEVE_FAILURE = "MOVE_TO_PRODUCTION_ARCHIEVE_FAILURE";

export const SEARCH_ITEM_IN_LOCATION_REQUEST = "SEARCH_ITEM_IN_LOCATION_REQUEST";
export const SEARCH_ITEM_IN_LOCATION_SUCCESS = "SEARCH_ITEM_IN_LOCATION_SUCCESS";
export const SEARCH_ITEM_IN_LOCATION_FAILURE = "SEARCH_ITEM_IN_LOCATION_FAILURE";

export const HANDLE_PRODUCT_ORDER_DETAIL_MODAL = "HANDLE_PRODUCT_ORDER_DETAIL_MODAL";

export const HANDLE_SEARCH_ITEMS_MODAL = "HANDLE_SEARCH_ITEMS_MODAL";

export const CREATE_ORDER_FOR_PRODUCTION_REQUEST = "CREATE_ORDER_FOR_PRODUCTION_REQUEST";
export const CREATE_ORDER_FOR_PRODUCTION_SUCCESS = "CREATE_ORDER_FOR_PRODUCTION_SUCCESS";
export const CREATE_ORDER_FOR_PRODUCTION_FAILURE = "CREATE_ORDER_FOR_PRODUCTION_FAILURE";

export const GET_PRODUCTION_ORDER_REQUEST = "GET_PRODUCTION_ORDER_REQUEST";
export const GET_PRODUCTION_ORDER_SUCCESS = "GET_PRODUCTION_ORDER_SUCCESS";
export const GET_PRODUCTION_ORDER_FAILURE = "GET_PRODUCTION_ORDER_FAILURE";

export const GET_PRODUCTION_ORDER_DETAIL_REQUEST = "GET_PRODUCTION_ORDER_DETAIL_REQUEST";
export const GET_PRODUCTION_ORDER_DETAIL_SUCCESS = "GET_PRODUCTION_ORDER_DETAIL_SUCCESS";
export const GET_PRODUCTION_ORDER_DETAIL_FAILURE = "GET_PRODUCTION_ORDER_DETAIL_FAILURE";

export const HANDLE_ACCOUNT_PULSE = "HANDLE_ACCOUNT_PULSE";

export const HANDLE_PRODUCTION_PAYMENT_MODAL = "HANDLE_PRODUCTION_PAYMENT_MODAL";

export const HANDLE_SUBORDER_PHONE = "HANDLE_SUBORDER_PHONE";

export const GET_SUB_ORDER_PHONE_LIST_REQUEST = "GET_SUB_ORDER_PHONE_LIST_REQUEST";
export const GET_SUB_ORDER_PHONE_LIST_SUCCESS = "GET_SUB_ORDER_PHONE_LIST_SUCCESS";
export const GET_SUB_ORDER_PHONE_LIST_FAILURE = "GET_SUB_ORDER_PHONE_LIST_FAILURE";

export const GET_USERS_BY_DEPARTMENT_LOCATION_REQUEST = "GET_USERS_BY_DEPARTMENT_LOCATION_REQUEST";
export const GET_USERS_BY_DEPARTMENT_LOCATION_SUCCESS = "GET_USERS_BY_DEPARTMENT_LOCATION_SUCCESS";
export const GET_USERS_BY_DEPARTMENT_LOCATION_FAILURE = "GET_USERS_BY_DEPARTMENT_LOCATION_FAILURE";


export const UPDATE_CONTACT_ROLE_BY_ACCOUNT_REQUEST =
  "UPDATE_CONTACT_ROLE_BY_ACCOUNT_REQUEST";
export const UPDATE_CONTACT_ROLE_BY_ACCOUNT_SUCCESS =
  "UPDATE_CONTACT_ROLE_BY_ACCOUNT_SUCCESS";
export const UPDATE_CONTACT_ROLE_BY_ACCOUNT_FAILURE =
  "UPDATE_CONTACT_ROLE_BY_ACCOUNT_FAILURE";


  export const ADD_ORDER_PROCUREMENT_REQUEST = "ADD_ORDER_PROCUREMENT_REQUEST";
export const ADD_ORDER_PROCUREMENT_SUCCESS = "ADD_ORDER_PROCUREMENT_SUCCESS";
export const ADD_ORDER_PROCUREMENT_FAILURE = "ADD_ORDER_PROCUREMENT_FAILURE";

export const GET_ORDER_PROCUREMENT_REQUEST =
  "GET_ORDER_PROCUREMENT_REQUEST";
export const GET_ORDER_PROCUREMENT_SUCCESS =
  "GET_ORDER_PROCUREMENT_SUCCESS";
export const GET_ORDER_PROCUREMENT_FAILURE =
  "GET_ORDER_PROCUREMENT_FAILURE";


  export const GET_CHATGPT_REQUEST =
  "GET_CHATGPT_REQUEST";
export const GET_CHATGPT_SUCCESS =
  "GET_CHATGPT_SUCCESS";
export const GET_CHATGPT_FAILURE =
  "GET_CHATGPT_FAILURE";


  export const HANDLE_UPDATE_PROCURE_ORDER_MODAL =
  "HANDLE_UPDATE_PROCURE_ORDER_MODAL";

  export const SET_PROCURE_EDIT = "SET_PROCURE_EDIT";

  export const UPDATE_PROCURE_STEP1_REQUEST =
  "UPDATE_PROCURE_STEP1_REQUEST";
export const UPDATE_PROCURE_STEP1_SUCCESS =
  "UPDATE_PROCURE_STEP1_SUCCESS";
export const UPDATE_PROCURE_STEP1_FAILURE =
  "UPDATE_PROCURE_STEP1_FAILURE";

  export const EMPTY_CLEARBIT_TABLE = "EMPTY_CLEARBIT_TABLE";


  export const HANDLE_ACCOUNT_OPPORTUNITY_MODAL =
  "HANDLE_ACCOUNT_OPPORTUNITY_MODAL";

  export const GET_BRAND_REQUEST = "GET_BRAND_REQUEST";
export const GET_BRAND_SUCCESS = "GET_BRAND_SUCCESS";
export const GET_BRAND_FAILURE = "GET_BRAND_FAILURE";


export const GET_MODEL_REQUEST = "GET_MODEL_REQUEST";
export const GET_MODEL_SUCCESS = "GET_MODEL_SUCCESS";
export const GET_MODEL_FAILURE = "GET_MODEL_FAILURE";


export const DELETE_PROCURE_DATA_REQUEST = "DELETE_PROCURE_DATA_REQUEST";
export const DELETE_PROCURE_DATA_SUCCESS = "DELETE_PROCURE_DATA_SUCCESS";
export const DELETE_PROCURE_DATA_FAILURE = "DELETE_PROCURE_DATA_FAILURE";

export const GET_QUOTATION_EXCEL_DETAILS_REQUEST = "GET_QUOTATION_EXCEL_DETAILS_REQUEST";
export const GET_QUOTATION_EXCEL_DETAILS_SUCCESS = "GET_QUOTATION_EXCEL_DETAILS_SUCCESS";
export const GET_QUOTATION_EXCEL_DETAILS_FAILURE = "GET_QUOTATION_EXCEL_DETAILS_FAILURE";



export const GET_PROCURE_DETAILS_REQUEST = "GET_PROCURE_DETAILS_REQUEST";
export const GET_PROCURE_DETAILS_SUCCESS = "GET_PROCURE_DETAILS_SUCCESS";
export const GET_PROCURE_DETAILS_FAILURE = "GET_PROCURE_DETAILS_FAILURE";

export const UPDATE_PROCURE_DETAILS_REQUEST = "UPDATE_PROCURE_DETAILS_REQUEST";
export const UPDATE_PROCURE_DETAILS_SUCCESS = "UPDATE_PROCURE_DETAILS_SUCCESS";
export const UPDATE_PROCURE_DETAILS_FAILURE = "UPDATE_PROCURE_DETAILS_FAILURE";

export const HANDLE_PROCURE_DETAILS_MODAL = "HANDLE_PROCURE_DETAILS_MODAL";


export const GET_QUOTATION_REPAIR_ORDER_REQUEST =
"GET_QUOTATION_REPAIR_ORDER_REQUEST";
export const GET_QUOTATION_REPAIR_ORDER_SUCCESS =
"GET_QUOTATION_REPAIR_ORDER_SUCCESS";
export const GET_QUOTATION_REPAIR_ORDER_FAILURE =
"GET_QUOTATION_REPAIR_ORDER_FAILURE";

export const GET_QUOTATION_PROCURE_ORDER_REQUEST =
"GET_QUOTATION_PROCURE_ORDER_REQUEST";
export const GET_QUOTATION_PROCURE_ORDER_SUCCESS =
"GET_QUOTATION_PROCURE_ORDER_SUCCESS";
export const GET_QUOTATION_PROCURE_ORDER_FAILURE =
"GET_QUOTATION_PROCURE_ORDER_FAILURE";



export const ADD_QUOTATION_CAR_REQUEST = "ADD_QUOTATION_CAR_REQUEST";
export const ADD_QUOTATION_CAR_SUCCESS = "ADD_QUOTATION_CAR_SUCCESS";
export const ADD_QUOTATION_CAR_FAILURE = "ADD_QUOTATION_CAR_FAILURE";


export const ADD_QUOTATION_PHONE_DETAILS_REQUEST = "ADD_QUOTATION_PHONE_DETAILS_REQUEST";
export const ADD_QUOTATION_PHONE_DETAILS_SUCCESS = "ADD_QUOTATION_PHONE_DETAILS_SUCCESS";
export const ADD_QUOTATION_PHONE_DETAILS_FAILURE = "ADD_QUOTATION_PHONE_DETAILS_FAILURE";


export const HANDLE_CLAER_SEARCHED_DATA_ACCOUNT = "HANDLE_CLAER_SEARCHED_DATA_ACCOUNT";



export const QUOTATION_TO_ORDER_CONVERT_REQUEST =
"QUOTATION_TO_ORDER_CONVERT_REQUEST";
export const QUOTATION_TO_ORDER_CONVERT_SUCCESS =
"QUOTATION_TO_ORDER_CONVERT_SUCCESS";
export const QUOTATION_TO_ORDER_CONVERT_FAILURE =
"QUOTATION_TO_ORDER_CONVERT_FAILURE";

export const HANDLE_STATUS_SHOW_DRAWER = "HANDLE_STATUS_SHOW_DRAWER";

export const HANDLE_INVOICE_ORDER_DRAWER = "HANDLE_INVOICE_ORDER_DRAWER";

export const GET_PROCURE_STATUS_ITEM_REQUEST = "GET_PROCURE_STATUS_ITEM_REQUEST";
export const GET_PROCURE_STATUS_ITEM_SUCCESS = "GET_PROCURE_STATUS_ITEM_SUCCESS";
export const GET_PROCURE_STATUS_ITEM_FAILURE = "GET_PROCURE_STATUS_ITEM_FAILURE";

export const UPDATE_ORDR_SUPLR_ITEMS_REQUEST = "UPDATE_ORDR_SUPLR_ITEMS_REQUEST";
export const UPDATE_ORDR_SUPLR_ITEMS_SUCCESS = "UPDATE_ORDR_SUPLR_ITEMS_SUCCESS";
export const UPDATE_ORDR_SUPLR_ITEMS_FAILURE = "UPDATE_ORDR_SUPLR_ITEMS_FAILURE";

export const GET_LOCATION_NAMES_BY_PRODUCTID_REQUEST = "GET_LOCATION_NAMES_BY_PRODUCTID_REQUEST";
export const GET_LOCATION_NAMES_BY_PRODUCTID_SUCCESS = "GET_LOCATION_NAMES_BY_PRODUCTID_SUCCESS";
export const GET_LOCATION_NAMES_BY_PRODUCTID_FAILURE = "GET_LOCATION_NAMES_BY_PRODUCTID_FAILURE";

export const GET_ACCOUNT_INVOICE_REQUEST = "GET_ACCOUNT_INVOICE_REQUEST";
export const GET_ACCOUNT_INVOICE_SUCCESS = "GET_ACCOUNT_INVOICE_SUCCESS";
export const GET_ACCOUNT_INVOICE_FAILURE = "GET_ACCOUNT_INVOICE_FAILURE";

export const GET_ORDER_INVOICE_REQUEST = "GET_ORDER_INVOICE_REQUEST";
export const GET_ORDER_INVOICE_SUCCESS = "GET_ORDER_INVOICE_SUCCESS";
export const GET_ORDER_INVOICE_FAILURE = "GET_ORDER_INVOICE_FAILURE";