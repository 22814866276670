export const HANDLE_LOCATION_MODAL = "HANDLE_LOCATION_MODAL";

export const SET_LOCATION_VIEW_TYPE="SET_LOCATION_VIEW_TYPE";

export const GET_LOCATION_DATA_REQUEST="GET_LOCATION_DATA_REQUEST";
export const GET_LOCATION_DATA_SUCCESS="GET_LOCATION_DATA_SUCCESS";
export const GET_LOCATION_DATA_FAILURE="GET_LOCATION_DATA_FAILURE";


export const CREATE_MACHINARY_REQUEST = "CREATE_MACHINARY_REQUEST";
export const CREATE_MACHINARY_SUCCESS = "CREATE_MACHINARY_SUCCESS";
export const CREATE_MACHINARY_FAILURE = "CREATE_MACHINARY_FAILURE";



export const GET_LOCATION_MACHINE_REQUEST = "GET_LOCATION_MACHINE_REQUEST";
export const GET_LOCATION_MACHINE_SUCCESS = "GET_LOCATION_MACHINE_SUCCESS";
export const GET_LOCATION_MACHINE_FAILURE = "GET_LOCATION_MACHINE_FAILURE";


export const GET_USER_LIST_LOCATION_REQUEST = "GET_USER_LIST_LOCATION_REQUEST";
export const GET_USER_LIST_LOCATION_SUCCESS = "GET_USER_LIST_LOCATION_SUCCESS";
export const GET_USER_LIST_LOCATION_FAILURE = "GET_USER_LIST_LOCATION_FAILURE";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";

export const HANDLE_LOCATION_SHIFT_DRAWER="HANDLE_LOCATION_SHIFT_DRAWER";
export const HANDLE_UPDATE_LOCATION_DRAWER="HANDLE_UPDATE_LOCATION_DRAWER";

export const UPDATE_LOCATIONS_REQUEST="UPDATE_LOCATIONS_REQUEST";
export const UPDATE_LOCATIONS_SUCCESS="UPDATE_LOCATIONS_SUCCESS";
export const UPDATE_LOCATIONS_FAILURE="UPDATE_LOCATIONS_FAILURE";

export const DELETE_LOCATIONS_REQUEST="DELETE_LOCATIONS_REQUEST";
export const DELETE_LOCATIONS_SUCCESS="DELETE_LOCATIONS_SUCCESS";
export const DELETE_LOCATIONS_FAILURE="DELETE_LOCATIONS_FAILURE";

export const GET_SHIFT_LOCATION_REQUEST="GET_SHIFT_LOCATION_REQUEST";
export const GET_SHIFT_LOCATION_SUCCESS="GET_SHIFT_LOCATION_SUCCESS";
export const GET_SHIFT_LOCATION_FAILURE="GET_SHIFT_LOCATION_FAILURE";

export const HANDLE_CREATE_SHIFT_DRAWER="HANDLE_CREATE_SHIFT_DRAWER";
export const CREATE_SHIFT_LOCATION_REQUEST="CREATE_SHIFT_LOCATION_REQUEST";
export const CREATE_SHIFT_LOCATION_SUCCESS="CREATE_SHIFT_LOCATION_SUCCESS";
export const CREATE_SHIFT_LOCATION_FAILURE="CREATE_SHIFT_LOCATION_FAILURE";

export const GET_ALLOCTION_SHIFT_REQUEST="GET_ALLOCTION_SHIFT_REQUEST";
export const GET_ALLOCTION_SHIFT_SUCCESS="GET_ALLOCTION_SHIFT_SUCCESS";
export const GET_ALLOCTION_SHIFT_FAILURE="GET_ALLOCTION_SHIFT_FAILURE";

export const HANDLE_LOCATION_CUSTOMER_DRAWER="HANDLE_LOCATION_CUSTOMER_DRAWER";


export const HANDLE_LOCATION_SUPPLER_DRAWER="HANDLE_LOCATION_SUPPLER_DRAWER";


export const GET_LOCATION_RECORDS_REQUEST = "GET_LOCATION_RECORDS_REQUEST";
export const GET_LOCATION_RECORDS_SUCCESS = "GET_LOCATION_RECORDS_SUCCESS";
export const GET_LOCATION_RECORDS_FAILURE = "GET_LOCATION_RECORDS_FAILURE";

export const ADDING_LOCATION_TOGGLE_REQUEST =
"ADDING_LOCATION_TOGGLE_REQUEST";
export const ADDING_LOCATION_TOGGLE_SUCCESS =
"ADDING_LOCATION_TOGGLE_SUCCESS";
export const ADDING_LOCATION_TOGGLE_FAILURE =
"ADDING_LOCATION_TOGGLE_FAILURE";

export const HANDLE_LOCATION_CELL_DRAWER = "HANDLE_LOCATION_CELL_DRAWER";

export const CREATE_LOCATION_CELL_REQUEST = "CREATE_LOCATION_CELL_REQUEST";
export const CREATE_LOCATION_CELL_SUCCESS = "CREATE_LOCATION_CELL_SUCCESS";
export const CREATE_LOCATION_CELL_FAILURE = "CREATE_LOCATION_CELL_FAILURE";

export const GET_LOCATION_CELL_REQUEST = "GET_LOCATION_CELL_REQUEST";
export const GET_LOCATION_CELL_SUCCESS = "GET_LOCATION_CELL_SUCCESS";
export const GET_LOCATION_CELL_FAILURE = "GET_LOCATION_CELL_FAILURE";




export const DELETE_USER_CELL_REQUEST = "DELETE_USER_CELL_REQUEST";
export const DELETE_USER_CELL_SUCCESS = "DELETE_USER_CELL_SUCCESS";
export const DELETE_USER_CELL_FAILURE = "DELETE_USER_CELL_FAILURE";




export const GET_CELL_CODE_REQUEST = "GET_CELL_CODE_REQUEST";
export const GET_CELL_CODE_SUCCESS = "GET_CELL_CODE_SUCCESS";
export const GET_CELL_CODE_FAILURE = "GET_CELL_CODE_FAILURE";




export const GET_USER_CELL_REQUEST = "GET_USER_CELL_REQUEST";
export const GET_USER_CELL_SUCCESS = "GET_USER_CELL_SUCCESS";
export const GET_USER_CELL_FAILURE = "GET_USER_CELL_FAILURE";







export const CREATE_USER_CELL_REQUEST = "CREATE_USER_CELL_REQUEST";
export const CREATE_USER_CELL_SUCCESS = "CREATE_USER_CELL_SUCCESS";
export const CREATE_USER_CELL_FAILURE = "CREATE_USER_CELL_FAILURE";




export const HANDLE_LOCATION_MACHINE_MODAL = "HANDLE_LOCATION_MACHINE_MODAL";


export const GET_USER_MACHINE_CARD_REQUEST = "GET_USER_MACHINE_CARD_REQUEST";
export const GET_USER_MACHINE_CARD_SUCCESS = "GET_USER_MACHINE_CARD_SUCCESS";
export const GET_USER_MACHINE_CARD_FAILURE = "GET_USER_MACHINE_CARD_FAILURE";



export const HANDLE_USER_CELL_MODAL = "HANDLE_USER_CELL_MODAL";




export const CREATE_MACHINARY_CELL_REQUEST = "CREATE_MACHINARY_CELL_REQUEST";
export const CREATE_MACHINARY_CELL_SUCCESS = "CREATE_MACHINARY_CELL_SUCCESS";
export const CREATE_MACHINARY_CELL_FAILURE = "CREATE_MACHINARY_CELL_FAILURE";



export const ADD_LOCATION_CELL_REQUEST = "ADD_LOCATION_CELL_REQUEST";
export const ADD_LOCATION_CELL_SUCCESS = "ADD_LOCATION_CELL_SUCCESS";
export const ADD_LOCATION_CELL_FAILURE = "ADD_LOCATION_CELL_FAILURE";



export const GET_LOCATION_MACHINE_DATA_REQUEST = "GET_LOCATION_MACHINE_DATA_REQUEST";
export const GET_LOCATION_MACHINE_DATA_SUCCESS = "GET_LOCATION_MACHINE_DATA_SUCCESS";
export const GET_LOCATION_MACHINE_DATA_FAILURE = "GET_LOCATION_MACHINE_DATA_FAILURE";


export const GET_CATALOGUE_CELL_REQUEST = "GET_CATALOGUE_CELL_REQUEST";
export const GET_CATALOGUE_CELL_SUCCESS = "GET_CATALOGUE_CELL_SUCCESS";
export const GET_CATALOGUE_CELL_FAILURE = "GET_CATALOGUE_CELL_FAILURE";

export const GET_ALL_LOCATION_CELL_REQUEST = "GET_ALL_LOCATION_CELL_REQUEST";
export const GET_ALL_LOCATION_CELL_SUCCESS = "GET_ALL_LOCATION_CELL_SUCCESS";
export const GET_ALL_LOCATION_CELL_FAILURE = "GET_ALL_LOCATION_CELL_FAILURE";

export const LINK_CELL_WITH_PRODUCT_REQUEST = "LINK_CELL_WITH_PRODUCT_REQUEST";
export const LINK_CELL_WITH_PRODUCT_SUCCESS = "LINK_CELL_WITH_PRODUCT_SUCCESS";
export const LINK_CELL_WITH_PRODUCT_FAILURE = "LINK_CELL_WITH_PRODUCT_FAILURE"

export const DELETE_LOCATION_CELL_REQUEST = "DELETE_LOCATION_CELL_REQUEST";
export const DELETE_LOCATION_CELL_SUCCESS = "DELETE_LOCATION_CELL_SUCCESS";
export const DELETE_LOCATION_CELL_FAILURE = "DELETE_LOCATION_CELL_FAILURE";




export const GET_CELL_CARD_LIST_REQUEST = "GET_CELL_CARD_LIST_REQUEST";
export const GET_CELL_CARD_LIST_SUCCESS = "GET_CELL_CARD_LIST_SUCCESS";
export const GET_CELL_CARD_LIST_FAILURE = "GET_CELL_CARD_LIST_FAILURE";


export const GET_LOCATION_DELETE_HISTORY_REQUEST = "GET_LOCATION_DELETE_HISTORY_REQUEST";
export const GET_LOCATION_DELETE_HISTORY_SUCCESS = "GET_LOCATION_DELETE_HISTORY_SUCCESS";
export const GET_LOCATION_DELETE_HISTORY_FAILURE = "GET_LOCATION_DELETE_HISTORY_FAILURE";


export const GET_LOCATION_DELETED_COUNT_REQUEST = "GET_LOCATION_DELETED_COUNT_REQUEST";
export const GET_LOCATION_DELETED_COUNT_SUCCESS = "GET_LOCATION_DELETED_COUNT_SUCCESS";
export const GET_LOCATION_DELETED_COUNT_FAILURE = "GET_LOCATION_DELETED_COUNT_FAILURE";


export const REINSTATE_TOGGLE_FOR_LOCATION_REQUEST = "REINSTATE_TOGGLE_FOR_LOCATION_REQUEST";
export const REINSTATE_TOGGLE_FOR_LOCATION_SUCCESS = "REINSTATE_TOGGLE_FOR_LOCATION_SUCCESS";
export const REINSTATE_TOGGLE_FOR_LOCATION_FAILURE = "REINSTATE_TOGGLE_FOR_LOCATION_FAILURE";


export const GET_DELETED_LOCATION_CELL_REQUEST = "GET_DELETED_LOCATION_CELL_REQUEST";
export const GET_DELETED_LOCATION_CELL_SUCCESS = "GET_DELETED_LOCATION_CELL_SUCCESS";
export const GET_DELETED_LOCATION_CELL_FAILURE = "GET_DELETED_LOCATION_CELL_FAILURE";


export const REINSTATE_TOGGLE_FOR_LOC_CELL_REQUEST = "REINSTATE_TOGGLE_FOR_LOC_CELL_REQUEST";
export const REINSTATE_TOGGLE_FOR_LOC_CELL_SUCCESS = "REINSTATE_TOGGLE_FOR_LOC_CELL_SUCCESS";
export const REINSTATE_TOGGLE_FOR_LOC_CELL_FAILURE = "REINSTATE_TOGGLE_FOR_LOC_CELL_FAILURE";


