export const ADD_MILEAGE_REQUEST = "ADD_MILEAGE_REQUEST";
export const ADD_MILEAGE_SUCCESS = "ADD_MILEAGE_SUCCESS";
export const ADD_MILEAGE_FAILURE = "ADD_MILEAGE_FAILURE";

export const GET_MILEAGE_BY_USER_ID_REQUEST = "GET_MILEAGE_BY_USER_ID_REQUEST";
export const GET_MILEAGE_BY_USER_ID_SUCCESS = "GET_MILEAGE_BY_USER_ID_SUCCESS";
export const GET_MILEAGE_BY_USER_ID_FAILURE = "GET_MILEAGE_BY_USER_ID_FAILURE";

export const HANDLE_MILEAGE_MODAL = "HANDLE_MILEAGE_MODAL";

export const GET_MILEAGE_BY_VOUCHER_ID_REQUEST =
  "GET_MILEAGE_BY_VOUCHER_ID_REQUEST";
export const GET_MILEAGE_BY_VOUCHER_ID_SUCCESS =
  "GET_MILEAGE_BY_VOUCHER_ID_SUCCESS";
export const GET_MILEAGE_BY_VOUCHER_ID_FAILURE =
  "GET_MILEAGE_BY_VOUCHER_ID_FAILURE";

export const HANDLE_UPDATE_MILEAGE_MODAL="HANDLE_UPDATE_MILEAGE_MODAL";
export const SET_MILEAGE_EDIT="SET_MILEAGE_EDIT";

export const UPDATE_MILEAGE_REQUEST="UPDATE_MILEAGE_REQUEST";
export const UPDATE_MILEAGE_SUCCESS="UPDATE_MILEAGE_SUCCESS";
export const UPDATE_MILEAGE_FAILURE="UPDATE_MILEAGE_FAILURE";

export const DELETE_MILEAGE_REQUEST="DELETE_MILEAGE_REQUEST";
export const DELETE_MILEAGE_SUCCESS="DELETE_MILEAGE_SUCCESS";
export const DELETE_MILEAGE_FAILURE="DELETE_MILEAGE_FAILURE";
export const DELETE_MILEAGE_VOUCHER_REQUEST="DELETE_MILEAGE_VOUCHER_REQUEST";
export const DELETE_MILEAGE_VOUCHER_SUCCESS="DELETE_MILEAGE_VOUCHER_SUCCESS";
export const DELETE_MILEAGE_VOUCHER_FAILURE="DELETE_MILEAGE_VOUCHER_FAILURE";

export const HANDLE_MILEAGE_VOUCHERID_DRAWER="HANDLE_MILEAGE_VOUCHERID_DRAWER";
export const SET_MILEAGE_VIEW_TYPE="SET_MILEAGE_VIEW_TYPE";

export const GET_PENDING_MILEAGE_REQUEST="GET_PENDING_MILEAGE_REQUEST";
export const GET_PENDING_MILEAGE_SUCCESS="GET_PENDING_MILEAGE_SUCCESS";
export const GET_PENDING_MILEAGE_FAILURE="GET_PENDING_MILEAGE_FAILURE";
export const GET_APPROVED_MILEAGE_REQUEST="GET_APPROVED_MILEAGE_REQUEST";
export const GET_APPROVED_MILEAGE_SUCCESS="GET_APPROVED_MILEAGE_SUCCESS";
export const GET_APPROVED_MILEAGE_FAILURE="GET_APPROVED_MILEAGE_FAILURE";
export const GET_REJECTED_MILEAGE_REQUEST="GET_REJECTED_MILEAGE_REQUEST";
export const GET_REJECTED_MILEAGE_SUCCESS="GET_REJECTED_MILEAGE_SUCCESS";
export const GET_REJECTED_MILEAGE_FAILURE="GET_REJECTED_MILEAGE_FAILURE";

export const HANDLE_STATUS_MILEAGE_MODAL="HANDLE_STATUS_MILEAGE_MODAL";

export const GET_MILEAGE_STATUS_BY_MILEAGEID_REQUEST = "GET_MILEAGE_STATUS_BY_MILEAGEID_REQUEST";
export const GET_MILEAGE_STATUS_BY_MILEAGEID_SUCCESS = "GET_MILEAGE_STATUS_BY_MILEAGEID_SUCCESS";
export const GET_MILEAGE_STATUS_BY_MILEAGEID_FAILURE = "GET_MILEAGE_STATUS_BY_MILEAGEID_FAILURE";

export const HANDLE_MILEAGE_NOTE_DRAWER="HANDLE_MILEAGE_NOTE_DRAWER";
export const ADD_MILEAGE_NOTE_REQUEST="ADD_MILEAGE_NOTE_REQUEST";
export const ADD_MILEAGE_NOTE_SUCCESS="ADD_MILEAGE_NOTE_SUCCESS";
export const ADD_MILEAGE_NOTE_FAILURE="ADD_MILEAGE_NOTE_FAILURE";
export const GET_MILEAGE_NOTE_REQUEST="GET_MILEAGE_NOTE_REQUEST";
export const GET_MILEAGE_NOTE_SUCCESS="GET_MILEAGE_NOTE_SUCCESS";
export const GET_MILEAGE_NOTE_FAILURE="GET_MILEAGE_NOTE_FAILURE";