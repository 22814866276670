export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const ADD_EVENTS_REQUEST = "ADD_EVENTS_REQUEST";
export const ADD_EVENTS_SUCCESS = "ADD_EVENTS_SUCCESS";
export const ADD_EVENTS_FAILURE = "ADD_EVENTS_FAILURE";

export const REMOVE_EVENTS_REQUEST = "REMOVE_EVENTS_REQUEST";
export const REMOVE_EVENTS_SUCCESS = "REMOVE_EVENTS_SUCCESS";
export const REMOVE_EVENTS_FAILURE = "REMOVE_EVENTS_FAILURE";

export const UPDATE_EVENTS_REQUEST = "UPDATE_EVENTS_REQUEST";
export const UPDATE_EVENTS_SUCCESS = "UPDATE_EVENTS_SUCCESS";
export const UPDATE_EVENTS_FAILURE = "UPDATE_EVENTS_FAILURE";

export const GET_EVENT_SEARCH_REQUEST="GET_EVENT_SEARCH_REQUEST";
export const GET_EVENT_SEARCH_SUCCESS="GET_EVENT_SEARCH_SUCCESS";
export const GET_EVENT_SEARCH_FAILURE="GET_EVENT_SEARCH_FAILURE";


export const GET_EVENT_COUNT_REQUEST = "GET_EVENT_COUNT_REQUEST";
export const GET_EVENT_COUNT_SUCCESS = "GET_EVENT_COUNT_SUCCESS";
export const GET_EVENT_COUNT_FAILURE = "GET_EVENT_COUNT_FAILURE";


export const HANDLE_CLAER_REDUCER_DATA_EVENT = "HANDLE_CLAER_REDUCER_DATA_EVENT";
