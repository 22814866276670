export const HANDLE_BOM_DRAWER="HANDLE_BOM_DRAWER";

export const ADD_BOM_REQUEST="ADD_BOM_REQUEST";
export const ADD_BOM_SUCCESS="ADD_BOM_SUCCESS";
export const ADD_BOM_FAILURE="ADD_BOM_FAILURE";
export const GET_BOM_REQUEST="GET_BOM_REQUEST";
export const GET_BOM_SUCCESS="GET_BOM_SUCCESS";
export const GET_BOM_FAILURE="GET_BOM_FAILURE";

export const HANDLE_ADD_INDENT__MODAL = "HANDLE_ADD_INDENT__MODAL";
export const HANDLE_PRO_DISPATCH_MODAL = "HANDLE_PRO_DISPATCH_MODAL";