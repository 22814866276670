export const GET_INDUSTRY_REQUEST = "GET_INDUSTRY_REQUEST";
export const GET_INDUSTRY_SUCCESS = "GET_INDUSTRY_SUCCESS";
export const GET_INDUSTRY_FAILURE = "GET_INDUSTRY_FAILURE";

export const ADD_INDUSTRY_REQUEST = "ADD_INDUSTRY_REQUEST";
export const ADD_INDUSTRY_SUCCESS = "ADD_INDUSTRY_SUCCESS";
export const ADD_INDUSTRY_FAILURE = "ADD_INDUSTRY_FAILURE";

export const REMOVE_INDUSTRY_REQUEST = "REMOVE_INDUSTRY_REQUEST";
export const REMOVE_INDUSTRY_SUCCESS = "REMOVE_INDUSTRY_SUCCESS";
export const REMOVE_INDUSTRY_FAILURE = "REMOVE_INDUSTRY_FAILURE";

export const UPDATE_INDUSTRY_REQUEST = "UPDATE_INDUSTRY_REQUEST";
export const UPDATE_INDUSTRY_SUCCESS = "UPDATE_INDUSTRY_SUCCESS";
export const UPDATE_INDUSTRY_FAILURE = "UPDATE_INDUSTRY_FAILURE";

export const GET_INDUSTRY_SEARCH_REQUEST="GET_INDUSTRY_SEARCH_REQUEST";
export const GET_INDUSTRY_SEARCH_SUCCESS="GET_INDUSTRY_SEARCH_SUCCESS";
export const GET_INDUSTRY_SEARCH_FAILURE="GET_INDUSTRY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_INDUSTRY = "HANDLE_CLAER_REDUCER_DATA_INDUSTRY";

export const GET_INDUSTRY_COUNT_REQUEST = "GET_INDUSTRY_COUNT_REQUEST";
export const GET_INDUSTRY_COUNT_SUCCESS = "GET_INDUSTRY_COUNT_SUCCESS";
export const GET_INDUSTRY_COUNT_FAILURE = "GET_INDUSTRY_COUNT_FAILURE";

