export const GET_MACHINARY_REQUEST = "GET_MACHINARY_REQUEST";
export const GET_MACHINARY_SUCCESS = "GET_MACHINARY_SUCCESS";
export const GET_MACHINARY_FAILURE = "GET_MACHINARY_FAILURE";

export const ADD_MACHINARY_REQUEST = "ADD_MACHINARY_REQUEST";
export const ADD_MACHINARY_SUCCESS = "ADD_MACHINARY_SUCCESS";
export const ADD_MACHINARY_FAILURE = "ADD_MACHINARY_FAILURE";

export const REMOVE_MACHINARY_REQUEST = "REMOVE_MACHINARY_REQUEST";
export const REMOVE_MACHINARY_SUCCESS = "REMOVE_MACHINARY_SUCCESS";
export const REMOVE_MACHINARY_FAILURE = "REMOVE_MACHINARY_FAILURE";

export const UPDATE_MACHINARY_REQUEST = "UPDATE_MACHINARY_REQUEST";
export const UPDATE_MACHINARY_SUCCESS = "UPDATE_MACHINARY_SUCCESS";
export const UPDATE_MACHINARY_FAILURE = "UPDATE_MACHINARY_FAILURE";

export const GET_MACHINARY_SEARCH_REQUEST="GET_MACHINARY_SEARCH_REQUEST";
export const GET_MACHINARY_SEARCH_SUCCESS="GET_MACHINARY_SEARCH_SUCCESS";
export const GET_MACHINARY_SEARCH_FAILURE="GET_MACHINARY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_MACHINARY = "HANDLE_CLAER_REDUCER_DATA_MACHINARY";

export const GET_MACHINARY_COUNT_REQUEST = "GET_MACHINARY_COUNT_REQUEST";
export const GET_MACHINARY_COUNT_SUCCESS = "GET_MACHINARY_COUNT_SUCCESS";
export const GET_MACHINARY_COUNT_FAILURE = "GET_MACHINARY_COUNT_FAILURE";

