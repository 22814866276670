export const SET_PRODUCTION_VIEW_TYPE = "SET_PRODUCTION_VIEW_TYPE";

export const REFURBISH_REJECT_PHONE = "REFURBISH_REJECT_PHONE";

export const HANDLE_REJECT_REASSIGN_MODAL = "HANDLE_REJECT_REASSIGN_MODAL";
export const HANDLE_REJECTED_REASSIGN_MODAL = "HANDLE_REJECTED_REASSIGN_MODAL";

export const GET_TODAY_PRODUCTION_REQUEST = "GET_TODAY_PRODUCTION_REQUEST";
export const GET_TODAY_PRODUCTION_SUCCESS = "GET_TODAY_PRODUCTION_SUCCESS";
export const GET_TODAY_PRODUCTION_FAILURE = "GET_TODAY_PRODUCTION_FAILURE";


export const CANT_REPAIR_BY_TECHNICIAN_REQUEST = "CANT_REPAIR_BY_TECHNICIAN_REQUEST";
export const CANT_REPAIR_BY_TECHNICIAN_SUCCESS = "CANT_REPAIR_BY_TECHNICIAN_SUCCESS";
export const CANT_REPAIR_BY_TECHNICIAN_FAILURE = "CANT_REPAIR_BY_TECHNICIAN_FAILURE";

export const GET_TAT_QUALITY_BY_ID_REQUEST = "GET_TAT_QUALITY_BY_ID_REQUEST";
export const GET_TAT_QUALITY_BY_ID_SUCCESS = "GET_TAT_QUALITY_BY_ID_SUCCESS";
export const GET_TAT_QUALITY_BY_ID_FAILURE = "GET_TAT_QUALITY_BY_ID_FAILURE";

export const GET_REJECTED_PHONE_LIST_REQUEST = "GET_REJECTED_PHONE_LIST_REQUEST";
export const GET_REJECTED_PHONE_LIST_SUCCESS = "GET_REJECTED_PHONE_LIST_SUCCESS";
export const GET_REJECTED_PHONE_LIST_FAILURE = "GET_REJECTED_PHONE_LIST_FAILURE";

export const UPDATE_CANT_REPAIR_QC_REQUEST = "UPDATE_CANT_REPAIR_QC_REQUEST";
export const UPDATE_CANT_REPAIR_QC_SUCCESS = "UPDATE_CANT_REPAIR_QC_SUCCESS";
export const UPDATE_CANT_REPAIR_QC_FAILURE = "UPDATE_CANT_REPAIR_QC_FAILURE";

export const ADD_LEAD_REQUEST = "ADD_LEAD_REQUEST";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const ADD_LEAD_FAILURE = "ADD_LEAD_FAILURE";

export const GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_REQUEST = "GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_REQUEST";
export const GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_SUCCESS = "GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_SUCCESS";
export const GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_FAILURE = "GET_NO_OF_REPAIR_TECHNICIAN_BY_ID_FAILURE";

export const GET_NO_OF_PHONE_IN_REPAIR_REQUEST = "GET_NO_OF_PHONE_IN_REPAIR_REQUEST";
export const GET_NO_OF_PHONE_IN_REPAIR_SUCCESS = "GET_NO_OF_PHONE_IN_REPAIR_SUCCESS";
export const GET_NO_OF_PHONE_IN_REPAIR_FAILURE = "GET_NO_OF_PHONE_IN_REPAIR_FAILURE";

export const EMPTY_REFURBISH_LIST = "EMPTY_REFURBISH_LIST";

export const HANDLE_PHONE_DETAILS_MODAL = "HANDLE_PHONE_DETAILS_MODAL";

export const HANDLE_REFURBISH_LEAD = "HANDLE_REFURBISH_LEAD";

export const GET_REMAINING_PHONES_LIST_REQUEST = "GET_REMAINING_PHONES_LIST_REQUEST";
export const GET_REMAINING_PHONES_LIST_SUCCESS = "GET_REMAINING_PHONES_LIST_SUCCESS";
export const GET_REMAINING_PHONES_LIST_FAILURE = "GET_REMAINING_PHONES_LIST_FAILURE";



export const DELETE_TASK_LIST_REQUEST = "DELETE_TASK_LIST_REQUEST";
export const DELETE_TASK_LIST_SUCCESS = "DELETE_TASK_LIST_SUCCESS";
export const DELETE_TASK_LIST_FAILURE = "DELETE_TASK_LIST_FAILURE";

export const UPDATE_QC_STATUS_REQUEST = "UPDATE_QC_STATUS_REQUEST";
export const UPDATE_QC_STATUS_SUCCESS = "UPDATE_QC_STATUS_SUCCESS";
export const UPDATE_QC_STATUS_FAILURE = "UPDATE_QC_STATUS_FAILURE";

export const GET_TASK_LIST_BY_PHONE_REQUEST = "GET_TASK_LIST_BY_PHONE_REQUEST";
export const GET_TASK_LIST_BY_PHONE_SUCCESS = "GET_TASK_LIST_BY_PHONE_SUCCESS";
export const GET_TASK_LIST_BY_PHONE_FAILURE = "GET_TASK_LIST_BY_PHONE_FAILURE";


export const GET_TOMORROW_PRODUCTION_REQUEST =
  "GET_TOMORROW_PRODUCTION_REQUEST";
export const GET_TOMORROW_PRODUCTION_SUCCESS =
  "GET_TOMORROW_PRODUCTION_SUCCESS";
export const GET_TOMORROW_PRODUCTION_FAILURE =
  "GET_TOMORROW_PRODUCTION_FAILURE";

export const LINK_DATE_TO_PRODUCTION_REQUEST =
  "LINK_DATE_TO_PRODUCTION_REQUEST";
export const LINK_DATE_TO_PRODUCTION_SUCCESS =
  "LINK_DATE_TO_PRODUCTION_SUCCESS";
export const LINK_DATE_TO_PRODUCTION_FAILURE =
  "LINK_DATE_TO_PRODUCTION_FAILURE";

export const GET_ALL_PRODUCTION_CONSUMPTION_LIST_REQUEST =
  "GET_ALL_PRODUCTION_CONSUMPTION_LIST_REQUEST";
export const GET_ALL_PRODUCTION_CONSUMPTION_LIST_SUCCESS =
  "GET_ALL_PRODUCTION_CONSUMPTION_LIST_SUCCESS";
export const GET_ALL_PRODUCTION_CONSUMPTION_LIST_FAILURE =
  "GET_ALL_PRODUCTION_CONSUMPTION_LIST_FAILURE";

export const ADD_PRODUCTION_REQUEST = "ADD_PRODUCTION_REQUEST";
export const ADD_PRODUCTION_SUCCESS = "ADD_PRODUCTION_SUCCESS";
export const ADD_PRODUCTION_FAILURE = "ADD_PRODUCTION_FAILURE";
//output get list
export const GET_ALL_PRODUCTION_OUTPUT_LIST_REQUEST =
  "GET_ALL_PRODUCTION_OUTPUT_LIST_REQUEST";
export const GET_ALL_PRODUCTION_OUTPUT_LIST_SUCCESS =
  "GET_ALL_PRODUCTION_OUTPUT_LIST_SUCCESS";
export const GET_ALL_PRODUCTION_OUTPUT_LIST_FAILURE =
  "GET_ALL_PRODUCTION_OUTPUT_LIST_FAILURE";

//add output production
export const ADD_PRODUCTION_OUTPUT_REQUEST = "ADD_PRODUCTION_OUTPUT_REQUEST";
export const ADD_PRODUCTION_OUTPUT_SUCCESS = "ADD_PRODUCTION_OUTPUT_SUCCESS";
export const ADD_PRODUCTION_OUTPUT_FAILURE = "ADD_PRODUCTION_OUTPUT_FAILURE";

export const GET_PHONE_DETAILS_REQUEST = "GET_PHONE_DETAILS_REQUEST";
export const GET_PHONE_DETAILS_SUCCESS = "GET_PHONE_DETAILS_SUCCESS";
export const GET_PHONE_DETAILS_FAILURE = "GET_PHONE_DETAILS_FAILURE";

export const GET_ALL_SPARE_LIST_REQUEST = "GET_ALL_SPARE_LIST_REQUEST";
export const GET_ALL_SPARE_LIST_SUCCESS = "GET_ALL_SPARE_LIST_SUCCESS";
export const GET_ALL_SPARE_LIST_FAILURE = "GET_ALL_SPARE_LIST_FAILURE";

//dispatchbuttonmodal
export const HANDLE_DISPATCH_BUTTON_MODAL = "HANDLE_DISPATCH_BUTTON_MODAL";

export const HANDLE_ALL_SPARE_MODAL = "HANDLE_ALL_SPARE_MODAL";
//add dispatch
export const ADD_PRODUCTION_DISPATCH_REQUEST =
  "ADD_PRODUCTION_DISPATCH_REQUEST";
export const ADD_PRODUCTION_DISPATCH_SUCCESS =
  "ADD_PRODUCTION_DISPATCH_SUCCESS";
export const ADD_PRODUCTION_DISPATCH_FAILURE =
  "ADD_PRODUCTION_DISPATCH_FAILURE";

export const ADD_FINAL_DATA_IN_THIRDSTEP_REQUEST =
  "ADD_FINAL_DATA_IN_THIRDSTEP_REQUEST";
export const ADD_FINAL_DATA_IN_THIRDSTEP_SUCCESS =
  "ADD_FINAL_DATA_IN_THIRDSTEP_SUCCESS";
export const ADD_FINAL_DATA_IN_THIRDSTEP_FAILURE =
  "ADD_FINAL_DATA_IN_THIRDSTEP_FAILURE";

export const TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_REQUEST =
  "TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_REQUEST";
export const TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_SUCCESS =
  "TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_SUCCESS";
export const TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_FAILURE =
  "TRANSFER_PRODUCTION_OUTPUT_TO_INVENTORY_FAILURE";
export const ADD_SPLIT_PRODUCTION_OUTPUT_REQUEST =
  "ADD_SPLIT_PRODUCTION_OUTPUT_REQUEST";
export const ADD_SPLIT_PRODUCTION_OUTPUT_SUCCESS =
  "ADD_SPLIT_PRODUCTION_OUTPUT_SUCCESS";
export const ADD_SPLIT_PRODUCTION_OUTPUT_FAILURE =
  "ADD_SPLIT_PRODUCTION_OUTPUT_FAILURE";
export const DELETE_PRODUCTION_OUTPUT_REQUEST =
  "DELETE_PRODUCTION_OUTPUT_REQUEST";
export const DELETE_PRODUCTION_OUTPUT_SUCCESS =
  "DELETE_PRODUCTION_OUTPUT_SUCCESS";
export const DELETE_PRODUCTION_OUTPUT_FAILURE =
  "DELETE_PRODUCTION_OUTPUT_FAILURE";
export const HANDLE_SPLIT_OUTPUT_MODAL = "HANDLE_SPLIT_OUTPUT_MODAL";

export const SET_EDIT_OUTPUT_PRODUCTION = "SET_EDIT_OUTPUT_PRODUCTION";

export const GET_PRODUCTION_ORDER_ID_REQUEST = "GET_PRODUCTION_ORDER_ID_REQUEST";
export const GET_PRODUCTION_ORDER_ID_SUCCESS = "GET_PRODUCTION_ORDER_ID_SUCCESS";
export const GET_PRODUCTION_ORDER_ID_FAILURE = "GET_PRODUCTION_ORDER_ID_FAILURE";

export const GET_PRODUCTION_URGENT_REQUEST = "GET_PRODUCTION_URGENT_REQUEST";
export const GET_PRODUCTION_URGENT_SUCCESS = "GET_PRODUCTION_URGENT_SUCCESS";
export const GET_PRODUCTION_URGENT_FAILURE = "GET_PRODUCTION_URGENT_FAILURE";

export const GET_PRODUCTION_HIGH_REQUEST = "GET_PRODUCTION_HIGH_REQUEST";
export const GET_PRODUCTION_HIGH_SUCCESS = "GET_PRODUCTION_HIGH_SUCCESS";
export const GET_PRODUCTION_HIGH_FAILURE = "GET_PRODUCTION_HIGH_FAILURE";

export const GET_PRODUCTION_NORMAL_REQUEST = "GET_PRODUCTION_NORMAL_REQUEST";
export const GET_PRODUCTION_NORMAL_SUCCESS = "GET_PRODUCTION_NORMAL_SUCCESS";
export const GET_PRODUCTION_NORMAL_FAILURE = "GET_PRODUCTION_NORMAL_FAILURE";

export const HANDLE_PRODUCTION_NOTES_MODAL = "HANDLE_PRODUCTION_NOTES_MODAL";

export const GET_PRODUCTION_USER_BYID_REQUEST =
  "GET_PRODUCTION_USER_BYID_REQUEST";
export const GET_PRODUCTION_USER_BYID_SUCCESS =
  "GET_PRODUCTION_USER_BYID_SUCCESS";
export const GET_PRODUCTION_USER_BYID_FAILURE =
  "GET_PRODUCTION_USER_BYID_FAILURE";

export const HANDLE_ASSIGN_ORDER_BY_ID_MODAL = "HANDLE_ASSIGN_ORDER_BY_ID_MODAL";
export const HANDLE_PRODUCTION_ORDERID_MODAL = "HANDLE_PRODUCTION_ORDERID_MODAL";
export const HANDLE_PHONE_NOTE_PRODUCTION_MODAL = "HANDLE_PHONE_NOTE_PRODUCTION_MODAL";

export const UPDATE_TECHNICIAN_BY_PHONE_REQUEST =
  "UPDATE_TECHNICIAN_BY_PHONE_REQUEST";
export const UPDATE_TECHNICIAN_BY_PHONE_SUCCESS =
  "UPDATE_TECHNICIAN_BY_PHONE_SUCCESS";
export const UPDATE_TECHNICIAN_BY_PHONE_FAILURE =
  "UPDATE_TECHNICIAN_BY_PHONE_FAILURE";


export const GET_NO_OF_PHONE_BY_ID_REQUEST =
  "GET_NO_OF_PHONE_BY_ID_REQUEST";
export const GET_NO_OF_PHONE_BY_ID_SUCCESS =
  "GET_NO_OF_PHONE_BY_ID_SUCCESS";
export const GET_NO_OF_PHONE_BY_ID_FAILURE =
  "GET_NO_OF_PHONE_BY_ID_FAILURE";

export const HANDLE_TECHNICIAN_MODAL_MODAL =
  "HANDLE_TECHNICIAN_MODAL_MODAL";

export const GET_NO_OF_TECHNICIAN_BY_ID_REQUEST =
  "GET_NO_OF_TECHNICIAN_BY_ID_REQUEST";
export const GET_NO_OF_TECHNICIAN_BY_ID_SUCCESS =
  "GET_NO_OF_TECHNICIAN_BY_ID_SUCCESS";
export const GET_NO_OF_TECHNICIAN_BY_ID_FAILURE =
  "GET_NO_OF_TECHNICIAN_BY_ID_FAILURE";

export const HANDLE_PHONE_BY_TECHNICIAN_MODAL =
  "HANDLE_PHONE_BY_TECHNICIAN_MODAL";

export const GET_PHONE_LIST_BY_USER_REQUEST =
  "GET_PHONE_LIST_BY_USER_REQUEST";
export const GET_PHONE_LIST_BY_USER_SUCCESS =
  "GET_PHONE_LIST_BY_USER_SUCCESS";
export const GET_PHONE_LIST_BY_USER_FAILURE =
  "GET_PHONE_LIST_BY_USER_FAILURE";

export const HANDLE_ORDER_PHONE =
  "HANDLE_ORDER_PHONE";

export const UPDATE_FINAL_PRICE_REQUEST =
  "UPDATE_FINAL_PRICE_REQUEST";
export const UPDATE_FINAL_PRICE_SUCCESS =
  "UPDATE_FINAL_PRICE_SUCCESS";
export const UPDATE_FINAL_PRICE_FAILURE =
  "UPDATE_FINAL_PRICE_FAILURE";

export const GET_PRODUCTION_NOTES_LIST_IN_ORDER_REQUEST = "GET_PRODUCTION_NOTES_LIST_IN_ORDER_REQUEST";
export const GET_PRODUCTION_NOTES_LIST_IN_ORDER_SUCCESS = "GET_PRODUCTION_NOTES_LIST_IN_ORDER_SUCCESS";
export const GET_PRODUCTION_NOTES_LIST_IN_ORDER_FAILURE = "GET_PRODUCTION_NOTES_LIST_IN_ORDER_FAILURE";

export const HANDLE_ASSIGN_REPAIR_MODAL =
  "HANDLE_ASSIGN_REPAIR_MODAL";

export const GET_ORDER_BY_USER_REQUEST =
  "GET_ORDER_BY_USER_REQUEST";
export const GET_ORDER_BY_USER_SUCCESS =
  "GET_ORDER_BY_USER_SUCCESS";
export const GET_ORDER_BY_USER_FAILURE =
  "GET_ORDER_BY_USER_FAILURE";

export const HANDLE_ORDER_PHONE_MODAL =
  "HANDLE_ORDER_PHONE_MODAL";

export const GET_ORDERID_BY_USER_REQUEST =
  "GET_ORDERID_BY_USER_REQUEST";
export const GET_ORDERID_BY_USER_SUCCESS =
  "GET_ORDERID_BY_USER_SUCCESS";
export const GET_ORDERID_BY_USER_FAILURE =
  "GET_ORDERID_BY_USER_FAILURE";

export const GET_REPAIR_PHONE_BY_ID_REQUEST =
  "GET_REPAIR_PHONE_BY_ID_REQUEST";
export const GET_REPAIR_PHONE_BY_ID_SUCCESS =
  "GET_REPAIR_PHONE_BY_ID_SUCCESS";
export const GET_REPAIR_PHONE_BY_ID_FAILURE =
  "GET_REPAIR_PHONE_BY_ID_FAILURE";

export const UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_REQUEST =
  "UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_REQUEST";
export const UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_SUCCESS =
  "UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_SUCCESS";
export const UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_FAILURE =
  "UPDATE_TECHNICIAN_FOR_REPAIR_PHONE_FAILURE";

export const GET_REPAIR_PHONE_BY_USER_REQUEST =
  "GET_REPAIR_PHONE_BY_USER_REQUEST";
export const GET_REPAIR_PHONE_BY_USER_SUCCESS =
  "GET_REPAIR_PHONE_BY_USER_SUCCESS";
export const GET_REPAIR_PHONE_BY_USER_FAILURE =
  "GET_REPAIR_PHONE_BY_USER_FAILURE";

export const UPDATE_REPAIR_STATUS_REQUEST =
  "UPDATE_REPAIR_STATUS_REQUEST";
export const UPDATE_REPAIR_STATUS_SUCCESS =
  "UPDATE_REPAIR_STATUS_SUCCESS";
export const UPDATE_REPAIR_STATUS_FAILURE =
  "UPDATE_REPAIR_STATUS_FAILURE";

export const HANDLE_REPAIR_PHONE_MODAL =
  "HANDLE_REPAIR_PHONE_MODAL";

export const GET_REPAIR_ORDER_BY_USER_REQUEST =
  "GET_REPAIR_ORDER_BY_USER_REQUEST";
export const GET_REPAIR_ORDER_BY_USER_SUCCESS =
  "GET_REPAIR_ORDER_BY_USER_SUCCESS";
export const GET_REPAIR_ORDER_BY_USER_FAILURE =
  "GET_REPAIR_ORDER_BY_USER_FAILURE";

export const HANDLE_REPAIR_PHONE_NOTES_ORDER_MODAL = "HANDLE_REPAIR_PHONE_NOTES_ORDER_MODAL";

export const HANDLE_PROCESS_EXPAND = "HANDLE_PROCESS_EXPAND";

export const HANDLE_SPARE_PROCESS = "HANDLE_SPARE_PROCESS";

export const HANDLE_ALL_TASK_MODAL = "HANDLE_ALL_TASK_MODAL";

export const HANDLE_ALL_SPARE_PROCESS_MODAL = "HANDLE_ALL_SPARE_PROCESS_MODAL";

export const HANDLE_QC_PHONE_NOTES_ORDER_MODAL = "HANDLE_QC_PHONE_NOTES_ORDER_MODAL";

export const HANDLE_SPARE_LIST = "HANDLE_SPARE_LIST";

export const HANDLE_QC_EXPAND = "HANDLE_QC_EXPAND";

export const UPDATE_QC_INSPECTION_BUTTON_REQUEST = "UPDATE_QC_INSPECTION_BUTTON_REQUEST";
export const UPDATE_QC_INSPECTION_BUTTON_SUCCESS = "UPDATE_QC_INSPECTION_BUTTON_SUCCESS";
export const UPDATE_QC_INSPECTION_BUTTON_FAILURE = "UPDATE_QC_INSPECTION_BUTTON_FAILURE";

export const UPDATE_REPAIR_INSPECTION_BUTTON_REQUEST = "UPDATE_REPAIR_INSPECTION_BUTTON_REQUEST";
export const UPDATE_REPAIR_INSPECTION_BUTTON_SUCCESS = "UPDATE_REPAIR_INSPECTION_BUTTON_SUCCESS";
export const UPDATE_REPAIR_INSPECTION_BUTTON_FAILURE = "UPDATE_REPAIR_INSPECTION_BUTTON_FAILURE";

export const GET_OPEN_USER_BY_USER_REQUEST =
  "GET_OPEN_USER_BY_USER_REQUEST";
export const GET_OPEN_USER_BY_USER_SUCCESS =
  "GET_OPEN_USER_BY_USER_SUCCESS";
export const GET_OPEN_USER_BY_USER_FAILURE =
  "GET_OPEN_USER_BY_USER_FAILURE";

export const GET_OPEN_QC_BY_USER_REQUEST =
  "GET_OPEN_QC_BY_USER_REQUEST";
export const GET_OPEN_QC_BY_USER_SUCCESS =
  "GET_OPEN_QC_BY_USER_SUCCESS";
export const GET_OPEN_QC_BY_USER_FAILURE =
  "GET_OPEN_QC_BY_USER_FAILURE";

export const HANDLE_PRODUCT_BUILDER_MODAL = "HANDLE_PRODUCT_BUILDER_MODAL";

export const GET_CATALOGUE_LIST_IN_REFURBISH_REQUEST =
  "GET_CATALOGUE_LIST_IN_REFURBISH_REQUEST";
export const GET_CATALOGUE_LIST_IN_REFURBISH_SUCCESS =
  "GET_CATALOGUE_LIST_IN_REFURBISH_SUCCESS";
export const GET_CATALOGUE_LIST_IN_REFURBISH_FAILURE =
  "GET_CATALOGUE_LIST_IN_REFURBISH_FAILURE";

export const UPDATE_CATALOGUE_IN_REFURBISH_REQUEST =
  "UPDATE_CATALOGUE_IN_REFURBISH_REQUEST";
export const UPDATE_CATALOGUE_IN_REFURBISH_SUCCESS =
  "UPDATE_CATALOGUE_IN_REFURBISH_SUCCESS";
export const UPDATE_CATALOGUE_IN_REFURBISH_FAILURE =
  "UPDATE_CATALOGUE_IN_REFURBISH_FAILURE";

export const GET_CATALOGUE_BY_USER_REQUEST =
  "GET_CATALOGUE_BY_USER_REQUEST";
export const GET_CATALOGUE_BY_USER_SUCCESS =
  "GET_CATALOGUE_BY_USER_SUCCESS";
export const GET_CATALOGUE_BY_USER_FAILURE =
  "GET_CATALOGUE_BY_USER_FAILURE";

export const CHOOSE_CATALOGUE_ITEM_REQUEST =
  "CHOOSE_CATALOGUE_ITEM_REQUEST";
export const CHOOSE_CATALOGUE_ITEM_SUCCESS =
  "CHOOSE_CATALOGUE_ITEM_SUCCESS";
export const CHOOSE_CATALOGUE_ITEM_FAILURE =
  "CHOOSE_CATALOGUE_ITEM_FAILURE";

export const GET_CHOOSEN_CATALOGUE_ITEM_REQUEST =
  "GET_CHOOSEN_CATALOGUE_ITEM_REQUEST";
export const GET_CHOOSEN_CATALOGUE_ITEM_SUCCESS =
  "GET_CHOOSEN_CATALOGUE_ITEM_SUCCESS";
export const GET_CHOOSEN_CATALOGUE_ITEM_FAILURE =
  "GET_CHOOSEN_CATALOGUE_ITEM_FAILURE";

export const HANDLE_PRODUCT_BUILDER_IN_PROCESS_MODAL =
  "HANDLE_PRODUCT_BUILDER_IN_PROCESS_MODAL";

export const SET_CLOSE_REPAIR_MODAL =
  "SET_CLOSE_REPAIR_MODAL";

export const ADD_PRODUCT_BUILDER_BYID_REQUEST = "ADD_PRODUCT_BUILDER_BYID_REQUEST";
export const ADD_PRODUCT_BUILDER_BYID_SUCCESS = "ADD_PRODUCT_BUILDER_BYID_SUCCESS";
export const ADD_PRODUCT_BUILDER_BYID_FAILURE = "ADD_PRODUCT_BUILDER_BYID_FAILURE";

export const GET_PRODUCT_BUILDER_BYID_REQUEST = "GET_PRODUCT_BUILDER_BYID_REQUEST";
export const GET_PRODUCT_BUILDER_BYID_SUCCESS = "GET_PRODUCT_BUILDER_BYID_SUCCESS";
export const GET_PRODUCT_BUILDER_BYID_FAILURE = "GET_PRODUCT_BUILDER_BYID_FAILURE";

export const GET_ALL_MANUFATUREID_REQUEST = "GET_ALL_MANUFATUREID_REQUEST";
export const GET_ALL_MANUFATUREID_SUCCESS = "GET_ALL_MANUFATUREID_SUCCESS";
export const GET_ALL_MANUFATUREID_FAILURE = "GET_ALL_MANUFATUREID_FAILURE";

export const ADD_CATALOGUE_BY_TECHNICIAN_REQUEST = "ADD_CATALOGUE_BY_TECHNICIAN_REQUEST";
export const ADD_CATALOGUE_BY_TECHNICIAN_SUCCESS = "ADD_CATALOGUE_BY_TECHNICIAN_SUCCESS";
export const ADD_CATALOGUE_BY_TECHNICIAN_FAILURE = "ADD_CATALOGUE_BY_TECHNICIAN_FAILURE";

export const GET_CATALOGUE_BY_TECHNICIAN_REQUEST = "GET_CATALOGUE_BY_TECHNICIAN_REQUEST";
export const GET_CATALOGUE_BY_TECHNICIAN_SUCCESS = "GET_CATALOGUE_BY_TECHNICIAN_SUCCESS";
export const GET_CATALOGUE_BY_TECHNICIAN_FAILURE = "GET_CATALOGUE_BY_TECHNICIAN_FAILURE";

export const UPDATE_FAR_GLASS_IN_PRODUCTION_REQUEST = "UPDATE_FAR_GLASS_IN_PRODUCTION_REQUEST";
export const UPDATE_FAR_GLASS_IN_PRODUCTION_SUCCESS = "UPDATE_FAR_GLASS_IN_PRODUCTION_SUCCESS";
export const UPDATE_FAR_GLASS_IN_PRODUCTION_FAILURE = "UPDATE_FAR_GLASS_IN_PRODUCTION_FAILURE";

export const HANDLE_INTAG_MODAL = "HANDLE_INTAG_MODAL";
export const ADD_TAGIN_PROCESS_REQUEST = "ADD_TAGIN_PROCESS_REQUEST";
export const ADD_TAGIN_PROCESS_SUCCESS = "ADD_TAGIN_PROCESS_SUCCESS";
export const ADD_TAGIN_PROCESS_FAILURE = "ADD_TAGIN_PROCESS_FAILURE";

export const GET_TAGIN_PROCESS_REQUEST = "GET_TAGIN_PROCESS_REQUEST";
export const GET_TAGIN_PROCESS_SUCCESS = "GET_TAGIN_PROCESS_SUCCESS";
export const GET_TAGIN_PROCESS_FAILURE = "GET_TAGIN_PROCESS_FAILURE";

export const GET_NO_OF_PHONE_IN_QC_BYID_REQUEST = "GET_NO_OF_PHONE_IN_QC_BYID_REQUEST";
export const GET_NO_OF_PHONE_IN_QC_BYID_SUCCESS = "GET_NO_OF_PHONE_IN_QC_BYID_SUCCESS";
export const GET_NO_OF_PHONE_IN_QC_BYID_FAILURE = "GET_NO_OF_PHONE_IN_QC_BYID_FAILURE";

export const ADD_TASK_BY_PHONE_ID_REQUEST = "ADD_TASK_BY_PHONE_ID_REQUEST";
export const ADD_TASK_BY_PHONE_ID_SUCCESS = "ADD_TASK_BY_PHONE_ID_SUCCESS";
export const ADD_TASK_BY_PHONE_ID_FAILURE = "ADD_TASK_BY_PHONE_ID_FAILURE";

export const GET_TASK_BY_PHONEID_REQUEST = "GET_TASK_BY_PHONEID_REQUEST";
export const GET_TASK_BY_PHONEID_SUCCESS = "GET_TASK_BY_PHONEID_SUCCESS";
export const GET_TASK_BY_PHONEID_FAILURE = "GET_TASK_BY_PHONEID_FAILURE";

export const APPROVE_SPARE_REQUEST = "APPROVE_SPARE_REQUEST";
export const APPROVE_SPARE_SUCCESS = "APPROVE_SPARE_SUCCESS";
export const APPROVE_SPARE_FAILURE = "APPROVE_SPARE_FAILURE";

export const UPDATE_PROCESS_TASK_REQUEST = "UPDATE_PROCESS_TASK_REQUEST";
export const UPDATE_PROCESS_TASK_SUCCESS = "UPDATE_PROCESS_TASK_SUCCESS";
export const UPDATE_PROCESS_TASK_FAILURE = "UPDATE_PROCESS_TASK_FAILURE";

export const UPDATE_PROCESS_NWTASK_REQUEST = "UPDATE_PROCESS_NWTASK_REQUEST";
export const UPDATE_PROCESS_NWTASK_SUCCESS = "UPDATE_PROCESS_NWTASK_SUCCESS";
export const UPDATE_PROCESS_NWTASK_FAILURE = "UPDATE_PROCESS_NWTASK_FAILURE";

export const GET_TASK_ITEM_COUNT_REQUEST = "GET_TASK_ITEM_COUNT_REQUEST";
export const GET_TASK_ITEM_COUNT_SUCCESS = "GET_TASK_ITEM_COUNT_SUCCESS";
export const GET_TASK_ITEM_COUNT_FAILURE = "GET_TASK_ITEM_COUNT_FAILURE";

export const UPDATE_PAUSE_STATUS_REQUEST = "UPDATE_PAUSE_STATUS_REQUEST";
export const UPDATE_PAUSE_STATUS_SUCCESS = "UPDATE_PAUSE_STATUS_SUCCESS";
export const UPDATE_PAUSE_STATUS_FAILURE = "UPDATE_PAUSE_STATUS_FAILURE";

export const UPDATE_SPARE_PACKET_REQUEST = "UPDATE_SPARE_PACKET_REQUEST";
export const UPDATE_SPARE_PACKET_SUCCESS = "UPDATE_SPARE_PACKET_SUCCESS";
export const UPDATE_SPARE_PACKET_FAILURE = "UPDATE_SPARE_PACKET_FAILURE";


export const GET_COMPLETED_PHONES_REQUEST = "GET_COMPLETED_PHONES_REQUEST";
export const GET_COMPLETED_PHONES_SUCCESS = "GET_COMPLETED_PHONES_SUCCESS";
export const GET_COMPLETED_PHONES_FAILURE = "GET_COMPLETED_PHONES_FAILURE";

export const REASSIGN_PHONES_REQUEST = "REASSIGN_PHONES_REQUEST";
export const REASSIGN_PHONES_SUCCESS = "REASSIGN_PHONES_SUCCESS";
export const REASSIGN_PHONES_FAILURE = "REASSIGN_PHONES_FAILURE";

export const GET_REMAINING_PHONES_REQUEST = "GET_REMAINING_PHONES_REQUEST";
export const GET_REMAINING_PHONES_SUCCESS = "GET_REMAINING_PHONES_SUCCESS";
export const GET_REMAINING_PHONES_FAILURE = "GET_REMAINING_PHONES_FAILURE";

export const ASSIGN_REJECTED_PHONES_REQUEST = "ASSIGN_REJECTED_PHONES_REQUEST";
export const ASSIGN_REJECTED_PHONES_SUCCESS = "ASSIGN_REJECTED_PHONES_SUCCESS";
export const ASSIGN_REJECTED_PHONES_FAILURE = "ASSIGN_REJECTED_PHONES_FAILURE";

export const GET_REJECTED_PHONES_REQUEST = "GET_REJECTED_PHONES_REQUEST";
export const GET_REJECTED_PHONES_SUCCESS = "GET_REJECTED_PHONES_SUCCESS";
export const GET_REJECTED_PHONES_FAILURE = "GET_REJECTED_PHONES_FAILURE";

export const GET_SPARE_LIST_BY_ID_REQUEST = "GET_SPARE_LIST_BY_ID_REQUEST";
export const GET_SPARE_LIST_BY_ID_SUCCESS = "GET_SPARE_LIST_BY_ID_SUCCESS";
export const GET_SPARE_LIST_BY_ID_FAILURE = "GET_SPARE_LIST_BY_ID_FAILURE";

export const UPDATE_SPARE_RECEIVE_REQUEST = "UPDATE_SPARE_RECEIVE_REQUEST";
export const UPDATE_SPARE_RECEIVE_SUCCESS = "UPDATE_SPARE_RECEIVE_SUCCESS";
export const UPDATE_SPARE_RECEIVE_FAILURE = "UPDATE_SPARE_RECEIVE_FAILURE";

export const REASSIGN_REJECTED_PHONE_REQUEST = "REASSIGN_REJECTED_PHONE_REQUEST";
export const REASSIGN_REJECTED_PHONE_SUCCESS = "REASSIGN_REJECTED_PHONE_SUCCESS";
export const REASSIGN_REJECTED_PHONE_FAILURE = "REASSIGN_REJECTED_PHONE_FAILURE";


export const GET_ALL_REFURBISH_COUNT_REQUEST = "GET_ALL_REFURBISH_COUNT_REQUEST";
export const GET_ALL_REFURBISH_COUNT_SUCCESS = "GET_ALL_REFURBISH_COUNT_SUCCESS";
export const GET_ALL_REFURBISH_COUNT_FAILURE = "GET_ALL_REFURBISH_COUNT_FAILURE";

export const GET_SEARCH_IMEI_REQUEST="GET_SEARCH_IMEI_REQUEST";
export const GET_SEARCH_IMEI_SUCCESS="GET_SEARCH_IMEI_SUCCESS";
export const GET_SEARCH_IMEI_FAILURE="GET_SEARCH_IMEI_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_REFURBISH = "HANDLE_CLAER_REDUCER_DATA_REFURBISH";

export const GET_SEARCH_IMEIPHONE_REQUEST="GET_SEARCH_IMEIPHONE_REQUEST";
export const GET_SEARCH_IMEIPHONE_SUCCESS="GET_SEARCH_IMEIPHONE_SUCCESS";
export const GET_SEARCH_IMEIPHONE_FAILURE="GET_SEARCH_IMEIPHONE_FAILURE";

export const HANDLE_CLAER_PHONEREDUCER_DATA_REFURBISH = "HANDLE_CLAER_PHONEREDUCER_DATA_REFURBISH";

export const GET_SEARCH_IMEIREPAIR_REQUEST="GET_SEARCH_IMEIREPAIR_REQUEST";
export const GET_SEARCH_IMEIREPAIR_SUCCESS="GET_SEARCH_IMEIREPAIR_SUCCESS";
export const GET_SEARCH_IMEIREPAIR_FAILURE="GET_SEARCH_IMEIREPAIR_FAILURE";

export const HANDLE_CLAER_REDUCER_DATAREAPIR_REFURBISH = "HANDLE_CLAER_REDUCER_DATAREAPIR_REFURBISH";

export const GET_DISPATCH_UPDATE_REQUEST = "GET_DISPATCH_UPDATE_REQUEST";
export const GET_DISPATCH_UPDATE_SUCCESS = "GET_DISPATCH_UPDATE_SUCCESS";
export const GET_DISPATCH_UPDATE_FAILURE = "GET_DISPATCH_UPDATE_FAILURE";


export const INPUT_QC_SEARCH_DATA_REQUEST =
"INPUT_QC_SEARCH_DATA_REQUEST";
export const INPUT_QC_SEARCH_DATA_SUCCESS =
"INPUT_QC_SEARCH_DATA_SUCCESS";
export const INPUT_QC_SEARCH_DATA_FAILURE =
"INPUT_QC_SEARCH_DATA_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_QC = "HANDLE_CLAER_SEARCHED_DATA_QC";

export const HANDLE_CLAER_SEARCHED_DATA_QA = "HANDLE_CLAER_SEARCHED_DATA_QA";

export const INPUT_PROCESS_SEARCH_DATA_REQUEST =
"INPUT_PROCESS_SEARCH_DATA_REQUEST";
export const INPUT_PROCESS_SEARCH_DATA_SUCCESS =
"INPUT_PROCESS_SEARCH_DATA_SUCCESS";
export const INPUT_PROCESS_SEARCH_DATA_FAILURE =
"INPUT_PROCESS_SEARCH_DATA_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_PROCESS = "HANDLE_CLAER_SEARCHED_DATA_PROCESS";

export const INPUT_ALL_SEARCH_DATA_REQUEST =
"INPUT_ALL_SEARCH_DATA_REQUEST";
export const INPUT_ALL_SEARCH_DATA_SUCCESS =
"INPUT_ALL_SEARCH_DATA_SUCCESS";
export const INPUT_ALL_SEARCH_DATA_FAILURE =
"INPUT_ALL_SEARCH_DATA_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_ALL = "HANDLE_CLAER_SEARCHED_DATA_ALL";

export const GET_QA_ORDER_LIST_REQUEST = "GET_QA_ORDER_LIST_REQUEST";
export const GET_QA_ORDER_LIST_SUCCESS = "GET_QA_ORDER_LIST_SUCCESS";
export const GET_QA_ORDER_LIST_FAILURE = "GET_QA_ORDER_LIST_FAILURE";

export const UPDATE_QA_INSPECTION_REQUEST = "UPDATE_QA_INSPECTION_REQUEST";
export const UPDATE_QA_INSPECTION_SUCCESS = "UPDATE_QA_INSPECTION_SUCCESS";
export const UPDATE_QA_INSPECTION_FAILURE = "UPDATE_QA_INSPECTION_FAILURE";

export const LINK_REFURBISH_TOGGLE_REQUEST =
"LINK_REFURBISH_TOGGLE_REQUEST";
export const LINK_REFURBISH_TOGGLE_SUCCESS =
"LINK_REFURBISH_TOGGLE_SUCCESS";
export const LINK_REFURBISH_TOGGLE_FAILURE =
"LINK_REFURBISH_TOGGLE_FAILURE";

export const UPDATE_DISPATCH_INSPECTION_BUTTON_REQUEST =
  "UPDATE_DISPATCH_INSPECTION_BUTTON_REQUEST";
export const UPDATE_DISPATCH_INSPECTION_BUTTON_SUCCESS =
  "UPDATE_DISPATCH_INSPECTION_BUTTON_SUCCESS";
export const UPDATE_DISPATCH_INSPECTION_BUTTON_FAILURE =
  "UPDATE_DISPATCH_INSPECTION_BUTTON_FAILURE";