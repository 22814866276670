export const SET_ACCESSMENT_VIEW_TYPE = "SET_ACCESSMENT_VIEW_TYPE";

export const HANDLE_ACCESSMENT_MODAL = "HANDLE_ACCESSMENT_MODAL";

export const HANDLE_QUESTION_MODAL = "HANDLE_QUESTION_MODAL";

export const SET_ASSESSMENT_EDIT = "SET_ASSESSMENT_EDIT";

export const HANDLE_UPDATE_ASSESSMENT_MODAL ="HANDLE_UPDATE_ASSESSMENT_MODAL";




export const GET_ACCESSMENT_QUES_REQUEST = "GET_ACCESSMENT_QUES_REQUEST";
export const GET_ACCESSMENT_QUES_SUCCESS = "GET_ACCESSMENT_QUES_SUCCESS";
export const GET_ACCESSMENT_QUES_FAILURE = "GET_ACCESSMENT_QUES_FAILURE";


export const CREATE_ACCESSMENT_QUES_REQUEST = "CREATE_ACCESSMENT_QUES_REQUEST";
export const CREATE_ACCESSMENT_QUES_SUCCESS = "CREATE_ACCESSMENT_QUES_SUCCESS";
export const CREATE_ACCESSMENT_QUES_FAILURE = "CREATE_ACCESSMENT_QUES_FAILURE";

export const GET_ASSESSMENT_DETAILS_REQUEST = "GET_ASSESSMENT_DETAILS_REQUEST";
export const GET_ASSESSMENT_DETAILS_SUCCESS = "GET_ASSESSMENT_DETAILS_SUCCESS";
export const GET_ASSESSMENT_DETAILS_FAILURE = "GET_ASSESSMENT_DETAILS_FAILURE";

export const ADD_ASSESSMENT_DETAILS_REQUEST = "ADD_ASSESSMENT_DETAILS_REQUEST";
export const ADD_ASSESSMENT_DETAILS_SUCCESS = "ADD_ASSESSMENT_DETAILS_SUCCESS";
export const ADD_ASSESSMENT_DETAILS_FAILURE = "ADD_ASSESSMENT_DETAILS_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

//search
export const INPUT_ASSESSMENT_SEARCH_DATA_REQUEST =
  "INPUT_ASSESSMENT_SEARCH_DATA_REQUEST";
export const INPUT_ASSESSMENT_SEARCH_DATA_SUCCESS =
  "INPUT_ASSESSMENT_SEARCH_DATA_SUCCESS";
export const INPUT_ASSESSMENT_SEARCH_DATA_FAILURE =
  "INPUT_ASSESSMENT_SEARCH_DATA_FAILURE";



export const UPDATE_PUBLISH_IND_REQUEST="UPDATE_PUBLISH_IND_REQUEST";
export const UPDATE_PUBLISH_IND_SUCCESS="UPDATE_PUBLISH_IND_SUCCESS";
export const UPDATE_PUBLISH_IND_FAILURE="UPDATE_PUBLISH_IND_FAILURE";

export const ADD_QUESTION_DETAILS_REQUEST = "ADD_QUESTION_DETAILS_REQUEST";
export const ADD_QUESTION_DETAILS_SUCCESS = "ADD_QUESTION_DETAILS_SUCCESS";
export const ADD_QUESTION_DETAILS_FAILURE = "ADD_QUESTION_DETAILS_FAILURE";

export const GET_QUESTION_LIST_BY_ID_REQUEST = "GET_QUESTION_LIST_BY_ID_REQUEST";
export const GET_QUESTION_LIST_BY_ID_SUCCESS = "GET_QUESTION_LIST_BY_ID_SUCCESS";
export const GET_QUESTION_LIST_BY_ID_FAILURE = "GET_QUESTION_LIST_BY_ID_FAILURE";

export const GET_ASSESSMENT_BY_ID_REQUEST = "GET_ASSESSMENT_BY_ID_REQUEST";
export const GET_ASSESSMENT_BY_ID_SUCCESS = "GET_ASSESSMENT_BY_ID_SUCCESS";
export const GET_ASSESSMENT_BY_ID_FAILURE = "GET_ASSESSMENT_BY_ID_FAILURE";

export const  UPDATE_ASSESSMENT_BY_ID_REQUEST ="UPDATE_ASSESSMENT_BY_ID_REQUEST";
export const UPDATE_ASSESSMENT_BY_ID_SUCCESS = "UPDATE_ASSESSMENT_BY_ID_SUCCESS";
export const  UPDATE_ASSESSMENT_BY_ID_FAILURE ="UPDATE_ASSESSMENT_BY_ID_FAILURE";
export const DELETE_ASSESSMENT_ID_REQUEST="DELETE_ASSESSMENT_ID_REQUEST";
export const DELETE_ASSESSMENT_ID_SUCCESS="DELETE_ASSESSMENT_ID_SUCCESS";
export const DELETE_ASSESSMENT_ID_FAILURE="DELETE_ASSESSMENT_ID_FAILURE";

export const  UPDATE_QUESTION_BY_ID_REQUEST ="UPDATE_QUESTION_BY_ID_REQUEST";
export const UPDATE_QUESTION_BY_ID_SUCCESS = "UPDATE_QUESTION_BY_ID_SUCCESS";
export const  UPDATE_QUESTION_BY_ID_FAILURE ="UPDATE_QUESTION_BY_ID_FAILURE";

export const FINALIZE_QUESTION_REQUEST="FINALIZE_QUESTION_REQUEST";
export const FINALIZE_QUESTION_SUCCESS="FINALIZE_QUESTION_SUCCESS";
export const FINALIZE_QUESTION_FAILURE="FINALIZE_QUESTION_FAILURE";

export const GET_FINAL_QUESTIONS_REQUEST="GET_FINAL_QUESTIONS_REQUEST";
export const GET_FINAL_QUESTIONS_SUCCESS="GET_FINAL_QUESTIONS_SUCCESS";
export const GET_FINAL_QUESTIONS_FAILURE="GET_FINAL_QUESTIONS_FAILURE";