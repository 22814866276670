export const HANDLE_PLANT_MODAL = "HANDLE_PLANT_MODAL";

export const GET_PRODUCTION_MANAGER_REQUEST = "GET_PRODUCTION_MANAGER_REQUEST";
export const GET_PRODUCTION_MANAGER_SUCCESS = "GET_PRODUCTION_MANAGER_SUCCESS";
export const GET_PRODUCTION_MANAGER_FAILURE = "GET_PRODUCTION_MANAGER_FAILURE";
//add plant
export const ADD_PLANT_REQUEST = "ADD_PLANT_REQUEST";
export const ADD_PLANT_SUCCESS = "ADD_PLANT_SUCCESS";
export const ADD_PLANT_FAILURE = "ADD_PLANT_FAILURE";
//get in table
export const GET_PLANT_REQUEST = "GET_PLANT_REQUEST";
export const GET_PLANT_SUCCESS = "GET_PLANT_SUCCESS";
export const GET_PLANT_FAILURE = "GET_PLANT_FAILURE";
//plant detail
export const GET_PLANT_BY_ID_REQUEST = "GET_PLANT_BY_ID_REQUEST";
export const GET_PLANT_BY_ID_SUCCESS = "GET_PLANT_BY_ID_SUCCESS";
export const GET_PLANT_BY_ID_FAILURE = "GET_PLANT_BY_ID_FAILURE";
//depute Button Modal
export const HANDLE_DEPUTE_BUTTON_MODAL = "HANDLE_DEPUTE_BUTTON_MODAL";
//addExecutive
export const ADD_EXECUTIVE_REQUEST = "ADD_EXECUTIVE_REQUEST";
export const ADD_EXECUTIVE_SUCCESS = "ADD_EXECUTIVE_SUCCESS";
export const ADD_EXECUTIVE_FAILURE = "ADD_EXECUTIVE_FAILURE";
//edit Plant
export const SET_PLANT_EDIT = "SET_PLANT_EDIT";
export const HANDLE_UPDATE_PLANT_MODAL = "HANDLE_UPDATE_PLANT_MODAL";
//edit PlantAllocation
export const SET_PLANT_ALLOCATION_EDIT = "SET_PLANT_ALLOCATION_EDIT";

export const ADD_SHIFTS_IN_PLANT_REQUEST = "ADD_SHIFTS_IN_PLANT_REQUEST";
export const ADD_SHIFTS_IN_PLANT_SUCCESS = "ADD_SHIFTS_IN_PLANT_SUCCESS";
export const ADD_SHIFTS_IN_PLANT_FAILURE = "ADD_SHIFTS_IN_PLANT_FAILURE";
