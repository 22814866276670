export const HANDLE_EXPENSE_MODAL = "HANDLE_EXPENSE_MODAL";

export const ADD_EXPENSE_REQUEST = "ADD_EXPENSE_REQUEST";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_FAILURE = "ADD_EXPENSE_FAILURE";

export const GET_EXPENSE_BY_USER_ID_REQUEST = "GET_EXPENSE_BY_USER_ID_REQUEST";
export const GET_EXPENSE_BY_USER_ID_SUCCESS = "GET_EXPENSE_BY_USER_ID_SUCCESS";
export const GET_EXPENSE_BY_USER_ID_FAILURE = "GET_EXPENSE_BY_USER_ID_FAILURE";

export const GET_EXPENSE_BY_ORGID_REQUEST = "GET_EXPENSE_BY_ORGID_REQUEST";
export const GET_EXPENSE_BY_ORGID_SUCCESS = "GET_EXPENSE_BY_ORGID_SUCCESS";
export const GET_EXPENSE_BY_ORGID_FAILURE = "GET_EXPENSE_BY_ORGID_FAILURE";

export const GET_EXPENSE_BY_VOUCHER_ID_REQUEST =
  "GET_EXPENSE_BY_VOUCHER_ID_REQUEST";
export const GET_EXPENSE_BY_VOUCHER_ID_SUCCESS =
  "GET_EXPENSE_BY_VOUCHER_ID_SUCCESS";
export const GET_EXPENSE_BY_VOUCHER_ID_FAILURE =
  "GET_EXPENSE_BY_VOUCHER_ID_FAILURE";

  export const SET_EXPENSE_EDIT = "SET_EXPENSE_EDIT";

  export const HANDLE_UPDATE_EXPENSE_MODAL = "HANDLE_UPDATE_EXPENSE_MODAL";

  export const UPDATE_EXPENSE_REQUEST = "UPDATE_EXPENSE_REQUEST";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAILURE = "UPDATE_EXPENSE_FAILURE";


export const DELETE_EXPENSE_REQUEST = "DELETE_EXPENSE_REQUEST";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_FAILURE = "DELETE_EXPENSE_FAILURE";


export const DELETE_EXPENSE_DRAWER_REQUEST = "DELETE_EXPENSE_DRAWER_REQUEST";
export const DELETE_EXPENSE_DRAWER_SUCCESS = "DELETE_EXPENSE_DRAWER_SUCCESS";
export const DELETE_EXPENSE_DRAWER_FAILURE = "DELETE_EXPENSE_DRAWER_FAILURE";

//document
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

//add customer Document
export const ADD_EXPENSE_DOCUMENT_REQUEST = "ADD_EXPENSE_DOCUMENT_REQUEST";
export const ADD_EXPENSE_DOCUMENT_SUCCESS = "ADD_EXPENSE_DOCUMENT_SUCCESS";
export const ADD_EXPENSE_DOCUMENT_FAILURE = "ADD_EXPENSE_DOCUMENT_FAILURE";

//get customer Document
export const GET_EXPENSE_DOCUMENTS_REQUEST = "GET_EXPENSE_DOCUMENTS_REQUEST";
export const GET_EXPENSE_DOCUMENTS_SUCCESS = "GET_EXPENSE_DOCUMENTS_SUCCESS";
export const GET_EXPENSE_DOCUMENTS_FAILURE = "GET_EXPENSE_DOCUMENTS_FAILURE";

export const HANDLE_STATUS_EXPENSE_MODAL="HANDLE_STATUS_EXPENSE_MODAL";

export const HANDLE_EXPENSE_VOUCHERID_DRAWER="HANDLE_EXPENSE_VOUCHERID_DRAWER";
export const SET_EXPENSE_VIEW_TYPE="SET_EXPENSE_VIEW_TYPE";

export const GET_PENDING_EXPENSE_REQUEST="GET_PENDING_EXPENSE_REQUEST";
export const GET_PENDING_EXPENSE_SUCCESS="GET_PENDING_EXPENSE_SUCCESS";
export const GET_PENDING_EXPENSE_FAILURE="GET_PENDING_EXPENSE_FAILURE";
export const GET_APPROVED_EXPENSE_REQUEST="GET_APPROVED_EXPENSE_REQUEST";
export const GET_APPROVED_EXPENSE_SUCCESS="GET_APPROVED_EXPENSE_SUCCESS";
export const GET_APPROVED_EXPENSE_FAILURE="GET_APPROVED_EXPENSE_FAILURE";
export const GET_REJECTED_EXPENSE_REQUEST="GET_REJECTED_EXPENSE_REQUEST";
export const GET_REJECTED_EXPENSE_SUCCESS="GET_REJECTED_EXPENSE_SUCCESS";
export const GET_REJECTED_EXPENSE_FAILURE="GET_REJECTED_EXPENSE_FAILURE";
export const GET_EXPENSE_STATUS_BY_EXPENSEID_REQUEST = "GET_EXPENSE_STATUS_BY_EXPENSEID_REQUEST";
export const GET_EXPENSE_STATUS_BY_EXPENSEID_SUCCESS = "GET_EXPENSE_STATUS_BY_EXPENSEID_SUCCESS";
export const GET_EXPENSE_STATUS_BY_EXPENSEID_FAILURE = "GET_EXPENSE_STATUS_BY_EXPENSEID_FAILURE";

export const HANDLE_PEXPENSE_VOUCHERID_DRAWER="HANDLE_PEXPENSE_VOUCHERID_DRAWER"
