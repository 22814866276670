export const GET_NOTES_LIST_BY_CUSTOMER_ID_REQUEST =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_REQUEST";
export const GET_NOTES_LIST_BY_CUSTOMER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_CUSTOMER_ID_FAILURE =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_FAILURE";


  export const ADD_CUSTOMER_NOTES_REQUEST = "ADD_CUSTOMER_NOTES_REQUEST";
export const ADD_CUSTOMER_NOTES_SUCCESS = "ADD_CUSTOMER_NOTES_SUCCESS";
export const ADD_CUSTOMER_NOTES_FAILURE = "ADD_CUSTOMER_NOTES_FAILURE";