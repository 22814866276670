export const GET_BRAND_CATEGORY_REQUEST = "GET_BRAND_CATEGORY_REQUEST";
export const GET_BRAND_CATEGORY_SUCCESS = "GET_BRAND_CATEGORY_SUCCESS";
export const GET_BRAND_CATEGORY_FAILURE = "GET_BRAND_CATEGORY_FAILURE";

export const ADD_BRAND_CATEGORY_REQUEST = "ADD_BRAND_CATEGORY_REQUEST";
export const ADD_BRAND_CATEGORY_SUCCESS = "ADD_BRAND_CATEGORY_SUCCESS";
export const ADD_BRAND_CATEGORY_FAILURE = "ADD_BRAND_CATEGORY_FAILURE";

export const REMOVE_BRAND_CATEGORY_REQUEST = "REMOVE_BRAND_CATEGORY_REQUEST";
export const REMOVE_BRAND_CATEGORY_SUCCESS = "REMOVE_BRAND_CATEGORY_SUCCESS";
export const REMOVE_BRAND_CATEGORY_FAILURE = "REMOVE_BRAND_CATEGORY_FAILURE";

export const UPDATE_BRAND_CATEGORY_REQUEST = "UPDATE_BRAND_CATEGORY_REQUEST";
export const UPDATE_BRAND_CATEGORY_SUCCESS = "UPDATE_BRAND_CATEGORY_SUCCESS";
export const UPDATE_BRAND_CATEGORY_FAILURE = "UPDATE_BRAND_CATEGORY_FAILURE";

export const GET_BRAND_CATEGORY_SEARCH_REQUEST="GET_BRAND_CATEGORY_SEARCH_REQUEST";
export const GET_BRAND_CATEGORY_SEARCH_SUCCESS="GET_BRAND_CATEGORY_SEARCH_SUCCESS";
export const GET_BRAND_CATEGORY_SEARCH_FAILURE="GET_BRAND_CATEGORY_SEARCH_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_BRAND_CATEGORY = "HANDLE_CLAER_REDUCER_DATA_BRAND_CATEGORY";


export const GET_BRAND_CATEGORY_COUNT_REQUEST = "GET_BRAND_CATEGORY_COUNT_REQUEST";
export const GET_BRAND_CATEGORY_COUNT_SUCCESS = "GET_BRAND_CATEGORY_COUNT_SUCCESS";
export const GET_BRAND_CATEGORY_COUNT_FAILURE = "GET_BRAND_CATEGORY_COUNT_FAILURE";

