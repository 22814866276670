export const SET_REPORT_VIEW_TYPE = "SET_REPORT_VIEW_TYPE";
export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";
export const SET_FISCAL_TIME_INTERVAL_REPORT =
  "SET_FISCAL_TIME_INTERVAL_REPORT";
export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";


export const ADD_REPORTS_ATTENDENCE_MODAL =
  "ADD_REPORTS_ATTENDENCE_MODAL";




  export const GET_REPORTS_ATTENDENCE_DATA_LIST_REQUEST = "GET_REPORTS_ATTENDENCE_DATA_LIST_REQUEST";
export const GET_REPORTS_ATTENDENCE_DATA_LIST_SUCCESS = "GET_REPORTS_ATTENDENCE_DATA_LIST_SUCCESS";
export const GET_REPORTS_ATTENDENCE_DATA_LIST_FAILURE = "GET_REPORTS_ATTENDENCE_DATA_LIST_FAILURE";


export const GET_REPORTS_PRODUCTIVITY_DATA_REQUEST = "GET_REPORTS_PRODUCTIVITY_DATA_REQUEST";
export const GET_REPORTS_PRODUCTIVITY_DATA_SUCCESS = "GET_REPORTS_PRODUCTIVITY_DATA_SUCCESS";
export const GET_REPORTS_PRODUCTIVITY_DATA_FAILURE = "GET_REPORTS_PRODUCTIVITY_DATA_FAILURE";



export const ADD_REPORTS_PRODUCTIVITY_MODAL =
  "ADD_REPORTS_PRODUCTIVITY_MODAL";


export const GET_REPORTS_PRODUCTIVITY_REQUEST="GET_REPORTS_PRODUCTIVITY_REQUEST";
export const GET_REPORTS_PRODUCTIVITY_SUCCESS="GET_REPORTS_PRODUCTIVITY_SUCCESS";
export const GET_REPORTS_PRODUCTIVITY_FAILURE="GET_REPORTS_PRODUCTIVITY_FAILURE";



export const GET_REPORTS_ATTENDENCE_REQUEST="GET_REPORTS_ATTENDENCE_REQUEST";
export const GET_REPORTS_ATTENDENCE_SUCCESS="GET_REPORTS_ATTENDENCE_SUCCESS";
export const GET_REPORTS_ATTENDENCE_FAILURE="GET_REPORTS_ATTENDENCE_FAILURE";





export const GET_TASK_DATA_REQUEST = "GET_TASK_DATA_REQUEST";
export const GET_TASK_DATA_SUCCESS = "GET_TASK_DATA_SUCCESS";
export const GET_TASK_DATA_FAILURE = "GET_TASK_DATA_FAILURE";



export const GET_REPORT_TASK_REQUEST = "GET_REPORT_TASK_REQUEST";
export const GET_REPORT_TASK_SUCCESS = "GET_REPORT_TASK_SUCCESS";
export const GET_REPORT_TASK_FAILURE = "GET_REPORT_TASK_FAILURE";



export const GET_REPORT_PROSPECT_REQUEST = "GET_REPORT_PROSPECT_REQUEST";
export const GET_REPORT_PROSPECT_SUCCESS = "GET_REPORT_PROSPECT_SUCCESS";
export const GET_REPORT_PROSPECT_FAILURE = "GET_REPORT_PROSPECT_FAILURE";

export const GET_ORGANISATION_REPORT_REQUEST =
  "GET_ORGANISATION_REPORT_REQUEST";
export const GET_ORGANISATION_REPORT_SUCCESS =
  "GET_ORGANISATION_REPORT_SUCCESS";
export const GET_ORGANISATION_REPORT_FAILURE =
  "GET_ORGANISATION_REPORT_FAILURE";

export const GET_MY_VIEW_REPORT_REQUEST = "GET_MY_VIEW_REPORT_REQUEST";
export const GET_MY_VIEW_REPORT_SUCCESS = "GET_MY_VIEW_REPORT_SUCCESS";
export const GET_MY_VIEW_REPORT_FAILURE = "GET_MY_VIEW_REPORT_FAILURE";

export const GET_SALES_REPORTS_REQUEST = "GET_SALES_REPORTS_REQUEST";
export const GET_SALES_REPORTS_SUCCESS = "GET_SALES_REPORTS_SUCCESS";
export const GET_SALES_REPORTS_FAILURE = "GET_SALES_REPORTS_FAILURE";

export const SET_SUB_SELECTED_REPORT_TYPE = "SET_SUB_SELECTED_REPORT_TYPE";

export const GET_ALL_REPORT_INVESTORS_REQUEST = "GET_ALL_REPORT_INVESTORS_REQUEST";
export const GET_ALL_REPORT_INVESTORS_SUCCESS = "GET_ALL_REPORT_INVESTORS_SUCCESS";
export const GET_ALL_REPORT_INVESTORS_FAILURE = "GET_ALL_REPORT_INVESTORS_FAILURE";