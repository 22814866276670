export const SET_DEAL_VIEW_TYPE="SET_DEAL_VIEW_TYPE";

export const GET_DEAL_REQUEST="GET_DEAL_REQUEST";
export const GET_DEAL_SUCCESS="GET_DEAL_SUCCESS";
export const GET_DEAL_FAILURE="GET_DEAL_FAILURE";

export const CREATE_DEAL_REQUEST="CREATE_DEAL_REQUEST";
export const CREATE_DEAL_SUCCESS="CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_FAILURE="CREATE_DEAL_FAILURE";
export const HANDLE_DEAL_MODAL="HANDLE_DEAL_MODAL";

export const GET_INVESTOR_DEALS_DATA_REQUEST = "GET_INVESTOR_DEALS_DATA_REQUEST";
export const GET_INVESTOR_DEALS_DATA_SUCCESS = "GET_INVESTOR_DEALS_DATA_SUCCESS";
export const GET_INVESTOR_DEALS_DATA_FAILURE = "GET_INVESTOR_DEALS_DATA_FAILURE";


export const GET_DEAL_DETAILS_BY_ID_REQUEST="GET_DEAL_DETAILS_BY_ID_REQUEST";
export const GET_DEAL_DETAILS_BY_ID_SUCCESS="GET_DEAL_DETAILS_BY_ID_SUCCESS";
export const GET_DEAL_DETAILS_BY_ID_FAILURE="GET_DEAL_DETAILS_BY_ID_FAILURE";

export const UPDATE_DEAL_BY_ID_REQUEST="UPDATE_DEAL_BY_ID_REQUEST";
export const UPDATE_DEAL_BY_ID_SUCCESS="UPDATE_DEAL_BY_ID_SUCCESS";
export const UPDATE_DEAL_BY_ID_FAILURE="UPDATE_DEAL_BY_ID_FAILURE";

export const HANDLE_UPDATE_DEAL_MODAL="HANDLE_UPDATE_DEAL_MODAL";

export const GET_ALL_DEALS_REQUEST="GET_ALL_DEALS_REQUEST";
export const GET_ALL_DEALS_SUCCESS="GET_ALL_DEALS_SUCCESS";
export const GET_ALL_DEALS_FAILURE="GET_ALL_DEALS_FAILURE";

export const EMPTY_DEALS_LIST="EMPTY_DEALS_LIST";

export const GET_ALL_DEAL_STAGES_REQUEST="GET_ALL_DEAL_STAGES_REQUEST";
export const GET_ALL_DEAL_STAGES_SUCCESS="GET_ALL_DEAL_STAGES_SUCCESS";
export const GET_ALL_DEAL_STAGES_FAILURE="GET_ALL_DEAL_STAGES_FAILURE";
 
export const HANDLE_DEAL_CONTACT_MODAL="HANDLE_DEAL_CONTACT_MODAL";
 
export const GET_DEALS_CONTACT_LIST_REQUEST="GET_DEALS_CONTACT_LIST_REQUEST";
export const GET_DEALS_CONTACT_LIST_SUCCESS="GET_DEALS_CONTACT_LIST_SUCCESS";
export const GET_DEALS_CONTACT_LIST_FAILURE="GET_DEALS_CONTACT_LIST_FAILURE";

export const ADD_DEAL_CONTACT_REQUEST="ADD_DEAL_CONTACT_REQUEST";
export const ADD_DEAL_CONTACT_SUCCESS="ADD_DEAL_CONTACT_SUCCESS";
export const ADD_DEAL_CONTACT_FAILURE="ADD_DEAL_CONTACT_FAILURE";



export const GET_DEAL_LINKED_STAGES_REQUEST =
"GET_DEAL_LINKED_STAGES_REQUEST";
export const GET_DEAL_LINKED_STAGES_SUCCESS =
"GET_DEAL_LINKED_STAGES_SUCCESS";
export const GET_DEAL_LINKED_STAGES_FAILURE =
"GET_DEAL_LINKED_STAGES_FAILURE";

export const GET_DEAL_LINKED_WORKFLOW_REQUEST =
  "GET_DEAL_LINKED_WORKFLOW_REQUEST";
export const GET_DEAL_LINKED_WORKFLOW_SUCCESS =
  "GET_DEAL_LINKED_WORKFLOW_SUCCESS";
export const GET_DEAL_LINKED_WORKFLOW_FAILURE =
  "GET_DEAL_LINKED_WORKFLOW_FAILURE";

  export const GET_DEALS_RECORDS_REQUEST="GET_DEALS_RECORDS_REQUEST";
  export const GET_DEALS_RECORDS_SUCCESS="GET_DEALS_RECORDS_SUCCESS";
  export const GET_DEALS_RECORDS_FAILURE="GET_DEALS_RECORDS_FAILURE";

  export const GET_DEALS_TEAM_RECORDS_REQUEST="GET_DEALS_TEAM_RECORDS_REQUEST";
  export const GET_DEALS_TEAM_RECORDS_SUCCESS="GET_DEALS_TEAM_RECORDS_SUCCESS";
  export const GET_DEALS_TEAM_RECORDS_FAILURE="GET_DEALS_TEAM_RECORDS_FAILURE";

  export const GET_DEALS_ALL_RECORDS_REQUEST="GET_DEALS_ALL_RECORDS_REQUEST";
  export const GET_DEALS_ALL_RECORDS_SUCCESS="GET_DEALS_ALL_RECORDS_SUCCESS";
  export const GET_DEALS_ALL_RECORDS_FAILURE="GET_DEALS_ALL_RECORDS_FAILURE";

  export const HANDLE_DEALS_NOTES_DRAWER_MODAL = "HANDLE_DEALS_NOTES_DRAWER_MODAL";

  export const HANDLE_DEALS_CONTACTS_DRAWER_MODAL = "HANDLE_DEALS_CONTACTS_DRAWER_MODAL";

  export const LINK_DEAL_REQUEST =
"LINK_DEAL_REQUEST";
export const LINK_DEAL_SUCCESS =
"LINK_DEAL_SUCCESS";
export const LINK_DEAL_FAILURE =
"LINK_DEAL_FAILURE";

export const ADD_DEALS_NOTES_REQUEST = "ADD_DEALS_NOTES_REQUEST";
export const ADD_DEALS_NOTES_SUCCESS = "ADD_DEALS_NOTES_SUCCESS";
export const ADD_DEALS_NOTES_FAILURE = "ADD_DEALS_NOTES_FAILURE";


export const GET_NOTES_LIST_BY_DEAL_ID_REQUEST =
  "GET_NOTES_LIST_BY_DEAL_ID_REQUEST";
export const GET_NOTES_LIST_BY_DEAL_ID_SUCCESS =
  "GET_NOTES_LIST_BY_DEAL_ID_SUCCESS";
export const GET_NOTES_LIST_BY_DEAL_ID_FAILURE =
  "GET_NOTES_LIST_BY_DEAL_ID_FAILURE";

  export const GET_WON_DEALS_REQUEST="GET_WON_DEALS_REQUEST";
  export const GET_WON_DEALS_SUCCESS="GET_WON_DEALS_SUCCESS";
  export const GET_WON_DEALS_FAILURE="GET_WON_DEALS_FAILURE";

  export const SEND_WON_CARD_REQUEST="SEND_WON_CARD_REQUEST";
  export const SEND_WON_CARD_SUCCESS="SEND_WON_CARD_SUCCESS";
  export const SEND_WON_CARD_FAILURE="SEND_WON_CARD_FAILURE";
  
  export const SEND_WON_TO_REQUEST="SEND_WON_TO_REQUEST";
  export const SEND_WON_TO_SUCCESS="SEND_WON_TO_SUCCESS";
  export const SEND_WON_TO_FAILURE="SEND_WON_TO_FAILURE";

  export const SEND_LOST_TO_REQUEST="SEND_LOST_TO_REQUEST";
  export const SEND_LOST_TO_SUCCESS="SEND_LOST_TO_SUCCESS";
  export const SEND_LOST_TO_FAILURE="SEND_LOST_TO_FAILURE";

  export const GET_LOST_RECORDS_REQUEST = "GET_LOST_RECORDS_REQUEST";
  export const GET_LOST_RECORDS_SUCCESS = "GET_LOST_RECORDS_SUCCESS";
  export const GET_LOST_RECORDS_FAILURE = "GET_LOST_RECORDS_FAILURE";



  export const UPDATE_DEAL_NAME_REQUEST =
  "UPDATE_DEAL_NAME_REQUEST";
export const UPDATE_DEAL_NAME_SUCCESS =
  "UPDATE_DEAL_NAME_SUCCESS";
export const UPDATE_DEAL_NAME_FAILURE =
  "UPDATE_DEAL_NAME_FAILURE";


  export const GET_ALL_DEALS_DATA_REQUEST = "GET_ALL_DEALS_DATA_REQUEST";
  export const GET_ALL_DEALS_DATA_SUCCESS = "GET_ALL_DEALS_DATA_SUCCESS";
  export const GET_ALL_DEALS_DATA_FAILURE = "GET_ALL_DEALS_DATA_FAILURE";

  export const GET_TEAMS_DEALS_DATA_REQUEST = "GET_TEAMS_DEALS_DATA_REQUEST";
  export const GET_TEAMS_DEALS_DATA_SUCCESS = "GET_TEAMS_DEALS_DATA_SUCCESS";
  export const GET_TEAMS_DEALS_DATA_FAILURE = "GET_TEAMS_DEALS_DATA_FAILURE";

  export const UPDATE_DEAL_DRAG_STAGE_REQUEST =
  "UPDATE_UPDATE_DEAL_DRAG_STAGE_REQUEST";
export const UPDATE_DEAL_DRAG_STAGE_SUCCESS =
  "UPDATE_UPDATE_DEAL_DRAG_STAGE_SUCCESS";
export const UPDATE_DEAL_DRAG_STAGE_FAILURE =
  "UPDATE_DEAL_DRAG_STAGE_FAILURE";

  export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

  export const ADD_DEAL_DOCUMENT_REQUEST = "ADD_DEAL_DOCUMENT_REQUEST";
export const ADD_DEAL_DOCUMENT_SUCCESS = "ADD_DEAL_DOCUMENT_SUCCESS";
export const ADD_DEAL_DOCUMENT_FAILURE = "ADD_DEAL_DOCUMENT_FAILURE";


export const GET_DEAL_DOCUMENTS_REQUEST = "GET_DEAL_DOCUMENTS_REQUEST";
export const GET_DEAL_DOCUMENTS_SUCCESS = "GET_DEAL_DOCUMENTS_SUCCESS";
export const GET_DEAL_DOCUMENTS_FAILURE = "GET_DEAL_DOCUMENTS_FAILURE";

export const GET_OPPORTUNITY_RECORD_REQUEST="GET_OPPORTUNITY_RECORD_REQUEST";
export const GET_OPPORTUNITY_RECORD_SUCCESS="GET_OPPORTUNITY_RECORD_SUCCESS";
export const GET_OPPORTUNITY_RECORD_FAILURE="GET_OPPORTUNITY_RECORD_FAILURE";

export const DELETE_DEAL_DATA_REQUEST = "DELETE_DEAL_DATA_REQUEST";
export const DELETE_DEAL_DATA_SUCCESS = "DELETE_DEAL_DATA_SUCCESS";
export const DELETE_DEAL_DATA_FAILURE = "DELETE_DEAL_DATA_FAILURE";

export const REMOVE_DEAL_DOCUMENT_REQUEST = "REMOVE_DEAL_DOCUMENT_REQUEST";
export const REMOVE_DEAL_DOCUMENT_SUCCESS = "REMOVE_DEAL_DOCUMENT_SUCCESS";
export const REMOVE_DEAL_DOCUMENT_FAILURE = "REMOVE_DEAL_DOCUMENT_FAILURE";

export const UPDATE_CONTACT_ROLE_BY_DEAL_ID_REQUEST =
  "UPDATE_CONTACT_ROLE_BY_DEAL_ID_REQUEST";
export const UPDATE_CONTACT_ROLE_BY_DEAL_ID_SUCCESS =
  "UPDATE_CONTACT_ROLE_BY_DEAL_ID_SUCCESS";
export const UPDATE_CONTACT_ROLE_BY_DEAL_ID_FAILURE =
  "UPDATE_CONTACT_ROLE_BY_DEAL_ID_FAILURE";

  export const GET_LOST_DEALS_REQUEST="GET_LOST_DEALS_REQUEST";
  export const GET_LOST_DEALS_SUCCESS="GET_LOST_DEALS_SUCCESS";
  export const GET_LOST_DEALS_FAILURE="GET_LOST_DEALS_FAILURE";


  export const GET_DEALS_CONTACT_REQUEST = "GET_DEALS_CONTACT_REQUEST";
export const GET_DEALS_CONTACT_SUCCESS = "GET_DEALS_CONTACT_SUCCESS";
export const GET_DEALS_CONTACT_FAILURE = "GET_DEALS_CONTACT_FAILURE";

export const SET_DEALS_CONTACT_REQUEST="SET_DEALS_CONTACT_REQUEST";
export const SET_DEALS_CONTACT_SUCCESS="SET_DEALS_CONTACT_SUCCESS";
export const SET_DEALS_CONTACT_FAILURE="SET_DEALS_CONTACT_FAILURE";

export const SET_DEALS_CONTACT_VALUE_REQUEST="SET_DEALS_CONTACT_VALUE_REQUEST";
export const SET_DEALS_CONTACT_VALUE_SUCCESS="SET_DEALS_CONTACT_VALUE_SUCCESS";
export const SET_DEALS_CONTACT_VALUE_FAILURE="SET_DEALS_CONTACT_VALUE_FAILURE";

export const GET_ACTIVE_ASSIGENED_TO_REQUEST = "GET_ACTIVE_ASSIGENED_TO_REQUEST";
export const GET_ACTIVE_ASSIGENED_TO_SUCCESS = "GET_ACTIVE_ASSIGENED_TO_SUCCESS";
export const GET_ACTIVE_ASSIGENED_TO_FAILURE = "GET_ACTIVE_ASSIGENED_TO_FAILURE";

export const GET_DELETE_RECORDS_REQUEST = "GET_DELETE_RECORDS_REQUEST";
  export const GET_DELETE_RECORDS_SUCCESS = "GET_DELETE_RECORDS_SUCCESS";
  export const GET_DELETE_RECORDS_FAILURE = "GET_DELETE_RECORDS_FAILURE";


  export const GET_DELETED_DEAL_REQUEST = "GET_DELETED_DEAL_REQUEST";
export const GET_DELETED_DEAL_SUCCESS = "GET_DELETED_DEAL_SUCCESS";
export const GET_DELETED_DEAL_FAILURE = "GET_DELETED_DEAL_FAILURE";


export const HANDLE_OWN_MODAL = "HANDLE_OWN_MODAL";
  
export const INPUT_DEAL_SEARCH_DATA_REQUEST =
"INPUT_DEAL_SEARCH_DATA_REQUEST";
export const INPUT_DEAL_SEARCH_DATA_SUCCESS =
"INPUT_DEAL_SEARCH_DATA_SUCCESS";
export const INPUT_DEAL_SEARCH_DATA_FAILURE =
"INPUT_DEAL_SEARCH_DATA_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_DEAL = "HANDLE_CLAER_SEARCHED_DATA_DEAL";