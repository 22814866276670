export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";



export const ADD_ROLES_REQUEST = "ADD_ROLES_REQUEST";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_FAILURE = "ADD_ROLES_FAILURE";


export const UPDATE_ROLES_REQUEST = "UPDATE_ROLES_REQUEST";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAILURE = "UPDATE_ROLES_FAILURE";

export const GET_ROLE_SEARCH_REQUEST="GET_ROLE_SEARCH_REQUEST";
export const GET_ROLE_SEARCH_SUCCESS="GET_ROLE_SEARCH_SUCCESS";
export const GET_ROLE_SEARCH_FAILURE="GET_ROLE_SEARCH_FAILURE";

export const REMOVE_ROLE_REQUEST = "REMOVE_ROLE_REQUEST";
export const REMOVE_ROLE_SUCCESS = "REMOVE_ROLE_SUCCESS";
export const REMOVE_ROLE_FAILURE = "REMOVE_ROLE_FAILURE";

export const GET_TALENT_ROLES_REQUEST = "GET_TALENT_ROLES_REQUEST";
export const GET_TALENT_ROLES_SUCCESS = "GET_TALENT_ROLES_SUCCESS";
export const GET_TALENT_ROLES_FAILURE = "GET_TALENT_ROLES_FAILURE";

export const ADD_TALENT_ROLES_REQUEST = "ADD_TALENT_ROLES_REQUEST";
export const ADD_TALENT_ROLES_SUCCESS = "ADD_TALENT_ROLES_SUCCESS";
export const ADD_TALENT_ROLES_FAILURE = "ADD_TALENT_ROLES_FAILURE";

export const REMOVE_TALENT_ROLE_REQUEST = "REMOVE_TALENT_ROLE_REQUEST";
export const REMOVE_TALENT_ROLE_SUCCESS = "REMOVE_TALENT_ROLE_SUCCESS";
export const REMOVE_TALENT_ROLE_FAILURE = "REMOVE_TALENT_ROLE_FAILURE";

export const UPDATE_TALENT_ROLES_REQUEST = "UPDATE_TALENT_ROLES_REQUEST";
export const UPDATE_TALENT_ROLES_SUCCESS = "UPDATE_TALENT_ROLES_SUCCESS";
export const UPDATE_TALENT_ROLES_FAILURE = "UPDATE_TALENT_ROLES_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_ROLE = "HANDLE_CLAER_REDUCER_DATA_ROLE";

export const HANDLE_CLAER_REDUCER_DATA_ROLE_TALENT = "HANDLE_CLAER_REDUCER_DATA_ROLE_TALENT";

export const GET_ROLE_SEARCH_TALENT_REQUEST="GET_ROLE_SEARCH_TALENT_REQUEST";
export const GET_ROLE_SEARCH_TALENT_SUCCESS="GET_ROLE_SEARCH_TALENT_SUCCESS";
export const GET_ROLE_SEARCH_TALENT_FAILURE="GET_ROLE_SEARCH_TALENT_FAILURE";

export const GET_ROLE_COUNT_REQUEST = "GET_ROLE_COUNT_REQUEST";
export const GET_ROLE_COUNT_SUCCESS = "GET_ROLE_COUNT_SUCCESS";
export const GET_ROLE_COUNT_FAILURE = "GET_ROLE_COUNT_FAILURE";

export const GET_EXTERNAL_ROLE_COUNT_REQUEST = "GET_EXTERNAL_ROLE_COUNT_REQUEST";
export const GET_EXTERNAL_ROLE_COUNT_SUCCESS = "GET_EXTERNAL_ROLE_COUNT_SUCCESS";
export const GET_EXTERNAL_ROLE_COUNT_FAILURE = "GET_EXTERNAL_ROLE_COUNT_FAILURE";




