export const SET_REQUIREMENT_VIEW_TYPE="SET_REQUIREMENT_VIEW_TYPE";

export const GET_REQUIREMENT_RECORD_REQUEST="GET_REQUIREMENT_RECORD_REQUEST";
export const GET_REQUIREMENT_RECORD_SUCCESS="GET_REQUIREMENT_RECORD_SUCCESS";
export const GET_REQUIREMENT_RECORD_FAILURE="GET_REQUIREMENT_RECORD_FAILURE";


export const GET_ALL_REQUIREMENT_TABLE_REQUEST =
  "GET_ALL_REQUIREMENT_TABLE_REQUEST";
export const GET_ALL_REQUIREMENT_TABLE_SUCCESS=
  "GET_ALL_REQUIREMENT_TABLE_SUCCESS";
export const GET_ALL_REQUIREMENT_TABLE_FAILURE =
  "GET_ALL_REQUIREMENT_TABLE_FAILURE";

  export const HANDLE_CLAER_REDUCER_DATA_REQUIREMENT = "HANDLE_CLAER_REDUCER_DATA_REQUIREMENT";




