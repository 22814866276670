export const GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_FAILURE = "GET_REGIONS_FAILURE";




export const ADD_REGIONS_REQUEST = "ADD_REGIONS_REQUEST";
export const ADD_REGIONS_SUCCESS = "ADD_REGIONS_SUCCESS";
export const ADD_REGIONS_FAILURE = "ADD_REGIONS_FAILURE";

export const GET_DROPDOWN_REGIONS_REQUEST = "GET_DROPDOWN_REGIONS_REQUEST";
export const GET_DROPDOWN_REGIONS_SUCCESS = "GET_DROPDOWN_REGIONS_SUCCESS";
export const GET_DROPDOWN_REGIONS_FAILURE = "GET_DROPDOWN_REGIONS_FAILURE";



export const UPDATE_REGIONS_REQUEST = "UPDATE_REGIONS_REQUEST";
export const UPDATE_REGIONS_SUCCESS = "UPDATE_REGIONS_SUCCESS";
export const UPDATE_REGIONS_FAILURE = "UPDATE_REGIONS_FAILURE";




export const REMOVE_REGIONS_REQUEST = "REMOVE_REGIONS_REQUEST";
export const REMOVE_REGIONS_SUCCESS = "REMOVE_REGIONS_SUCCESS";
export const REMOVE_REGIONS_FAILURE = "REMOVE_REGIONS_FAILURE";




export const GET_REGION_SEARCH_REQUEST="GET_REGION_SEARCH_REQUEST";
export const GET_REGION_SEARCH_SUCCESS="GET_REGION_SEARCH_SUCCESS";
export const GET_REGION_SEARCH_FAILURE="GET_REGION_SEARCH_FAILURE";




export const GET_REGION_COUNT_REQUEST = "GET_REGION_COUNT_REQUEST";
export const GET_REGION_COUNT_SUCCESS = "GET_REGION_COUNT_SUCCESS";
export const GET_REGION_COUNT_FAILURE = "GET_REGION_COUNT_FAILURE";


export const GET_TARGET_REQUEST = "GET_TARGET_REQUEST";
export const GET_TARGET_SUCCESS = "GET_TARGET_SUCCESS";
export const GET_TARGET_FAILURE = "GET_TARGET_FAILURE";



export const ADD_TARGET_REQUEST = "ADD_TARGET_REQUEST";
export const ADD_TARGET_SUCCESS = "ADD_TARGET_SUCCESS";
export const ADD_TARGET_FAILURE = "ADD_TARGET_FAILURE";



export const HANDLE_REGION_DRAWER_MODAL = "HANDLE_REGION_DRAWER_MODAL";