// export const GET_CALLS_BY_USER_ID_REQUEST = 'GET_CALLS_BY_USER_ID_REQUEST';
// export const GET_CALLS_BY_USER_ID_SUCCESS = 'GET_CALLS_BY_USER_ID_SUCCESS';
// export const GET_CALLS_BY_USER_ID_FAILURE = 'GET_CALLS_BY_USER_ID_FAILURE';

// export const GET_EVENTS_BY_USER_ID_REQUEST = 'GET_EVENTS_BY_USER_ID_REQUEST';
// export const GET_EVENTS_BY_USER_ID_SUCCESS = 'GET_EVENTS_BY_USER_ID_SUCCESS';
// export const GET_EVENTS_BY_USER_ID_FAILURE = 'GET_EVENTS_BY_USER_ID_FAILURE';

// export const GET_TASKS_BY_USER_ID_REQUEST = 'GET_TASKS_BY_USER_ID_REQUEST';
// export const GET_TASKS_BY_USER_ID_SUCCESS = 'GET_TASKS_BY_USER_ID_SUCCESS';
// export const GET_TASKS_BY_USER_ID_FAILURE = 'GET_TASKS_BY_USER_ID_FAILURE';
export const SET_PLANNER_VIEW_TYPE = "SET_PLANNER_VIEW_TYPE";

export const SET_PLANNER_DATE = 'SET_PLANNER_DATE';
export const HANDLE_EVENT_MODAL = 'HANDLE_EVENT_MODAL';
export const SET_DATE_AND_TIME = 'SET_DATE_AND_TIME';
export const HANDLE_CHOOSER_MODAL = 'HANDLE_CHOOSER_MODAL'
export const HANDLE_VIEW_EDIT_MODAL = 'HANDLE_VIEW_EDIT_MODAL'
export const SET_FORM_MODAL_TYPE = 'SET_FORM_MODAL_TYPE'

export const GET_PERMISSIONS_LIST_REQUEST = "GET_PERMISSIONS_LIST_REQUEST";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";
export const GET_PERMISSIONS_LIST_FAILURE = "GET_PERMISSIONS_LIST_FAILURE";


export const SHARE_PLANNER_PERMISSION_REQUEST = "SHARE_PLANNER_PERMISSION_REQUEST";
export const SHARE_PLANNER_PERMISSION_SUCCESS = "SHARE_PLANNER_PERMISSION_SUCCESS";
export const SHARE_PLANNER_PERMISSION_FAILURE = "SHARE_PLANNER_PERMISSION_FAILURE";