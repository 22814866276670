export const SET_REGIONAL_DASH_VIEW_TYPE = "SET_REGIONAL_DASH_VIEW_TYPE";

export const HANDLE_SALES_MODAL = "HANDLE_SALES_MODAL";

export const HANDLE_SALES_PLAN_DRAWER_MODAL = "HANDLE_SALES_PLAN_DRAWER_MODAL";

export const HANDLE_INVESTMENT_MODAL = "HANDLE_INVESTMENT_MODAL";


export const HANDLE_SALES_ORDER_DRAWER_MODAL = "HANDLE_SALES_ORDER_DRAWER_MODAL";


export const EMPTY_ORDER_PLAN = "EMPTY_ORDER_PLAN";







export const HANDLE_SALES_QUOTATION_DRAWER_MODAL = "HANDLE_SALES_QUOTATION_DRAWER_MODAL";




export const UPDATE_TASK_DRAG_STAGE_REQUEST =
"UPDATE_UPDATE_TASK_DRAG_STAGE_REQUEST";
export const UPDATE_TASK_DRAG_STAGE_SUCCESS =
"UPDATE_UPDATE_TASK_DRAG_STAGE_SUCCESS";
export const UPDATE_TASK_DRAG_STAGE_FAILURE =
"UPDATE_TASK_DRAG_STAGE_FAILURE";


export const HANDLE_FULLFILLMENT_MODAL = "HANDLE_FULLFILLMENT_MODAL";

export const CHANGE_SELECTED_REGIONAL_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_REGIONAL_TIME_INTERVAL_REPORT";

  export const GET_REGION_SALES_LIST_REQUEST = "GET_REGION_SALES_LIST_REQUEST";
export const GET_REGION_SALES_LIST_SUCCESS = "GET_REGION_SALES_LIST_SUCCESS";
export const GET_REGION_SALES_LIST_FAILURE = "GET_REGION_SALES_LIST_FAILURE";



export const GET_ORDER_PLAN_LIST_REQUEST = "GET_ORDER_PLAN_LIST_REQUEST";
export const GET_ORDER_PLAN_LIST_SUCCESS = "GET_ORDER_PLAN_LIST_SUCCESS";
export const GET_ORDER_PLAN_LIST_FAILURE = "GET_ORDER_PLAN_LIST_FAILURE";


export const GET_REGION_TASK_LIST_REQUEST = "GET_REGION_TASK_LIST_REQUEST";
export const GET_REGION_TASK_LIST_SUCCESS = "GET_REGION_TASK_LIST_SUCCESS";
export const GET_REGION_TASK_LIST_FAILURE = "GET_REGION_TASK_LIST_FAILURE";
// export const GET_CO2_TABLE_VIEW_REQUEST = "GET_CO2_TABLE_VIEW_REQUEST";
// export const GET_CO2_TABLE_VIEW_SUCCESS = "GET_CO2_TABLE_VIEW_SUCCESS";
// export const GET_CO2_TABLE_VIEW_FAILURE = "GET_CO2_TABLE_VIEW_FAILURE";




