export const GET_SKILLS_CLOUD_REQUEST = "GET_SKILLS_CLOUD_REQUEST";
export const GET_SKILLS_CLOUD_SUCCESS = "GET_SKILLS_CLOUD_SUCCESS";
export const GET_SKILLS_CLOUD_FAILURE = "GET_SKILLS_CLOUD_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";



export const GET_REPAIR_DASHBOARD_ORDER_OPEN_REQUEST = "GET_REPAIR_DASHBOARD_ORDER_OPEN_REQUEST";
export const GET_REPAIR_DASHBOARD_ORDER_OPEN_SUCCESS = "GET_REPAIR_DASHBOARD_ORDER_OPEN_SUCCESS";
export const GET_REPAIR_DASHBOARD_ORDER_OPEN_FAILURE = "GET_REPAIR_DASHBOARD_ORDER_OPEN_FAILURE";


export const GET_REPAIR_DASHBOARD_ORDER_CLOSE_REQUEST = "GET_REPAIR_DASHBOARD_ORDER_CLOSE_REQUEST";
export const GET_REPAIR_DASHBOARD_ORDER_CLOSE_SUCCESS = "GET_REPAIR_DASHBOARD_ORDER_CLOSE_SUCCESS";
export const GET_REPAIR_DASHBOARD_ORDER_CLOSE_FAILURE = "GET_REPAIR_DASHBOARD_ORDER_CLOSE_FAILURE";


export const HANDLE_DASHBOARD_REPAIR_ORDER_MODAL =
  "HANDLE_DASHBOARD_REPAIR_ORDER_MODAL";

  export const HANDLE_DASHBOARD_REPAIR_ORDER_CLOSE_MODAL =
  "HANDLE_DASHBOARD_REPAIR_ORDER_CLOSE_MODAL";

  export const HANDLE_DASHBOARD_REPAIR_ORDER_OPEN_MODAL =
  "HANDLE_DASHBOARD_REPAIR_ORDER_OPEN_MODAL";


  export const GET_REPAIR_DASHBOARD_ORDER_ADDED_REQUEST = "GET_REPAIR_DASHBOARD_ORDER_ADDED_REQUEST";
export const GET_REPAIR_DASHBOARD_ORDER_ADDED_SUCCESS = "GET_REPAIR_DASHBOARD_ORDER_ADDED_SUCCESS";
export const GET_REPAIR_DASHBOARD_ORDER_ADDED_FAILURE = "GET_REPAIR_DASHBOARD_ORDER_ADDED_FAILURE";



export const GET_QUOTATION_TABLE_DATA_REQUEST = "GET_QUOTATION_TABLE_DATA_REQUEST";
export const GET_QUOTATION_TABLE_DATA_SUCCESS = "GET_QUOTATION_TABLE_DATA_SUCCESS";
export const GET_QUOTATION_TABLE_DATA_FAILURE = "GET_QUOTATION_TABLE_DATA_FAILURE";

export const GET_LEAVES_GANTT_REQUEST = "GET_LEAVES_GANTT_REQUEST";
export const GET_LEAVES_GANTT_SUCCESS = "GET_LEAVES_GANTT_SUCCESS";
export const GET_LEAVES_GANTT_FAILURE = "GET_LEAVES_GANTT_FAILURE";

export const GET_JUMPSTART_ORDER_DETAIL_REQUEST = "GET_JUMPSTART_ORDER_DETAIL_REQUEST";
export const GET_JUMPSTART_ORDER_DETAIL_SUCCESS = "GET_JUMPSTART_ORDER_DETAIL_SUCCESS";
export const GET_JUMPSTART_ORDER_DETAIL_FAILURE = "GET_JUMPSTART_ORDER_DETAIL_FAILURE";

export const GET_FINACE_ORDER_DETAIL_REQUEST = "GET_FINACE_ORDER_DETAIL_REQUEST";
export const GET_FINACE_ORDER_DETAIL_SUCCESS = "GET_FINACE_ORDER_DETAIL_SUCCESS";
export const GET_FINACE_ORDER_DETAIL_FAILURE = "GET_FINACE_ORDER_DETAIL_FAILURE";

export const GET_ENTERPRISE_ORDER_DETAIL_REQUEST = "GET_ENTERPRISE_ORDER_DETAIL_REQUEST";
export const GET_ENTERPRISE_ORDER_DETAIL_SUCCESS = "GET_ENTERPRISE_ORDER_DETAIL_SUCCESS";
export const GET_ENTERPRISE_ORDER_DETAIL_FAILURE = "GET_ENTERPRISE_ORDER_DETAIL_FAILURE";

export const GET_JUMPSTART_DISTRIBUTOR_DETAIL_REQUEST = "GET_JUMPSTART_DISTRIBUTOR_DETAIL_REQUEST";
export const GET_JUMPSTART_DISTRIBUTOR_DETAIL_SUCCESS = "GET_JUMPSTART_DISTRIBUTOR_DETAIL_SUCCESS";
export const GET_JUMPSTART_DISTRIBUTOR_DETAIL_FAILURE = "GET_JUMPSTART_DISTRIBUTOR_DETAIL_FAILURE";

export const GET_TASKS_DASHBOARD_GANTT_REQUEST = "GET_TASKS_DASHBOARD_GANTT_REQUEST";
export const GET_TASKS_DASHBOARD_GANTT_SUCCESS = "GET_TASKS_DASHBOARD_GANTT_SUCCESS";
export const GET_TASKS_DASHBOARD_GANTT_FAILURE = "GET_TASKS_DASHBOARD_GANTT_FAILURE";

export const GET_THIS_MONTH_TASK_GANTT_REQUEST = "GET_THIS_MONTH_TASK_GANTT_REQUEST";
export const GET_THIS_MONTH_TASK_GANTT_SUCCESS = "GET_THIS_MONTH_TASK_GANTT_SUCCESS";
export const GET_THIS_MONTH_TASK_GANTT_FAILURE = "GET_THIS_MONTH_TASK_GANTT_FAILURE";

export const HANDLE_ACTION_DRAWER_MODAL = "HANDLE_ACTION_DRAWER_MODAL";

export const GET_ACTION_NOTIFICATIONS_REQUEST = "GET_ACTION_NOTIFICATIONS_REQUEST";
export const GET_ACTION_NOTIFICATIONS_SUCCESS = "GET_ACTION_NOTIFICATIONS_SUCCESS";
export const GET_ACTION_NOTIFICATIONS_FAILURE = "GET_ACTION_NOTIFICATIONS_FAILURE";

export const GET_ORDER_LIST_BY_ORDER_ID_REQUEST = "GET_ORDER_LIST_BY_ORDER_ID_REQUEST";
export const GET_ORDER_LIST_BY_ORDER_ID_SUCCESS = "GET_ORDER_LIST_BY_ORDER_ID_SUCCESS";
export const GET_ORDER_LIST_BY_ORDER_ID_FAILURE = "GET_ORDER_LIST_BY_ORDER_ID_FAILURE";

export const SET_DASHBOARD_VIEW_TYPE = "SET_DASHBOARD_VIEW_TYPE";
//  export const SET_DASHBOARD_TYPE = "SET_DASHBOARD_TYPE";

// export const CHANGE_SELECTED_TODO_TIME_INTERVAL_REPORT =
//   "CHANGE_SELECTED_TODO_TIME_INTERVAL_REPORT";

export const GET_DATE_WISE_REPORT_REQUEST = "GET_DATE_WISE_REPORT_REQUEST";
export const GET_DATE_WISE_REPORT_SUCCESS = "GET_DATE_WISE_REPORT_SUCCESS";
export const GET_DATE_WISE_REPORT_FAILURE = "GET_DATE_WISE_REPORT_FAILURE";

export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";
export const SET_SUB_SELECTED_REPORT_TYPE = "SET_SUB_SELECTED_REPORT_TYPE";

export const GET_DASHBOARD_TABLE_REQUEST = "GET_DASHBOARD_TABLE_REQUEST";
export const GET_DASHBOARD_TABLE_SUCCESS = "GET_DASHBOARD_TABLE_SUCCESS";
export const GET_DASHBOARD_TABLE_FAILURE = "GET_DASHBOARD_TABLE_FAILURE";

export const GET_RECRUITER_DASHBOARD_LIST_REQUEST = "GET_RECRUITER_DASHBOARD_LIST_REQUEST";
export const GET_RECRUITER_DASHBOARD_LIST_SUCCESS = "GET_RECRUITER_DASHBOARD_LIST_SUCCESS";
export const GET_RECRUITER_DASHBOARD_LIST_FAILURE = "GET_RECRUITER_DASHBOARD_LIST_FAILURE";

export const GET_DASHBOARD_TABLE_PROGRESS_REQUEST = "GET_DASHBOARD_TABLE_PROGRESS_REQUEST";
export const GET_DASHBOARD_TABLE_PROGRESS_SUCCESS = "GET_DASHBOARD_TABLE_PROGRESS_SUCCESS";
export const GET_DASHBOARD_TABLE_PROGRESS_FAILURE = "GET_DASHBOARD_TABLE_PROGRESS_FAILURE";

export const GET_DASHBOARD_COMMISSION_TABLE_REQUEST = "GET_DASHBOARD_COMMISSION_TABLE_REQUEST";
export const GET_DASHBOARD_COMMISSION_TABLE_SUCCESS = "GET_DASHBOARD_COMMISSION_TABLE_SUCCESS";
export const GET_DASHBOARD_COMMISSION_TABLE_FAILURE = "GET_DASHBOARD_COMMISSION_TABLE_FAILURE";



export const GET_PROSPECT_LIFETIME_REQUEST = "GET_PROSPECT_LIFETIME_REQUEST";
export const GET_PROSPECT_LIFETIME_SUCCESS = "GET_PROSPECT_LIFETIME_SUCCESS";
export const GET_PROSPECT_LIFETIME_FAILURE = "GET_PROSPECT_LIFETIME_FAILURE";

export const GET_DASHBOARD_CUSTOMER_CHART_REQUEST = "GET_DASHBOARD_CUSTOMER_CHART_REQUEST";
export const GET_DASHBOARD_CUSTOMER_CHART_SUCCESS = "GET_DASHBOARD_CUSTOMER_CHART_SUCCESS";
export const GET_DASHBOARD_CUSTOMER_CHART_FAILURE = "GET_DASHBOARD_CUSTOMER_CHART_FAILURE";

export const GET_DASHBOARD_CLOSURE_RATIO_REQUEST = "GET_DASHBOARD_CLOSURE_RATIO_REQUEST";
export const GET_DASHBOARD_CLOSURE_RATIO_SUCCESS = "GET_DASHBOARD_CLOSURE_RATIO_SUCCESS";
export const GET_DASHBOARD_CLOSURE_RATIO_FAILURE = "GET_DASHBOARD_CLOSURE_RATIO_FAILURE";

export const GET_DASHBOARD_SUMMARY_CHART_REQUEST = "GET_DASHBOARD_SUMMARY_CHART_REQUEST";
export const GET_DASHBOARD_SUMMARY_CHART_SUCCESS = "GET_DASHBOARD_SUMMARY_CHART_SUCCESS";
export const GET_DASHBOARD_SUMMARY_CHART_FAILURE = "GET_DASHBOARD_SUMMARY_CHART_FAILURE";

export const GET_TODOS_REQUEST = "GET_TODOS_REQUEST";
export const GET_TODOS_SUCCESS = "GET_TODOS_SUCCESS";
export const GET_TODOS_FAILURE = "GET_TODOS_FAILURE";

export const GET_TODOS_COUNT_REQUEST = "GET_TODOS_COUNT_REQUEST";
export const GET_TODOS_COUNT_SUCCESS = "GET_TODOS_COUNT_SUCCESS";
export const GET_TODOS_COUNT_FAILURE = "GET_TODOS_COUNT_FAILURE";


export const GET_AVG_HOUR_REQUEST = "GET_AVG_HOUR_REQUEST";
export const GET_AVG_HOUR__SUCCESS = "GET_AVG_HOUR__SUCCESS";
export const GET_AVG_HOUR__FAILURE = "GET_AVG_HOUR__FAILURE";

export const GET_DASHBOARD_FUNNEL_REQUEST = "GET_DASHBOARD_FUNNEL_REQUEST";
export const GET_DASHBOARD_FUNNEL_SUCCESS = "GET_DASHBOARD_FUNNEL_SUCCESS";
export const GET_DASHBOARD_FUNNEL_FAILURE = "GET_DASHBOARD_FUNNEL_FAILURE";

export const GET_DASHBOARD_INDICATOR_REQUEST = "GET_DASHBOARD_INDICATOR_REQUEST";
export const GET_DASHBOARD_INDICATOR_SUCCESS = "GET_DASHBOARD_INDICATOR_SUCCESS";
export const GET_DASHBOARD_INDICATOR_FAILURE = "GET_DASHBOARD_INDICATOR_FAILURE";

export const GET_SALES_DATE_WISE_REPORT_REQUEST = "GET_SALES_DATE_WISE_REPORT_REQUEST";
export const GET_SALES_DATE_WISE_REPORT_SUCCESS = "GET_SALES_DATE_WISE_REPORT_SUCCESS";
export const GET_SALES_DATE_WISE_REPORT_FAILURE = "GET_SALES_DATE_WISE_REPORT_FAILURE";

export const GET_ALL_DATE_WISE_REPORT_REQUEST = "GET_ALL_DATE_WISE_REPORT_REQUEST";
export const GET_ALL_DATE_WISE_REPORT_SUCCESS = "GET_ALL_DATE_WISE_REPORT_SUCCESS";
export const GET_ALL_DATE_WISE_REPORT_FAILURE = "GET_ALL_DATE_WISE_REPORT_FAILURE";

export const UPDATE_TODO_CALL_BY_ID_REQUEST = "UPDATE_TODO_CALL_BY_ID_REQUEST";
export const UPDATE_TODO_CALL_BY_ID_SUCCESS = "UPDATE_TODO_CALL_BY_ID_SUCCESS";
export const UPDATE_TODO_CALL_BY_ID_FAILURE = "UPDATE_TODO_CALL_BY_ID_FAILURE";

export const UPDATE_TODO_EVENT_BY_ID_REQUEST =
  "UPDATE_TODO_EVENT_BY_ID_REQUEST";
export const UPDATE_TODO_EVENT_BY_ID_SUCCESS =
  "UPDATE_TODO_EVENT_BY_ID_SUCCESS";
export const UPDATE_TODO_EVENT_BY_ID_FAILURE =
  "UPDATE_TODO_EVENT_BY_ID_FAILURE";

export const UPDATE_TODO_TASK_BY_ID_REQUEST = "UPDATE_TODO_TASK_BY_ID_REQUEST";
export const UPDATE_TODO_TASK_BY_ID_SUCCESS = "UPDATE_TODO_TASK_BY_ID_SUCCESS";
export const UPDATE_TODO_TASK_BY_ID_FAILURE = "UPDATE_TODO_TASK_BY_ID_FAILURE";

export const GET_ALL_SALES_DATE_WISE_REPORT_REQUEST = "GET_ALL_SALES_DATE_WISE_REPORT_REQUEST";
export const GET_ALL_SALES_DATE_WISE_REPORT_SUCCESS = "GET_ALL_SALES_DATE_WISE_REPORT_SUCCESS";
export const GET_ALL_SALES_DATE_WISE_REPORT_FAILURE = "GET_ALL_SALES_DATE_WISE_REPORT_FAILURE";

export const GET_ALL_DASHBOARD_CLOSURE_RATIO_REQUEST = "GET_ALL_DASHBOARD_CLOSURE_RATIO_REQUEST";
export const GET_ALL_DASHBOARD_CLOSURE_RATIO_SUCCESS = "GET_ALL_DASHBOARD_CLOSURE_RATIO_SUCCESS";
export const GET_ALL_DASHBOARD_CLOSURE_RATIO_FAILURE = "GET_ALL_DASHBOARD_CLOSURE_RATIO_FAILURE";

export const GET_ALL_DASHBOARD_CUSTOMER_CHART_REQUEST = "GET_ALL_DASHBOARD_CUSTOMER_CHART_REQUEST";
export const GET_ALL_DASHBOARD_CUSTOMER_CHART_SUCCESS = "GET_ALL_DASHBOARD_CUSTOMER_CHART_SUCCESS";
export const GET_ALL_DASHBOARD_CUSTOMER_CHART_FAILURE = "GET_ALL_DASHBOARD_CUSTOMER_CHART_FAILURE";

export const GET_ALL_DASHBOARD_TABLE_PROGRESS_REQUEST = "GET_ALL_DASHBOARD_TABLE_PROGRESS_REQUEST";
export const GET_ALL_DASHBOARD_TABLE_PROGRESS_SUCCESS = "GET_ALL_DASHBOARD_TABLE_PROGRESS_SUCCESS";
export const GET_ALL_DASHBOARD_TABLE_PROGRESS_FAILURE = "GET_ALL_DASHBOARD_TABLE_PROGRESS_FAILURE";

export const GET_ALL_DASHBOARD_FUNNEL_REQUEST = "GET_ALL_DASHBOARD_FUNNEL_REQUEST";
export const GET_ALL_DASHBOARD_FUNNEL_SUCCESS = "GET_ALL_DASHBOARD_FUNNEL_SUCCESS";
export const GET_ALL_DASHBOARD_FUNNEL_FAILURE = "GET_ALL_DASHBOARD_FUNNEL_FAILURE";

export const HANDLE_ADD_JOB_DETAIL_MODAL = "HANDLE_ADD_JOB_DETAIL_MODAL";

export const ADD_ACTION_NOTIFICATIONS_REQUEST = "ADD_ACTION_NOTIFICATIONS_REQUEST";
export const ADD_ACTION_NOTIFICATIONS_SUCCESS = "ADD_ACTION_NOTIFICATIONS_SUCCESS";
export const ADD_ACTION_NOTIFICATIONS_FAILURE = "ADD_ACTION_NOTIFICATIONS_FAILURE";

export const GET_STAGE_ACTION_NOTIFICATIONS_REQUEST = "GET_STAGE_ACTION_NOTIFICATIONS_REQUEST";
export const GET_STAGE_ACTION_NOTIFICATIONS_SUCCESS = "GET_STAGE_ACTION_NOTIFICATIONS_SUCCESS";
export const GET_STAGE_ACTION_NOTIFICATIONS_FAILURE = "GET_STAGE_ACTION_NOTIFICATIONS_FAILURE";

export const GET_ACTION_STEPS_REQUEST = "GET_ACTION_STEPS_REQUEST";
export const GET_ACTION_STEPS_SUCCESS = "GET_ACTION_STEPS_SUCCESS";
export const GET_ACTION_STEPS_FAILURE = "GET_ACTION_STEPS_FAILURE";

export const HANDLE_BILLABLE_CANDIDATE_MODAL = "HANDLE_BILLABLE_CANDIDATE_MODAL";

export const GET_CANDIDATES_BILLABLE_AMOUNT_REQUEST = "GET_CANDIDATES_BILLABLE_AMOUNT_REQUEST";
export const GET_CANDIDATES_BILLABLE_AMOUNT_SUCCESS = "GET_CANDIDATES_BILLABLE_AMOUNT_SUCCESS";
export const GET_CANDIDATES_BILLABLE_AMOUNT_FAILURE = "GET_CANDIDATES_BILLABLE_AMOUNT_FAILURE";
// export const UPDATE_REQUIREMENT_STAGE_REQUEST =
//   "UPDATE_REQUIREMENT_STAGE_REQUEST";
// export const UPDATE_REQUIREMENT_STAGE_SUCCESS =
//   "UPDATE_REQUIREMENT_STAGE_SUCCESS";
// export const UPDATE_REQUIREMENT_STAGE_FAILURE =
//   "UPDATE_REQUIREMENT_STAGE_FAILURE";

export const GET_DETAILS_LIST_REQUEST = "GET_DETAILS_LIST_REQUEST";
export const GET_DETAILS_LIST_SUCCESS = "GET_DETAILS_LIST_SUCCESS";
export const GET_DETAILS_LIST_FAILURE = "GET_DETAILS_LIST_FAILURE";

export const GET_UPCOMING_EVENTS_REQUEST = "GET_UPCOMING_EVENTS_REQUEST";
export const GET_UPCOMING_EVENTS_SUCCESS = "GET_UPCOMING_EVENTS_SUCCESS";
export const GET_UPCOMING_EVENTS_FAILURE = "GET_UPCOMING_EVENTS_FAILURE";

export const GET_TASK_PER_REQUEST = "GET_TASK_PER_REQUEST";
export const GET_TASK_PER_SUCCESS = "GET_TASK_PER_SUCCESS";
export const GET_TASK_PER_FAILURE = "GET_TASK_PER_FAILURE";

export const GET_JUMPSTART_BULB_REQUEST = "GET_JUMPSTART_BULB_REQUEST";
export const GET_JUMPSTART_BULB_SUCCESS = "GET_JUMPSTART_BULB_SUCCESS";
export const GET_JUMPSTART_BULB_FAILURE = "GET_JUMPSTART_BULB_FAILURE";
export const GET_JUMPSTART_BULB2_REQUEST = "GET_JUMPSTART_BULB2_REQUEST";
export const GET_JUMPSTART_BULB2_SUCCESS = "GET_JUMPSTART_BULB2_SUCCESS";
export const GET_JUMPSTART_BULB2_FAILURE = "GET_JUMPSTART_BULB2_FAILURE";
export const GET_JUMPSTART_BULB3_REQUEST = "GET_JUMPSTART_BULB3_REQUEST";
export const GET_JUMPSTART_BULB3_SUCCESS = "GET_JUMPSTART_BULB3_SUCCESS";
export const GET_JUMPSTART_BULB3_FAILURE = "GET_JUMPSTART_BULB3_FAILURE";



export const HANDLE_ORDER_CLOSED_MODAL = "HANDLE_ORDER_CLOSED_MODAL";

export const GET_DASHBOARD_USER_LIST_REQUEST = "GET_DASHBOARD_USER_LIST_REQUEST";
export const GET_DASHBOARD_USER_LIST_SUCCESS = "GET_DASHBOARD_USER_LIST_SUCCESS";
export const GET_DASHBOARD_USER_LIST_FAILURE = "GET_DASHBOARD_USER_LIST_FAILURE";
export const GET_HOT_COLD_WARM_REQUEST = "GET_HOT_COLD_WARM_REQUEST";
export const GET_HOT_COLD_WARM_SUCCESS = "GET_HOT_COLD_WARM_SUCCESS";
export const GET_HOT_COLD_WARM_FAILURE = "GET_HOT_COLD_WARM_FAILURE";

export const GET_JUMPSTART_CUSTOMER_LIST_REQUEST = "GET_JUMPSTART_CUSTOMER_LIST_REQUEST";
export const GET_JUMPSTART_CUSTOMER_LIST_SUCCESS = "GET_JUMPSTART_CUSTOMER_LIST_SUCCESS";
export const GET_JUMPSTART_CUSTOMER_LIST_FAILURE = "GET_JUMPSTART_CUSTOMER_LIST_FAILURE";
export const GET_JUMPSTART_CUSTOMER2_LIST_REQUEST = "GET_JUMPSTART_CUSTOMER2_LIST_REQUEST";
export const GET_JUMPSTART_CUSTOMER2_LIST_SUCCESS = "GET_JUMPSTART_CUSTOMER2_LIST_SUCCESS";
export const GET_JUMPSTART_CUSTOMER2_LIST_FAILURE = "GET_JUMPSTART_CUSTOMER2_LIST_FAILURE";

export const GET_DASH_CUSTOMER_ADDED_LEADS_REQUEST = "GET_DASH_CUSTOMER_ADDED_LEADS_REQUEST";
export const GET_DASH_CUSTOMER_ADDED_LEADS_SUCCESS = "GET_DASH_CUSTOMER_ADDED_LEADS_SUCCESS";
export const GET_DASH_CUSTOMER_ADDED_LEADS_FAILURE = "GET_DASH_CUSTOMER_ADDED_LEADS_FAILURE";

export const GET_JUMPSTART_TASK_LIST_REQUEST = "GET_JUMPSTART_TASK_LIST_REQUEST";
export const GET_JUMPSTART_TASK_LIST_SUCCESS = "GET_JUMPSTART_TASK_LIST_SUCCESS";
export const GET_JUMPSTART_TASK_LIST_FAILURE = "GET_JUMPSTART_TASK_LIST_FAILURE";


export const GET_DASHBOARD_TASK_REQUEST = "GET_DASHBOARD_TASK_REQUEST";
export const GET_DASHBOARD_TASK_SUCCESS = "GET_DASHBOARD_TASK_SUCCESS";
export const GET_DASHBOARD_TASK_FAILURE = "GET_DASHBOARD_TASK_FAILURE";


export const GET_DASH_CUSTOMER_ADDED_CONTACTS_REQUEST = "GET_DASH_CUSTOMER_ADDED_CONTACTS_REQUEST";
export const GET_DASH_CUSTOMER_ADDED_CONTACTS_SUCCESS = "GET_DASH_CUSTOMER_ADDED_CONTACTS_SUCCESS";
export const GET_DASH_CUSTOMER_ADDED_CONTACTS_FAILURE = "GET_DASH_CUSTOMER_ADDED_CONTACTS_FAILURE";

export const GET_JUMPSTART_TASK_2_LIST_REQUEST = "GET_JUMPSTART_TASK_2_LIST_REQUEST";
export const GET_JUMPSTART_TASK_2_LIST_SUCCESS = "GET_JUMPSTART_TASK_2_LIST_SUCCESS";
export const GET_JUMPSTART_TASK_2_LIST_FAILURE = "GET_JUMPSTART_TASK_2_LIST_FAILURE";

export const GET_JUMPSTART_INVESTOR_REQUEST = "GET_JUMPSTART_INVESTOR_REQUEST";
export const GET_JUMPSTART_INVESTOR_SUCCESS = "GET_JUMPSTART_INVESTOR_SUCCESS";
export const GET_JUMPSTART_INVESTOR_FAILURE = "GET_JUMPSTART_INVESTOR_FAILURE";
export const GET_JUMPSTART_INVESTOR_2_REQUEST = "GET_JUMPSTART_INVESTOR_2_REQUEST";
export const GET_JUMPSTART_INVESTOR_2_SUCCESS = "GET_JUMPSTART_INVESTOR_2_SUCCESS";
export const GET_JUMPSTART_INVESTOR_2_FAILURE = "GET_JUMPSTART_INVESTOR_2_FAILURE";
export const GET_JUMPSTART_INVESTOR_3_REQUEST = "GET_JUMPSTART_INVESTOR_3_REQUEST";
export const GET_JUMPSTART_INVESTOR_3_SUCCESS = "GET_JUMPSTART_INVESTOR_3_SUCCESS";
export const GET_JUMPSTART_INVESTOR_3_FAILURE = "GET_JUMPSTART_INVESTOR_3_FAILURE";
export const GET_JUMPSTART_INVESTOR_4_REQUEST = "GET_JUMPSTART_INVESTOR_4_REQUEST";
export const GET_JUMPSTART_INVESTOR_4_SUCCESS = "GET_JUMPSTART_INVESTOR_4_SUCCESS";
export const GET_JUMPSTART_INVESTOR_4_FAILURE = "GET_JUMPSTART_INVESTOR_4_FAILURE";

export const GET_INVSTR_HOT_COLD_WARM_REQUEST = "GET_INVSTR_HOT_COLD_WARM_REQUEST";
export const GET_INVSTR_HOT_COLD_WARM_SUCCESS = "GET_INVSTR_HOT_COLD_WARM_SUCCESS";
export const GET_INVSTR_HOT_COLD_WARM_FAILURE = "GET_INVSTR_HOT_COLD_WARM_FAILURE";

export const GET_DASH_INVESTOR_ADDED_PITCH_REQUEST = "GET_DASH_INVESTOR_ADDED_PITCH_REQUEST";
export const GET_DASH_INVESTOR_ADDED_PITCH_SUCCESS = "GET_DASH_INVESTOR_ADDED_PITCH_SUCCESS";
export const GET_DASH_INVESTOR_ADDED_PITCH_FAILURE = "GET_DASH_INVESTOR_ADDED_PITCH_FAILURE";

export const GET_DASH_INVESTOR_ADDED_CONTACTINVEST_REQUEST = "GET_DASH_INVESTOR_ADDED_CONTACTINVEST_REQUEST";
export const GET_DASH_INVESTOR_ADDED_CONTACTINVEST_SUCCESS = "GET_DASH_INVESTOR_ADDED_CONTACTINVEST_SUCCESS";
export const GET_DASH_INVESTOR_ADDED_CONTACTINVEST_FAILURE = "GET_DASH_INVESTOR_ADDED_CONTACTINVEST_FAILURE";

export const HANDLE_LEAD_QUALIFIED_DRAWER = "HANDLE_LEAD_QUALIFIED_DRAWER";
export const GET_LEADS_QUALIFIED_REQUEST = "GET_LEADS_QUALIFIED_REQUEST";
export const GET_LEADS_QUALIFIED_SUCCESS = "GET_LEADS_QUALIFIED_SUCCESS";
export const GET_LEADS_QUALIFIED_FAILURE = "GET_LEADS_QUALIFIED_FAILURE";

export const HANDLE_LEAD_ADDED_DRAWER = "HANDLE_LEAD_ADDED_DRAWER";
export const GET_LEADS_ADDED_REQUEST = "GET_LEADS_ADDED_REQUEST";
export const GET_LEADS_ADDED_SUCCESS = "GET_LEADS_ADDED_SUCCESS";
export const GET_LEADS_ADDED_FAILURE = "GET_LEADS_ADDED_FAILURE";

export const HANDLE_OPPO_ADDED_DRAWER = "HANDLE_OPPO_ADDED_DRAWER";
export const GET_OPPO_ADDED_REQUEST = "GET_OPPO_ADDED_REQUEST";
export const GET_OPPO_ADDED_SUCCESS = "GET_OPPO_ADDED_SUCCESS";
export const GET_OPPO_ADDED_FAILURE = "GET_OPPO_ADDED_FAILURE";

export const HANDLE_OPPO_CLOSED_DRAWER = "HANDLE_OPPO_CLOSED_DRAWER";
export const GET_OPPO_CLOSED_REQUEST = "GET_OPPO_CLOSED_REQUEST";
export const GET_OPPO_CLOSED_SUCCESS = "GET_OPPO_CLOSED_SUCCESS";
export const GET_OPPO_CLOSED_FAILURE = "GET_OPPO_CLOSED_FAILURE";

export const HANDLE_PITCH_QUALIFIED_DRAWER = "HANDLE_PITCH_QUALIFIED_DRAWER";
export const GET_PITCH_QUALIFIED_REQUEST = "GET_PITCH_QUALIFIED_REQUEST";
export const GET_PITCH_QUALIFIED_SUCCESS = "GET_PITCH_QUALIFIED_SUCCESS";
export const GET_PITCH_QUALIFIED_FAILURE = "GET_PITCH_QUALIFIED_FAILURE";

export const HANDLE_PITCH_ADDED_DRAWER = "HANDLE_PITCH_ADDED_DRAWER";
export const GET_PITCH_ADDED_REQUEST = "GET_PITCH_ADDED_REQUEST";
export const GET_PITCH_ADDED_SUCCESS = "GET_PITCH_ADDED_SUCCESS";
export const GET_PITCH_ADDED_FAILURE = "GET_PITCH_ADDED_FAILURE";

export const HANDLE_DEAL_ADDED_DRAWER = "HANDLE_DEAL_ADDED_DRAWER";
export const GET_DEAL_ADDED_REQUEST = "GET_DEAL_ADDED_REQUEST";
export const GET_DEAL_ADDED_SUCCESS = "GET_DEAL_ADDED_SUCCESS";
export const GET_DEAL_ADDED_FAILURE = "GET_DEAL_ADDED_FAILURE";

export const HANDLE_DEAL_CLOSED_DRAWER = "HANDLE_DEAL_CLOSED_DRAWER";
export const GET_DEAL_CLOSED_REQUEST = "GET_DEAL_CLOSED_REQUEST";
export const GET_DEAL_CLOSED_SUCCESS = "GET_DEAL_CLOSED_SUCCESS";
export const GET_DEAL_CLOSED_FAILURE = "GET_DEAL_CLOSED_FAILURE";

export const HANDLE_LEAD_HCW_DRAWER = "HANDLE_LEAD_HCW_DRAWER";
export const GET_LEAD_HOTLIST_REQUEST = "GET_LEAD_HOTLIST_REQUEST";
export const GET_LEAD_HOTLIST_SUCCESS = "GET_LEAD_HOTLIST_SUCCESS";
export const GET_LEAD_HOTLIST_FAILURE = "GET_LEAD_HOTLIST_FAILURE";
export const GET_LEAD_COLDLIST_REQUEST = "GET_LEAD_COLDLIST_REQUEST";
export const GET_LEAD_COLDLIST_SUCCESS = "GET_LEAD_COLDLIST_SUCCESS";
export const GET_LEAD_COLDLIST_FAILURE = "GET_LEAD_COLDLIST_FAILURE";
export const GET_LEAD_WARMLIST_REQUEST = "GET_LEAD_WARMLIST_REQUEST";
export const GET_LEAD_WARMLIST_SUCCESS = "GET_LEAD_WARMLIST_SUCCESS";
export const GET_LEAD_WARMLIST_FAILURE = "GET_LEAD_WARMLIST_FAILURE";

export const HANDLE_PITCH_HCW_DRAWER = "HANDLE_PITCH_HCW_DRAWER";
export const GET_PITCH_HOTLIST_REQUEST = "GET_PITCH_HOTLIST_REQUEST";
export const GET_PITCH_HOTLIST_SUCCESS = "GET_PITCH_HOTLIST_SUCCESS";
export const GET_PITCH_HOTLIST_FAILURE = "GET_PITCH_HOTLIST_FAILURE";
export const GET_PITCH_COLDLIST_REQUEST = "GET_PITCH_COLDLIST_REQUEST";
export const GET_PITCH_COLDLIST_SUCCESS = "GET_PITCH_COLDLIST_SUCCESS";
export const GET_PITCH_COLDLIST_FAILURE = "GET_PITCH_COLDLIST_FAILURE";
export const GET_PITCH_WARMLIST_REQUEST = "GET_PITCH_WARMLIST_REQUEST";
export const GET_PITCH_WARMLIST_SUCCESS = "GET_PITCH_WARMLIST_SUCCESS";
export const GET_PITCH_WARMLIST_FAILURE = "GET_PITCH_WARMLIST_FAILURE";

export const HANDLE_TASK_NAME_DRAWER = "HANDLE_TASK_NAME_DRAWER";

export const GET_TASK_NAME_REQUEST = "GET_TASK_NAME_REQUEST";
export const GET_TASK_NAME_SUCCESS = "GET_TASK_NAME_SUCCESS";
export const GET_TASK_NAME_FAILURE = "GET_TASK_NAME_FAILURE";

export const GET_DASHBOARD_COMPLETED_TASK_REQUEST = "GET_DASHBOARD_COMPLETED_TASK_REQUEST";
export const GET_DASHBOARD_COMPLETED_TASK_SUCCESS = "GET_DASHBOARD_COMPLETED_TASK_SUCCESS";
export const GET_DASHBOARD_COMPLETED_TASK_FAILURE = "GET_DASHBOARD_COMPLETED_TASK_FAILURE";
export const HANDLE_COMPLETED_TASK_TYPE_DRAWER = "HANDLE_COMPLETED_TASK_TYPE_DRAWER";
export const GET_COMPLETED_TASK_TYPE_REQUEST = "GET_COMPLETED_TASK_TYPE_REQUEST";
export const GET_COMPLETED_TASK_TYPE_SUCCESS = "GET_COMPLETED_TASK_TYPE_SUCCESS";
export const GET_COMPLETED_TASK_TYPE_FAILURE = "GET_COMPLETED_TASK_TYPE_FAILURE";

export const GET_JUMPSTART_ORDER_COUNT_REQUEST = "GET_JUMPSTART_ORDER_COUNT_REQUEST";
export const GET_JUMPSTART_ORDER_COUNT_SUCCESS = "GET_JUMPSTART_ORDER_COUNT_SUCCESS";
export const GET_JUMPSTART_ORDER_COUNT_FAILURE = "GET_JUMPSTART_ORDER_COUNT_FAILURE";

export const GET_JUMPSTART_FINANCE_DETAIL_REQUEST = "GET_JUMPSTART_FINANCE_DETAIL_REQUEST";
export const GET_JUMPSTART_FINANCE_DETAIL_SUCCESS = "GET_JUMPSTART_FINANCE_DETAIL_SUCCESS";
export const GET_JUMPSTART_FINANCE_DETAIL_FAILURE = "GET_JUMPSTART_FINANCE_DETAIL_FAILURE";




export const GET_PROSPECT_DATA_REQUEST = "GET_PROSPECT_DATA_REQUEST";
export const GET_PROSPECT_DATA_SUCCESS = "GET_PROSPECT_DATA_SUCCESS";
export const GET_PROSPECT_DATA_FAILURE = "GET_PROSPECT_DATA_FAILURE";


export const GET_CUSTOMER_CHART_REQUEST = "GET_CUSTOMER_CHART_REQUEST";
export const GET_CUSTOMER_CHART_SUCCESS = "GET_CUSTOMER_CHART_SUCCESS";
export const GET_CUSTOMER_CHART_FAILURE = "GET_CUSTOMER_CHART_FAILURE";



export const GET_ORDER_CANCEL_LIST_REQUEST = "GET_ORDER_CANCEL_LIST_REQUEST";
export const GET_ORDER_CANCEL_LIST_SUCCESS = "GET_ORDER_CANCEL_LIST_SUCCESS";
export const GET_ORDER_CANCEL_LIST_FAILURE = "GET_ORDER_CANCEL_LIST_FAILURE";


export const HANDLE_ORDER_OPEN_MODAL = "HANDLE_ORDER_OPEN_MODAL";



export const GET_ORDER_CLOSED_LIST_REQUEST = "GET_ORDER_CLOSED_LIST_REQUEST";
export const GET_ORDER_CLOSED_LIST_SUCCESS = "GET_ORDER_CLOSED_LIST_SUCCESS";
export const GET_ORDER_CLOSED_LIST_FAILURE = "GET_ORDER_CLOSED_LIST_FAILURE";


export const HANDLE_ORDER_CANCEL_MODAL = "HANDLE_ORDER_CANCEL_MODAL";



export const GET_ORDER_ADDED_LIST_REQUEST = "GET_ORDER_ADDED_LIST_REQUEST";
export const GET_ORDER_ADDED_LIST_SUCCESS = "GET_ORDER_ADDED_LIST_SUCCESS";
export const GET_ORDER_ADDED_LIST_FAILURE = "GET_ORDER_ADDED_LIST_FAILURE";

export const GET_CUSTOMER_ADDED_LIST_REQUEST = "GET_CUSTOMER_ADDED_LIST_REQUEST";
export const GET_CUSTOMER_ADDED_LIST_SUCCESS = "GET_CUSTOMER_ADDED_LIST_SUCCESS";
export const GET_CUSTOMER_ADDED_LIST_FAILURE = "GET_CUSTOMER_ADDED_LIST_FAILURE";

export const GET_CONTACT_ADDED_LIST_REQUEST = "GET_CONTACT_ADDED_LIST_REQUEST";
export const GET_CONTACT_ADDED_LIST_SUCCESS = "GET_CONTACT_ADDED_LIST_SUCCESS";
export const GET_CONTACT_ADDED_LIST_FAILURE = "GET_CONTACT_ADDED_LIST_FAILURE";

export const GET_ORDER_OPEN_LIST_REQUEST = "GET_ORDER_OPEN_LIST_REQUEST";
export const GET_ORDER_OPEN_LIST_SUCCESS = "GET_ORDER_OPEN_LIST_SUCCESS";
export const GET_ORDER_OPEN_LIST_FAILURE = "GET_ORDER_OPEN_LIST_FAILURE";



export const HANDLE_ORDER_ADDED_MODAL = "HANDLE_ORDER_ADDED_MODAL";

export const HANDLE_CUSTOMER_ADDED_MODAL = "HANDLE_CUSTOMER_ADDED_MODAL";

export const HANDLE_CONTACT_ADDED_MODAL = "HANDLE_CONTACT_ADDED_MODAL";



export const GET_PROSPECT_TABLE_DATA_REQUEST = "GET_PROSPECT_TABLE_DATA_REQUEST";
export const GET_PROSPECT_TABLE_DATA_SUCCESS = "GET_PROSPECT_TABLE_DATA_SUCCESS";
export const GET_PROSPECT_TABLE_DATA_FAILURE = "GET_PROSPECT_TABLE_DATA_FAILURE";



export const HANDLE_QUOTATION_LIFE_DRAWER = "HANDLE_QUOTATION_LIFE_DRAWER";



export const HANDLE_PROSPECT_DRAWER = "HANDLE_PROSPECT_DRAWER";


export const GET_DASHBOARD_CHART_REQUEST = "GET_DASHBOARD_CHART_REQUEST";
export const GET_DASHBOARD_CHART_SUCCESS = "GET_DASHBOARD_CHART_SUCCESS";
export const GET_DASHBOARD_CHART_FAILURE = "GET_DASHBOARD_CHART_FAILURE";




export const HANDLE_QUOTATION_YEAR_DRAWER = "HANDLE_QUOTATION_YEAR_DRAWER";







export const GET_OPEN_QUOTATION_YEAR_REQUEST = "GET_OPEN_QUOTATION_YEAR_REQUEST";
export const GET_OPEN_QUOTATION_YEAR_SUCCESS = "GET_OPEN_QUOTATION_YEAR_SUCCESS";
export const GET_OPEN_QUOTATION_YEAR_FAILURE = "GET_OPEN_QUOTATION_YEAR_FAILURE";



export const GET_DEVELOP_CHART_REQUEST = "GET_DEVELOP_CHART_REQUEST";
export const GET_DEVELOP_CHART_SUCCESS = "GET_DEVELOP_CHART_SUCCESS";
export const GET_DEVELOP_CHART_FAILURE = "GET_DEVELOP_CHART_FAILURE";




export const GET_PROSPECT_QUOTATION_REQUEST = "GET_PROSPECT_QUOTATION_REQUEST";
export const GET_PROSPECT_QUOTATION_SUCCESS = "GET_PROSPECT_QUOTATION_SUCCESS";
export const GET_PROSPECT_QUOTATION_FAILURE = "GET_PROSPECT_QUOTATION_FAILURE";

export const GET_REGION_RECORDS_REQUEST = "GET_REGION_RECORDS_REQUEST";
export const GET_REGION_RECORDS_SUCCESS = "GET_REGION_RECORDS_SUCCESS";
export const GET_REGION_RECORDS_FAILURE = "GET_REGION_RECORDS_FAILURE";


export const GET_MULTI_ORG_REGION_RECORDS_REQUEST = "GET_MULTI_ORG_REGION_RECORDS_REQUEST";
export const GET_MULTI_ORG_REGION_RECORDS_SUCCESS = "GET_MULTI_ORG_REGION_RECORDS_SUCCESS";
export const GET_MULTI_ORG_REGION_RECORDS_FAILURE = "GET_MULTI_ORG_REGION_RECORDS_FAILURE";