export const SET_PROJECTS_VIEW_TYPE = "SET_PROJECTS_VIEW_TYPE";


export const HANDLE_PROJECTS_MODAL = "HANDLE_PROJECTS_MODAL";

export const REMOVE_PROJECTS_DATA_REQUEST = "REMOVE_PROJECTS_DATA_REQUEST";
export const REMOVE_PROJECTS_DATA_SUCCESS = "REMOVE_PROJECTS_DATA_SUCCESS";
export const REMOVE_PROJECTS_DATA_FAILURE = "REMOVE_PROJECTS_DATA_FAILURE";

export const ADD_PROJECTS_DATA_REQUEST = "ADD_PROJECTS_DATA_REQUEST";
export const ADD_PROJECTS_DATA_SUCCESS = "ADD_PROJECTS_DATA_SUCCESS";
export const ADD_PROJECTS_DATA_FAILURE = "ADD_PROJECTS_DATA_FAILURE";

export const HANDLE_INVOICE_PROJECT_MODAL="HANDLE_INVOICE_PROJECT_MODAL";


export const UPDATE_PROJECTS_REQUEST =
  "UPDATE_PROJECTS_REQUEST";
export const UPDATE_PROJECTS_SUCCESS =
  "UPDATE_PROJECTS_SUCCESS";
export const UPDATE_PROJECTS_FAILURE =
  "UPDATE_PROJECTS_FAILURE";


export const SET_PROJECT_EDIT = "SET_PROJECT_EDIT";


export const HANDLE_UPDATE_PROJECTS_MODAL =
  "HANDLE_UPDATE_PROJECTS_MODAL";

export const GET_PROJECTS_DETAIL_BY_ID_REQUEST =
  "GET_PROJECTS_DETAIL_BY_ID_REQUEST";
export const GET_PROJECTS_DETAIL_BY_ID_SUCCESS =
  "GET_PROJECTS_DETAIL_BY_ID_SUCCESS";
export const GET_PROJECTS_DETAIL_BY_ID_FAILURE =
  "GET_PROJECTS_DETAIL_BY_ID_FAILURE";



  export const GET_PROJECTS_TASK_ID_REQUEST = "GET_PROJECTS_TASK_ID_REQUEST";
export const GET_PROJECTS_TASK_ID_SUCCESS = "GET_PROJECTS_TASK_ID_SUCCESS";
export const GET_PROJECTS_TASK_ID_FAILURE = "GET_PROJECTS_TASK_ID_FAILURE";


export const GET_PROJECTS_TEAM_ID_REQUEST = "GET_PROJECTS_TEAM_ID_REQUEST";
export const GET_PROJECTS_TEAM_ID_SUCCESS = "GET_PROJECTS_TEAM_ID_SUCCESS";
export const GET_PROJECTS_TEAM_ID_FAILURE = "GET_PROJECTS_TEAM_ID_FAILURE";



export const GET_PROJECTS_DATA_REQUEST = "GET_PROJECTS_DATA_REQUEST";
export const GET_PROJECTS_DATA_SUCCESS = "GET_PROJECTS_DATA_SUCCESS";
export const GET_PROJECTS_DATA_FAILURE = "GET_PROJECTS_DATA_FAILURE";

export const GET_CANDIDATE_TOTAL_BILLING_REQUEST = "GET_CANDIDATE_TOTAL_BILLING_REQUEST";
export const GET_CANDIDATE_TOTAL_BILLING_SUCCESS = "GET_CANDIDATE_TOTAL_BILLING_SUCCESS";
export const GET_CANDIDATE_TOTAL_BILLING_FAILURE = "GET_CANDIDATE_TOTAL_BILLING_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT = "CHANGE_SELECTED_TIME_INTERVAL_REPORT";

export const HANDLE_CREATE_INVOICE_DRAWER = "HANDLE_CREATE_INVOICE_DRAWER";