// Innoveren
 const login_url = "https://develop.tekorero.com/employeePortal";
 const base_url = "https://develop.tekorero.com/employeePortal/api/v1";
 const base_url2 = "https://develop.tekorero.com/Erp";
 const asses_url = "https://develop.tekorero.com/Assessment-0.0.1-SNAPSHOT"
 const sub_url = "https://develop.tekorero.com/Subscription/api/v2";


//Nubox
// const login_url = "https://hrnuboxed.azurewebsites.net";
// const base_url = "https://hrnuboxed.azurewebsites.net/api/v1";
// const base_url2 = "https://erpnuboxed.azurewebsites.net";
// const asses_url = "https://develop.tekorero.com/Assessment-0.0.1-SNAPSHOT"

// // // CT
// const login_url = "https://crm.celltechnologies.nl";
// const base_url = "https://crm.celltechnologies.nl/api/v1";
// const base_url2 = "https://crm.celltechnologies.nl/Erp";
// const asses_url = "https://crm.celltechnologies.nl/Assessment-0.0.1-SNAPSHOT";
// const sub_url = "https://crm.celltechnologies.nl/Assessment-0.0.1-SNAPSHOT";


//TestHr//hrweb( dkorekologo)
// const login_url = "https://develop.tekorero.com/testHr";
// const base_url = "https://develop.tekorero.com/testHr/api/v1";
// const base_url2 = "https://develop.tekorero.com/testErp";
// const asses_url = "https://develop.tekorero.com/Assessment-0.0.1-SNAPSHOT";
// const sub_url = "https://develop.tekorero.com/Subscription/api/v2";

export { base_url, sub_url,login_url, asses_url, base_url2 };



