export const CLEAR_REDUCER_STATE = "CLEAR_REDUCER_STATE";
export const SET_OPPORTUNITY_VIEW_TYPE = "SET_OPPORTUNITY_VIEW_TYPE";

export const SET_CURRENT_OPPORTUNITY_RECRUITMENT_DATA = "SET_CURRENT_OPPORTUNITY_RECRUITMENT_DATA";

export const HANDLE_OPPORTUNITY_MODAL = "HANDLE_OPPORTUNITY_MODAL";

export const ADD_OPPORTUNITY_REQUEST = "ADD_OPPORTUNITY_REQUEST";
export const ADD_OPPORTUNITY_SUCCESS = "ADD_OPPORTUNITY_SUCCESS";
export const ADD_OPPORTUNITY_FAILURE = "ADD_OPPORTUNITY_FAILURE";

export const HANDLE_BAR_CHART_ORDER_MODAL = "HANDLE_BAR_CHART_ORDER_MODAL";

export const SET_ADD_REQUIREMENT = "SET_ADD_REQUIREMENT";

export const HANDLE_ADD_REQUIREMENT_DETAIL_MODAL = "HANDLE_ADD_REQUIREMENT_DETAIL_MODAL";

//send Email on stage
export const SEND_EMAIL_STAGE_UPDATE_REQUEST =
  "SEND_EMAIL_STAGE_UPDATE_REQUEST";
export const SEND_EMAIL_STAGE_UPDATE_SUCCESS =
  "SEND_EMAIL_STAGE_UPDATE_SUCCESS";
export const SEND_EMAIL_STAGE_UPDATE_FAILURE =
  "SEND_EMAIL_STAGE_UPDATE_FAILURE";

export const GET_OPPORTUNITY_REQUEST = "GET_OPPORTUNITY_REQUEST";
export const GET_OPPORTUNITY_SUCCESS = "GET_OPPORTUNITY_SUCCESS";
export const GET_OPPORTUNITY_FAILURE = "GET_OPPORTUNITY_FAILURE";

export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_REQUEST";
export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_SUCCESS";
export const GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE =
  "GET_ALL_RECRUITMENT_DETAILS_BY_OPP_ID_FAILURE";

  export const HANDLE_ADD_REQUIREMENT_MODAL = "HANDLE_ADD_REQUIREMENT_MODAL";


  export const GET_SKILLS_COUNT_REQUEST = "GET_SKILLS_COUNT_REQUEST";
  export const GET_SKILLS_COUNT_SUCCESS = "GET_SKILLS_COUNT_SUCCESS";
  export const GET_SKILLS_COUNT_FAILURE = "GET_SKILLS_COUNT_FAILURE";

  export const ADD_CANDIDATE_DATE_REQUEST = "ADD_CANDIDATE_DATE_REQUEST";
  export const ADD_CANDIDATE_DATE_SUCCESS = "ADD_CANDIDATE_DATE_SUCCESS";
  export const ADD_CANDIDATE_DATE_FAILURE = "ADD_CANDIDATE_DATE_FAILURE";

  export const GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_REQUEST = "GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_REQUEST";
export const GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_SUCCESS = "GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_SUCCESS";
export const GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_FAILURE = "GET_OPPORTUNITY_INITIATIVES_SKILLS_DETAILS_FAILURE";

export const GET_CURRENCY_REQUEST = "GET_CURRENCY_REQUEST";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILURE = "GET_CURRENCY_FAILURE";

export const GET_OPPORTUNITY_DETAILS_BY_ID_REQUEST =
  "GET_OPPORTUNITY_DETAILS_BY_ID_REQUEST";
export const GET_OPPORTUNITY_DETAILS_BY_ID_SUCCESS =
  "GET_OPPORTUNITY_DETAILS_BY_ID_SUCCESS";
export const GET_OPPORTUNITY_DETAILS_BY_ID_FAILURE =
  "GET_OPPORTUNITY_DETAILS_BY_ID_FAILURE";

  export const HANDLE_REMARKS_MODAL = "HANDLE_REMARKS_MODAL";

// Add document  Modal
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
/* get Opportunity notes*/
export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_NOTES_LIST_BY_OPPORTUNITY_ID_FAILURE";

// Add Recruit Modal
export const HANDLE_RECRUIT_MODAL = "HANDLE_RECRUIT_MODAL";

export const HANDLE_CUSTOMER_MODAL = "HANDLE_CUSTOMER_MODAL";

// Add Profile modal
export const HANDLE_TAGPROFILE_MODAL = "HANDLE_TAGPROFILE_MODAL";

// Update Opportunity Modal
export const HANDLE_UPDATE_OPPORTUNITY_MODAL =
  "HANDLE_UPDATE_OPPORTUNITY_MODAL";

export const SET_OPPORTUNITY_EDIT = "SET_OPPORTUNITY_EDIT";

// Update opportunity post
export const UPDATE_OPPORTUNITY_BY_ID_REQUEST =
  "UPDATE_OPPORTUNITY_BY_ID_REQUEST";
export const UPDATE_OPPORTUNITY_BY_ID_SUCCESS =
  "UPDATE_OPPORTUNITY_BY_ID_SUCCESS";
export const UPDATE_OPPORTUNITY_BY_ID_FAILURE =
  "UPDATE_OPPORTUNITY_BY_ID_FAILURE";

// add/link opportunity document
export const ADD_OPPORTUNITY_DOCUMENT_REQUEST =
  "ADD_OPPORTUNITY_DOCUMENT_REQUEST";
export const ADD_OPPORTUNITY_DOCUMENT_SUCCESS =
  "ADD_OPPORTUNITY_DOCUMENT_SUCCESS";
export const ADD_OPPORTUNITY_DOCUMENT_FAILURE =
  "ADD_OPPORTUNITY_DOCUMENT_FAILURE";

//  get list of documents of an opportunity
export const GET_OPPORTUNITY_DOCUMENTS_REQUEST =
  "GET_OPPORTUNITY_DOCUMENTS_REQUEST";
export const GET_OPPORTUNITY_DOCUMENTS_SUCCESS =
  "GET_OPPORTUNITY_DOCUMENTS_SUCCESS";
export const GET_OPPORTUNITY_DOCUMENTS_FAILURE =
  "GET_OPPORTUNITY_DOCUMENTS_FAILURE";

//search
export const INPUT_OPPORTUNITY_SEARCH_DATA_REQUEST =
  "INPUT_OPPORTUNITY_SEARCH_DATA_REQUEST";
export const INPUT_OPPORTUNITY_SEARCH_DATA_SUCCESS =
  "INPUT_OPPORTUNITY_SEARCH_DATA_SUCCESS";
export const INPUT_OPPORTUNITY_SEARCH_DATA_FAILURE =
  "INPUT_OPPORTUNITY_SEARCH_DATA_FAILURE";

export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_REQUEST";
export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE =
  "GET_CONTACT_LIST_BY_OPPORTUNITY_ID_FAILURE";

// add recruit
export const LINK_RECRUIT_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_TO_OPPORTUNITY_FAILURE";

//get recruit
export const GET_RECRUIT_TO_OPPORTUNITY_REQUEST =
  "GET_RECRUIT_TO_OPPORTUNITY_REQUEST";
export const GET_RECRUIT_TO_OPPORTUNITY_SUCCESS =
  "GET_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const GET_RECRUIT_TO_OPPORTUNITY_FAILURE =
  "GET_RECRUIT_TO_OPPORTUNITY_FAILURE";

export const DELETE_OPPORTUNITY_DATA_REQUEST = "DELETE_OPPORTUNITY_DATA_REQUEST";
export const DELETE_OPPORTUNITY_DATA_SUCCESS = "DELETE_OPPORTUNITY_DATA_SUCCESS";
export const DELETE_OPPORTUNITY_DATA_FAILURE = "DELETE_OPPORTUNITY_DATA_FAILURE";

//delete opportunity list
export const GET_DELETED_OPPORTUNITY_REQUEST = "GET_DELETED_OPPORTUNITY_REQUEST";
export const GET_DELETED_OPPORTUNITY_SUCCESS = "GET_DELETED_OPPORTUNITY_SUCCESS";
export const GET_DELETED_OPPORTUNITY_FAILURE = "GET_DELETED_OPPORTUNITY_FAILURE";


export const GET_PERMISSIONS_LIST_REQUEST = "GET_PERMISSIONS_LIST_REQUEST";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";
export const GET_PERMISSIONS_LIST_FAILURE = "GET_PERMISSIONS_LIST_FAILURE";

//OPPORTUNITY share
export const ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST = "ADD_SHARE_OPPORTUNITY_PERMISSION_REQUEST";
export const ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS = "ADD_SHARE_OPPORTUNITY_PERMISSION_SUCCESS";
export const ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE = "ADD_SHARE_OPPORTUNITY_PERMISSION_FAILURE";


//Profile
export const LINK_PROFILE_TO_OPPORTUNITY_REQUEST =
  "LINK_PROFILE_TO_OPPORTUNITY_REQUEST";
export const LINK_PROFILE_TO_OPPORTUNITY_SUCCESS =
  "LINK_PROFILE_TO_OPPORTUNITY_SUCCESS";
export const LINK_PROFILE_TO_OPPORTUNITY_FAILURE =
  "LINK_PROFILE_TO_OPPORTUNITY_FAILURE";

  export const ADD_RECRUITMENT_PROFILE_REQUEST =
  "ADD_RECRUITMENT_PROFILE_REQUEST";
export const ADD_RECRUITMENT_PROFILE_SUCCESS =
  "ADD_RECRUITMENT_PROFILE_SUCCESS";
export const ADD_RECRUITMENT_PROFILE_FAILURE =
  "ADD_RECRUITMENT_PROFILE_FAILURE";


  export const ADD_REMARK_REQUEST = "ADD_REMARK_REQUEST";
  export const ADD_REMARK_SUCCESS = "ADD_REMARK_SUCCESS";
  export const ADD_REMARK_FAILURE = "ADD_REMARK_FAILURE";

  export const GET_REMARK_REQUEST = "GET_REMARK_REQUEST";
export const GET_REMARK_SUCCESS = "GET_REMARK_SUCCESS";
export const GET_REMARK_FAILURE = "GET_REMARK_FAILURE";


export const LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_SKILLS_TO_OPPORTUNITY_FAILURE";


  export const GET_REGION_SALES_QUOTATION_LIST_REQUEST = "GET_REGION_SALES_QUOTATION_LIST_REQUEST";
export const GET_REGION_SALES_QUOTATION_LIST_SUCCESS = "GET_REGION_SALES_QUOTATION_LIST_SUCCESS";
export const GET_REGION_SALES_QUOTATION_LIST_FAILURE = "GET_REGION_SALES_QUOTATION_LIST_FAILURE";

  export const SET_CURRENT_RECRUITMENT_DATA = "SET_CURRENT_RECRUITMENT_DATA";
  export const HANDLE_SELECT_SPONSOR_MODAL = "HANDLE_SELECT_SPONSOR_MODAL";

  export const LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_CANDIDATE_TO_OPPORTUNITY_FAILURE";


  export const LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_REQUEST =
  "LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_REQUEST";
export const LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_SUCCESS =
  "LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_FAILURE =
  "LINK_CANDIDATE_RECRUIT_TO_OPPORTUNITY_FAILURE";

  export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_STAGE_TO_OPPORTUNITY_FAILURE";

  export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST";
export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS";
export const LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE =
  "LINK_RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE";

  export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const GET_OPPORTUNITY_TEAM_RECORDS_REQUEST = "GET_OPPORTUNITY_TEAM_RECORDS_REQUEST";
export const GET_OPPORTUNITY_TEAM_RECORDS_SUCCESS = "GET_OPPORTUNITY_TEAM_RECORDS_SUCCESS";
export const GET_OPPORTUNITY_TEAM_RECORDS_FAILURE = "GET_OPPORTUNITY_TEAM_RECORDS_FAILURE";

export const SKILL_SET_LIST_REQUEST =
  "SKILL_SET_LIST_REQUEST";
export const SKILL_SET_LIST_SUCCESS =
  "SKILL_SET_LIST_SUCCESS";
export const SKILL_SET_LIST_FAILURE =
  "SKILL_SET_LIST_FAILURE";

  export const HANDLE_RECRUITER_MODAL = "HANDLE_RECRUITER_MODAL";
  export const SET_CURRENT_RECRUITER_DATA = "SET_CURRENT_RECRUITER_DATA";


  export const GET_RECRUITER_NAME_REQUEST = "GET_RECRUITER_NAME_REQUEST";
  export const GET_RECRUITER_NAME_SUCCESS = "GET_RECRUITER_NAME_SUCCESS";
  export const GET_RECRUITER_NAME_FAILURE = "GET_RECRUITER_NAME_FAILURE";

  export const GET_RECRUITER_REQUEST = "GET_RECRUITER_REQUEST";
export const GET_RECRUITER_SUCCESS = "GET_RECRUITER_SUCCESS";
export const GET_RECRUITER_FAILURE = "GET_RECRUITER_FAILURE";

export const HANDLE_UPDATE_REMARK_MODAL =
  "HANDLE_UPDATE_REMARK_MODAL";

  export const SET_REMARK_EDIT = "SET_REMARK_EDIT";

export const GET_RECRUITER_LIST_REQUEST = "GET_RECRUITER_LIST_REQUEST";
export const GET_RECRUITER_LIST_SUCCESS = "GET_RECRUITER_LIST_SUCCESS";
export const GET_RECRUITER_LIST_FAILURE = "GET_RECRUITER_LIST_FAILURE";

export const GET_SALES_LIST_REQUEST = "GET_SALES_LIST_REQUEST";
export const GET_SALES_LIST_SUCCESS = "GET_SALES_LIST_SUCCESS";
export const GET_SALES_LIST_FAILURE = "GET_SALES_LIST_FAILURE";

export const HANDLE_CANDIDATE_DATE_MODAL = "HANDLE_CANDIDATE_DATE_MODAL";

export const GET_CANDIDATE_REQUIREMENT_REQUEST = "GET_CANDIDATE_REQUIREMENT_REQUEST";
export const GET_CANDIDATE_REQUIREMENT_SUCCESS = "GET_CANDIDATE_REQUIREMENT_SUCCESS";
export const GET_CANDIDATE_REQUIREMENT_FAILURE = "GET_CANDIDATE_REQUIREMENT_FAILURE";

export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_REQUEST";
export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_SUCCESS";
export const LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE =
  "LINK_CONTACTS_TO_CHECK_OPPORTUNITY_ID_FAILURE";

  export const GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_REQUEST";
export const GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_SUCCESS";
export const GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE =
  "GET_ALL_RECRUITMENT_BY_OPP_ID_FAILURE";

  export const GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_REQUEST";
export const GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_SUCCESS";
export const GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE =
  "GET_RECRUITMENT_POSITION_BY_OPP_ID_FAILURE";

  export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_REQUEST";
export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_SUCCESS";
export const GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE =
  "GET_RECRUITMENT_POSITION_FILLED_BY_OPP_ID_FAILURE";

  export const GET_ALL_OPPORTUNITIES_REQUEST = "GET_ALL_OPPORTUNITIES_REQUEST";
  export const GET_ALL_OPPORTUNITIES_SUCCESS = "GET_ALL_OPPORTUNITIES_SUCCESS";
  export const GET_ALL_OPPORTUNITIES_FAILURE = "GET_ALL_OPPORTUNITIES_FAILURE";


  export const DELETE_REQUIREMENT_DATA_REQUEST = "DELETE_REQUIREMENT_DATA_REQUEST";
export const DELETE_REQUIREMENT_DATA_SUCCESS = "DELETE_REQUIREMENT_DATA_SUCCESS";
export const DELETE_REQUIREMENT_DATA_FAILURE = "DELETE_REQUIREMENT_DATA_FAILURE";

export const UPDATE_RECRUITMENT_REQUEST = "UPDATE_RECRUITMENT_REQUEST";
export const UPDATE_RECRUITMENT_SUCCESS = "UPDATE_RECRUITMENT_SUCCESS";
export const UPDATE_RECRUITMENT_FAILURE = "UPDATE_RECRUITMENT_FAILURE";


export const ADD_OPPORTUNITY_NOTES_REQUEST = "ADD_OPPORTUNITY_NOTES_REQUEST";
export const ADD_OPPORTUNITY_NOTES_SUCCESS = "ADD_OPPORTUNITY_NOTES_SUCCESS";
export const ADD_OPPORTUNITY_NOTES_FAILURE = "ADD_OPPORTUNITY_NOTES_FAILURE";

export const HANDLE_REACT_SPEECH_MODAL="HANDLE_REACT_SPEECH_MODAL"

export const ADD_WEBSITE_REQUEST = "ADD_WEBSITE_REQUEST";
export const ADD_WEBSITE_SUCCESS = "ADD_WEBSITE_SUCCESS";
export const ADD_WEBSITE_FAILURE = "ADD_WEBSITE_FAILURE";



  
export const REINSTATE_TOGGLE_FOR_OPPORTUNITY_REQUEST="REINSTATE_TOGGLE_FOR_OPPORTUNITY_REQUEST";
export const REINSTATE_TOGGLE_FOR_OPPORTUNITY_SUCCESS="REINSTATE_TOGGLE_FOR_OPPORTUNITY_SUCCESS";
export const REINSTATE_TOGGLE_FOR_OPPORTUNITY_FAILURE="REINSTATE_TOGGLE_FOR_OPPORTUNITY_FAILURE";

// export const PUBLISH_SUMMARY_REQUEST = "PUBLISH_SUMMARY_REQUEST";
// export const PUBLISH_SUMMARY_SUCCESS = "PUBLISH_SUMMARY_SUCCESS";
// export const PUBLISH_SUMMARY_FAILURE = "PUBLISH_SUMMARY_FAILURE";

// export const UNPUBLISH_SUMMARY_REQUEST = "UNPUBLISH_SUMMARY_REQUEST";
// export const UNPUBLISH_SUMMARY_SUCCESS = "UNPUBLISH_SUMMARY_SUCCESS";
// export const UNPUBLISH_SUMMARY_FAILURE = "UNPUBLISH_SUMMARY_FAILURE";
export const GET_RECRUIT_TO_REQUIREMENT_REQUEST="GET_RECRUIT_TO_REQUIREMENT_REQUEST";
export const GET_RECRUIT_TO_REQUIREMENT_SUCCESS="GET_RECRUIT_TO_REQUIREMENT_SUCCESS";
export const GET_RECRUIT_TO_REQUIREMENT_FAILURE="GET_RECRUIT_TO_REQUIREMENT_FAILURE";

export const GET_RECRUITER_REQUIREMENT_REQUEST = "GET_RECRUITER_REQUIREMENT_REQUEST";
export const GET_RECRUITER_REQUIREMENT_SUCCESS = "GET_RECRUITER_REQUIREMENT_SUCCESS";
export const GET_RECRUITER_REQUIREMENT_FAILURE = "GET_RECRUITER_REQUIREMENT_FAILURE";

export const GET_OPPORTUNITY_RECORD_REQUEST="GET_OPPORTUNITY_RECORD_REQUEST";
export const GET_OPPORTUNITY_RECORD_SUCCESS="GET_OPPORTUNITY_RECORD_SUCCESS";
export const GET_OPPORTUNITY_RECORD_FAILURE="GET_OPPORTUNITY_RECORD_FAILURE";

export const UPDATE_REMARK_REQUEST = "UPDATE_REAMARK_REQUEST";
export const UPDATE_REMARK_SUCCESS = "UPDATE_REMARK_SUCCESS";
export const UPDATE_REMARK_FAILURE = "UPDATE_REMARK_FAILURE";

export const UPDATE_OPPORTUNITY_OWNERSHIP_REQUEST="UPDATE_OPPORTUNITY_OWNERSHIP_REQUEST";
export const UPDATE_OPPORTUNITY_OWNERSHIP_SUCCESS="UPDATE_OPPORTUNITY_OWNERSHIP_SUCCESS";
export const UPDATE_OPPORTUNITY_OWNERSHIP_FAILURE="UPDATE_OPPORTUNITY_OWNERSHIP_FAILURE";

export const GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID_REQUEST="GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID_REQUEST";
export const GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID_SUCCESS="GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID__SUCCESS";
export const GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID_FAILURE="GET_ALL_RECRUITMENT_AVG_TIME_BY_OPPID__FAILURE";

export const INPUT_JOB_ORDER_SEARCH_DATA_REQUEST="INPUT_JOB_ORDER_SEARCH_DATA_REQUEST";
export const INPUT_JOB_ORDER_SEARCH_DATA_SUCCESS="INPUT_JOB_ORDER_SEARCH_DATA_SUCCESS";
export const INPUT_JOB_ORDER_SEARCH_DATA_FAILURE="INPUT_JOB_ORDER_SEARCH_DATA_FAILURE";

export const LINK_CLOSE_REQUIREMENT_REQUEST = "LINK_CLOSE_REQUIREMENT_REQUEST";
export const LINK_CLOSE_REQUIREMENT_SUCCESS = "LINK_CLOSE_REQUIREMENT_SUCCESS";
export const LINK_CLOSE_REQUIREMENT_FAILURE = "LINK_CLOSE_REQUIREMENT_FAILURE";


export const GET_CLOSED_REQUIREMENT_REQUEST =
  "GET_CLOSED_REQUIREMENT_REQUEST";
export const GET_CLOSED_REQUIREMENT_SUCCESS =
  "GET_CLOSED_REQUIREMENT_SUCCESS";
export const GET_CLOSED_REQUIREMENT_FAILURE =
  "GET_CLOSED_REQUIREMENT_FAILURE";

  export const HANDLE_SENTIMENT_MODAL = "HANDLE_SENTIMENT_MODAL";

  export const ADD_SENTIMENT_REQUEST = "ADD_SENTIMENT_REQUEST";
  export const ADD_SENTIMENT_SUCCESS = "ADD_SENTIMENT_SUCCESS";
  export const ADD_SENTIMENT_FAILURE = "ADD_SENTIMENT_FAILURE";

  export const LINK_OPENED_REQUIREMENT_REQUEST = "LINK_OPENED_REQUIREMENT_REQUEST";
export const LINK_OPENED_REQUIREMENT_SUCCESS = "LINK_OPENED_REQUIREMENT_SUCCESS";
export const LINK_OPENED_REQUIREMENT_FAILURE = "LINK_OPENED_REQUIREMENT_FAILURE";

export const HANDLE_MONSTER_MODAL = "HANDLE_MONSTER_MODAL";

export const GET_JOB_CATEGORY_REQUEST =
  "GET_JOB_CATEGORY_REQUEST";
export const GET_JOB_CATEGORY_SUCCESS =
  "GET_JOB_CATEGORY_SUCCESS";
export const GET_JOB_CATEGORY_FAILURE =
  "GET_JOB_CATEGORY_FAILURE";

  export const GET_JOB_BOARD_NAME_REQUEST =
  "GET_JOB_BOARD_NAME_REQUEST";
export const GET_JOB_BOARD_NAME_SUCCESS =
  "GET_JOB_BOARD_NAME_SUCCESS";
export const GET_JOB_BOARD_NAME_FAILURE =
  "GET_JOB_BOARD_NAME_FAILURE";

  export const GET_JOB_OCCUPATION_REQUEST =
  "GET_JOB_OCCUPATION_REQUEST";
export const GET_JOB_OCCUPATION_SUCCESS =
  "GET_JOB_OCCUPATION_SUCCESS";
export const GET_JOB_OCCUPATION_FAILURE =
  "GET_JOB_OCCUPATION_FAILURE";

  export const HANDLE_OPPORTUNITY_DRAWER_MODAL = "HANDLE_OPPORTUNITY_DRAWER_MODAL";

  export const CHANGE_SELECTED_CLOSURE_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_CLOSURE_TIME_INTERVAL_REPORT";


  export const GET_JOB_BOARD_INDUSTRY_REQUEST =
  "GET_JOB_BOARD_INDUSTRY_REQUEST";
export const GET_JOB_BOARD_INDUSTRY_SUCCESS =
  "GET_JOB_BOARD_INDUSTRY_SUCCESS";
export const GET_JOB_BOARD_INDUSTRY_FAILURE =
  "GET_JOB_BOARD_INDUSTRY_FAILURE";

  export const LINK_MONSTER_REQUEST =
  "LINK_MONSTER_REQUEST";
export const LINK_MONSTER_SUCCESS =
  "LINK_MONSTER_SUCCESS";
export const LINK_MONSTER_FAILURE =
  "LINK_MONSTER_FAILURE";


  export const GET_REQUIREMENT_OWNER_REQUEST = "GET_REQUIREMENT_OWNER_REQUEST";
export const GET_REQUIREMENT_OWNER_SUCCESS = "GET_REQUIREMENT_OWNER_SUCCESS";
export const GET_REQUIREMENT_OWNER_FAILURE = "GET_CANDIDATE_REQUIREMENT_FAILURE";

export const GET_RECRUIT_DELETE_REQUEST =
  "GET_RECRUIT_DELETE_REQUEST";
export const GET_RECRUIT_DELETE_SUCCESS =
  "GET_RECRUIT_DELETE_SUCCESS";
export const GET_RECRUIT_DELETE_FAILURE =
  "GET_RECRUIT_DELETE_FAILURE";


export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_REQUEST =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_REQUEST";
export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_SUCCESS";
export const UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_FAILURE =
  "UPDATE_CONTACT_ROLE_BY_OPPORTUNITY_ID_FAILURE";

  export const UPDATE_OPPORTUNITY_NAME_REQUEST = "UPDATE_OPPORTUNITY_NAME_REQUEST";
export const UPDATE_OPPORTUNITY_NAME_SUCCESS = "UPDATE_OPPORTUNITY_NAME_SUCCESS";
export const UPDATE_OPPORTUNITY_NAME_FAILURE = "UPDATE_OPPORTUNITY_NAME_FAILURE";

export const GET_INITITATIVE_SKILLS_REQUEST = "GET_INITITATIVE_SKILLS_REQUEST";
export const GET_INITITATIVE_SKILLS_SUCCESS = "GET_INITITATIVE_SKILLS_SUCCESS";
export const GET_INITITATIVE_SKILLS_FAILURE = "GET_INITITATIVE_SKILLS_FAILURE";

export const GET_INITIATIVE_REQUEST =
  "GET_INITIATIVE_REQUEST";
export const GET_INITIATIVE_SUCCESS =
  "GET_INITIATIVE_SUCCESS";
export const GET_INITIATIVE_FAILURE =
  "GET_INITIATIVE_FAILURE";


  export const GET_OPPORTUNITY_SKILLS_REQUEST = "GET_OPPORTUNITY_SKILLS_REQUEST";
export const GET_OPPORTUNITY_SKILLS_SUCCESS = "GET_OPPORTUNITY_SKILLS_SUCCESS";
export const GET_OPPORTUNITY_SKILLS_FAILURE = "GET_OPPORTUNITY_SKILLS_FAILURE";

export const HANDLE_MESSAGE_MODAL = "HANDLE_MESSAGE_MODAL";



export const ADD_OPPORTUNITY_SKILLS_REQUEST =
  "ADD_OPPORTUNITY_SKILLS_REQUEST";
export const ADD_OPPORTUNITY_SKILLS_SUCCESS =
  "ADD_OPPORTUNITY_SKILLS_SUCCESS";
export const ADD_OPPORTUNITY_SKILLS_FAILURE =
  "ADD_OPPORTUNITY_SKILLS_FAILURE";

  export const GET_DELETE_RECORDS_REQUEST = "GET_DELETE_RECORDS_REQUEST";
  export const GET_DELETE_RECORDS_SUCCESS = "GET_DELETE_RECORDS_SUCCESS";
  export const GET_DELETE_RECORDS_FAILURE = "GET_DELETE_RECORDS_FAILURE";

  export const GET_CLOSE_RECORDS_REQUEST = "GET_CLOSE_RECORDS_REQUEST";
  export const GET_CLOSE_RECORDS_SUCCESS = "GET_CLOSE_RECORDS_SUCCESS";
  export const GET_CLOSE_RECORDS_FAILURE = "GET_CLOSE_RECORDS_FAILURE";

  export const GET_ALL_RECORDS_REQUEST = "GET_ALL_RECORDS_REQUEST";
  export const GET_ALL_RECORDS_SUCCESS = "GET_ALL_RECORDS_SUCCESS";
  export const GET_ALL_RECORDS_FAILURE = "GET_ALL_RECORDS_FAILURE";


  export const GET_LOST_RECORDS_REQUEST = "GET_LOST_RECORDS_REQUEST";
  export const GET_LOST_RECORDS_SUCCESS = "GET_LOST_RECORDS_SUCCESS";
  export const GET_LOST_RECORDS_FAILURE = "GET_LOST_RECORDS_FAILURE";

  
  export const RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST =
  "RECRUIT_STATUS_TO_OPPORTUNITY_REQUEST";
export const RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS =
  "RECRUIT_STATUS_TO_OPPORTUNITY_SUCCESS";
export const RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE =
  "RECRUIT_STATUS_TO_OPPORTUNITY_FAILURE";

  export const RECRUIT_LOST_STATUS_TO_OPPORTUNITY_REQUEST =
  "RECRUIT_LOST_STATUS_TO_OPPORTUNITY_REQUEST";
export const RECRUIT_LOST_STATUS_TO_OPPORTUNITY_SUCCESS =
  "RECRUIT_LOST_STATUS_TO_OPPORTUNITY_SUCCESS";
export const RECRUIT_LOST_STATUS_TO_OPPORTUNITY_FAILURE =
  "RECRUIT_LOST_STATUS_TO_OPPORTUNITY_FAILURE";

export const LINK_CLOSE_OPPORTUNITY_REQUEST = "LINK_CLOSE_OPPORTUNITY_REQUEST";
export const LINK_CLOSE_OPPORTUNITY_SUCCESS = "LINK_CLOSE_OPPORTUNITY_SUCCESS";
export const LINK_CLOSE_OPPORTUNITY_FAILURE = "LINK_CLOSE_OPPORTUNITY_FAILURE";

export const LINK_TAG_CUSTOMER_OPPORTUNITY_REQUEST ="LINK_TAG_CUSTOMER_OPPORTUNITY_REQUEST";
export const LINK_TAG_CUSTOMER_OPPORTUNITY_SUCCESS ="LINK_TAG_CUSTOMER_OPPORTUNITY_SUCCESS";
export const LINK_TAG_CUSTOMER_OPPORTUNITY_FAILURE ="LINK_TAG_CUSTOMER_OPPORTUNITY_FAILURE";

export const GET_LOST_OPPORTUNITY_REQUEST = "GET_LOST_OPPORTUNITY_REQUEST";
export const GET_LOST_OPPORTUNITY_SUCCESS = "GET_LOST_OPPORTUNITY_SUCCESS";
export const GET_LOST_OPPORTUNITY_FAILURE = "GET_LOST_OPPORTUNITY_FAILURE";

export const GET_CLOSE_OPPORTUNITY_REQUEST = "GET_CLOSE_OPPORTUNITY_REQUEST";
export const GET_CLOSE_OPPORTUNITY_SUCCESS = "GET_CLOSE_OPPORTUNITY_SUCCESS";
export const GET_CLOSE_OPPORTUNITY_FAILURE = "GET_CLOSE_OPPORTUNITY_FAILURE";

export const GET_WORKFLOW_REQUEST =
  "GET_WORKFLOW_REQUEST";
export const GET_WORKFLOW_SUCCESS =
  "GET_WORKFLOW_SUCCESS";
export const GET_WORKFLOW_FAILURE =
  "GET_WORKFLOW_FAILURE";

  export const GET_STAGES_REQUEST =
  "GET_STAGES_REQUEST";
export const GET_STAGES_SUCCESS =
  "GET_STAGES_SUCCESS";
export const GET_STAGES_FAILURE =
  "GET_STAGES_FAILURE";

  export const DELETE_OPPORTUNITY_REQUEST = "DELETE_OPPORTUNITY_REQUEST";
export const DELETE_OPPORTUNITY_SUCCESS = "DELETE_OPPORTUNITY_SUCCESS";
export const DELETE_OPPORTUNITY_FAILURE = "DELETE_OPPORTUNITY_FAILURE";

export const GET_OPPORTUNITY_FORECAST_REQUEST = " GET_OPPORTUNITY_FORECAST_REQUEST";
export const GET_OPPORTUNITY_FORECAST_SUCCESS = "GET_OPPORTUNITY_FORECAST_SUCCESS";
export const GET_OPPORTUNITY_FORECAST_FAILURE = "GET_OPPORTUNITY_FORECAST_FAILURE";

export const SELECT_OPPORTUNITY_REQUEST = "SELECT_OPPORTUNITY_REQUEST";
export const SELECT_OPPORTUNITY_SUCCESS = "SELECT_OPPORTUNITY_SUCCESS";
export const SELECT_OPPORTUNITY_FAILURE = "SELECT_OPPORTUNITY_FAILURE";

export const LINK_LOST_OPPORTUNITY_REQUEST = "LINK_LOST_OPPORTUNITY_REQUEST";
export const LINK_LOST_OPPORTUNITY_SUCCESS = "LINK_LOST_OPPORTUNITY_SUCCESS";
export const LINK_LOST_OPPORTUNITY_FAILURE = "LINK_LOST_OPPORTUNITY_FAILURE";

export const DELETE_LOST_OPPORTUNITY_REQUEST = "DELETE_LOST_OPPORTUNITY_REQUEST";
export const DELETE_LOST_OPPORTUNITY_SUCCESS = "DELETE_LOST_OPPORTUNITY_SUCCESS";
export const DELETE_LOST_OPPORTUNITY_FAILURE = "DELETE_LOST_OPPORTUNITY_FAILURE";

export const REINSTATE_TOGGLE_FOR_LOST_REQUEST="REINSTATE_TOGGLE_FOR_LOST_REQUEST";
export const REINSTATE_TOGGLE_FOR_LOST_SUCCESS="REINSTATE_TOGGLE_FOR_LOST_SUCCESS";
export const REINSTATE_TOGGLE_FOR_LOST_FAILURE="REINSTATE_TOGGLE_FOR_LOST_FAILURE";


export const LINK_OPPORTUNITY_REQUEST =
"LINK_OPPORTUNITY_REQUEST";
export const LINK_OPPORTUNITY_SUCCESS =
"LINK_OPPORTUNITY_SUCCESS";
export const LINK_OPPORTUNITY_FAILURE =
"LINK_OPPORTUNITY_FAILURE";

export const HANDLE_RECRUITMENT_EMAIL_DRAWER_MODAL = "HANDLE_RECRUITMENT_EMAIL_DRAWER_MODAL";



export const UPDATE_REQUIREMENT_STAGE_REQUEST =
  "UPDATE_REQUIREMENT_STAGE_REQUEST";
export const UPDATE_REQUIREMENT_STAGE_SUCCESS =
  "UPDATE_REQUIREMENT_STAGE_SUCCESS";
export const UPDATE_REQUIREMENT_STAGE_FAILURE =
  "UPDATE_REQUIREMENT_STAGE_FAILURE";



  export const UPDATE_OPPORTUNITY_DRAG_STAGE_REQUEST =
  "UPDATE_UPDATE_OPPORTUNITY_DRAG_STAGE_REQUEST";
export const UPDATE_OPPORTUNITY_DRAG_STAGE_SUCCESS =
  "UPDATE_UPDATE_OPPORTUNITY_DRAG_STAGE_SUCCESS";
export const UPDATE_OPPORTUNITY_DRAG_STAGE_FAILURE =
  "UPDATE_OPPORTUNITY_DRAG_STAGE_FAILURE";


  export const GET_WON_OPPORTUNITY_REQUEST = "GET_WON_OPPORTUNITY_REQUEST";
export const GET_WON_OPPORTUNITY_SUCCESS = "GET_WON_OPPORTUNITY_SUCCESS";
export const GET_WON_OPPORTUNITY_FAILURE = "GET_WON_OPPORTUNITY_FAILURE";

export const GET_WON_RECORDS_REQUEST = "GET_WON_RECORDS_REQUEST";
export const GET_WON_RECORDS_SUCCESS = "GET_WON_RECORDS_SUCCESS";
export const GET_WON_RECORDS_FAILURE = "GET_WON_RECORDS_FAILURE";


export const GET_OPP_LINKED_STAGES_REQUEST =
"GET_OPP_LINKED_STAGES_REQUEST";
export const GET_OPP_LINKED_STAGES_SUCCESS =
"GET_OPP_LINKED_STAGES_SUCCESS";
export const GET_OPP_LINKED_STAGES_FAILURE =
"GET_OPP_LINKED_STAGES_FAILURE";

export const GET_OPP_LINKED_WORKFLOW_REQUEST =
  "GET_OPP_LINKED_WORKFLOW_REQUEST";
export const GET_OPP_LINKED_WORKFLOW_SUCCESS =
  "GET_OPP_LINKED_WORKFLOW_SUCCESS";
export const GET_OPP_LINKED_WORKFLOW_FAILURE =
  "GET_OPP_LINKED_WORKFLOW_FAILURE";

export const GET_ALL_OPPORTUNITY_DATA_REQUEST = "GET_ALL_OPPORTUNITY_DATA_REQUEST";
export const GET_ALL_OPPORTUNITY_DATA_SUCCESS = "GET_ALL_OPPORTUNITY_DATA_SUCCESS";
export const GET_ALL_OPPORTUNITY_DATA_FAILURE = "GET_ALL_OPPORTUNITY_DATA_FAILURE";

export const HANDLE_OPPORTUNITY_NOTES_DRAWER_MODAL = "HANDLE_OPPORTUNITY_NOTES_DRAWER_MODAL";

export const GET_ALL_OPPORTUNITY_REQUEST="GET_ALL_OPPORTUNITY_REQUEST";
export const GET_ALL_OPPORTUNITY_SUCCESS="GET_ALL_OPPORTUNITY_SUCCESS";
export const GET_ALL_OPPORTUNITY_FAILURE="GET_ALL_OPPORTUNITY_FAILURE";

export const GET_TEAM_OPPORTUNITY_REQUEST="GET_TEAM_OPPORTUNITY_REQUEST";
export const GET_TEAM_OPPORTUNITY_SUCCESS="GET_TEAM_OPPORTUNITY_SUCCESS";
export const GET_TEAM_OPPORTUNITY_FAILURE="GET_TEAM_OPPORTUNITY_FAILURE";

export const EMPTY_OPPORTUNITY_LIST="EMPTY_OPPORTUNITY_LIST";


export const LINK_OPPORTUNITY_CONTRACT_REQUEST =
  "LINK_OPPORTUNITY_CONTRACT_REQUEST";
export const LINK_OPPORTUNITY_CONTRACT_SUCCESS =
  "LINK_OPPORTUNITY_CONTRACT_SUCCESS";
export const LINK_OPPORTUNITY_CONTRACT_FAILURE =
  "LINK_OPPORTUNITY_CONTRACT_FAILURE";

  export const HANDLE_OPPOORTUNITY_ROW_EMAIL_MODAL = "HANDLE_OPPOORTUNITY_ROW_EMAIL_MODAL";

export const LINK_PRODUCT_WITH_OPPORTUNITY_REQUEST="LINK_PRODUCT_WITH_OPPORTUNITY_REQUEST";
export const LINK_PRODUCT_WITH_OPPORTUNITY_SUCCESS="LINK_PRODUCT_WITH_OPPORTUNITY_SUCCESS";
export const LINK_PRODUCT_WITH_OPPORTUNITY_FAILURE="LINK_PRODUCT_WITH_OPPORTUNITY_FAILURE";
export const GET_LINK_PRODUCT_WITH_OPPORTUNITY_REQUEST="GET_LINK_PRODUCT_WITH_OPPORTUNITY_REQUEST";
export const GET_LINK_PRODUCT_WITH_OPPORTUNITY_SUCCESS="GET_LINK_PRODUCT_WITH_OPPORTUNITY_SUCCESS";
export const GET_LINK_PRODUCT_WITH_OPPORTUNITY_FAILURE="GET_LINK_PRODUCT_WITH_OPPORTUNITY_FAILURE";

export const GET_ALL_CATALOGUE_SEARCH_REQUEST = "GET_ALL_CATALOGUE_SEARCH_REQUEST";
export const GET_ALL_CATALOGUE_SEARCH_SUCCESS = "GET_ALL_CATALOGUE_SEARCH_SUCCESS";
export const GET_ALL_CATALOGUE_SEARCH_FAILURE = "GET_ALL_CATALOGUE_SEARCH_FAILURE";

export const HANDLE_CLAER_SEARCHED_DATA_OPPORTUNITY = "HANDLE_CLAER_SEARCHED_DATA_OPPORTUNITY";
