export const ADD_SLA_REQUEST = "ADD_SLA_REQUEST";
export const ADD_SLA_SUCCESS = "ADD_SLA_SUCCESS";
export const ADD_SLA_FAILURE = "ADD_SLA_FAILURE";

export const HANDLE_LEAVES_MODAL = "HANDLE_LEAVES_MODAL";

export const GET_SLA_REQUEST = "GET_SLA_REQUEST";
export const GET_SLA_SUCCESS = "GET_SLA_SUCCESS";
export const GET_SLA_FAILURE = "GET_SLA_FAILURE";

export const HANDLE_TEMPLATE_VIEW_MODAL = "HANDLE_TEMPLATE_VIEW_MODAL";

export const SET_CURRENT_NOTIFICATION = "SET_CURRENT_NOTIFICATION";

export const ADD_MATRIX_REQUEST = "ADD_MATRIX_REQUEST";
export const ADD_MATRIX_SUCCESS = "ADD_MATRIX_SUCCESS";
export const ADD_MATRIX_FAILURE = "ADD_MATRIX_FAILURE";

export const GET_MATRIX_REQUEST = "GET_MATRIX_REQUEST";
export const GET_MATRIX_SUCCESS = "GET_MATRIX_SUCCESS";
export const GET_MATRIX_FAILURE = "GET_MATRIX_FAILURE";

export const HANDLE_TEMPLATE_MODAL = "HANDLE_TEMPLATE_MODAL";
export const HANDLE_TEMPLATE_NOTIFICATION_MODAL="HANDLE_TEMPLATE_NOTIFICATION_MODAL";

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const ADD_NOTIFICATION_TEMPLATE_REQUEST =
  "ADD_NOTIFICATION_TEMPLATE_REQUEST";
export const ADD_NOTIFICATION_TEMPLATE_SUCCESS =
  "ADD_NOTIFICATION_TEMPLATE_SUCCESS";
export const ADD_NOTIFICATION_TEMPLATE_FAILURE =
  "ADD_NOTIFICATION_TEMPLATE_FAILURE";

export const GET_NOTIFICATION_TEMPLATE_REQUEST =
  "GET_NOTIFICATION_TEMPLATE_REQUEST";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";
export const GET_NOTIFICATION_TEMPLATE_FAILURE =
  "GET_NOTIFICATION_TEMPLATE_FAILURE";

export const UPDATE_NOTIFICATION_TEMPLATE_REQUEST =
  "UPDATE_NOTIFICATION_TEMPLATE_REQUEST";
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "UPDATE_NOTIFICATION_TEMPLATE_SUCCESS";
export const UPDATE_NOTIFICATION_TEMPLATE_FAILURE =
  "UPDATE_NOTIFICATION_TEMPLATE_FAILURE";

export const SET_CURRENT_EMAIL = "SET_CURRENT_EMAIL";

export const ADD_SEARCH_TAB_REQUEST = "ADD_SEARCH_TAB_REQUEST";
export const ADD_SEARCH_TAB_SUCCESS = "ADD_SEARCH_TAB_SUCCESS";
export const ADD_SEARCH_TAB_FAILURE = "ADD_SEARCH_TAB_FAILURE";

export const GET_SEARCH_TAB_REQUEST = "GET_SEARCH_TAB_REQUEST";
export const GET_SEARCH_TAB_SUCCESS = "GET_SEARCH_TAB_SUCCESS";
export const GET_SEARCH_TAB_FAILURE = "GET_SEARCH_TAB_FAILURE";

//RecruitPro For Email
export const ADD_RECRUITPRO_FOR_EMAIL_REQUEST =
  "ADD_RECRUITPRO_FOR_EMAIL_REQUEST";
export const ADD_RECRUITPRO_FOR_EMAIL_SUCCESS =
  "ADD_RECRUITPRO_FOR_EMAIL_SUCCESS";
export const ADD_RECRUITPRO_FOR_EMAIL_FAILURE =
  "ADD_RECRUITPRO_FOR_EMAIL_FAILURE";

  export const GET_RECRUITPRO_FOR_EMAIL_REQUEST =
  "GET_RECRUITPRO_FOR_EMAIL_REQUEST";
export const GET_RECRUITPRO_FOR_EMAIL_SUCCESS =
  "GET_RECRUITPRO_FOR_EMAIL_SUCCESS";
export const GET_RECRUITPRO_FOR_EMAIL_FAILURE =
  "GET_RECRUITPRO_FOR_EMAIL_FAILURE";

//   export const UPDATE_SEQUENCE_REQUEST =
//   "UPDATE_SEQUENCE_REQUEST";
// export const UPDATE_SEQUENCE_SUCCESS =
//   "UPDATE_SEQUENCE_SUCCESS";
// export const UPDATE_SEQUENCE_FAILURE =
//   "UPDATE_SEQUENCE_FAILURE";

  export const GET_ACTION_TABLE_REQUEST =
  "GET_ACTION_TABLE_REQUEST";
export const GET_ACTION_TABLE_SUCCESS =
  "GET_ACTION_TABLE_SUCCESS";
export const GET_ACTION_TABLE_FAILURE =
  "GET_ACTION_TABLE_FAILURE";