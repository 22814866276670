export const SET_COURSE_VIEW_TYPE = "SET_COURSE_VIEW_TYPE";

export const HANDLE_COURSE_MODAL = "HANDLE_COURSE_MODAL";

export const ADD_COURSE_REQUEST = "ADD_COURSE_REQUEST";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILURE = "ADD_COURSE_FAILURE";


export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";


export const GET_COURSE_DETAILS_BY_ID_REQUEST =
  "GET_COURSE_DETAILS_BY_ID_REQUEST";
export const GET_COURSE_DETAILS_BY_ID_SUCCESS =
  "GET_COURSE_DETAILS_BY_ID_SUCCESS";
export const GET_COURSE_DETAILS_BY_ID_FAILURE =
  "GET_COURSE_DETAILS_BY_ID_FAILURE";



  export const ADD_TOPIC_REQUEST =
  "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_SUCCESS =
  "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILURE =
  "ADD_TOPIC_FAILURE";


  export const GET_TOPICS_REQUEST =
  "GET_TOPICS_REQUEST";
export const GET_TOPICS_SUCCESS =
  "GET_TOPICS_SUCCESS";
export const GET_TOPICS_FAILURE =
  "GET_TOPICS_FAILURE";


  export const SET_COURSE_EDIT = "SET_COURSE_EDIT";


  export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILURE = "UPDATE_COURSE_FAILURE";

export const HANDLE_EDIT_DRAWER_MODAL = "HANDLE_EDIT_DRAWER_MODAL";

  export const HANDLE_TEST_DRAWER_MODAL = "HANDLE_TEST_DRAWER_MODAL";
