export const HANDLE_CUSTOMER_MODAL = "HANDLE_CUSTOMER_MODAL";

export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";




export const ADD_CUSTOMER_IMPORT_FORM_REQUEST = "ADD_CUSTOMER_IMPORT_FORM_REQUEST";
export const ADD_CUSTOMER_IMPORT_FORM_SUCCESS = "ADD_CUSTOMER_IMPORT_FORM_SUCCESS";
export const ADD_CUSTOMER_IMPORT_FORM_FAILURE = "ADD_CUSTOMER_IMPORT_FORM_FAILURE";






export const HANDLE_CUSTOMER_IMPORT_MODAL = "HANDLE_CUSTOMER_IMPORT_MODAL";



export const EMPTY_CLEARBIT_TABLE = "EMPTY_CLEARBIT_TABLE";

export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const GET_CUSTOMERS_FILTER_DATA_REQUEST = "GET_CUSTOMERS_FILTER_DATA_REQUEST";
export const GET_CUSTOMERS_FILTER_DATA_SUCCESS = "GET_CUSTOMERS_FILTER_DATA_SUCCESS";
export const GET_CUSTOMERS_FILTER_DATA_FAILURE = "GET_CUSTOMERS_FILTER_DATA_FAILURE";


export const ADD_LOCATION_DETAILS_REQUEST = "ADD_LOCATION_DETAILS_REQUEST";
export const ADD_LOCATION_DETAILS_SUCCESS = "ADD_LOCATION_DETAILS_SUCCESS";
export const ADD_LOCATION_DETAILS_FAILURE = "ADD_LOCATION_DETAILS_FAILURE";


export const HANDLE_CUSTOMER_PROJECT_MODAL =
  "HANDLE_CUSTOMER_PROJECT_MODAL";


  export const LINKED_PROJECT_TASK_REQUEST = "LINKED_PROJECT_TASK_REQUEST";
export const LINKED_PROJECT_TASK_SUCCESS = "LINKED_PROJECT_TASK_SUCCESS";
export const LINKED_PROJECT_TASK_FAILURE = "LINKED_PROJECT_TASK_FAILURE";


export const GET_CUSTOMER_PROJECT_REQUEST = "GET_CUSTOMER_PROJECT_REQUEST";
export const GET_CUSTOMER_PROJECT_SUCCESS = "GET_CUSTOMER_PROJECT_SUCCESS";
export const GET_CUSTOMER_PROJECT_FAILURE = "GET_CUSTOMER_PROJECT_FAILURE";




export const GET_CUSTOMERS_DATA_REQUEST = "GET_CUSTOMERS_DATA_REQUEST";
export const GET_CUSTOMERS_DATA_SUCCESS = "GET_CUSTOMERS_DATA_SUCCESS";
export const GET_CUSTOMERS_DATA_FAILURE = "GET_CUSTOMERS_DATA_FAILURE";

export const GET_INVESTOR_DATA_REQUEST = "GET_INVESTOR_DATA_REQUEST";
export const GET_INVESTOR_DATA_SUCCESS = "GET_INVESTOR_DATA_SUCCESS";
export const GET_INVESTOR_DATA_FAILURE = "GET_INVESTOR_DATA_FAILURE";


// export const GET_CUSTOMERS_LIST_REQUEST = "GET_CUSTOMERS_LIST_REQUEST";
// export const GET_CUSTOMERS_LIST_SUCCESS = "GET_CUSTOMERS_LIST_SUCCESS";
// export const GET_CUSTOMERS_LIST_FAILURE = "GET_CUSTOMERS_LIST_FAILURE";

export const ADD_CUSTOMER_NOTES_REQUEST = "ADD_CUSTOMER_NOTES_REQUEST";
export const ADD_CUSTOMER_NOTES_SUCCESS = "ADD_CUSTOMER_NOTES_SUCCESS";
export const ADD_CUSTOMER_NOTES_FAILURE = "ADD_CUSTOMER_NOTES_FAILURE";

export const SET_CLEARBIT_DATA = "SET_CLEARBIT_DATA";

export const EMPTY_CUSTOMER_TABLE = "EMPTY_CUSTOMER_TABLE";

export const CHANGE_SELECTED_TODO_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TODO_TIME_INTERVAL_REPORT";

export const HANDLE_CUSTOMER_REACT_SPEECH_MODAL = "HANDLE_CUSTOMER_REACT_SPEECH_MODAL"

export const GET_CUSTOMER_BY_ID_REQUEST = "GET_CUSTOMER_BY_ID_REQUEST";
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS";
export const GET_CUSTOMER_BY_ID_FAILURE = "GET_CUSTOMER_BY_ID_FAILURE";

export const GET_CUSTOMER_DETAILS_BY_ID_REQUEST =
  "GET_CUSTOMER_DETAILS_BY_ID_REQUEST";
export const GET_CUSTOMER_DETAILS_BY_ID_SUCCESS =
  "GET_CUSTOMER_DETAILS_BY_ID_SUCCESS";
export const GET_CUSTOMER_DETAILS_BY_ID_FAILURE =
  "GET_CUSTOMER_DETAILS_BY_ID_FAILURE";

export const SET_CUSTOMER_VIEW_TYPE = "SET_CUSTOMER_VIEW_TYPE";

//document
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

/*Customer notes*/
export const GET_NOTES_LIST_BY_CUSTOMER_ID_REQUEST =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_REQUEST";
export const GET_NOTES_LIST_BY_CUSTOMER_ID_SUCCESS =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_SUCCESS";
export const GET_NOTES_LIST_BY_CUSTOMER_ID_FAILURE =
  "GET_NOTES_LIST_BY_CUSTOMER_ID_FAILURE";

  export const SET_CUSTOMER_CARD_EDIT = "SET_CUSTOMER_CARD_EDIT";


export const HANDLE_UPDATE_CUSTOMER_MODAL = "HANDLE_UPDATE_CUSTOMER_MODAL";

export const HANDLE_UPDATE_CUSTOMER_INITIATIVE_MODAL = "HANDLE_UPDATE_CUSTOMER_INITIATIVE_MODAL";

export const SET_CUSTOMER_EDIT = "SET_CUSTOMER_EDIT";

export const UPDATE_CUSTOMER_BY_ID_REQUEST = "UPDATE_CUSTOMER_BY_ID_REQUEST";
export const UPDATE_CUSTOMER_BY_ID_SUCCESS = "UPDATE_CUSTOMER_BY_ID_SUCCESS";
export const UPDATE_CUSTOMER_BY_ID_FAILURE = "UPDATE_CUSTOMER_BY_ID_FAILURE";

export const ADD_CUSTOMER_DOCUMENT_REQUEST = "ADD_CUSTOMER_DOCUMENT_REQUEST";
export const ADD_CUSTOMER_DOCUMENT_SUCCESS = "ADD_CUSTOMER_DOCUMENT_SUCCESS";
export const ADD_CUSTOMER_DOCUMENT_FAILURE = "ADD_CUSTOMER_DOCUMENT_FAILURE";

export const ADD_HOSPITAL_DOCUMENT_REQUEST = "ADD_HOSPITAL_DOCUMENT_REQUEST";
export const ADD_HOSPITAL_DOCUMENT_SUCCESS = "ADD_HOSPITAL_DOCUMENT_SUCCESS";
export const ADD_HOSPITAL_DOCUMENT_FAILURE = "ADD_HOSPITAL_DOCUMENT_FAILURE";

export const GET_CUSTOMER_DOCUMENTS_REQUEST = "GET_CUSTOMER_DOCUMENTS_REQUEST";
export const GET_CUSTOMER_DOCUMENTS_SUCCESS = "GET_CUSTOMER_DOCUMENTS_SUCCESS";
export const GET_CUSTOMER_DOCUMENTS_FAILURE = "GET_CUSTOMER_DOCUMENTS_FAILURE";

export const GET_SELECT_DROP_REQUEST = "GET_SELECT_DROP_REQUEST";
export const GET_SELECT_DROP_SUCCESS = "GET_SELECT_DROP_SUCCESS";
export const GET_SELECT_DROP_FAILURE = "GET_SELECT_DROP_FAILURE";

//add opportunity
// export const ADD_CUSTOMER_OPPORTUNITY_REQUEST =
//   "ADD_CUSTOMER_OPPORTUNITY_REQUEST";
// export const ADD_CUSTOMER_OPPORTUNITY_SUCCESS =
//   "ADD_CUSTOMER_OPPORTUNITY_SUCCESS";
// export const ADD_CUSTOMER_OPPORTUNITY_FAILURE =
//   "ADD_CUSTOMER_OPPORTUNITY_FAILURE";

// get opportunity

export const GET_CUSTOMER_OPPORTUNITY_REQUEST =
  "GET_CUSTOMER_OPPORTUNITY_REQUEST";
export const GET_CUSTOMER_OPPORTUNITY_SUCCESS =
  "GET_CUSTOMER_OPPORTUNITY_SUCCESS";
export const GET_CUSTOMER_OPPORTUNITY_FAILURE =
  "GET_CUSTOMER_OPPORTUNITY_FAILURE";

export const GET_CUSTOMER_CONTACT_REQUEST = "GET_CUSTOMER_CONTACT_REQUEST";
export const GET_CUSTOMER_CONTACT_SUCCESS = "GET_CUSTOMER_CONTACT_SUCCESS";
export const GET_CUSTOMER_CONTACT_FAILURE = "GET_CUSTOMER_CONTACT_FAILURE";
//add contact
export const ADD_CUSTOMER_CONTACT_REQUEST = "ADD_CUSTOMER_CONTACT_REQUEST";
export const ADD_CUSTOMER_CONTACT_SUCCESS = "ADD_CUSTOMER_CONTACT_SUCCESS";
export const ADD_CUSTOMER_CONTACT_FAILURE = "ADD_CUSTOMER_CONTACT_FAILURE";

export const HANDLE_CUSTOMER_CONTACT_MODAL = "HANDLE_CUSTOMER_CONTACT_MODAL";

//add customer opportunity
export const ADD_CUSTOMER_OPPORTUNITY_REQUEST =
  "ADD_CUSTOMER_OPPORTUNITY_REQUEST";
export const ADD_CUSTOMER_OPPORTUNITY_SUCCESS =
  "ADD_CUSTOMER_OPPORTUNITY_SUCCESS";
export const ADD_CUSTOMER_OPPORTUNITY_FAILURE =
  "ADD_CUSTOMER_OPPORTUNITY_FAILURE";
//handle customer opportunity modal
export const HANDLE_CUSTOMER_OPPORTUNITY_MODAL =
  "HANDLE_CUSTOMER_OPPORTUNITY_MODAL";

  export const HANDLE_CUSTOMER_PROJECT_DRAWER =
  "HANDLE_CUSTOMER_PROJECT_DRAWER";

export const HANDLE_UPDATE_CUSTOMER_OPPORTUNITY_MODAL =
  "HANDLE_UPDATE_CUSTOMER_OPPORTUNITY_MODAL";

  export const HANDLE_UPDATE_CUSTOMER_CONTACT_MODAL =
  "HANDLE_UPDATE_CUSTOMER_CONTACT_MODAL";

//search
export const INPUT_CUSTOMER_SEARCH_DATA_REQUEST =
  "INPUT_CUSTOMER_SEARCH_DATA_REQUEST";
export const INPUT_CUSTOMER_SEARCH_DATA_SUCCESS =
  "INPUT_CUSTOMER_SEARCH_DATA_SUCCESS";
export const INPUT_CUSTOMER_SEARCH_DATA_FAILURE =
  "INPUT_CUSTOMER_SEARCH_DATA_FAILURE";

//contact share of customer
export const ADD_SHARE_CUSTOMER_PERMISSION_REQUEST = "ADD_SHARE_CUSTOMER_PERMISSION_REQUEST";
export const ADD_SHARE_CUSTOMER_PERMISSION_SUCCESS = "ADD_SHARE_CUSTOMER_PERMISSION_SUCCESS";
export const ADD_SHARE_CUSTOMER_PERMISSION_FAILURE = "ADD_SHARE_CUSTOMER_PERMISSION_FAILURE";

export const GET_PERMISSIONS_LIST_CUSTOMER_REQUEST = "GET_PERMISSIONS_LIST_CUSTOMER_REQUEST";
export const GET_PERMISSIONS_LIST_CUSTOMER_SUCCESS = "GET_PERMISSIONS_LIST_CUSTOMER_SUCCESS";
export const GET_PERMISSIONS_LIST_CUSTOMER_FAILURE = "GET_PERMISSIONS_LIST_CUSTOMER_FAILURE";

export const GET_ALL_CUSTOMERS_REQUEST = "GET_ALL_CUSTOMERS_REQUEST";
export const GET_ALL_CUSTOMERS_SUCCESS = "GET_ALL_CUSTOMERS_SUCCESS";
export const GET_ALL_CUSTOMERS_FAILURE = "GET_ALL_CUSTOMERS_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const GET_CUSTOMER_TEAM_RECORDS_REQUEST = "GET_CUSTOMER_TEAM_RECORDS_REQUEST";
export const GET_CUSTOMER_TEAM_RECORDS_SUCCESS = "GET_CUSTOMER_TEAM_RECORDS_SUCCESS";
export const GET_CUSTOMER_TEAM_RECORDS_FAILURE = "GET_CUSTOMER_TEAM_RECORDS_FAILURE";

// Add Recruit Modal
export const HANDLE_RECRUIT_MODAL = "HANDLE_RECRUIT_MODAL";

// Add Profile modal
export const HANDLE_TAGPROFILE_MODAL = "HANDLE_TAGPROFILE_MODAL";

export const LINK_RECRUIT_TO_CUSTOMER_REQUEST = "LINK_RECRUIT_TO_CUSTOMER_REQUEST";
export const LINK_RECRUIT_TO_CUSTOMER_SUCCESS = "LINK_RECRUIT_TO_CUSTOMER_SUCCESS";
export const LINK_RECRUIT_TO_CUSTOMER_FAILURE = "LINK_RECRUIT_TO_CUSTOMER_FAILURE";

export const GET_RECRUIT_TO_CUSTOMER_REQUEST = "GET_RECRUIT_TO_CUSTOMER_REQUEST";
export const GET_RECRUIT_TO_CUSTOMER_SUCCESS = "GET_RECRUIT_TO_CUSTOMER_SUCCESS";
export const GET_RECRUIT_TO_CUSTOMER_FAILURE = "GET_RECRUIT_TO_CUSTOMER_FAILURE";

export const LINK_PROFILE_TO_CUSTOMER_REQUEST = "LINK_PROFILE_TO_CUSTOMER_REQUEST";
export const LINK_PROFILE_TO_CUSTOMER_SUCCESS = "LINK_PROFILE_TO_CUSTOMER_SUCCESS";
export const LINK_PROFILE_TO_CUSTOMER_FAILURE = "LINK_PROFILE_TO_CUSTOMER_FAILURE";

export const ADD_RECRUITMENT_PROFILE_REQUEST =
  "ADD_RECRUITMENT_PROFILE_REQUEST";
export const ADD_RECRUITMENT_PROFILE_SUCCESS =
  "ADD_RECRUITMENT_PROFILE_SUCCESS";
export const ADD_RECRUITMENT_PROFILE_FAILURE =
  "ADD_RECRUITMENT_PROFILE_FAILURE";

export const SET_CURRENT_RECRUITMENT_DATA = "SET_CURRENT_RECRUITMENT_DATA";
export const HANDLE_SELECT_SPONSOR_MODAL = "HANDLE_SELECT_SPONSOR_MODAL";

export const UPDATE_CUSTOMER_OPPORTUNITY_REQUEST = "UPDATE_CUSTOMER_OPPORTUNITY_REQUEST";
export const UPDATE_CUSTOMER_OPPORTUNITY_SUCCESS = "UPDATE_CUSTOMER_OPPORTUNITY_SUCCESS";
export const UPDATE_CUSTOMER_OPPORTUNITY_FAILURE = "UPDATE_CUSTOMER_OPPORTUNITY_FAILURE";

export const SET_EDIT_CUSTOMER_OPPORTUNITY = "SET_EDIT_CUSTOMER_OPPORTUNITY";
export const SET_EDIT_CUSTOMER_INITIATIVE = "SET_EDIT_CUSTOMER_INITIATIVE";

// Add File Recruit Modal
export const HANDLE_FILE_RECRUIT_MODAL = "HANDLE_FILE_RECRUIT_MODAL";

export const ADD_ATTENDENCE_REQUEST = "ADD_ATTENDENCE_REQUEST";
export const ADD_ATTENDENCE_SUCCESS = "ADD_ATTENDENCE_SUCCESS";
export const ADD_ATTENDENCE_FAILURE = "ADD_ATTENDENCE_FAILURE";

export const PUT_CUSTO_CONTACT_TOGGLE_REQUEST = "PUT_CUSTO_CONTACT_TOGGLE_REQUEST";
export const PUT_CUSTO_CONTACT_TOGGLE_SUCCESS = "PUT_CUSTO_CONTACT_TOGGLE_SUCCESS";
export const PUT_CUSTO_CONTACT_TOGGLE_FAILURE = "PUT_CUSTO_CONTACT_TOGGLE_FAILURE";

export const GET_CUSTOMERS_BY_CATEGORY_REQUEST = "GET_CUSTOMERS_BY_CATEGORY_REQUEST";
export const GET_CUSTOMERS_BY_CATEGORY_SUCCESS = "GET_CUSTOMERS_BY_CATEGORY_SUCCESS";
export const GET_CUSTOMERS_BY_CATEGORY_FAILURE = "GET_CUSTOMERS_BY_CATEGORY_FAILURE";

export const GET_CATEGORY_RECORDS_REQUEST = "GET_CATEGORY_RECORDS_REQUEST";
export const GET_CATEGORY_RECORDS_SUCCESS = "GET_CATEGORY_RECORDS_SUCCESS";
export const GET_CATEGORY_RECORDS_BLUE_SUCCESS = "GET_CATEGORY_RECORDS_BLUE_SUCCESS"
export const GET_CATEGORY_RECORDS_FAILURE = "GET_CATEGORY_RECORDS_FAILURE";

//get
export const GET_COMMERCIALS_BY_CUSTOMER_ID_REQUEST =
  "GET_COMMERCIALS_BY_CUSTOMER_ID_REQUEST";
export const GET_COMMERCIALS_BY_CUSTOMER_ID_SUCCESS =
  "GET_COMMERCIALS_BY_CUSTOMER_ID_SUCCESS";
export const GET_COMMERCIALS_BY_CUSTOMER_ID_FAILURE =
  "GET_COMMERCIALS_BY_CUSTOMER_ID_FAILURE";

//ADD
export const ADD_COMMERCIALS_BY_CUSTOMER_ID_REQUEST =
  "ADD_COMMERCIALS_BY_CUSTOMER_ID_REQUEST";
export const ADD_COMMERCIALS_BY_CUSTOMER_ID_SUCCESS =
  "ADD_COMMERCIALS_BY_CUSTOMER_ID_SUCCESS";
export const ADD_COMMERCIALS_BY_CUSTOMER_ID_FAILURE =
  "ADD_COMMERCIALS_BY_CUSTOMER_ID_FAILURE";
export const HANDLE_INVOICE_MODAL = "HANDLE_INVOICE_MODAL";

export const HANDLE_CALL_ACTIVITY_MODAL = "HANDLE_CALL_ACTIVITY_MODAL";

//ADD INVOICE
export const ADD_INVOICE_BY_CUSTOMER_ID_REQUEST = "ADD_INVOICE_BY_CUSTOMER_ID_REQUEST";
export const ADD_INVOICE_BY_CUSTOMER_ID_SUCCESS = "ADD_INVOICE_BY_CUSTOMER_ID_SUCCESS";
export const ADD_INVOICE_BY_CUSTOMER_ID_FAILURE = "ADD_INVOICE_BY_CUSTOMER_ID_FAILURE";
//GET INVOICE
export const GET_INVOICE_BY_CUSTOMER_ID_REQUEST = "GET_INVOICE_BY_CUSTOMER_ID_REQUEST";
export const GET_INVOICE_BY_CUSTOMER_ID_SUCCESS = "GET_INVOICE_BY_CUSTOMER_ID_SUCCESS";
export const GET_INVOICE_BY_CUSTOMER_ID_FAILURE = "GET_INVOICE_BY_CUSTOMER_ID_FAILURE";


export const UPDATE_CUSTOMER_OWNERSHIP_REQUEST = "UPDATE_CUSTOMER_OWNERSHIP_REQUEST";
export const UPDATE_CUSTOMER_OWNERSHIP_SUCCESS = "UPDATE_CUSTOMER_OWNERSHIP_SUCCESS";
export const UPDATE_CUSTOMER_OWNERSHIP_FAILURE = "UPDATE_CUSTOMER_OWNERSHIP_FAILURE";

export const HANDLE_CUSTOMER_DRAWER_MODAL = "HANDLE_CUSTOMER_DRAWER_MODAL";

export const HANDLE_UPDATE_CUSTOMER_DRAWER_MODAL = "HANDLE_UPDATE_CUSTOMER_DRAWER_MODAL";

export const GET_CUSTOMER_KEY_SKILL_REQUEST =
  "GET_CUSTOMER_KEY_SKILL_REQUEST";
export const GET_CUSTOMER_KEY_SKILL_SUCCESS =
  "GET_CUSTOMER_KEY_SKILL_SUCCESS";
export const GET_CUSTOMER_KEY_SKILL_FAILURE =
  "GET_CUSTOMER_KEY_SKILL_FAILURE";

  export const SET_EDIT_CUSTOMER_CONTACT = "SET_EDIT_CUSTOMER_CONTACT";

  export const UPDATE_CUSTOMER_CONTACT_BY_ID_REQUEST = "UPDATE_CUSTOMER_CONTACT_BY_ID_REQUEST";
export const UPDATE_CUSTOMER_CONTACT_BY_ID_SUCCESS = "UPDATE_CUSTOMER_CONTACT_BY_ID_SUCCESS";
export const UPDATE_CUSTOMER_CONTACT_BY_ID_FAILURE = "UPDATE_CUSTOMER_CONTACT_BY_ID_FAILURE";

export const ADD_INITIATIVE_BY_CUSTOMER_ID_REQUEST =
  "ADD_INITIATIVE_BY_CUSTOMER_ID_REQUEST";
export const ADD_INITIATIVE_BY_CUSTOMER_ID_SUCCESS =
  "ADD_INITIATIVE_BY_CUSTOMER_ID_SUCCESS";
export const ADD_INITIATIVE_BY_CUSTOMER_ID_FAILURE =
  "ADD_INITIATIVE_BY_CUSTOMER_ID_FAILURE";

  export const GET_INITIATIVE_BY_CUSTOMER_ID_REQUEST =
  "GET_INITIATIVE_BY_CUSTOMER_ID_REQUEST";
export const GET_INITIATIVE_BY_CUSTOMER_ID_SUCCESS =
  "GET_INITIATIVE_BY_CUSTOMER_ID_SUCCESS";
export const GET_INITIATIVE_BY_CUSTOMER_ID_FAILURE =
  "GET_INITIATIVE_BY_CUSTOMER_ID_FAILURE";

  export const DELETE_TOPIC_BY_CUSTOMER_ID_REQUEST =
  "DELETE_TOPIC_BY_CUSTOMER_ID_REQUEST";
export const DELETE_TOPIC_BY_CUSTOMER_ID_SUCCESS =
  "DELETE_TOPIC_BY_CUSTOMER_ID_SUCCESS";
export const DELETE_TOPIC_BY_CUSTOMER_ID_FAILURE =
  "DELETE_TOPIC_BY_CUSTOMER_ID_FAILURE";


  export const GET_CUSTOMER_RECRUIT_REQUEST =
  "GET_CUSTOMER_RECRUIT_REQUEST";
export const GET_CUSTOMER_RECRUIT_SUCCESS =
  "GET_RECRUIT_TO_OPPORTUNITY_SUCCESS";
export const GET_CUSTOMER_RECRUIT_FAILURE =
  "GET_CUSTOMER_RECRUIT_FAILURE";


  export const GET_LATEST_CUSTOMER_REQUEST = "GET_LATEST_CUSTOMER_REQUEST";
export const GET_LATEST_CUSTOMER_SUCCESS = "GET_LATEST_CUSTOMER_SUCCESS";
export const GET_LATEST_CUSTOMER_FAILURE = "GET_LATEST_CUSTOMER_FAILURE";

export const GET_CUSTOMER_REQUIREMENT_REQUEST =
  "GET_CUSTOMER_REQUIREMENT_REQUEST";
export const GET_CUSTOMER_REQUIREMENT_SUCCESS =
  "GET_CUSTOMER_REQUIREMENT_SUCCESS";
export const GET_CUSTOMER_REQUIREMENT_FAILURE =
  "GET_CUSTOMER_REQUIREMENT_FAILURE";


  export const GET_CUSTOMER_CLOSER_REQUEST =
  "GET_CUSTOMER_CLOSER_REQUEST";
export const GET_CUSTOMER_CLOSER_SUCCESS =
  "GET_CUSTOMER_CLOSER_SUCCESS";
export const GET_CUSTOMER_CLOSER_FAILURE =
  "GET_CUSTOMER_CLOSER_FAILURE";

  export const UPDATE_CUSTOMER_INITIATIVE_REQUEST = "UPDATE_CUSTOMER_INITIATIVE_REQUEST";
export const UPDATE_CUSTOMER_INITIATIVE_SUCCESS = "UPDATE_CUSTOMER_INITIATIVE_SUCCESS";
export const UPDATE_CUSTOMER_INITIATIVE_FAILURE = "UPDATE_CUSTOMER_INITIATIVE_FAILURE";


  export const GET_ALL_CUSTOMER_BY_ALPHABET_REQUEST =
  "GET_ALL_CUSTOMER_BY_ALPHABET_REQUEST";
export const GET_ALL_CUSTOMER_BY_ALPHABET_SUCCESS =
  "GET_ALL_CUSTOMER_BY_ALPHABET_SUCCESS";
export const GET_ALL_CUSTOMER_BY_ALPHABET_FAILURE =
  "GET_ALL_CUSTOMER_BY_ALPHABET_FAILURE";


  export const GET_CUSTOMER_PAGINATION_REQUEST = "GET_CUSTOMER_PAGINATION_REQUEST";
export const GET_CUSTOMER_PAGINATION_SUCCESS = "GET_CUSTOMER_PAGINATION_SUCCESS";
export const GET_CUSTOMER_PAGINATION_FAILURE = "GET_CUSTOMER_PAGINATION_FAILURE";



  export const GET_ALL_CUSTOMER_BY_POSITION_REQUEST =
  "GET_ALL_CUSTOMER_BY_POSITION_REQUEST";
export const GET_ALL_CUSTOMER_BY_POSITION_SUCCESS =
  "GET_ALL_CUSTOMER_BY_POSITION_SUCCESS";
export const GET_ALL_CUSTOMER_BY_POSITION_FAILURE =
  "GET_ALL_CUSTOMER_BY_POSITION_FAILURE";


  export const GET_ALL_CUSTOMER_BY_CLOSER_REQUEST =
  "GET_ALL_CUSTOMER_BY_CLOSER_REQUEST";
export const GET_ALL_CUSTOMER_BY_CLOSER_SUCCESS =
  "GET_ALL_CUSTOMER_BY_CLOSER_SUCCESS";
export const GET_ALL_CUSTOMER_BY_CLOSER_FAILURE =
  "GET_ALL_CUSTOMER_BY_CLOSER_FAILURE";

  export const HANDLE_CUSTOMER_EMAIL_DRAWER_MODAL = "HANDLE_CUSTOMER_EMAIL_DRAWER_MODAL";

  export const ADD_INITIATIVES_REQUEST =
  "ADD_INITIATIVES_REQUEST";
export const ADD_INITIATIVES_SUCCESS =
  "ADD_INITIATIVES_SUCCESS";
export const ADD_INITIATIVES_FAILURE =
  "ADD_INITIATIVES_FAILURE";


  export const GET_INITIATIVES_REQUEST =
  "GET_INITIATIVES_REQUEST";
export const GET_INITIATIVES_SUCCESS =
  "GET_INITIATIVES_SUCCESS";
export const GET_INITIATIVES_FAILURE =
  "GET_INITIATIVES_FAILURE";

export const DELETE_INITIATIVE_DATA_REQUEST = "DELETE_INITIATIVE_DATA_REQUEST";
export const DELETE_INITIATIVE_DATA_SUCCESS = "DELETE_INITIATIVE_DATA_SUCCESS";
export const DELETE_INITIATIVE_DATA_FAILURE = "DELETE_INITIATIVE_DATA_FAILURE";

export const GET_ATTENDANCE_LIST_REQUEST = "GET_ATTENDANCE_LIST_REQUEST";
export const GET_ATTENDANCE_LIST_SUCCESS = "GET_ATTENDANCE_LIST_SUCCESS";
export const GET_ATTENDANCE_LIST_FAILURE = "GET_ATTENDANCE_LIST_FAILURE";

export const GET_ALL_CUSTOMERS_DATA_REQUEST = "GET_ALL_CUSTOMERS_DATA_REQUEST";
export const GET_ALL_CUSTOMERS_DATA_SUCCESS = "GET_ALL_CUSTOMERS_DATA_SUCCESS";
export const GET_ALL_CUSTOMERS_DATA_FAILURE = "GET_ALL_CUSTOMERS_DATA_FAILURE";

export const HANDLE_CUSTOMER_NOTES_DRAWER_MODAL = "HANDLE_CUSTOMER_NOTES_DRAWER_MODAL";

export const HANDLE_CUSTOMER_CONTACT_DRAWER_MODAL = "HANDLE_CUSTOMER_CONTACT_DRAWER_MODAL";

export const HANDLE_CUSTOMER_OPPORTUNITY_DRAWER_MODAL = "HANDLE_CUSTOMER_OPPORTUNITY_DRAWER_MODAL";

export const HANDLE_CUSTOMER_PULSE_DRAWER_MODAL = "HANDLE_CUSTOMER_PULSE_DRAWER_MODAL";

export const GET_OPPORTUNITY_RECORD_REQUEST="GET_OPPORTUNITY_RECORD_REQUEST";
export const GET_OPPORTUNITY_RECORD_SUCCESS="GET_OPPORTUNITY_RECORD_SUCCESS";
export const GET_OPPORTUNITY_RECORD_FAILURE="GET_OPPORTUNITY_RECORD_FAILURE";



export const GET_CUSTOMER_NOTE_LIST_REQUEST = "GET_CUSTOMER_NOTE_LIST_REQUEST";
export const GET_CUSTOMER_NOTE_LIST_SUCCESS = "GET_CUSTOMER_NOTE_LIST_SUCCESS";
export const GET_CUSTOMER_NOTE_LIST_FAILURE = "GET_CUSTOMER_NOTE_LIST_FAILURE";



export const HANDLE_CUSTOMER_NOTE_DRAWER_MODAL = "HANDLE_CUSTOMER_NOTE_DRAWER_MODAL";

export const HANDLE_CUSTOMER_ACTIVITY_MODAL = "HANDLE_CUSTOMER_ACTIVITY_MODAL";


export const GET_CUSTOMER_ACTIVITY_TIMELINE_REQUEST = "GET_CUSTOMER_ACTIVITY_TIMELINE_REQUEST";
export const GET_CUSTOMER_ACTIVITY_TIMELINE_SUCCESS = "GET_CUSTOMER_ACTIVITY_TIMELINE_SUCCESS";
export const GET_CUSTOMER_ACTIVITY_TIMELINE_FAILURE = "GET_CUSTOMER_ACTIVITY_TIMELINE_FAILURE";

export const CUSTOMER_TO_ACCOUNT_CONVERT_REQUEST =
"CUSTOMER_TO_ACCOUNT_CONVERT_REQUEST";
export const CUSTOMER_TO_ACCOUNT_CONVERT_SUCCESS =
"CUSTOMER_TO_ACCOUNT_CONVERT_SUCCESS";
export const CUSTOMER_TO_ACCOUNT_CONVERT_FAILURE =
"CUSTOMER_TO_ACCOUNT_CONVERT_FAILURE";

export const GET_ALL_CUSTOMERS_LIST_REQUEST="GET_ALL_CUSTOMERS_LIST_REQUEST";
export const GET_ALL_CUSTOMERS_LIST_SUCCESS="GET_ALL_CUSTOMERS_LIST_SUCCESS";
export const GET_ALL_CUSTOMERS_LIST_FAILURE="GET_ALL_CUSTOMERS_LIST_FAILURE";

export const ADD_CUSTOMER_ACTIVITY_CALL_REQUEST = "ADD_CUSTOMER_ACTIVITY_CALL_REQUEST";
export const ADD_CUSTOMER_ACTIVITY_CALL_SUCCESS = "ADD_CUSTOMER_ACTIVITY_CALL_SUCCESS";
export const ADD_CUSTOMER_ACTIVITY_CALL_FAILURE = "ADD_CUSTOMER_ACTIVITY_CALL_FAILURE";

export const ADD_CUSTOMER_ACTIVITY_EVENT_REQUEST = "ADD_CUSTOMER_ACTIVITY_EVENT_REQUEST";
export const ADD_CUSTOMER_ACTIVITY_EVENT_SUCCESS = "ADD_CUSTOMER_ACTIVITY_EVENT_SUCCESS";
export const ADD_CUSTOMER_ACTIVITY_EVENT_FAILURE = "ADD_CUSTOMER_ACTIVITY_EVENT_FAILURE";


export const ADD_CUSTOMER_ACTIVITY_TASK_REQUEST = "ADD_CUSTOMER_ACTIVITY_TASK_REQUEST";
export const ADD_CUSTOMER_ACTIVITY_TASK_SUCCESS = "ADD_CUSTOMER_ACTIVITY_TASK_SUCCESS";
export const ADD_CUSTOMER_ACTIVITY_TASK_FAILURE = "ADD_CUSTOMER_ACTIVITY_TASK_FAILURE";

export const GET_TEAM_CUSTOMER_REQUEST="GET_TEAM_CUSTOMER_REQUEST";
export const GET_TEAM_CUSTOMER_SUCCESS="GET_TEAM_CUSTOMER_SUCCESS";
export const GET_TEAM_CUSTOMER_FAILURE="GET_TEAM_CUSTOMER_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_CUSTOMER = "HANDLE_CLAER_REDUCER_DATA_CUSTOMER";

export const LINK_CUSTOMER_CONTRACT_REQUEST =
  "LINK_CUSTOMER_CONTRACT_REQUEST";
export const LINK_CUSTOMER_CONTRACT_SUCCESS =
  "LINK_CUSTOMER_CONTRACT_SUCCESS";
export const LINK_CUSTOMER_CONTRACT_FAILURE =
  "LINK_CUSTOMER_CONTRACT_FAILURE";


  export const GET_PROSPECT_WEIGHTED_VALUE_REQUEST = "GET_PROSPECT_WEIGHTED_VALUE_REQUEST";
export const GET_PROSPECT_WEIGHTED_VALUE_SUCCESS = "GET_PROSPECT_WEIGHTED_VALUE_SUCCESS";
export const GET_PROSPECT_WEIGHTED_VALUE_FAILURE = "GET_PROSPECT_WEIGHTED_VALUE_FAILURE";


export const GET_PROSPECT_OPP_VALUE_REQUEST = "GET_PROSPECT_OPP_VALUE_REQUEST";
export const GET_PROSPECT_OPP_VALUE_SUCCESS = "GET_PROSPECT_OPP_VALUE_SUCCESS";
export const GET_PROSPECT_OPP_VALUE_FAILURE = "GET_PROSPECT_OPP_VALUE_FAILURE";


export const GET_PROSPECT_PIPELINE_VALUE_REQUEST = "GET_PROSPECT_PIPELINE_VALUE_REQUEST";
export const GET_PROSPECT_PIPELINE_VALUE_SUCCESS = "GET_PROSPECT_PIPELINE_VALUE_SUCCESS";
export const GET_PROSPECT_PIPELINE_VALUE_FAILURE = "GET_PROSPECT_PIPELINE_VALUE_FAILURE";

export const GET_PROSPECT_CONTACT_VALUE_REQUEST = "GET_PROSPECT_CONTACT_VALUE_REQUEST";
export const GET_PROSPECT_CONTACT_VALUE_SUCCESS = "GET_PROSPECT_CONTACT_VALUE_SUCCESS";
export const GET_PROSPECT_CONTACT_VALUE_FAILURE = "GET_PROSPECT_CONTACT_VALUE_FAILURE";

export const GET_CUSTOMER_ACTIVITY_RECORDS_REQUEST = "GET_CUSTOMER_ACTIVITY_RECORDS_REQUEST";
export const GET_CUSTOMER_ACTIVITY_RECORDS_SUCCESS = "GET_CUSTOMER_ACTIVITY_RECORDS_SUCCESS";
export const GET_CUSTOMER_ACTIVITY_RECORDS_FAILURE = "GET_CUSTOMER_ACTIVITY_RECORDS_FAILURE";

export const GET_WON_CUSTOMER_OPP_VALUE_REQUEST = "GET_WON_CUSTOMER_OPP_VALUE_REQUEST";
export const GET_WON_CUSTOMER_OPP_VALUE_SUCCESS = "GET_WON_CUSTOMER_OPP_VALUE_SUCCESS";
export const GET_WON_CUSTOMER_OPP_VALUE_FAILURE = "GET_WON_CUSTOMER_OPP_VALUE_FAILURE";


export const GET_WON_CUSTOMER_PIPELINE_VALUE_REQUEST = "GET_WON_CUSTOMER_PIPELINE_VALUE_REQUEST";
export const GET_WON_CUSTOMER_PIPELINE_VALUE_SUCCESS = "GET_WON_CUSTOMER_PIPELINE_VALUE_SUCCESS";
export const GET_WON_CUSTOMER_PIPELINE_VALUE_FAILURE = "GET_WON_CUSTOMER_PIPELINE_VALUE_FAILURE";


export const GET_WON_CUSTOMER_WEIGHTED_VALUE_REQUEST = "GET_WON_CUSTOMER_WEIGHTED_VALUE_REQUEST";
export const GET_WON_CUSTOMER_WEIGHTED_VALUE_SUCCESS = "GET_WON_CUSTOMER_WEIGHTED_VALUE_SUCCESS";
export const GET_WON_CUSTOMER_WEIGHTED_VALUE_FAILURE = "GET_WON_CUSTOMER_WEIGHTED_VALUE_FAILURE";

export const HANDLE_CAMPAIGN_DRAWER="HANDLE_CAMPAIGN_DRAWER";

export const ADD_CUSTOMER_EVENT_REQUEST = "ADD_CUSTOMER_EVENT_REQUEST";
export const ADD_CUSTOMER_EVENT_SUCCESS = "ADD_CUSTOMER_EVENT_SUCCESS";
export const ADD_CUSTOMER_EVENT_FAILURE = "ADD_CUSTOMER_EVENT_FAILURE";

export const ADD_CUSTOMER_CAMPAIGN_EVENT_REQUEST = "ADD_CUSTOMER_CAMPAIGN_EVENT_REQUEST";
export const ADD_CUSTOMER_CAMPAIGN_EVENT_SUCCESS = "ADD_CUSTOMER_CAMPAIGN_EVENT_SUCCESS";
export const ADD_CUSTOMER_CAMPAIGN_EVENT_FAILURE = "ADD_CUSTOMER_CAMPAIGN_EVENT_FAILURE";

export const GET_CUSTOMER_CAMPAIGN_EVENT_REQUEST = "GET_CUSTOMER_CAMPAIGN_EVENT_REQUEST";
export const GET_CUSTOMER_CAMPAIGN_EVENT_SUCCESS = "GET_CUSTOMER_CAMPAIGN_EVENT_SUCCESS";
export const GET_CUSTOMER_CAMPAIGN_EVENT_FAILURE = "GET_CUSTOMER_CAMPAIGN_EVENT_FAILURE";

export const GET_CUSTOMER_ALL_RECORDS_REQUEST = "GET_CUSTOMER_ALL_RECORDS_REQUEST";
export const GET_CUSTOMER_ALL_RECORDS_SUCCESS = "GET_CUSTOMER_ALL_RECORDS_SUCCESS";
export const GET_CUSTOMER_ALL_RECORDS_FAILURE = "GET_CUSTOMER_ALL_RECORDS_FAILURE";

export const HANDLE_CUSTOMER_CONTACT_JUMPSTART_MODAL = "HANDLE_CUSTOMER_CONTACT_JUMPSTART_MODAL";


export const HANDLE_CUSTOMER_ACTIVITY_JUMPSTART_MODAL = "HANDLE_CUSTOMER_ACTIVITY_JUMPSTART_MODAL";

export const HANDLE_CUSTOMER_OPEN_OPPORTUNITY_JUMPSTART_MODAL = "HANDLE_CUSTOMER_OPEN_OPPORTUNITY_JUMPSTART_MODAL";

export const HANDLE_CUSTOMER_WON_OPPORTUNITY_JUMPSTART_MODAL = "HANDLE_CUSTOMER_WON_OPPORTUNITY_JUMPSTART_MODAL";

export const GET_CONTACTS_OF_JUMPSTART_REQUEST = "GET_CONTACTS_OF_JUMPSTART_REQUEST";
export const GET_CONTACTS_OF_JUMPSTART_SUCCESS = "GET_CONTACTS_OF_JUMPSTART_SUCCESS";
export const GET_CONTACTS_OF_JUMPSTART_FAILURE = "GET_CONTACTS_OF_JUMPSTART_FAILURE";

export const GET_OPEN_OPP_OF_JUMPSTART_REQUEST = "GET_OPEN_OPP_OF_JUMPSTART_REQUEST";
export const GET_OPEN_OPP_OF_JUMPSTART_SUCCESS = "GET_OPEN_OPP_OF_JUMPSTART_SUCCESS";
export const GET_OPEN_OPP_OF_JUMPSTART_FAILURE = "GET_OPEN_OPP_OF_JUMPSTART_FAILURE";

export const GET_WON_OPP_OF_JUMPSTART_REQUEST = "GET_WON_OPP_OF_JUMPSTART_REQUEST";
export const GET_WON_OPP_OF_JUMPSTART_SUCCESS = "GET_WON_OPP_OF_JUMPSTART_SUCCESS";
export const GET_WON_OPP_OF_JUMPSTART_FAILURE = "GET_WON_OPP_OF_JUMPSTART_FAILURE";

export const GET_CONTACT_DOCUMENTS_REQUEST = "GET_CONTACT_DOCUMENTS_REQUEST";
export const GET_CONTACT_DOCUMENTS_SUCCESS = "GET_CONTACT_DOCUMENTS_SUCCESS";
export const GET_CONTACT_DOCUMENTS_FAILURE = "GET_CONTACT_DOCUMENTS_FAILURE";


export const GET_OPPORTUNITY_DOCUMENTS_REQUEST =
  "GET_OPPORTUNITY_DOCUMENTS_REQUEST";
export const GET_OPPORTUNITY_DOCUMENTS_SUCCESS =
  "GET_OPPORTUNITY_DOCUMENTS_SUCCESS";
export const GET_OPPORTUNITY_DOCUMENTS_FAILURE =
  "GET_OPPORTUNITY_DOCUMENTS_FAILURE";

export const GET_DEAL_DOCUMENTS_REQUEST = "GET_DEAL_DOCUMENTS_REQUEST";
export const GET_DEAL_DOCUMENTS_SUCCESS = "GET_DEAL_DOCUMENTS_SUCCESS";
export const GET_DEAL_DOCUMENTS_FAILURE = "GET_DEAL_DOCUMENTS_FAILURE";

export const GET_INVESTOR_DOCUMENTS_REQUEST="GET_INVESTOR_DOCUMENTS_REQUEST";
export const GET_INVESTOR_DOCUMENTS_SUCCESS="GET_INVESTOR_DOCUMENTS_SUCCESS";
export const GET_INVESTOR_DOCUMENTS_FAILURE="GET_INVESTOR_DOCUMENTS_FAILURE";


export const UPDATE_ACTIVITY_CALL_FORM_REQUEST = "UPDATE_ACTIVITY_CALL_FORM_REQUEST";
export const UPDATE_ACTIVITY_CALL_FORM_SUCCESS = "UPDATE_ACTIVITY_CALL_FORM_SUCCESS";
export const UPDATE_ACTIVITY_CALL_FORM_FAILURE = "UPDATE_ACTIVITY_CALL_FORM_FAILURE";

export const UPDATE_ACTIVITY_TASK_FORM_REQUEST = "UPDATE_ACTIVITY_TASK_FORM_REQUEST";
export const UPDATE_ACTIVITY_TASK_FORM_SUCCESS = "UPDATE_ACTIVITY_TASK_FORM_SUCCESS";
export const UPDATE_ACTIVITY_TASK_FORM_FAILURE = "UPDATE_ACTIVITY_TASK_FORM_FAILURE";

export const UPDATE_ACTIVITY_EVENT_FORM_REQUEST = "UPDATE_ACTIVITY_EVENT_FORM_REQUEST";
export const UPDATE_ACTIVITY_EVENT_FORM_SUCCESS = "UPDATE_ACTIVITY_EVENT_FORM_SUCCESS";
export const UPDATE_ACTIVITY_EVENT_FORM_FAILURE = "UPDATE_ACTIVITY_EVENT_FORM_FAILURE";

export const SET_ACTIVITY_EVENTS_EDIT = "SET_ACTIVITY_EVENTS_EDIT";