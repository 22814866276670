export const HANDLE_CANDIDATE_MODAL = "HANDLE_CANDIDATE_MODAL";
export const HANDLE_CANDIDATE_FILTER_MODAL = "HANDLE_CANDIDATE_FILTER_MODAL";
export const HANDLE_CANDIDATE_RESUME_MODAL = "HANDLE_CANDIDATE_RESUME_MODAL";
export const HANDLE_UPDATE_CANDIDATE_RESUME_MODAL = "HANDLE_UPDATE_CANDIDATE_RESUME_MODAL";

export const HANDLE_UPDATE_CANDIDATE_MODAL = "HANDLE_UPDATE_CANDIDATE_MODAL";
export const SET_CANDIDATE_EDIT = "SET_CANDIDATE_EDIT";
export const TABLE_DYNAMIC_VALUE_DATA = "TABLE_DYNAMIC_VALUE_DATA";

export const HANDLE_CANDIDATE_EMAIL_MODAL = "HANDLE_CANDIDATE_EMAIL_MODAL";

export const HANDLE_PLAYER_MODAL = "HANDLE_PLAYER_MODAL";

export const EMPTY_CANDIDATE_TABLE = "EMPTY_CANDIDATE_TABLE";

export const HANDLE_CANDIDATES_TASKS_DRAWER_MODAL = "HANDLE_CANDIDATES_TASKS_DRAWER_MODAL";

export const HANDLE_RECRUITER_DRAWER_MODAL = "HANDLE_RECRUITER_DRAWER_MODAL";

export const ADD_CANDIDATE_REQUEST = "ADD_CANDIDATE_REQUEST";
export const ADD_CANDIDATE_SUCCESS = "ADD_CANDIDATE_SUCCESS";
export const ADD_CANDIDATE_FAILURE = "ADD_CANDIDATE_FAILURE";

export const GET_CANDIDATES_REQUEST = "GET_CANDIDATES_REQUEST";
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS";
export const GET_CANDIDATES_FAILURE = "GET_CANDIDATES_FAILURE";

export const GET_CANDIDATE_BY_ID_REQUEST = "GET_CANDIDATE_BY_ID_REQUEST";
export const GET_CANDIDATE_BY_ID_SUCCESS = "GET_CANDIDATE_BY_ID_SUCCESS";
export const GET_CANDIDATE_BY_ID_FAILURE = "GET_CANDIDATE_BY_ID_FAILURE";

export const UPDATE_CANDIDATE_BY_ID_REQUEST = "UPDATE_CANDIDATE_BY_ID_REQUEST";
export const UPDATE_CANDIDATE_BY_ID_SUCCESS = "UPDATE_CANDIDATE_BY_ID_SUCCESS";
export const UPDATE_CANDIDATE_BY_ID_FAILURE = "UPDATE_CANDIDATE_BY_ID_FAILURE";

//document
export const HANDLE_DOCUMENT_UPLOAD_MODAL = "HANDLE_DOCUMENT_UPLOAD_MODAL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

/*CANDIDATE notes*/
export const GET_NOTES_LIST_BY_CANDIDATE_ID_REQUEST =
  "GET_NOTES_LIST_BY_CANDIDATE_ID_REQUEST";
export const GET_NOTES_LIST_BY_CANDIDATE_ID_SUCCESS =
  "GET_NOTES_LIST_BY_CANDIDATE_ID_SUCCESS";
export const GET_NOTES_LIST_BY_CANDIDATE_ID_FAILURE =
  "GET_NOTES_LIST_BY_CANDIDATE_ID_FAILURE";

// add/link CANDIDATE document
export const ADD_CANDIDATE_DOCUMENT_REQUEST = "ADD_CANDIDATE_DOCUMENT_REQUEST";
export const ADD_CANDIDATE_DOCUMENT_SUCCESS = "ADD_CANDIDATE_DOCUMENT_SUCCESS";
export const ADD_CANDIDATE_DOCUMENT_FAILURE = "ADD_CANDIDATE_DOCUMENT_FAILURE";

//  get list of documents of an CANDIDATE
export const GET_CANDIDATE_DOCUMENTS_REQUEST =
  "GET_CANDIDATE_DOCUMENTS_REQUEST";
export const GET_CANDIDATE_DOCUMENTS_SUCCESS =
  "GET_CANDIDATE_DOCUMENTS_SUCCESS";
export const GET_CANDIDATE_DOCUMENTS_FAILURE =
  "GET_CANDIDATE_DOCUMENTS_FAILURE";

//candidate skills
export const ADD_TOPIC_BY_CANDIDATE_ID_REQUEST =
  "ADD_TOPIC_BY_CANDIDATE_ID_REQUEST";
export const ADD_TOPIC_BY_CANDIDATE_ID_SUCCESS =
  "ADD_TOPIC_BY_CANDIDATE_ID_SUCCESS";
export const ADD_TOPIC_BY_CANDIDATE_ID_FAILURE =
  "ADD_TOPIC_BY_CANDIDATE_ID_FAILURE";

export const GET_TOPICS_BY_CANDIDATE_ID_REQUEST =
  "GET_TOPICS_BY_CANDIDATE_ID_REQUEST";
export const GET_TOPICS_BY_CANDIDATE_ID_SUCCESS =
  "GET_TOPICS_BY_CANDIDATE_ID_SUCCESS";
export const GET_TOPICS_BY_CANDIDATE_ID_FAILURE =
  "GET_TOPICS_BY_CANDIDATE_ID_FAILURE";

export const DELETE_TOPIC_BY_CANDIDATE_ID_REQUEST =
  "DELETE_TOPIC_BY_CANDIDATE_ID_REQUEST";
export const DELETE_TOPIC_BY_CANDIDATE_ID_SUCCESS =
  "DELETE_TOPIC_BY_CANDIDATE_ID_SUCCESS";
export const DELETE_TOPIC_BY_CANDIDATE_ID_FAILURE =
  "DELETE_TOPIC_BY_CANDIDATE_ID_FAILURE";
//education
export const HANDLE_CANDIDATE_EDUCATION_MODAL =
  "HANDLE_CANDIDATE_EDUCATION_MODAL";

//add education
export const ADD_CANDIDATE_EDUCATIONAL_DETAILS_REQUEST =
  "ADD_CANDIDATE_EDUCATIONAL_DETAILS_REQUEST";
export const ADD_CANDIDATE_EDUCATIONAL_DETAILS_SUCCESS =
  "ADD_CANDIDATE_EDUCATIONAL_DETAILS_SUCCESS";
export const ADD_CANDIDATE_EDUCATIONAL_DETAILS_FAILURE =
  "ADD_CANDIDATE_EDUCATIONAL_DETAILS_FAILURE";
//get edu
export const GET_CANDIDATE_EDUCATION_DETAILS_REQUEST =
  "GET_CANDIDATE_EDUCATION_DETAILS_REQUEST";
export const GET_CANDIDATE_EDUCATION_DETAILS_SUCCESS =
  "GET_CANDIDATE_EDUCATION_DETAILS_SUCCESS";
export const GET_CANDIDATE_EDUCATION_DETAILS_FAILURE =
  "GET_CANDIDATE_EDUCATION_DETAILS_FAILURE";

//edit
export const SET_EDIT_CANDIDATE_EDUCATION = "SET_EDIT_CANDIDATE_EDUCATION";
//delete edu
export const DELETE_CANDIDATE_EDUCATION_REQUEST =
  "DELETE_CANDIDATE_EDUCATION_REQUEST";
export const DELETE_CANDIDATE_EDUCATION_SUCCESS =
  "DELETE_CANDIDATE_EDUCATION_SUCCESS";
export const DELETE_CANDIDATE_EDUCATION_FAILURE =
  "DELETE_CANDIDATE_EDUCATION_FAILURE";

// update edu
export const UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_REQUEST =
  "UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_REQUEST";
export const UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_SUCCESS =
  "UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_SUCCESS";
export const UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_FAILURE =
  "UPDATE_CANDIDATE_EDUCATIONAL_DETAILS_FAILURE";

export const HANDLE_UPDATE_CANDIDATE_EDUCATION_MODAL =
  "HANDLE_UPDATE_CANDIDATE_EDUCATION_MODAL";

//Training
export const HANDLE_CANDIDATE_TRAINING_MODAL =
  "HANDLE_CANDIDATE_TRAINING_MODAL";

export const ADD_CANDIDATE_TRAINING_DETAILS_REQUEST =
  "ADD_CANDIDATE_TRAINING_DETAILS_REQUEST";
export const ADD_CANDIDATE_TRAINING_DETAILS_SUCCESS =
  "ADD_CANDIDATE_TRAINING_DETAILS_SUCCESS";
export const ADD_CANDIDATE_TRAINING_DETAILS_FAILURE =
  "ADD_CANDIDATE_TRAINING_DETAILS_FAILURE";

export const GET_CANDIDATE_TRAINING_DETAILS_REQUEST =
  "GET_CANDIDATE_TRAINING_DETAILS_REQUEST";
export const GET_CANDIDATE_TRAINING_DETAILS_SUCCESS =
  "GET_CANDIDATE_TRAINING_DETAILS_SUCCESS";
export const GET_CANDIDATE_TRAINING_DETAILS_FAILURE =
  "GET_CANDIDATE_TRAINING_DETAILS_FAILURE";
//update
export const UPDATE_CANDIDATE_TRAINING_DETAILS_REQUEST =
  "UPDATE_CANDIDATE_TRAINING_DETAILS_REQUEST";
export const UPDATE_CANDIDATE_TRAINING_DETAILS_SUCCESS =
  "UPDATE_CANDIDATE_TRAINING_DETAILS_SUCCESS";
export const UPDATE_CANDIDATE_TRAINING_DETAILS_FAILURE =
  "UPDATE_CANDIDATE_TRAINING_DETAILS_FAILURE";
export const SET_CANDIDATE_TRAINING_EDIT = "SET_CANDIDATE_TRAINING_EDIT";
export const HANDLE_UPDATE_CANDIDATE_TRAINING_MODAL =
  "HANDLE_UPDATE_CANDIDATE_TRAINING_MODAL";
//delete
export const DELETE_CANDIDATE_TRAINING_REQUEST =
  "DELETE_CANDIDATE_TRAINING_REQUEST";
export const DELETE_CANDIDATE_TRAINING_SUCCESS =
  "DELETE_CANDIDATE_TRAINING_SUCCESS";
export const DELETE_CANDIDATE_TRAINING_FAILURE =
  "DELETE_CANDIDATE_TRAINING_FAILURE";
//employment
export const HANDLE_CANDIDATE_EMPLOYMENT_MODAL =
  "HANDLE_CANDIDATE_EMPLOYMENT_MODAL";
//add
export const ADD_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST =
  "ADD_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST";
export const ADD_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS =
  "ADD_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS";
export const ADD_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE =
  "ADD_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE";

//get
export const GET_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST =
  "GET_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST";
export const GET_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS =
  "GET_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS";
export const GET_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE =
  "GET_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE";
//edit
export const SET_CANDIDATE_EMPLOYMENT_EDIT = "SET_CANDIDATE_EMPLOYMENT_EDIT";
//delete
export const DELETE_CANDIDATE_EMPLOYMENT_REQUEST =
  "DELETE_CANDIDATE_EMPLOYMENT_REQUEST";
export const DELETE_CANDIDATE_EMPLOYMENT_SUCCESS =
  "DELETE_CANDIDATE_EMPLOYMENT_SUCCESS";
export const DELETE_CANDIDATE_EMPLOYMENT_FAILURE =
  "DELETE_CANDIDATE_EMPLOYMENT_FAILURE";
export const HANDLE_CANDIDATE_UPDATE_EMPLOYMENT_MODAL =
  "HANDLE_CANDIDATE_UPDATE_EMPLOYMENT_MODAL";
export const UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST =
  "UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_REQUEST";
export const UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS =
  "UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_SUCCESS";
export const UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE =
  "UPDATE_CANDIDATE_EMPLOYMENT_DETAILS_FAILURE";
//Search
export const INPUT_CANDIDATE_SEARCH_DATA_REQUSET =
  "INPUT_CANDIDATE_SEARCH_DATA_REQUSET";
export const INPUT_CANDIDATE_SEARCH_DATA_SUCCESS =
  "INPUT_CANDIDATE_SEARCH_DATA_SUCCESS";
export const INPUT_CANDIDATE_SEARCH_DATA_FAILURE =
  "INPUT_CANDIDATE_SEARCH_DATA_FAILURE";

//Search
export const INPUT_CANDIDATE_SKILL_SEARCH_DATA_REQUEST =
  "INPUT_CANDIDATE_SKILL_SEARCH_DATA_REQUEST";
export const INPUT_CANDIDATE_SKILL_SEARCH_DATA_SUCCESS =
  "INPUT_CANDIDATE_SKILL_SEARCH_DATA_SUCCESS";
export const INPUT_CANDIDATE_SKILL_SEARCH_DATA_FAILURE =
  "INPUT_CANDIDATE_SKILL_SEARCH_DATA_FAILURE";

export const HANDLE_CANDIDATE_BANK_MODAL = "HANDLE_CANDIDATE_BANK_MODAL";

export const ADD_BANK_DETAILS_REQUEST = "ADD_BANK_DETAILS_REQUEST";
export const ADD_BANK_DETAILS_SUCCESS = "ADD_BANK_DETAILS_SUCCESS";
export const ADD_BANK_DETAILS_FAILURE = "ADD_BANK_DETAILS_FAILURE";

export const GET_CANDIDATE_BANK_DETAILS_REQUEST =
  "GET_CANDIDATE_BANK_DETAILS_REQUEST";
export const GET_CANDIDATE_BANK_DETAILS_SUCCESS =
  "GET_CANDIDATE_BANK_DETAILS_SUCCESS";
export const GET_CANDIDATE_BANK_DETAILS_FAILURE =
  "GET_CANDIDATE_BANK_DETAILS_FAILURE";

export const HANDLE_UPDATE_BANK_MODAL = "HANDLE_UPDATE_BANK_MODAL";

export const UPDATE_BANK_DETAILS_REQUEST = "UPDATE_BANK_DETAILS_REQUEST";
export const UPDATE_BANK_DETAILS_SUCCESS = "UPDATE_BANK_DETAILS_SUCCESS";
export const UPDATE_BANK_DETAILS_FAILURE = "UPDATE_BANK_DETAILS_FAILURE";

export const DELETE_BANK_REQUEST = "DELETE_BANK_REQUEST";
export const DELETE_BANK_SUCCESS = "DELETE_BANK_SUCCESS";
export const DELETE_BANK_FAILURE = "DELETE_BANK_FAILURE";

export const SET_BANK_EDIT = "SET_BANK_EDIT";

export const GET_PERMISSIONS_LIST_REQUEST = "GET_PERMISSIONS_LIST_REQUEST";
export const GET_PERMISSIONS_LIST_SUCCESS = "GET_PERMISSIONS_LIST_SUCCESS";
export const GET_PERMISSIONS_LIST_FAILURE = "GET_PERMISSIONS_LIST_FAILURE";

//candidate share
export const ADD_SHARE_CANDIDATE_PERMISSION_REQUEST =
  "ADD_SHARE_CANDIDATE_PERMISSION_REQUEST";
export const ADD_SHARE_CANDIDATE_PERMISSION_SUCCESS =
  "ADD_SHARE_CANDIDATE_PERMISSION_SUCCESS";
export const ADD_SHARE_CANDIDATE_PERMISSION_FAILURE =
  "ADD_SHARE_CANDIDATE_PERMISSION_FAILURE";

export const LINK_CANDIDATE_STATUS_REQUEST = "LINK_CANDIDATE_STATUS_REQUEST";
export const LINK_CANDIDATE_STATUS_SUCCESS = "LINK_CANDIDATE_STATUS_SUCCESS";
export const LINK_CANDIDATE_STATUS_FAILURE = "LINK_CANDIDATE_STATUS_FAILURE";

export const GET_ALL_CANDIDATES_REQUEST = "GET_ALL_CANDIDATES_REQUEST";
export const GET_ALL_CANDIDATES_SUCCESS = "GET_ALL_CANDIDATES_SUCCESS";
export const GET_ALL_CANDIDATES_FAILURE = "GET_ALL_CANDIDATES_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";

export const SET_CANDIDATE_VIEW_TYPE = "SET_CANDIDATE_VIEW_TYPE";
export const GET_ALL_RECORDS_REQUEST = "GET_ALL_RECORDS_REQUEST";
export const GET_ALL_RECORDS_SUCCESS = "GET_ALL_RECORDS_SUCCESS";
export const GET_ALL_RECORDS_FAILURE = "GET_ALL_RECORDS_FAILURE";

export const HANDLE_CANDIDATE_ACTIVITY_MODAL =
  "HANDLE_CANDIDATE_ACTIVITY_MODAL";
export const ADD_CANDIDATE_CALL_REQUEST = "ADD_CANDIDATE_CALL_REQUEST";
export const ADD_CANDIDATE_CALL_SUCCESS = "ADD_CANDIDATE_CALL_SUCCESS";
export const ADD_CANDIDATE_CALL_FAILURE = "ADD_CANDIDATE_CALL_FAILURE";

export const ADD_CANDIDATE_EVENT_REQUEST = "ADD_CANDIDATE_EVENT_REQUEST";
export const ADD_CANDIDATE_EVENT_SUCCESS = "ADD_CANDIDATE_EVENT_SUCCESS";
export const ADD_CANDIDATE_EVENT_FAILURE = "ADD_CANDIDATE_EVENT_FAILURE";

export const ADD_CANDIDATE_TASK_REQUEST = "ADD_CANDIDATE_TASK_REQUEST";
export const ADD_CANDIDATE_TASK_SUCCESS = "ADD_CANDIDATE_TASK_SUCCESS";
export const ADD_CANDIDATE_TASK_FAILURE = "ADD_CANDIDATE_TASK_FAILURE";

export const GET_ACTIVITY_LIST_BY_CANDIDATEID_REQUEST =
  "GET_ACTIVITY_LIST_BY_CANDIDATEID_REQUEST";
export const GET_ACTIVITY_LIST_BY_CANDIDATEID_SUCCESS =
  "GET_ACTIVITY_LIST_BY_CANDIDATEID_SUCCESS";
export const GET_ACTIVITY_LIST_BY_CANDIDATEID_FAILURE =
  "GET_ACTIVITY_LIST_BY_CANDIDATEID_FAILURE";


export const SET_TYPE_CHOOSE_CANDIDATE_REQUEST =
  "SET_TYPE_CHOOSE_CANDIDATE_REQUEST";
export const SET_TYPE_CHOOSE_CANDIDATE_SUCCESS =
  "SET_TYPE_CHOOSE_CANDIDATE_SUCCESS";


export const GET_PLACEMENT_REQUEST = "GET_PLACEMENT_REQUEST";
export const GET_PLACEMENT_SUCCESS = "GET_PLACEMENT_SUCCESS";
export const GET_PLACEMENT_FAILURE = "GET_PLACEMENT_FAILURE";

export const HANDLE_CANDIDATE_ACTIVITY_TABLE_MODAL =
  "HANDLE_CANDIDATE_ACTIVITY_TABLE_MODAL";

export const ADD_RESUME_FORM_REQUEST = "ADD_RESUME_FORM_REQUEST";
export const ADD_RESUME_FORM_SUCCESS = "ADD_RESUME_FORM_SUCCESS";
export const ADD_RESUME_FORM_FAILURE = "ADD_RESUME_FORM_FAILURE";

export const SET_CLEARBIT_CANDIDATE_DATA = "SET_CLEARBIT_CANDIDATE_DATA";

export const GET_CANDIDATES_BY_CATEGORY_REQUEST = "GET_CANDIDATES_BY_CATEGORY_REQUEST";
export const GET_CANDIDATES_BY_CATEGORY_SUCCESS = "GET_CANDIDATES_BY_CATEGORY_SUCCESS";
export const GET_CANDIDATES_BY_CATEGORY_FAILURE = "GET_CANDIDATES_BY_CATEGORY_FAILURE";

export const GET_CANDIDATES_TREE_MAP_REQUEST = "GET_CANDIDATES_TREE_MAP_REQUEST";
export const GET_CANDIDATES_TREE_MAP_SUCCESS = "GET_CANDIDATES_TREE_MAP_SUCCESS";
export const GET_CANDIDATES_TREE_MAP_FAILURE = "GET_CANDIDATES_TREE_MAP_FAILURE";

export const GET_CANDIDATE_CATEGORY_RECORDS_REQUEST = "GET_CANDIDATE_CATEGORY_RECORDS_REQUEST";
export const GET_CANDIDATE_CATEGORY_RECORDS_SUCCESS = "GET_CANDIDATE_CATEGORY_RECORDS_SUCCESS";
export const GET_CANDIDATE_CATEGORY_RECORDS_BLUE_SUCCESS = "GET_CANDIDATE_CATEGORY_RECORDS_BLUE_SUCCESS";
export const GET_CANDIDATE_CATEGORY_RECORDS_FAILURE = "GET_CATEGORY_RECORDS_FAILURE";

//EXPERIENCE 
// export const HANDLE_CANDIDATE_EXPERIENCE_MODAL = "HANDLE_CANDIDATE_EXPERIENCE_MODAL";
//add
export const ADD_EXPERIENCE_BY_CANDIDATE_ID_REQUEST =
  "ADD_EXPERIENCE_BY_CANDIDATE_ID_REQUEST";
export const ADD_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS =
  "ADD_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS";
export const ADD_EXPERIENCE_BY_CANDIDATE_ID_FAILURE =
  "ADD_EXPERIENCE_BY_CANDIDATE_ID_FAILURE";

//get
export const GET_EXPERIENCE_BY_CANDIDATE_ID_REQUEST =
  "GET_EXPERIENCE_BY_CANDIDATE_ID_REQUEST";
export const GET_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS =
  "GET_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS";
export const GET_EXPERIENCE_BY_CANDIDATE_ID_FAILURE =
  "GET_EXPERIENCE_BY_CANDIDATE_ID_FAILURE";

//delete
export const DELETE_EXPERIENCE_BY_CANDIDATE_ID_REQUEST =
  "DELETE_EXPERIENCE_BY_CANDIDATE_ID_REQUEST";
export const DELETE_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS =
  "DELETE_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS";
export const DELETE_EXPERIENCE_BY_CANDIDATE_ID_FAILURE =
  "DELETE_EXPERIENCE_BY_CANDIDATE_ID_FAILURE";
// update
export const UPDATE_EXPERIENCE_BY_CANDIDATE_ID_REQUEST =
  "UPDATE_EXPERIENCE_BY_CANDIDATE_ID_REQUEST";
export const UPDATE_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS =
  "UPDATE_EXPERIENCE_BY_CANDIDATE_ID_SUCCESS";
export const UPDATE_EXPERIENCE_BY_CANDIDATE_ID_FAILURE =
  "UPDATE_EXPERIENCE_BY_CANDIDATE_ID_FAILURE";



  export const CANDIDATE_COUNT_SEARCH_DATA_REQUSET =
  "CANDIDATE_COUNT_SEARCH_DATA_REQUSET";
export const CANDIDATE_COUNT_SEARCH_DATA_SUCCESS =
  "CANDIDATE_COUNT_SEARCH_DATA_SUCCESS";
export const CANDIDATE_COUNT_SEARCH_DATA_FAILURE =
  "CANDIDATE_COUNT_SEARCH_DATA_FAILURE";

  export const ADD_CANDIDATE_NOTES_REQUEST = "ADD_CANDIDATE_NOTES_REQUEST";
export const ADD_CANDIDATE_NOTES_SUCCESS = "ADD_CANDIDATE_NOTES_SUCCESS";
export const ADD_CANDIDATE_NOTES_FAILURE = "ADD_CANDIDATE_NOTES_FAILURE";

  export const GET_CANDIDATE_FILTER_REQUEST = "GET_CANDIDATE_FILTER_REQUEST";
  export const GET_CANDIDATE_FILTER_SUCCESS = "GET_CANDIDATE_FILTER_SUCCESS";
  export const GET_CANDIDATE_FILTER_FAILURE = "GET_CANDIDATE_FILTER_FAILURE";

  export const UPDATE_CANDIDATE_OWNERSHIP_REQUEST="UPDATE_CANDIDATE_OWNERSHIP_REQUEST";
  export const UPDATE_CANDIDATE_OWNERSHIP_SUCCESS="UPDATE_CANDIDATE_OWNERSHIP_SUCCESS";
  export const UPDATE_CANDIDATE_OWNERSHIP_FAILURE="UPDATE_CANDIDATE_OWNERSHIP_FAILURE";


  export const HANDLE_CANDIDATE_REACT_SPEECH_MODAL="HANDLE_CANDIDATE_REACT_SPEECH_MODAL"

  export const HANDLE_CHOICE_CANDIDATE_MODAL = "HANDLE_CHOICE_CANDIDATE_MODAL";


  export const CHOOSE_CANDIDATE_REQUEST="CHOOSE_CANDIDATE_REQUEST";
  export const CHOOSE_CANDIDATE_SUCCESS="CHOOSE_CANDIDATE_SUCCESS";
  export const CHOOSE_CANDIDATE_FAILURE="CHOOSE_CANDIDATE_FAILURE";

  export const GET_BLACKLIST_CANDIDATE_REQUEST = "GET_BLACKLIST_CANDIDATE_REQUEST";
export const GET_BLACKLIST_CANDIDATE_SUCCESS = "GET_BLACKLIST_CANDIDATE_SUCCESS";
export const GET_BLACKLIST_CANDIDATE_FAILURE = "GET_BLACKLIST_CANDIDATE_FAILURE";


export const GET_CANDIDATE_TASKS_INFO_REQUEST =
  "GET_CANDIDATE_TASKS_INFO_REQUEST";
export const GET_CANDIDATE_TASKS_INFO_SUCCESS =
  "GET_CANDIDATE_TASKS_INFO_SUCCESS";
export const GET_CANDIDATE_TASKS_INFO_FAILURE =
  "GET_CANDIDATE_TASKS_INFO_FAILURE";


export const HANDLE_DONOT_CALL_MODAL = "HANDLE_DONOT_CALL_MODAL";
export const HANDLE_CANDIDATE_DRAWER_MODAL = "HANDLE_CANDIDATE_DRAWER_MODAL";

export const ADD_DONOT_CALL_REQUEST = "ADD_DONOT_CALL_REQUEST";
export const ADD_DONOT_CALL_SUCCESS = "ADD_DONOT_CALL_SUCCESS";
export const ADD_DONOT_CALL_FAILURE = "ADD_DONOT_CALL_FAILURE";

export const GET_CANDIDATES_BLACKLIST_REQUEST = "GET_CANDIDATES_BLACKLIST_REQUEST";
export const GET_CANDIDATES_BLACKLIST_SUCCESS = "GET_CANDIDATES_BLACKLIST_SUCCESS";
export const GET_CANDIDATES_BLACKLIST_FAILURE = "GET_CANDIDATES_BLACKLIST_FAILURE";

export const ADD_CANDIDATE_EMAIL_REQUEST = "ADD_CANDIDATE_EMAIL_REQUEST";
export const ADD_CANDIDATE_EMAIL_SUCCESS = "ADD_CANDIDATE_EMAIL_SUCCESS";
export const ADD_CANDIDATE_EMAIL_FAILURE = "ADD_CANDIDATE_EMAIL_FAILURE";

export const GET_FILTERED_CONTACT_REQUEST = "GET_FILTERED_CONTACT_REQUEST";
export const GET_FILTERED_CONTACT_SUCCESS = "GET_FILTERED_CONTACT_SUCCESS";
export const GET_FILTERED_CONTACT_FAILURE = "GET_FILTERED_CONTACT_FAILURE";
export const HANDLE_CANDIDATE_ROW_EMAIL_MODAL = "HANDLE_CANDIDATE_ROW_EMAIL_MODAL";
  
export const LINK_EMPLOYEE_STATUS_REQUEST =
  "LINK_EMPLOYEE_STATUS_REQUEST";
export const LINK_EMPLOYEE_STATUS_SUCCESS =
  "LINK_EMPLOYEE_STATUS_SUCCESS";
export const LINK_EMPLOYEE_STATUS_FAILURE =
  "LINK_EMPLOYEE_STATUS_FAILURE";

  export const GET_CANDIDATE_SORT_REQUEST = "GET_CANDIDATE_SORT_REQUEST";
export const GET_CANDIDATE_SORT_SUCCESS = "GET_CANDIDATE_SORT_SUCCESS";
export const GET_CANDIDATE_SORT_FAILURE = "GET_CANDIDATE_SORT_FAILURE";

  export const UPDATE_EXPERIENCE_ROLE_REQUEST="UPDATE_EXPERIENCE_ROLE_REQUEST";
  export const UPDATE_EXPERIENCE_ROLE_SUCCESS="UPDATE_EXPERIENCE_ROLE_SUCCESS";
  export const UPDATE_EXPERIENCE_ROLE_FAILURE="UPDATE_EXPERIENCE_ROLE_FAILURE";

  export const ADD_CERTIFICATION_BY_CANDIDATE_ID_REQUEST =
  "ADD_CERTIFICATION_BY_CANDIDATE_ID_REQUEST";
export const ADD_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS =
  "ADD_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS";
export const ADD_CERTIFICATION_BY_CANDIDATE_ID_FAILURE =
  "ADD_CERTIFICATION_BY_CANDIDATE_ID_FAILURE";

  export const GET_CERTIFICATION_BY_CANDIDATE_ID_REQUEST =
  "GET_CERTIFICATION_BY_CANDIDATE_ID_REQUEST";
export const GET_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS =
  "GET_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS";
export const GET_CERTIFICATION_BY_CANDIDATE_ID_FAILURE =
  "GET_CERTIFICATION_BY_CANDIDATE_ID_FAILURE";

  export const DELETE_CERTIFICATION_BY_CANDIDATE_ID_REQUEST =
  "DELETE_CERTIFICATION_BY_CANDIDATE_ID_REQUEST";
export const DELETE_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS =
  "DELETE_CERTIFICATION_BY_CANDIDATE_ID_SUCCESS";
export const DELETE_CERTIFICATION_BY_CANDIDATE_ID_FAILURE =
  "DELETE_CERTIFICATION_BY_CANDIDATE_ID_FAILURE";


  export const GET_CANDIDATES_PAGINATION_REQUEST = "GET_CANDIDATES_PAGINATION_REQUEST";
export const GET_CANDIDATES_PAGINATION_SUCCESS = "GET_CANDIDATES_PAGINATION_SUCCESS";
export const GET_CANDIDATES_PAGINATION_FAILURE = "GET_CANDIDATES_PAGINATION_FAILURE";







export const GET_CANDIDATES_WHITE_PAGINATION_REQUEST = "GET_CANDIDATES_WHITE_PAGINATION_REQUEST";
export const GET_CANDIDATES_WHITE_PAGINATION_SUCCESS = "GET_CANDIDATES_WHITE_PAGINATION_SUCCESS";
export const GET_CANDIDATES_WHITE_PAGINATION_FAILURE = "GET_CANDIDATES_WHITE_PAGINATION_FAILURE";



export const GET_CANDIDATES_BLUE_PAGINATION_REQUEST = "GET_CANDIDATES_BLUE_PAGINATION_REQUEST";
export const GET_CANDIDATES_BLUE_PAGINATION_SUCCESS = "GET_CANDIDATES_BLUE_PAGINATION_SUCCESS";
export const GET_CANDIDATES_BLUE_PAGINATION_FAILURE = "GET_CANDIDATES_BLUE_PAGINATION_FAILURE";

export const EMPTY_WHITE_CANDIDATE_TABLE = "EMPTY_WHITE_CANDIDATE_TABLE";


export const GET_CANDIDATES_DOLLAR_TABLE_REQUEST = "GET_CANDIDATES_DOLLAR_TABLE_REQUEST";
export const GET_CANDIDATES_DOLLAR_TABLE_SUCCESS = "GET_CANDIDATES_DOLLAR_TABLE_SUCCESS";
export const GET_CANDIDATES_DOLLAR_TABLE_FAILURE = "GET_CANDIDATES_DOLLAR_TABLE_FAILURE";

export const ADD_PARSING_FORM_REQUEST = "ADD_PARSING_FORM_REQUEST";
export const ADD_PARSING_FORM_SUCCESS = "ADD_PARSING_FORM_SUCCESS";
export const ADD_PARSING_FORM_FAILURE = "ADD_PARSING_FORM_FAILURE";


export const HANDLE_CLAER_REDUCER_DATA_CANDIDATE = "HANDLE_CLAER_REDUCER_DATA_CANDIDATE";


