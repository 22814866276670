export const GET_EDUCATION_REQUEST = "GET_EDUCATION_REQUEST";
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS";
export const GET_EDUCATION_FAILURE = "GET_EDUCATION_FAILURE";


export const ADD_EDUCATION_REQUEST = "ADD_EDUCATION_REQUEST";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_FAILURE = "ADD_EDUCATION_FAILURE";

export const UPDATE_EDUCATION_REQUEST = "UPDATE_EDUCATION_REQUEST";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_FAILURE = "UPDATE_EDUCATION_FAILURE";

export const GET_EDUCATION_SEARCH_REQUEST="GET_EDUCATION_SEARCH_REQUEST";
export const GET_EDUCATION_SEARCH_SUCCESS="GET_EDUCATION_SEARCH_SUCCESS";
export const GET_EDUCATION_SEARCH_FAILURE="GET_EDUCATION_SEARCH_FAILURE";

export const REMOVE_EDUCATION_REQUEST = "REMOVE_EDUCATION_REQUEST";
export const REMOVE_EDUCATION_SUCCESS = "REMOVE_EDUCATION_SUCCESS";
export const REMOVE_EDUCATION_FAILURE = "REMOVE_EDUCATION_FAILURE";

export const HANDLE_CLAER_REDUCER_DATA_EDUCATION = "HANDLE_CLAER_REDUCER_DATA_EDUCATION";


export const GET_EDUCATION_COUNT_REQUEST = "GET_EDUCATION_COUNT_REQUEST";
export const GET_EDUCATION_COUNT_SUCCESS = "GET_EDUCATION_COUNT_SUCCESS";
export const GET_EDUCATION_COUNT_FAILURE = "GET_EDUCATION_COUNT_FAILURE";
