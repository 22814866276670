export const ADDING_MODULE_REQUEST =
  "ADDING_MODULE_REQUEST";
export const ADDING_MODULE_SUCCESS =
  "ADDING_MODULE_SUCCESS";
export const ADDING_MODULE_FAILURE =
  "ADDING_MODULE_FAILURE";

  export const GET_MODULE_REQUEST =
  "GET_MODULE_REQUEST";
export const GET_MODULE_SUCCESS =
  "GET_MODULE_SUCCESS";
export const GET_MODULE_FAILURE =
  "GET_MODULE_FAILURE";

  export const HANDLE_STRIPE_MODAL="HANDLE_STRIPE_MODAL";