export const SET_BILLING_VIEW_TYPE = "SET_BILLING_VIEW_TYPE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT = "CHANGE_SELECTED_TIME_INTERVAL_REPORT";

export const GET_BILLING_TABLE_REQUEST = "GET_BILLING_TABLE_REQUEST";
export const GET_BILLING_TABLE_SUCCESS = "GET_BILLING_TABLE_SUCCESS";
export const GET_BILLING_TABLE_FAILURE = "GET_BILLING_TABLE_FAILURE";

export const SET_BILLING_BY_DESIGNATION = "SET_BILLING_BY_DESIGNATION";

export const GET_DESIGNATION_WISE_BILLING_REQUEST = "GET_DESIGNATION_WISE_BILLING_REQUEST";
export const GET_DESIGNATION_WISE_BILLING_SUCCESS = "GET_DESIGNATION_WISE_BILLING_SUCCESS";
export const GET_DESIGNATION_WISE_BILLING_FAILURE = "GET_DESIGNATION_WISE_BILLING_FAILURE";
