export const SET_ORDER_VIEW_TYPE = "SET_ORDER_VIEW_TYPE";
export const HANDLE_ORDER_MODAL = "HANDLE_ORDER_MODAL";

export const GET_ORDER_LIST_REQUEST = "GET_ORDER_LIST_REQUEST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_FAILURE = "GET_ORDER_LIST_FAILURE";

export const GET_DISTRIBUTOR_LIST_REQUEST = "GET_DISTRIBUTOR_LIST_REQUEST";
export const GET_DISTRIBUTOR_LIST_SUCCESS = "GET_DISTRIBUTOR_LIST_SUCCESS";
export const GET_DISTRIBUTOR_LIST_FAILURE = "GET_DISTRIBUTOR_LIST_FAILURE";

export const GET_CUSTOMER_LIST_REQUEST = "GET_CUSTOMER_LIST_REQUEST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";

export const GET_COMPLETE_ORDERS_REQUEST = "GET_COMPLETE_ORDERS_REQUEST";
export const GET_COMPLETE_ORDERS_SUCCESS = "GET_COMPLETE_ORDERS_SUCCESS";
export const GET_COMPLETE_ORDERS_FAILURE = "GET_COMPLETE_ORDERS_FAILURE";

export const EMPTY_ORDERS_LIST = "EMPTY_ORDERS_LIST";

export const EMPTY_MORDERS_LIST = "EMPTY_MORDERS_LIST";

export const GET_ALL_COMPLETE_ORDER_LIST_REQUEST = "GET_ALL_COMPLETE_ORDER_LIST_REQUEST";
export const GET_ALL_COMPLETE_ORDER_LIST_SUCCESS = "GET_ALL_COMPLETE_ORDER_LIST_SUCCESS";
export const GET_ALL_COMPLETE_ORDER_LIST_FAILURE = "GET_ALL_COMPLETE_ORDER_LIST_FAILURE";

export const GET_DISTRIBUTOR_ORDER_LIST_REQUEST = "GET_DISTRIBUTOR_ORDER_LIST_REQUEST";
export const GET_DISTRIBUTOR_ORDER_LIST_SUCCESS = "GET_DISTRIBUTOR_ORDER_LIST_SUCCESS";
export const GET_DISTRIBUTOR_ORDER_LIST_FAILURE = "GET_DISTRIBUTOR_ORDER_LIST_FAILURE";

export const DISTRIBUTOR_DELIVERY_DATE_REQUEST = "DISTRIBUTOR_DELIVERY_DATE_REQUEST";
export const DISTRIBUTOR_DELIVERY_DATE_SUCCESS = "DISTRIBUTOR_DELIVERY_DATE_SUCCESS";
export const DISTRIBUTOR_DELIVERY_DATE_FAILURE = "DISTRIBUTOR_DELIVERY_DATE_FAILURE";


export const CUSTOMER_DELIVERY_DATE_REQUEST = "CUSTOMER_DELIVERY_DATE_REQUEST";
export const CUSTOMER_DELIVERY_DATE_SUCCESS = "CUSTOMER_DELIVERY_DATE_SUCCESS";
export const CUSTOMER_DELIVERY_DATE_FAILURE = "CUSTOMER_DELIVERY_DATE_FAILURE";

export const HANDLE_ORDER_PRODUCT_MODAL = "HANDLE_ORDER_PRODUCT_MODAL";

export const SUBMIT_CUSTOMER_ORDER_ID_REQUEST = "SUBMIT_CUSTOMER_ORDER_ID_REQUEST";
export const SUBMIT_CUSTOMER_ORDER_ID_SUCCESS = "SUBMIT_CUSTOMER_ORDER_ID_SUCCESS";
export const SUBMIT_CUSTOMER_ORDER_ID_FAILURE = "SUBMIT_CUSTOMER_ORDER_ID_FAILURE";

export const GET_ALL_ORDER_LIST_REQUEST = "GET_ALL_ORDER_LIST_REQUEST";
export const GET_ALL_ORDER_LIST_SUCCESS = "GET_ALL_ORDER_LIST_SUCCESS";
export const GET_ALL_ORDER_LIST_FAILURE = "GET_ALL_ORDER_LIST_FAILURE";

export const GET_ALL_HIGH_ORDER_LIST_REQUEST = "GET_ALL_HIGH_ORDER_LIST_REQUEST";
export const GET_ALL_HIGH_ORDER_LIST_SUCCESS = "GET_ALL_HIGH_ORDER_LIST_SUCCESS";
export const GET_ALL_HIGH_ORDER_LIST_FAILURE = "GET_ALL_HIGH_ORDER_LIST_FAILURE";

export const GET_ALL_MEDIUM_ORDER_LIST_REQUEST = "GET_ALL_MEDIUM_ORDER_LIST_REQUEST";
export const GET_ALL_MEDIUM_ORDER_LIST_SUCCESS = "GET_ALL_MEDIUM_ORDER_LIST_SUCCESS";
export const GET_ALL_MEDIUM_ORDER_LIST_FAILURE = "GET_ALL_MEDIUM_ORDER_LIST_FAILURE";

export const GET_ALL_LOW_ORDER_LIST_REQUEST = "GET_ALL_LOW_ORDER_LIST_REQUEST";
export const GET_ALL_LOW_ORDER_LIST_SUCCESS = "GET_ALL_LOW_ORDER_LIST_SUCCESS";
export const GET_ALL_LOW_ORDER_LIST_FAILURE = "GET_ALL_LOW_ORDER_LIST_FAILURE";

export const GET_ORDER_COUNT_REQUEST = "GET_ORDER_COUNT_REQUEST";
export const GET_ORDER_COUNT_SUCCESS = "GET_ORDER_COUNT_SUCCESS";
export const GET_ORDER_COUNT_FAILURE = "GET_ORDER_COUNT_FAILURE";


export const GET_ALL_ORDER_COUNT_REQUEST = "GET_ALL_ORDER_COUNT_REQUEST";
export const GET_ALL_ORDER_COUNT_SUCCESS = "GET_ALL_ORDER_COUNT_SUCCESS";
export const GET_ALL_ORDER_COUNT_FAILURE = "GET_ALL_ORDER_COUNT_FAILURE";

export const HANDLE_NOTES_MODAL_IN_ORDER = "HANDLE_NOTES_MODAL_IN_ORDER";

export const HANDLE_STATUS_OF_ORDER_MODAL = "HANDLE_STATUS_OF_ORDER_MODAL";

export const HANDLE_PAID_BUTTON_MODAL = "HANDLE_PAID_BUTTON_MODAL";

export const GET_PRODUCTION_ORDER_REQUEST = "GET_PRODUCTION_ORDER_REQUEST";
export const GET_PRODUCTION_ORDER_SUCCESS = "GET_PRODUCTION_ORDER_SUCCESS";
export const GET_PRODUCTION_ORDER_FAILURE = "GET_PRODUCTION_ORDER_FAILURE";

export const GET_PRODUCTION_HISTORY_ORDER_REQUEST = "GET_PRODUCTION_HISTORY_ORDER_REQUEST";
export const GET_PRODUCTION_HISTORY_ORDER_SUCCESS = "GET_PRODUCTION_HISTORY_ORDER_SUCCESS";
export const GET_PRODUCTION_HISTORY_ORDER_FAILURE = "GET_PRODUCTION_HISTORY_ORDER_FAILURE";

export const GET_PRODUCTION_ALL_ORDER_REQUEST = "GET_PRODUCTION_ALL_ORDER_REQUEST";
export const GET_PRODUCTION_ALL_ORDER_SUCCESS = "GET_PRODUCTION_ALL_ORDER_SUCCESS";
export const GET_PRODUCTION_ALL_ORDER_FAILURE = "GET_PRODUCTION_ALL_ORDER_FAILURE";

export const DELETE_ORDER_REPAIR_DATA_REQUEST = "DELETE_ORDER_REPAIR_DATA_REQUEST";
export const DELETE_ORDER_REPAIR_DATA_SUCCESS = "DELETE_ORDER_REPAIR_DATA_SUCCESS";
export const DELETE_ORDER_REPAIR_DATA_FAILURE = "DELETE_ORDER_REPAIR_DATA_FAILURE";


export const GET_REPAIR_HIGH_ORDER_LIST_REQUEST = "GET_REPAIR_HIGH_ORDER_LIST_REQUEST";
export const GET_REPAIR_HIGH_ORDER_LIST_SUCCESS = "GET_REPAIR_HIGH_ORDER_LIST_SUCCESS";
export const GET_REPAIR_HIGH_ORDER_LIST_FAILURE = "GET_REPAIR_HIGH_ORDER_LIST_FAILURE";

export const GET_REPAIR_MEDIUM_ORDER_LIST_REQUEST = "GET_REPAIR_MEDIUM_ORDER_LIST_REQUEST";
export const GET_REPAIR_MEDIUM_ORDER_LIST_SUCCESS = "GET_REPAIR_MEDIUM_ORDER_LIST_SUCCESS";
export const GET_REPAIR_MEDIUM_ORDER_LIST_FAILURE = "GET_REPAIR_MEDIUM_ORDER_LIST_FAILURE";

export const GET_REPAIR_LOW_ORDER_LIST_REQUEST = "GET_REPAIR_LOW_ORDER_LIST_REQUEST";
export const GET_REPAIR_LOW_ORDER_LIST_SUCCESS = "GET_REPAIR_LOW_ORDER_LIST_SUCCESS";
export const GET_REPAIR_LOW_ORDER_LIST_FAILURE = "GET_REPAIR_LOW_ORDER_LIST_FAILURE";


export const GET_DELETED_HIGH_ORDER_LIST_REQUEST = "GET_DELETED_HIGH_ORDER_LIST_REQUEST";
export const GET_DELETED_HIGH_ORDER_LIST_SUCCESS = "GET_DELETED_HIGH_ORDER_LIST_SUCCESS";
export const GET_DELETED_HIGH_ORDER_LIST_FAILURE = "GET_DELETED_HIGH_ORDER_LIST_FAILURE";


export const GET_DELETED_MEDIUM_ORDER_LIST_REQUEST = "GET_DELETED_MEDIUM_ORDER_LIST_REQUEST";
export const GET_DELETED_MEDIUM_ORDER_LIST_SUCCESS = "GET_DELETED_MEDIUM_ORDER_LIST_SUCCESS";
export const GET_DELETED_MEDIUM_ORDER_LIST_FAILURE = "GET_DELETED_MEDIUM_ORDER_LIST_FAILURE";

export const GET_DELETED_LOW_ORDER_LIST_REQUEST = "GET_DELETED_LOW_ORDER_LIST_REQUEST";
export const GET_DELETED_LOW_ORDER_LIST_SUCCESS = "GET_DELETED_LOW_ORDER_LIST_SUCCESS";
export const GET_DELETED_LOW_ORDER_LIST_FAILURE = "GET_DELETED_LOW_ORDER_LIST_FAILURE";


export const DELETE_ORDER_DATA_REQUEST = "DELETE_ORDER_DATA_REQUEST";
export const DELETE_ORDER_DATA_SUCCESS = "DELETE_ORDER_DATA_SUCCESS";
export const DELETE_ORDER_DATA_FAILURE = "DELETE_ORDER_DATA_FAILURE";


export const REINSTATE_TOGGLE_FOR_ORDER_REQUEST = "REINSTATE_TOGGLE_FOR_ORDER_REQUEST";
export const REINSTATE_TOGGLE_FOR_ORDER_SUCCESS = "REINSTATE_TOGGLE_FOR_ORDER_SUCCESS";
export const REINSTATE_TOGGLE_FOR_ORDER_FAILURE = "REINSTATE_TOGGLE_FOR_ORDER_FAILURE";


export const GET_COMPLETED_HIGH_ORDER_LIST_REQUEST = "GET_COMPLETED_HIGH_ORDER_LIST_REQUEST";
export const GET_COMPLETED_HIGH_ORDER_LIST_SUCCESS = "GET_COMPLETED_HIGH_ORDER_LIST_SUCCESS";
export const GET_COMPLETED_HIGH_ORDER_LIST_FAILURE = "GET_COMPLETED_HIGH_ORDER_LIST_FAILURE";


export const GET_COMPLETED_MEDIUM_ORDER_LIST_REQUEST = "GET_COMPLETED_MEDIUM_ORDER_LIST_REQUEST";
export const GET_COMPLETED_MEDIUM_ORDER_LIST_SUCCESS = "GET_COMPLETED_MEDIUM_ORDER_LIST_SUCCESS";
export const GET_COMPLETED_MEDIUM_ORDER_LIST_FAILURE = "GET_COMPLETED_MEDIUM_ORDER_LIST_FAILURE";


export const GET_COMPLETED_LOW_ORDER_LIST_REQUEST = "GET_COMPLETED_LOW_ORDER_LIST_REQUEST";
export const GET_COMPLETED_LOW_ORDER_LIST_SUCCESS = "GET_COMPLETED_LOW_ORDER_LIST_SUCCESS";
export const GET_COMPLETED_LOW_ORDER_LIST_FAILURE = "GET_COMPLETED_LOW_ORDER_LIST_FAILURE";

export const EMPTY_COMPLETE_ORDERS_LIST = "EMPTY_COMPLETE_ORDERS_LIST";

export const INPUT_ORDER_NO_SEARCH_REQUEST = "INPUT_ORDER_NO_SEARCH_REQUEST";
export const INPUT_ORDER_NO_SEARCH_SUCCESS = "INPUT_ORDER_NO_SEARCH_SUCCESS";
export const INPUT_ORDER_NO_SEARCH_FAILURE = "INPUT_ORDER_NO_SEARCH_FAILURE";

export const HANDLE_CLAER_SEARCHED_ORDER = "HANDLE_CLAER_SEARCHED_ORDER";


export const GET_ECOM_LIST_REQUEST = "GET_ECOM_LIST_REQUEST";
export const GET_ECOM_LIST_SUCCESS = "GET_ECOM_LIST_SUCCESS";
export const GET_ECOM_LIST_FAILURE = "GET_ECOM_LIST_FAILURE";

export const GET_ECOM_STATUS_ITEM_REQUEST = "GET_ECOM_STATUS_ITEM_REQUEST";
export const GET_ECOM_STATUS_ITEM_SUCCESS = "GET_ECOM_STATUS_ITEM_SUCCESS";
export const GET_ECOM_STATUS_ITEM_FAILURE = "GET_ECOM_STATUS_ITEM_FAILURE";