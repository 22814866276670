export const SET_SUPPLIES_VIEW_TYPE = "SET_SUPPLIES_VIEW_TYPE";

export const HANDLE_SUPPLIES_MODAL = "HANDLE_SUPPLIES_MODAL";

export const HANDLE_BRAND_MODEL = "HANDLE_BRAND_MODEL";

export const ADD_SUPPLIES_REQUEST = "ADD_SUPPLIES_REQUEST";
export const ADD_SUPPLIES_SUCCESS = "ADD_SUPPLIES_SUCCESS";
export const ADD_SUPPLIES_FAILURE = "ADD_SUPPLIES_FAILURE";


export const UPLOAD_MATERIAL_LIST_REQUEST = "UPLOAD_MATERIAL_LIST_REQUEST";
export const UPLOAD_MATERIAL_LIST_SUCCESS = "UPLOAD_MATERIAL_LIST_SUCCESS";
export const UPLOAD_MATERIAL_LIST_FAILURE = "UPLOAD_MATERIAL_LIST_FAILURE";

export const HANDLE_UPLOAD_MATERIAL_MODAL = "HANDLE_UPLOAD_MATERIAL_MODAL";

export const DELETE_SUPPLIES_REQUEST = "DELETE_SUPPLIES_REQUEST";
export const DELETE_SUPPLIES_SUCCESS = "DELETE_SUPPLIES_SUCCESS";
export const DELETE_SUPPLIES_FAILURE = "DELETE_SUPPLIES_FAILURE";

export const ADD_MASTER_LIST_REQUEST = "ADD_MASTER_LIST_REQUEST";
export const ADD_MASTER_LIST_SUCCESS = "ADD_MASTER_LIST_SUCCESS";
export const ADD_MASTER_LIST_FAILURE = "ADD_MASTER_LIST_FAILURE";

export const GET_SUPPLIES_LIST_REQUEST = "GET_SUPPLIES_LIST_REQUEST";
export const GET_SUPPLIES_LIST_SUCCESS = "GET_SUPPLIES_LIST_SUCCESS";
export const GET_SUPPLIES_LIST_FAILURE = "GET_SUPPLIES_LIST_FAILURE";

export const ADD_TO_CATALOGUE_REQUEST = "ADD_TO_CATALOGUE_REQUEST";
export const ADD_TO_CATALOGUE_SUCCESS = "ADD_TO_CATALOGUE_SUCCESS";
export const ADD_TO_CATALOGUE_FAILURE = "ADD_TO_CATALOGUE_FAILURE";

export const HANDLE_UPDATE_SUPPLIES_DRAWER = "HANDLE_UPDATE_SUPPLIES_DRAWER";

export const SET_EDIT_SUPPLIES = "SET_EDIT_SUPPLIES";

export const UPDATE_SUPPLIES_BY_ID_REQUEST = "UPDATE_SUPPLIES_BY_ID_REQUEST";
export const UPDATE_SUPPLIES_BY_ID_SUCCESS = "UPDATE_SUPPLIES_BY_ID_SUCCESS";
export const UPDATE_SUPPLIES_BY_ID_FAILURE = "UPDATE_SUPPLIES_BY_ID_FAILURE";

export const GET_SUPPLIES_HISTORY_REQUEST = "GET_SUPPLIES_HISTORY_REQUEST";
export const GET_SUPPLIES_HISTORY_SUCCESS = "GET_SUPPLIES_HISTORY_SUCCESS";
export const GET_SUPPLIES_HISTORY_FAILURE = "GET_SUPPLIES_HISTORY_FAILURE";

export const HANDLE_DELETE_FEEDBACK_MODAL = "HANDLE_DELETE_FEEDBACK_MODAL";

export const HANDLE_CURENCY_PRICE_MODAL = "HANDLE_CURENCY_PRICE_MODAL";

export const DELETE_PURCHASE_DATA_REQUEST = "DELETE_PURCHASE_DATA_REQUEST";
export const DELETE_PURCHASE_DATA_SUCCESS = "DELETE_PURCHASE_DATA_SUCCESS";
export const DELETE_PURCHASE_DATA_FAILURE = "DELETE_PURCHASE_DATA_FAILURE";

export const GET_DELETE_HISTORY_REQUEST = "GET_DELETE_HISTORY_REQUEST";
export const GET_DELETE_HISTORY_SUCCESS = "GET_DELETE_HISTORY_SUCCESS";
export const GET_DELETE_HISTORY_FAILURE = "GET_DELETE_HISTORY_FAILURE";

export const REINSTATE_TOGGLE_FOR_SUPPLIES_REQUEST = "REINSTATE_TOGGLE_FOR_SUPPLIES_REQUEST";
export const REINSTATE_TOGGLE_FOR_SUPPLIES_SUCCESS = "REINSTATE_TOGGLE_FOR_SUPPLIES_SUCCESS";
export const REINSTATE_TOGGLE_FOR_SUPPLIES_FAILURE = "REINSTATE_TOGGLE_FOR_SUPPLIES_FAILURE";

export const ADD_PRICE_RATE_REQUEST = "ADD_PRICE_RATE_REQUEST";
export const ADD_PRICE_RATE_SUCCESS = "ADD_PRICE_RATE_SUCCESS";
export const ADD_PRICE_RATE_FAILURE = "ADD_PRICE_RATE_FAILURE";

export const GET_SUPPLIES_BY_GROUP_ID_REQUEST = "GET_SUPPLIES_BY_GROUP_ID_REQUEST";
export const GET_SUPPLIES_BY_GROUP_ID_SUCCESS = "GET_SUPPLIES_BY_GROUP_ID_SUCCESS";
export const GET_SUPPLIES_BY_GROUP_ID_FAILURE = "GET_SUPPLIES_BY_GROUP_ID_FAILURE";

export const GET_MATERIAL_PRICE_BY_ID_REQUEST = "GET_MATERIAL_PRICE_BY_ID_REQUEST";
export const GET_MATERIAL_PRICE_BY_ID_SUCCESS = "GET_MATERIAL_PRICE_BY_ID_SUCCESS";
export const GET_MATERIAL_PRICE_BY_ID_FAILURE = "GET_MATERIAL_PRICE_BY_ID_FAILURE";

export const GET_TAGGED_BRAND_BY_ID_REQUEST = "GET_TAGGED_BRAND_BY_ID_REQUEST";
export const GET_TAGGED_BRAND_BY_ID_SUCCESS = "GET_TAGGED_BRAND_BY_ID_SUCCESS";
export const GET_TAGGED_BRAND_BY_ID_FAILURE = "GET_TAGGED_BRAND_BY_ID_FAILURE";

export const GET_BRAND_MODEL_REQUEST = "GET_BRAND_MODEL_REQUEST";
export const GET_BRAND_MODEL_SUCCESS = "GET_BRAND_MODEL_SUCCESS";
export const GET_BRAND_MODEL_FAILURE = "GET_BRAND_MODEL_FAILURE";

export const GET_SUPPLIES_COUNT_REQUEST = "GET_SUPPLIES_COUNT_REQUEST";
export const GET_SUPPLIES_COUNT_SUCCESS = "GET_SUPPLIES_COUNT_SUCCESS";
export const GET_SUPPLIES_COUNT_FAILURE = "GET_SUPPLIES_COUNT_FAILURE";

export const GET_SUPPLIES_DELETED_COUNT_REQUEST = "GET_SUPPLIES_DELETED_COUNT_REQUEST";
export const GET_SUPPLIES_DELETED_COUNT_SUCCESS = "GET_SUPPLIES_DELETED_COUNT_SUCCESS";
export const GET_SUPPLIES_DELETED_COUNT_FAILURE = "GET_SUPPLIES_DELETED_COUNT_FAILURE";

export const HANDLE_MATERIAL_BUILDER_DRAWER = "HANDLE_MATERIAL_BUILDER_DRAWER";

export const ADD_MATERIAL_BUILDER_REQUEST = "ADD_MATERIAL_BUILDER_REQUEST";
export const ADD_MATERIAL_BUILDER_SUCCESS = "ADD_MATERIAL_BUILDER_SUCCESS";
export const ADD_MATERIAL_BUILDER_FAILURE = "ADD_MATERIAL_BUILDER_FAILURE";

export const GET_MATERIAL_BUILDER_BYID_REQUEST = "GET_MATERIAL_BUILDER_BYID_REQUEST";
export const GET_MATERIAL_BUILDER_BYID_SUCCESS = "GET_MATERIAL_BUILDER_BYID_SUCCESS";
export const GET_MATERIAL_BUILDER_BYID_FAILURE = "GET_MATERIAL_BUILDER_BYID_FAILURE";

export const GET_SEARCH_MATERIAL_BUILDER_REQUEST = "GET_SEARCH_MATERIAL_BUILDER_REQUEST";
export const GET_SEARCH_MATERIAL_BUILDER_SUCCESS = "GET_SEARCH_MATERIAL_BUILDER_SUCCESS";
export const GET_SEARCH_MATERIAL_BUILDER_FAILURE = "GET_SEARCH_MATERIAL_BUILDER_FAILURE";

export const REMOVE_MATERIAL_BUILDER_REQUEST = "REMOVE_MATERIAL_BUILDER_REQUEST";
export const REMOVE_MATERIAL_BUILDER_SUCCESS = "REMOVE_MATERIAL_BUILDER_SUCCESS";
export const REMOVE_MATERIAL_BUILDER_FAILURE = "REMOVE_MATERIAL_BUILDER_FAILURE";

export const UPDATE_MATERIAL_BUILDER_REQUEST = "UPDATE_MATERIAL_BUILDER_REQUEST";
export const UPDATE_MATERIAL_BUILDER_SUCCESS = "UPDATE_MATERIAL_BUILDER_SUCCESS";
export const UPDATE_MATERIAL_BUILDER_FAILURE = "UPDATE_MATERIAL_BUILDER_FAILURE";

export const HANDLE_SUPPLIERSLIST_DRAWER="HANDLE_SUPPLIERSLIST_DRAWER";

export const GET_SUPPLIES_SUPPLIERS_REQUEST = "GET_SUPPLIES_SUPPLIERS_REQUEST";
export const GET_SUPPLIES_SUPPLIERS_SUCCESS = "GET_SUPPLIES_SUPPLIERS_SUCCESS";
export const GET_SUPPLIES_SUPPLIERS_FAILURE = "GET_SUPPLIES_SUPPLIERS_FAILURE";

export const SET_SUPPLIES_SUPPLIER_REQUEST="SET_SUPPLIES_SUPPLIER_REQUEST";
export const SET_SUPPLIES_SUPPLIER_SUCCESS="SET_SUPPLIES_SUPPLIER_SUCCESS";
export const SET_SUPPLIES_SUPPLIER_FAILURE="SET_SUPPLIES_SUPPLIER_FAILURE";


export const LINK_MATERIAL_TOGGLE_REQUEST =
  "LINK_MATERIAL_TOGGLE_REQUEST";
export const LINK_MATERIAL_TOGGLE_SUCCESS =
  "LINK_MATERIAL_TOGGLE_SUCCESS";
export const LINK_MATERIAL_TOGGLE_FAILURE =
  "LINK_MATERIAL_TOGGLE_FAILURE";


  export const LINK_MATERIAL_FIFO_TOGGLE_REQUEST =
  "LINK_MATERIAL_FIFO_TOGGLE_REQUEST";
export const LINK_MATERIAL_FIFO_TOGGLE_SUCCESS =
  "LINK_MATERIAL_FIFO_TOGGLE_SUCCESS";
export const LINK_MATERIAL_FIFO_TOGGLE_FAILURE =
  "LINK_MATERIAL_FIFO_TOGGLE_FAILURE";

  export const HANDLE_CLAER_REDUCER_DATA_MATERIAL = "HANDLE_CLAER_REDUCER_DATA_MATERIAL";

  export const INPUT_SUPPLIES_SEARCH_DATA_REQUEST =
  "INPUT_SUPPLIES_SEARCH_DATA_REQUEST";
export const INPUT_SUPPLIES_SEARCH_DATA_SUCCESS =
  "INPUT_SUPPLIES_SEARCH_DATA_SUCCESS";
export const INPUT_SUPPLIES_SEARCH_DATA_FAILURE =
  "INPUT_SUPPLIES_SEARCH_DATA_FAILURE";

  export const HANDLE_MATERIAL_INVENTORY = "HANDLE_MATERIAL_INVENTORY";

  export const GET_MATERIAL_INVENTORY_REQUEST="GET_MATERIAL_INVENTORY_REQUEST";
  export const GET_MATERIAL_INVENTORY_SUCCESS="GET_MATERIAL_INVENTORY_SUCCESS";
  export const GET_MATERIAL_INVENTORY_FAILURE="GET_MATERIAL_INVENTORY_FAILURE";

  export const HANDLE_PRICE_MODAL = 'HANDLE_PRICE_MODAL';

  export const CREATE_MATERIAL_CURRENCY_REQUEST = "CREATE_MATERIAL_CURRENCY_REQUEST";
export const CREATE_MATERIAL_CURRENCY_SUCCESS = "CREATE_MATERIAL_CURRENCY_SUCCESS";
export const CREATE_MATERIAL_CURRENCY_FAILURE = "CREATE_MATERIAL_CURRENCY_FAILURE";

export const GET_MATERIAL_CURRENCY_REQUEST = "GET_MATERIAL_CURRENCY_REQUEST";
export const GET_MATERIAL_CURRENCY_SUCCESS = "GET_MATERIAL_CURRENCY_SUCCESS";
export const GET_MATERIAL_CURRENCY_FAILURE = "GET_MATERIAL_CURRENCY_FAILURE";

export const CREATE_MATERIAL_DISCOUNT_REQUEST = "CREATE_MATERIAL_DISCOUNT_REQUEST";
export const CREATE_MATERIAL_DISCOUNT_SUCCESS = "CREATE_MATERIAL_DISCOUNT_SUCCESS";
export const CREATE_MATERIAL_DISCOUNT_FAILURE = "CREATE_MATERIAL_DISCOUNT_FAILURE";

export const GET_MATERIAL_DISCOUNT_REQUEST = "GET_MATERIAL_DISCOUNT_REQUEST";
export const GET_MATERIAL_DISCOUNT_SUCCESS = "GET_MATERIAL_DISCOUNT_SUCCESS";
export const GET_MATERIAL_DISCOUNT_FAILURE = "GET_MATERIAL_DISCOUNT_FAILURE";

export const CREATE_MATERIAL_DISCOUNTB2C_REQUEST = "CREATE_MATERIAL_DISCOUNTB2C_REQUEST";
export const CREATE_MATERIAL_DISCOUNTB2C_SUCCESS = "CREATE_MATERIAL_DISCOUNTB2C_SUCCESS";
export const CREATE_MATERIAL_DISCOUNTB2C_FAILURE = "CREATE_MATERIAL_DISCOUNTB2C_FAILURE";

export const GET_MATERIAL_DISCOUNTB2C_REQUEST = "GET_MATERIAL_DISCOUNTB2C_REQUEST";
export const GET_MATERIAL_DISCOUNTB2C_SUCCESS = "GET_MATERIAL_DISCOUNTB2C_SUCCESS";
export const GET_MATERIAL_DISCOUNTB2C_FAILURE = "GET_MATERIAL_DISCOUNTB2C_FAILURE";

export const FEATURED_MATERIAL_TOGGLE_REQUEST =
  "FEATURED_MATERIAL_TOGGLE_REQUEST";
export const FEATURED_MATERIAL_TOGGLE_SUCCESS =
  "FEATURED_MATERIAL_TOGGLE_SUCCESS";
export const FEATURED_MATERIAL_TOGGLE_FAILURE =
  "FEATURED_MATERIAL_TOGGLE_FAILURE";

export const GET_MATERIAL_CATEGORY_REQUEST = "GET_MATERIAL_CATEGORY_REQUEST";
export const GET_MATERIAL_CATEGORY_SUCCESS = "GET_MATERIAL_CATEGORY_SUCCESS";
export const GET_MATERIAL_CATEGORY_FAILURE = "GET_MATERIAL_CATEGORY_FAILURE";
export const ADD_MATERIAL_CATEGORY_REQUEST = "ADD_MATERIAL_CATEGORY_REQUEST";
export const ADD_MATERIAL_CATEGORY_SUCCESS = "ADD_MATERIAL_CATEGORY_SUCCESS";
export const ADD_MATERIAL_CATEGORY_FAILURE = "ADD_MATERIAL_CATEGORY_FAILURE";

export const MATERIAL_CATEGORY_SEARCH_REQUEST =
"MATERIAL_CATEGORY_SEARCH_REQUEST";
export const MATERIAL_CATEGORY_SEARCH_SUCCESS =
"MATERIAL_CATEGORY_SEARCH_SUCCESS";
export const MATERIAL_CATEGORY_SEARCH_FAILURE =
"MATERIAL_CATEGORY_SEARCH_FAILURE";

export const GET_MATERIALS_BY_SUPPLIES_ID_REQUEST = "GET_MATERIALS_BY_SUPPLIES_ID_REQUEST";
export const GET_MATERIALS_BY_SUPPLIES_ID_SUCCESS = "GET_MATERIALS_BY_SUPPLIES_ID_SUCCESS";
export const GET_MATERIALS_BY_SUPPLIES_ID_FAILURE = "GET_MATERIALS_BY_SUPPLIES_ID_FAILURE";

export const SUPPLIES_PUNBLISH_TOGGLE_REQUEST = "SUPPLIES_PUNBLISH_TOGGLE_REQUEST";
export const SUPPLIES_PUNBLISH_TOGGLE_SUCCESS = "SUPPLIES_PUNBLISH_TOGGLE_SUCCESS";
export const SUPPLIES_PUNBLISH_TOGGLE_FAILURE = "SUPPLIES_PUNBLISH_TOGGLE_FAILURE";

export const MATERIAL_RECOMMEND_TOGGLE_REQUEST = "MATERIAL_RECOMMEND_TOGGLE_REQUEST";
export const MATERIAL_RECOMMEND_TOGGLE_SUCCESS = "MATERIAL_RECOMMEND_TOGGLE_SUCCESS";
export const MATERIAL_RECOMMEND_TOGGLE_FAILURE = "MATERIAL_RECOMMEND_TOGGLE_FAILURE";

export const MATERIAL_PRICE_TYPE_REQUEST = "MATERIAL_PRICE_TYPE_REQUEST";
export const MATERIAL_PRICE_TYPE_SUCCESS = "MATERIAL_PRICE_TYPE_SUCCESS";
export const MATERIAL_PRICE_TYPE_FAILURE = "MATERIAL_PRICE_TYPE_FAILURE";
